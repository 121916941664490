.edge-section {
  padding: 130px 200px 50px;
  background: #fff;
  border-radius: 15px;
}

.qr-box {
  width: 100%;
  height: 230px;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.project-slider {
  padding: 85px 50px;
  background: #F0F0F0;
}

.section-title {
  font-size: 56px;
  line-height: 75px;
  font-weight: 500;
  margin-bottom: 25px;
}

.project-box-wrap {
  width: 100%;
  height: 110px;
  position: relative;
  background: #FFF;
  box-shadow: 5px 5px 45px 0px rgba(0,0,0,0.06);
}

.project-logo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  position: relative;
  z-index: 1;
}

.project-about {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 100px;
  left: 0;
  background: rgb(240,81,57);
  background: linear-gradient(90deg, rgba(240,81,57,0.95) 0%, rgba(240,134,57,0.95) 100%);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all .3s cubic-bezier(0.23, 1, 0.320, 1);
}

.project-about p {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  color: #FFF;
}

@media (max-width: 1440px) {
  .qr-box {
    height: auto;
  }
}
.qr-box img {
  height: 100%;
}
@media (max-width: 1440px) {
  .qr-box img {
    height: auto;
    width: 100%;
  }
}
.qr-box-wrap {
  background: #f05139;
  background: linear-gradient(175deg, #f05139 0%, #f08439 100%);
  padding: 30px;
  border-radius: 15px;
  height: 100%;
}
.qr-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 30px !important;
  color: #fff;
}
.qr-logo-box {
  width: 100%;
  height: 170px;
  border-radius: 0px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1440px) {
  .qr-logo-box {
    height: 65px;
  }
  .qr-title {
    font-size: 16px;
  }
}
.qr-logo-box img {
  width: 50%;
}

.product-box-wrap {
  position: relative;
  z-index: 5;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

@media (max-width: 1680px) {
  .edge-section {
    padding: 130px 130px 50px;
  }
}

@media (max-width: 1440px) {
  .qr-box-wrap {
    padding: 25px;
  }
  .connect-now {
    padding: 10px;
  }
}

@media (max-width: 1280px) {
  .qr-box-wrap {
    padding: 20px;
  }
  .qr-title {
    font-size: 14px;
    margin-bottom: 20px !important;
  }
  .edge-section {
    padding: 110px 110px 50px;
  }
}

@media (max-width: 1024px) {
  .mt-25px {
    margin-top: 25px;
  }

  .mb-25px {
    margin-bottom: 25px;
  }

  .edge-section {
    padding: 130px 100px 50px;
  }
}

@media (max-width: 992px) {
  .edge-section {
    padding: 130px 70px 50px;
  }

  .mt-md-25px {
    margin-top: 25px;
  }
}

@media (max-width: 600px) {
  .mt-sm-20px {
    margin-top: 20px;
  }

  .edge-section {
    padding: 50px;
  }
}
.hidden {
  display: none !important;
}

.modal-dialog {
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
}
.modal-content {
  border: none;
}
.modal-header {
  border-bottom: none;
  padding: 0;
}

.modal .close {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #D2D2D2;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #D2D2D2;
  opacity: 1;
  font-size: 18px;
  transition: all .3s ease;
}
button:focus {
  outline: none;
}
.modal .close:hover {
  color: #000;
  border-color: #000;
}
.modal-head-logo {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #D2D2D2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}
.modal-head-logo img {
  width: 70px;
}

.form-control {
  padding: 25px;
  margin-bottom: 15px;
  width: 100%;
  border: 1px solid #D2D2D2;
  border-radius: 5px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 200;
}
.modal-body {
  padding: 0;
}
.modal button.submit-btn {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  background: #2F61CB;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  color: #fff;
  transition: all .3s ease;
}
.modal button.submit-btn:hover {
  background-color: rgba(47, 97, 203, 0.3);
  color: #2F61CB;
}
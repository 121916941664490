@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://cdn.jsdelivr.net/npm/animate.css@3.5.2/animate.min.css);
@font-face {
  font-family: Mulish;
  src: url(../fonts/Mulish-Regular.woff2) format("woff2"),
    url(../fonts/Mulish-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display Bold";
  src: url(../fonts/SFProDisplayBold.woff2) format("woff2"),
    url(../fonts/SFProDisplayBold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}
@font-face {
  font-family: "SF Pro Display Light";
  src: url(../fonts/SFProDisplayLight.woff2) format("woff2"),
    url(../fonts/SFProDisplayLight.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}
@font-face {
  font-family: "SF Pro Display Regular";
  src: url(../fonts/SFProDisplayRegular.woff2) format("woff2"),
    url(../fonts/SFProDisplayRegular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}
@font-face {
  font-family: "SF Pro Display Semibold";
  src: url(../fonts/SFProDisplaySemibold.woff2) format("woff2"),
    url(../fonts/SFProDisplaySemibold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}
@font-face {
  font-family: "Muli Regular";
  src: url(../fonts/MuliRegular.woff2) format("woff2"),
    url(../fonts/MuliRegular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-03BC;
}
@font-face {
  font-family: mulilight;
  src: url(../fonts/web-fonts/muli-light.woff2) format("woff2"),
    url(../fonts/web-fonts/muli-light.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: muliregular;
  src: url(../fonts/web-fonts/muli.woff2) format("woff2"),
    url(../fonts/web-fonts/muli.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: mulibold;
  src: url(../fonts/web-fonts/muli-bold.woff2) format("woff2"),
    url(../fonts/web-fonts/muli-bold.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: mulisemibold;
  src: url(../fonts/web-fonts/muli-semibold.woff2) format("woff2"),
    url(../fonts/web-fonts/muli-semibold.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: barlow_condensedblack;
  src: url(../fonts/web-fonts/barlowcondensed-black.woff2) format("woff2"),
    url(../fonts/web-fonts/barlowcondensed-black.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: barlow_condensedbold;
  src: url(../fonts/web-fonts/barlowcondensed-bold.woff2) format("woff2"),
    url(../fonts/web-fonts/barlowcondensed-bold.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: barlow_condensedlight;
  src: url(../fonts/web-fonts/barlowcondensed-light.woff2) format("woff2"),
    url(../fonts/web-fonts/barlowcondensed-light.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: barlow_condensedregular;
  src: url(../fonts/web-fonts/barlowcondensed-regular.woff2) format("woff2"),
    url(../fonts/web-fonts/barlowcondensed-regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: barlow_condensedsemibold;
  src: url(../fonts/web-fonts/barlowcondensed-semibold.woff2) format("woff2"),
    url(../fonts/web-fonts/barlowcondensed-semibold.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: barlow_condensedSBdIt;
  src: url(../fonts/web-fonts/barlowcondensed-semibolditalic.woff2)
      format("woff2"),
    url(../fonts/web-fonts/barlowcondensed-semibolditalic.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: barlow_condensedthin;
  src: url(../fonts/web-fonts/barlowcondensed-thin.woff2) format("woff2"),
    url(../fonts/web-fonts/barlowcondensed-thin.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: barlow_condensedthin_italic;
  src: url(../fonts/web-fonts/barlowcondensed-thinitalic.woff2) format("woff2"),
    url(../fonts/web-fonts/barlowcondensed-thinitalic.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Barlow;
  src: url(../font/BarlowCondensed-Medium.ttf);
}

:root {
  --heading-color: "#37517e";
  --primary: "#f05139";
}
body::-webkit-scrollbar {
  -webkit-appearance: none !important;
  width: 8px !important;
}
body::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}
.o_web .address-input {
  width: 100%;
  padding: 0.7rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 1rem;
}
.o_web .nav {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.o_web button {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0 !important;
}
.o_web a {
  color: #fc3044;
}
.o_web a:hover {
  color: #dc1e31;
}
.o_web a {
  text-decoration: none !important;
}
.o_web p {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 12px;
  color: #8c8c8c;
  letter-spacing: 0.5px;
  line-height: 20px;
  padding-top: 1rem;
}
.o_web .header {
  background: #fff;
  height: 62px;
  -webkit-box-shadow: 1px 1px 8px rgba(255, 255, 255, 0.01);
  box-shadow: 1px 1px 8px rgba(255, 255, 255, 0.01);
  padding: 22px 0;
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 99;
}
.o_web .header nav {
  margin-top: -0.5rem;
}
.o_web .swap-input {
  margin-bottom: 20px;
}
.o_web .arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.o_web .swap-card {
  -webkit-box-shadow: rgba(0, 0, 0, 0.01) 0 0 1px, rgba(0, 0, 0, 0.04) 0 4px 8px,
    rgba(0, 0, 0, 0.04) 0 16px 24px, rgba(0, 0, 0, 0.01) 0 24px 32px !important;
  box-shadow: rgba(0, 0, 0, 0.01) 0 0 1px, rgba(0, 0, 0, 0.04) 0 4px 8px,
    rgba(0, 0, 0, 0.04) 0 16px 24px, rgba(0, 0, 0, 0.01) 0 24px 32px !important;
  padding: 1rem;
  border-radius: 12px !important;
}
.o_web .sharpened {
  border-radius: 0 0 12px 12px !important;
}
.o_web .asset-dropdown {
  width: 21.75rem;
  height: 400px;
  background-color: #fff;
  -webkit-box-shadow: 6px 9px 20px #00000029 !important;
  box-shadow: 6px 9px 20px #00000029 !important;
  -webkit-transform: translateY(60px);
  transform: translateY(60px);
  border-radius: 12px;
  overflow: hidden;
  z-index: 1;
  display: none;
  position: absolute;
}
.o_web .buy-dropdown {
  width: 15rem;
  height: 200px;
  background-color: #fff;
  -webkit-box-shadow: 6px 9px 20px #00000029 !important;
  box-shadow: 6px 9px 20px #00000029 !important;
  -webkit-transform: translateY(60px);
  transform: translateY(60px);
  border-radius: 12px;
  overflow: hidden;
  z-index: 1;
  display: none;
  position: absolute;
}

.o_web .pool-dropdown {
  width: 23rem;
  height: 200px;
  background-color: #fff;
  -webkit-box-shadow: 6px 9px 20px #00000029 !important;
  box-shadow: 6px 9px 20px #00000029 !important;
  -webkit-transform: translateY(60px);
  transform: translateY(60px);
  border-radius: 12px;
  overflow: hidden;
  z-index: 1;
  display: none;
  position: absolute;
}

@media screen and (max-width: 576px) {
  .o_web .pool-dropdown {
    width: 15rem;
  }
}

@media (max-width: 321px) and (orientation: portrait) {
  span.wallet-address {
    font-size: 0.8em !important;
  }
}

@media (min-width: 319px) and (max-width: 414px) and (orientation: portrait) {
  span.wallet-address {
    font-size: 0.9em !important;
  }

  .ambassador-btn {
    float: right;
  }
}
.o_web .individual-token {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0.5rem;
}
.o_web .input-with-dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.o_web .input-with-dropdown input {
  padding: 1rem;
  border: none;
  width: 100%;
  height: 3.5rem;
  border-radius: 12px;
  /* font-size: 18px; */
  font-weight: 300;
  -webkit-box-shadow: 6px 9px 20px #00000029 !important;
  box-shadow: 6px 9px 20px #00000029 !important;
}
.o_web .form-label {
  font-size: 14px;
  font-weight: 500;
  color: #989898;
}
.o_web .dropdown-btn {
  background-color: #fff;
  border: none;
  -webkit-box-shadow: 6px 9px 20px #00000029 !important;
  box-shadow: 6px 9px 20px #00000029 !important;
  padding: 0.8rem 0.75rem;
  width: 7rem;
  height: 3.5rem;
  margin-right: 8px;
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.o_web .dropdown-btn:focus {
  border: 1px solid #ef4b23;
}

.dropdown-item {
  margin: 0;
}
.dropdown-item:hover {
  color: #989898;
}
.o_web .individual-token:hover,
.individual-pool:hover {
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
  cursor: pointer;
}
.o_web .asset-dropdown .individual-token:nth-child(even) {
  background-color: #f8f8f8 !important;
}
.o_web .asset-dropdown .individual-token:nth-child(even):hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.o_web .individual-token p {
  font-size: 15px;
  font-weight: 700;
}

p.pool-p {
  font-family: "SF Pro Display Bold";
  padding-left: 15px;
  padding-right: 15px;
  line-height: 1em;
  margin: 0;
  font-size: 0.9rem !important;
}

span.pool-span {
  font-family: "SF Pro Display Regular" !important;
  padding-left: 15px;
  padding-right: 15px;
  color: #989898;
  font-size: 0.9em !important;
}
.o_web .individual-token img {
  margin-right: 0.5rem;
  width: 20px;
  height: 20px;
}
.o_web .MuiOutlinedInput-root {
  border-radius: none !important;
}
.o_web .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.o_web .header {
  -webkit-box-shadow: 6px 9px 20px 5px #00000029 !important;
  box-shadow: 6px 9px 20px 5px #00000029 !important;
}
.o_web .header .navbar {
  padding: 0;
  background-color: #fff;
  width: 100%;
}
.o_web .header .navbar .nav-wrapper {
  width: 100%;
}
.o_web .header .navbar .navbar-brand {
  padding: 0;
  margin-right: 54px;
}

.btn-outlinegray {
  border: 2px solid #5a5a5a;
  font-size: 12px;
  line-height: 19px;
  font-family: mulibold;
  color: #5a5a5a !important;
  border-radius: 33px;
  -webkit-border-radius: 33px;
  -moz-border-radius: 33px;
  padding: 6px 30px;
  display: inline-block;
}

.btn-outlinegray:hover {
  background: #5a5a5a;
  color: #fff !important;
}

@media screen and (min-width: 1199px) {
  .o_web .header .navbar .ascollapse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    width: 100%;
  }
}
@media screen and (max-width: 1199px) {
  .o_web .header .navbar .ascollapse {
    padding: 30px;
    text-align: right;
  }
}
@media screen and (max-width: 1199px) {
  .o_web .header .navbar .frshdw {
    -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  }
}
.o_web .header .asnavbar.navbar-expand-xl .navbar-nav .nav-item {
  margin-right: 45px;
}
@media screen and (max-width: 1199px) {
  .o_web .header .asnavbar.navbar-expand-xl .navbar-nav .nav-item {
    margin-right: 0;
  }
}
.o_web .header .asnavbar.navbar-expand-xl .navbar-nav .nav-item .nav-link {
  padding: 7px 0;
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 15.5px;
  letter-spacing: 0.2px;
  color: #989898;
  text-transform: capitalize;
}
.o_web
  .header
  .asnavbar.navbar-expand-xl
  .navbar-nav
  .nav-item
  .nav-link:hover {
  color: #0f1740;
}
.o_web
  .header
  .asnavbar.navbar-expand-xl
  .navbar-nav
  .nav-item.active
  .nav-link {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  color: #f08639;
}
.o_web .right_nave {
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 1200px) {
  .o_web .right_nave {
    margin: 10px 0 0 0;
  }
}
.o_web .right_nave li {
  display: inline-block;
  margin-left: 55px;
  vertical-align: top;
}
@media screen and (max-width: 480px) {
  .o_web .right_nave li {
    margin-left: 18px;
  }
}
.o_web .right_nave li a {
  display: block;
  height: 27px;
  width: 27px;
  background: url(../images/sprite_img.svg) no-repeat 0 0;
  background-size: 500px;
}
.o_web .rn_ic_1 span {
  display: none;
  position: relative;
  font-size: 12px;
  top: 1.8rem;
  left: -6px;
  color: #989898;
}
.o_web .rn_ic_2 span {
  display: none;
  position: relative;
  font-size: 12px;
  top: 1.55rem;
  left: 2px;
  color: #989898;
}
.o_web .rn_ic_3 span {
  display: none;
  position: relative;
  font-size: 12px;
  top: 1.5rem;
  left: -2.5px;
  color: #989898;
}
.o_web .rn_ic_4 span {
  display: none;
  position: relative;
  font-size: 12px;
  top: 1.5rem;
  left: -7px;
  color: #989898;
}
.o_web .rn_ic_1:hover span,
.o_web .rn_ic_2:hover span,
.o_web .rn_ic_3:hover span,
.o_web .rn_ic_4:hover span {
  color: #f08639;
}
.o_web .app-bar {
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f08639),
      to(#f07039)
    ) 0 0 no-repeat padding-box;
  background: transparent linear-gradient(180deg, #f08639 0, #f07039 100%) 0 0
    no-repeat padding-box;
  height: 4rem;
  padding: 0 2rem;
}
.o_web .right_nave li a.rn_ic_1 {
  background-position: -6px -64px;
  line-height: 0.9rem;
}
.o_web .right_nave li a.rn_ic_1.active,
.o_web .right_nave li a.rn_ic_1:hover {
  background-position: -6px -104px;
}
.o_web .right_nave li a.rn_ic_2 {
  background-position: -54px -64px;
}
.o_web .right_nave li a.rn_ic_2.active,
.o_web .right_nave li a.rn_ic_2:hover {
  background-position: -54px -104px;
}
.o_web .right_nave li a.rn_ic_3 {
  background-position: -110px -64px;
}
.o_web .right_nave li a.rn_ic_3.active,
.o_web .right_nave li a.rn_ic_3:hover {
  background-position: -110px -104px;
}
.o_web .right_nave li a.rn_ic_4 {
  background-position: -166px -64px;
}
.o_web .right_nave li a.rn_ic_4.active,
.o_web .right_nave li a.rn_ic_4:hover {
  background-position: -166px -104px;
}
.o_web .right_nave li a.rn_ic_5 {
  background-position: -237px -64px;
}
.o_web .right_nave li a.rn_ic_5.active,
.o_web .right_nave li a.rn_ic_5:hover {
  background-position: -237px -104px;
}
.o_web .right_nave li:first-child {
  margin-left: 0;
}
.o_web .ftr_btm {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f08639),
      to(#f07039)
    ) 0 0 no-repeat padding-box;
  background: transparent linear-gradient(180deg, #f08639 0, #f07039 100%) 0 0
    no-repeat padding-box;
  padding: 15px 0;
  z-index: 19;
}
.o_web .ftr_btm .d-flex {
  max-width: 670px;
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media screen and (max-width: 480px) {
  .o_web .ftr_btm .d-flex {
    display: block !important;
  }
}
.o_web .ftr_btm .d-flex p {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.3px;
  color: #fff;
  margin-top: 0 !important;
}
@media screen and (max-width: 480px) {
  .o_web .ftr_btm .d-flex p {
    margin: 0 0 15px 0;
  }
}
.o_web .ftr_btm .d-flex .btn-accept {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #fff;
  border: 1px solid;
  border-radius: 35px;
  border-color: #fff;
  width: 155px;
  height: 35px;
}
.o_web .ftr_btm .d-flex .btn-accept:hover {
  border-color: #ffac81;
}
.o_web .content {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 50px;
}
@media screen and (max-width: 767px) {
  .o_web .content {
    padding-top: 30px;
  }
}
@media screen and (max-width: 480px) {
  .o_web .content {
    padding-bottom: 50px;
  }
}
.o_web .max-480 {
  max-width: 480px;
  margin: 0 auto;
}
.o_web ::-webkit-input-placeholder {
  color: #989898 !important;
}
.o_web :-ms-input-placeholder {
  color: #989898 !important;
}
.o_web ::-ms-input-placeholder {
  color: #989898 !important;
}
.o_web ::placeholder {
  color: #989898 !important;
}
.o_web .def_ttl {
  font-family: "Muli Regular", Mulish, sans-serif;
  font-size: 14px;
  letter-spacing: 4.2px;
  color: var(--heading-color);
  text-transform: uppercase;
  margin-bottom: 50px;
  font-weight: 600;
  color: #707070;
}
.o_web .mx-400 {
  max-width: 380px;
  margin: 0 auto;
}
.o_web .def_tab_v1 {
  border-bottom: none;
  margin-bottom: 10px;
}
.o_web .def_tab_v1 .nav-item:first-child {
  margin-right: 120px;
}
.o_web .def_tab_v1 .nav-item .nav-link {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.36px;
  color: #989898;
  border: none;
  border-radius: 0;
  padding: 0 5px;
  background: 0 0;
}
.o_web .def_tab_v1 .nav-item .nav-link.active {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  color: #0f1740;
  font-size: 15px;
}
.focussable-link:focus,
.o_web .def_tab_v1 .nav-item .nav-link:focus {
  color: #ef4b23 !important;
}
.focussable-button:focus {
  text-decoration: underline;
}
.o_web .def_wt_bx {
  margin-top: 10px;
  margin-bottom: 5px;
  background: #fff 0 0 no-repeat padding-box;
  border-radius: 12px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.01) 0 0 1px, rgba(0, 0, 0, 0.04) 0 4px 8px,
    rgba(0, 0, 0, 0.04) 0 16px 24px, rgba(0, 0, 0, 0.01) 0 24px 32px !important;
  box-shadow: 6px 20px 20px 5px #00000029 !important;
  padding: 25px 12px 30px 12px;
  position: relative;
}
.o_web .react-tel-input .form-control {
  width: 100% !important;
  height: 3.5rem !important;
  background-color: #fff !important;
}
.o_web .form-control {
  border: 1px solid #f2f5f7 !important;
}
.o_web .def_form .form-group .form-control {
  background-color: #fff !important;
  border: 1px solid #f8f8f8;
  border-radius: 12px;
  height: 3.5rem;
  color: #0f1740;
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 15px;
  position: relative;
  -webkit-appearance: none !important;
  -webkit-box-shadow: 6px 9px 20px #00000029 !important;
  box-shadow: 6px 9px 20px #00000029 !important;
}
.o_web .form-control:disabled {
  background-color: #fff !important;
}
.o_web .jJcagm {
  height: 1.5rem !important;
}
.o_web .def_form .form-group .checkbox {
  padding-left: 20px;
}
.o_web .def_form .form-group .checkbox input[type="checkbox"] {
  opacity: 0;
  z-index: 1;
  position: absolute;
  margin-left: -20px;
}
.o_web .def_form .form-group .checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 10px;
  color: #989898;
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0.3px;
  cursor: pointer;
  margin-bottom: 0;
  padding-top: 3px;
}
.o_web .def_form .form-group .checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  top: 2px;
  margin-left: -20px;
  background: #f4f4f4;
  -webkit-box-shadow: 3px 3px 7px #00000029;
  box-shadow: 3px 3px 7px #00000029;
  border: 0.25px solid #cacaca;
  border-radius: 4px;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.o_web .def_form .form-group .checkbox label:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 1px;
  top: 3px;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 9px;
  color: #555;
}
.o_web
  .def_form
  .form-group
  .checkbox
  input[type="checkbox"]:checked
  + label:before {
  border: 0.25px solid #cacaca;
}
.o_web
  .def_form
  .form-group
  .checkbox
  input[type="checkbox"]:checked
  + label:after {
  content: "" !important;
  height: 17px;
  width: 17px;
  background: url(../images/sprite_img.svg) no-repeat -306px -70px;
  background-size: 500px;
}
.o_web .def_form .form-group .control-label {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #989898;
  padding-left: 5px;
}
.o_web .control-label {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #989898;
  padding-left: 5px;
}
.o_web .def_form .frgt_lnk {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.36px;
  color: #f08639;
  display: inline-block;
  text-decoration: underline;
  margin-bottom: 40px;
}
.o_web .text-right {
  text-align: right;
}
.o_web .def_form .frgt_lnk:hover {
  color: #ffac81;
}
.o_web .def_sb_btn {
  position: relative;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f05139),
      to(#f08639)
    ) 0 0 no-repeat padding-box;
  background: transparent linear-gradient(180deg, #f05139 0, #f08639 100%) 0 0
    no-repeat padding-box;
  border-radius: 17px;
  border: 4px solid #fff;
  display: block;
  width: 100%;
  margin: 0 auto 30px auto;
  height: 3rem;
  text-align: left;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 15px;
  text-transform: capitalize;
  line-height: 22px;
  letter-spacing: 0.42px;
  color: #fff;
  padding: 5px 13px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.01) 0 0 1px, rgba(0, 0, 0, 0.04) 0 4px 8px,
    rgba(0, 0, 0, 0.04) 0 16px 24px, rgba(0, 0, 0, 0.01) 0 24px 32px !important;
  box-shadow: rgba(0, 0, 0, 0.01) 0 0 1px, rgba(0, 0, 0, 0.04) 0 4px 8px,
    rgba(0, 0, 0, 0.04) 0 16px 24px, rgba(0, 0, 0, 0.01) 0 24px 32px !important;
}

@media (max-width: 361px) and (orientation: portrait) {
  .o_web .def_sb_btn {
    font-size: 11px;
  }
}
.focussable:focus {
  box-shadow: 0 4px 10px rgba(116, 116, 116, 0.61) !important;
}
.focussable-image:focus {
  border: 1px solid #f05139;
  border-radius: 5px;
}
.o_web a {
  cursor: pointer;
}
.o_web .def_sb_btn_loading {
  position: relative;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f05139),
      color-stop(35%, #f08639)
    ) 0 0 no-repeat padding-box;
  background: transparent linear-gradient(180deg, #f05139 0, #f08639 35%) 0 0
    no-repeat padding-box;
  border-radius: 18px;
  border: 4px solid #fff;
  display: block;
  width: 96%;
  margin: 0 auto 30px auto;
  -webkit-box-shadow: 0 6px 9px #00000017 !important;
  box-shadow: 0 6px 9px #00000017 !important;
  height: 3rem;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 22px;
  letter-spacing: 0.42px;
  color: #fff;
  padding: 5px 13px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.01) 0 0 1px, rgba(0, 0, 0, 0.04) 0 4px 8px,
    rgba(0, 0, 0, 0.04) 0 16px 24px, rgba(0, 0, 0, 0.01) 0 24px 32px !important;
  box-shadow: rgba(0, 0, 0, 0.01) 0 0 1px, rgba(0, 0, 0, 0.04) 0 4px 8px,
    rgba(0, 0, 0, 0.04) 0 16px 24px, rgba(0, 0, 0, 0.01) 0 24px 32px !important;
}
.o_web .def_sb_btn::before {
  content: "";
  position: absolute;
  right: 5px;
  top: 15px;
  height: 10px;
  width: 30px;
  background: url(../images/sprite_img.svg) no-repeat -328px -23px;
  background-size: 500px;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.o_web .def_sb_btn:hover::before {
  right: 2px;
}
.o_web .def_sb_btnv2::before {
  right: 13px;
  top: 15px;
  height: 20px;
  width: 16px;
  background: url(../images/sprite_img.svg) no-repeat -330px -109px;
  background-size: 500px;
}
.o_web .footer {
  position: fixed;
  left: 0;
  bottom: 0 !important;
  width: 100%;
  padding: 10px;
  text-align: center;
  background-color: #f4f4f4;
  z-index: 9;
}
@media screen and (max-width: 480px) {
  .o_web .footer {
    bottom: 122px;
  }
}
.o_web .footer ul {
  margin: 0;
  padding: 0;
}
.o_web .footer ul li {
  display: inline-block;
  list-style: none;
  margin: 0 15px;
}
.o_web .footer ul li a {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 10px;
  color: #707070;
  line-height: 20px;
  letter-spacing: 0.84px;
  text-transform: uppercase;
}
.o_web .footer ul li a:focus,
.o_web .footer ul li a:hover {
  color: #ffac81;
}
.o_web .fgt_p_v1 {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 15px;
  color: #989898;
  line-height: 25px;
  letter-spacing: 0.36px;
  padding: 0 17px;
}
.o_web .fgt_p_v2 {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 10px;
  color: #989898;
  line-height: 18px;
  letter-spacing: 0.36px;
  padding: 0 17px;
}
.o_web .fgt_p_v3 {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 12px;
  color: #989898;
  line-height: 28px;
  letter-spacing: 0.36px;
  margin-top: 15px;
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.o_web .max-745 {
  max-width: 745px;
  margin: 0 auto;
  padding-bottom: 20px;
}
.o_web .left_d {
  width: 310px;
}
@media screen and (max-width: 767px) {
  .o_web .left_d {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
}
.o_web .left_d .mx-400 {
  margin-left: 0;
  max-width: 100%;
}
.o_web .right_d {
  width: 410px;
}
@media screen and (max-width: 767px) {
  .o_web .right_d {
    width: 100%;
  }
}
.o_web .b_ttl {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 15px;
  color: #989898;
  line-height: 28px;
  letter-spacing: 0.42px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.o_web .def_tab_v2 {
  position: relative;
  border: none;
  margin-bottom: -9px;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.o_web .def_tab_v2 li {
  margin-right: 2px;
}
.o_web .def_tab_v2 li:last-child {
  margin-right: 0;
}
.o_web .def_tab_v2 li a {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 12px;
  color: #989898;
  line-height: 16px;
  letter-spacing: 0.9px;
  background-color: #ededed;
  border-radius: 2px 2px 0 0 !important;
  border: none !important;
  height: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* padding: 10px 0; */
  margin: 0 auto;
  width: 110px;
}
.o_web .purchase-nav li a {
  width: 140px;
}
.o_web .def_tab_v2 li:nth-child(1) {
  -webkit-clip-path: polygon(0 0, 85% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 85% 0, 100% 100%, 0 100%);
}
.o_web .def_tab_v2 li:nth-child(1) a {
  padding-right: 2em;
}
.o_web .def_tab_v2 li:nth-child(2) {
  position: relative;
  left: -11.5px;
  -webkit-clip-path: polygon(0 0, 85% 0, 100% 100%, 15% 100%);
  clip-path: polygon(0 0, 85% 0, 100% 100%, 15% 100%);
}
.o_web .def_tab_v2 li:nth-child(3) a {
  padding-left: 2em;
}
.o_web .def_tab_v2 li:nth-child(3) {
  position: relative;
  left: -24px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
}
.o_web .def_tab_v2 li a.active {
  font-family: "SF Pro Display Bold";
  font-size: 12px;
  color: #989898 !important;
  background-color: #fff !important;
}
@media screen and (max-width: 575px) {
  .o_web .def_tab_v2 li a {
    padding: 7px 13px;
  }
}
.o_web .tgl_btn {
  position: relative;
  background: #fff;
  -webkit-box-shadow: 6px 9px 20px #00000029 !important;
  box-shadow: 6px 9px 20px #00000029 !important;
  border-radius: 12px;
  text-align: center;
  height: 3.5rem;
  min-width: 58px;
  border: none !important;
  outline: 0 !important;
  margin-right: 8px;
}
.o_web .tgl_btn img {
  height: 32px;
  width: 32px;
}
.o_web .arrow-up {
  -webkit-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
}
.o_web .swap-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}
.o_web .amount {
  font-size: 32px;
  color: #010002;
  font-weight: 600;
  margin-top: 0 !important;
}
.o_web .amount-sm {
  font-size: 24px;
  color: #010002;
  font-weight: 600;
  margin-top: 0 !important;
}
.o_web .loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
}
.o_web .loader p {
  padding-top: 0;
}
.o_web .address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.o_web .address-sm {
  font-size: 18px;
  color: #010002;
  font-weight: 600;
  margin-top: 0 !important;
  word-break: break-all;
}
.o_web .label {
  margin-bottom: 0.1rem;
}
.o_web .time {
  font-size: 20px;
  color: #010002;
  padding-top: 0.5rem !important;
}
.o_web .swap-details {
  font-size: 18px;
  color: #a4a3aa;
}
.o_web .swap-btn {
  position: relative;
  background: #f05139;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f05139),
    color-stop(47%, #f08639),
    to(#f08639)
  );
  background: linear-gradient(to bottom, #f05139 0, #f08639 47%, #f08639 100%);
  border-radius: 12px;
  border: 3px solid #fff;
  display: block;
  width: 100%;
  margin-bottom: 30px;
  height: 3.5rem;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 15px;
  text-transform: capitalize;
  line-height: 22px;
  letter-spacing: 0.42px;
  color: #fff;
  padding: 5px 13px;
}
.o_web .error {
  color: var(--red);
  font-size: 15px;
  padding-top: 0;
}

.o_web .back-btn {
  text-align: center;
  margin-top: 0;
  border: none;
  background-color: #fff;
  border-radius: 12px;
  border: 3px solid #f08639;
  display: block;
  width: 30%;
  margin-bottom: 30px;
  height: 2.5rem;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 15px;
  text-transform: capitalize;
  line-height: 22px;
  letter-spacing: 0.42px;
  color: #f08639;
  padding: 5px 13px;
}

.o_web .back-btn:hover {
  text-align: center;
  margin-top: 0;
  border: none;
  background-color: #f08639;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f05139),
    color-stop(47%, #f08639),
    to(#f08639)
  );
  background: linear-gradient(to bottom, #f05139 0, #f08639 47%, #f08639 100%);
  border-radius: 12px;
  border: 3px solid #f08639;
  display: block;
  width: 30%;
  margin-bottom: 30px;
  height: 2.5rem;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 15px;
  text-transform: capitalize;
  line-height: 22px;
  letter-spacing: 0.42px;
  color: white;
  padding: 5px 13px;
}

.o_web .back-btn-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.o_web input:focus {
  outline: 0 !important;
}
.o_web .swap-info p {
  font-size: 15px;
}
.o_web .wt_arrow {
  display: -ms-flexbox !important;
  display: -webkit-box !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.o_web .wt_arrow::after {
  content: "";
  position: relative;
  display: inline-block;
  height: 9px;
  width: 14px;
  background: url(../images/sprite_img.svg) no-repeat -386px -23px;
  background-size: 500px;
}
.o_web .max_prnt {
  position: relative;
  width: 100%;
}
.o_web .max_prnt .mx_btn {
  font-family: "SF Pro Display SemiBold", Mulish, sans-serif;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #32cc86;
  position: absolute;
  left: 13px;
  top: 18px;
  border: none !important;
  z-index: 1;
  border: 1px solid #6ac98c !important;
  border-radius: 5px;
  background: 0 0;
}
.o_web .max_prnt .form-control {
  width: 100% !important;
  text-align: right;
  padding-left: 48px;
  font-size: 26px !important;
  line-height: 36px !important;
  letter-spacing: -0.24px !important;
  -webkit-box-shadow: 6px 9px 20px #00000029 !important;
  box-shadow: 6px 9px 20px #00000029 !important;
}
.o_web ::-webkit-input-placeholder {
  font-size: 15px;
}
.o_web :-ms-input-placeholder {
  font-size: 15px;
}
.o_web ::-ms-input-placeholder {
  font-size: 15px;
}
.o_web ::placeholder {
  font-size: 15px;
}
.o_web .btminftxt {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.24px;
  color: #989898;
}
.o_web .btminftxt span {
  color: #32cc86;
}
.o_web .p_rltv {
  position: relative;
}
.o_web .select_bx {
  position: absolute;
  height: 300px;
  overflow: scroll;
  left: 3px;
  right: 3px;
  top: 0;
  background: #f8f8f8 0 0;
  -webkit-box-shadow: 6px 9px 20px #00000029;
  box-shadow: 6px 9px 20px #00000029;
  border-radius: 12px;
  display: none;
  z-index: 99;
}
.o_web .select_bx .inner_pdng {
  padding: 10px 10px 10px 10px;
}
.o_web .select_bx .inner_pdng h3 {
  font-family: "SF Pro Display Light", Mulish, sans-serif;
  font-size: 17px;
  line-height: 34px;
  letter-spacing: 0.68px;
  color: #0f1740;
  font-weight: 300;
}
.o_web .select_bx .inner_pdng h3 img {
  margin-top: -3px;
  height: 20px;
  width: 20px;
}
.o_web .select_bx .inner_pdng h3 span {
  position: relative;
  display: inline-block;
  height: 9px;
  width: 16px;
  background: url(../images/sprite_img.svg) no-repeat -453px -23px;
  background-size: 500px;
  float: right;
  margin-top: 13px;
  cursor: pointer;
}
.o_web .select_bx .btcn_lst {
  margin: 0;
  padding: 0;
}
.o_web .select_bx .btcn_lst li {
  list-style: none;
  display: block;
}
.o_web .select_bx .btcn_lst li a {
  position: relative;
  padding: 15px 20px 13px 43px;
  display: block;
  cursor: pointer;
}
.o_web .select_bx .btcn_lst li a .slct_img {
  position: absolute;
  left: 20px;
  top: 15px;
  width: 15px;
}
.o_web .select_bx .btcn_lst li a h5 {
  font-family: "SF Pro Display Bold", Mulish, sans-serif;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #010002;
  margin-bottom: 3px;
}
.o_web .select_bx .btcn_lst li a h6 {
  font-family: "SF Pro Display Bold", Mulish, sans-serif;
  font-size: 8px;
  line-height: 13px;
  letter-spacing: 0.24px;
  color: #010002;
  margin-bottom: 3px;
}
.o_web .select_bx .btcn_lst li a p {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 7px;
  line-height: 10px;
  letter-spacing: 0.18px;
  color: #929292;
  margin-bottom: 0;
}
.o_web .select_bx .btcn_lst li a p span {
  color: #0dc471 !important;
}
.o_web .select_bx .btcn_lst li a p span.clr_rd {
  color: #fc3044 !important;
}
.o_web .select_bx .btcn_lst li a:hover {
  background: #e4e4e4 !important;
}
.o_web .select_bx .btcn_lst li:nth-child(odd) a {
  background: #fff;
}
.o_web .select_bx .cntr_lst li a {
  padding: 10px 20px 8px 43px;
}
.o_web .select_bx .cntr_lst li a img {
  top: 10px !important;
}
.o_web .rt_info h4 {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.16px;
  color: #0f1740;
  margin-bottom: 0;
}
.o_web .rt_info h4 span {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 9px;
  line-height: 17px;
  letter-spacing: 0.24px;
  color: #3c3c3b;
}
.o_web .gdnt_bx {
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f08639),
      to(#f07039)
    ) 0 0 no-repeat padding-box;
  background: transparent linear-gradient(180deg, #f08639 0, #f07039 100%) 0 0
    no-repeat padding-box;
  padding: 15px 15px;
  border-radius: 19px 19px 0 0;
  width: 377px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .o_web .gdnt_bx {
    width: 95%;
  }
}
.o_web .gdnt_bx .info_t_v1 h6 {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.18px;
  color: #fff;
  margin-bottom: 5px;
  margin-top: 3px;
}
.o_web .gdnt_bx .info_t_v1 h5 {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 13px;
  line-height: 28px;
  letter-spacing: 0.42px;
  color: #fff;
  margin-bottom: 0;
}
.o_web .title-details {
  font-size: 10px !important;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif !important;
  color: #111a44;
}
.o_web .gdnt_bx .info_t_v1 h4 {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.36px;
  color: #111a44;
  margin-top: 3px;
  margin-bottom: 15px;
}
.o_web .info_t_v2 .d-flex {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
}
@media screen and (max-width: 767px) {
  .o_web .info_t_v2 .d-flex {
    margin-bottom: 8px;
  }
}
.o_web .info_t_v2 .d-flex a {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.18px;
  color: #fff;
}
.o_web .info_t_v2 .d-flex a.kvc_a {
  position: relative;
  padding-right: 20px;
  margin-top: 2px;
}
.o_web .info_t_v2 .d-flex a.kvc_a::after {
  content: "";
  position: absolute;
  right: -1px;
  top: -4px;
  width: 17px;
  height: 18px;
  background: url(../images/sprite_img.svg) no-repeat -277px -17px;
  background-size: 500px;
}
.o_web .snackbar .MuiSnackbarContent-root {
  background-color: #f16b39;
  color: #fff;
  font-family: "Muli Regular";
  font-size: 15px;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.o_web .ant-tooltip-arrow {
  display: none !important;
}
.o_web .info_t_v3 h6 {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 9px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #fff;
  margin-bottom: 3px;
}
.o_web .info_t_v3 h6 span {
  cursor: pointer;
  display: inline-block;
  font-size: 6px;
  line-height: 8px;
  letter-spacing: 0.18px;
  color: #fff;
  border: 1px solid #ffffff70;
  border-radius: 2px;
  padding: 1px 2px;
  vertical-align: top;
  margin-top: 2px;
  margin-left: 3px;
}
.o_web .info_t_v3 p {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  position: absolute;
  font-size: 10px;
  top: -18px;
  line-height: 17px;
  letter-spacing: 0.18px;
  color: #fff;
  margin-bottom: 0;
  text-transform: uppercase;
}
.o_web .info_t_v4 .float-right {
  margin: -10px 0 0 0;
}
@media screen and (max-width: 767px) {
  .o_web .info_t_v4 .float-right {
    margin: 0;
    float: none !important;
  }
}
.o_web .info_t_v4 label {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 8px;
  line-height: 17px;
  letter-spacing: 0.24px;
  color: #fff;
  margin-bottom: 0;
}
.o_web .info_t_v4 h5 {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: #fff;
  margin-bottom: 0;
}
.o_web .info_t_v4 h5 span {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  position: relative;
  font-size: 8px;
  line-height: 17px;
  letter-spacing: 0.24px;
  color: #fff;
  vertical-align: flex-end;
}
.o_web .hstr_tbl_bx {
  background: #fcfcfc;
  border-radius: 12px;
  -webkit-box-shadow: 0 8px 20px #00000029;
  box-shadow: 0 8px 20px #00000029;
}
.o_web .hstr_tbl_bx h3 {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.42px;
  color: #989898;
  margin-bottom: 0;
  padding: 15px 12px 10px 12px;
}
.o_web .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0) !important;
}
.o_web .at {
  font-size: 16px !important;
}
.o_web .table-striped tbody tr:nth-of-type(even) {
  background-color: #f3f3f3 !important;
}
.o_web #no-more-tables table thead th {
  background-color: #0f1740;
  border: none;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.24px;
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;
}
.o_web #no-more-tables table tbody tr td {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #3c3c3b;
}
.o_web #auction-history-tables table thead th {
  background-color: #0f1740;
  border: none;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.24px;
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;
}
.o_web #auction-history-tables table tbody tr td {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #3c3c3b;
}
.o_web #no-more-tables table tbody tr td .clm_txt {
  cursor: pointer;
  display: inline-block;
  font-size: 6px;
  line-height: 8px;
  letter-spacing: 0.18px;
  color: #707070;
  border: 1px solid #707070;
  border-radius: 2px;
  padding: 1px 3px;
  vertical-align: middle;
  margin-top: -1px;
  margin-left: 10px;
}
.o_web #no-more-tables table tbody tr td .clm_txt.active {
  color: #0dc471;
  border: 1px solid #0dc471;
}
.o_web #no-more-tables table tbody tr td.fnt_sm {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 12px;
  color: #0f1740;
}
@media screen and (max-width: 767px) {
  .o_web #no-more-tables table,
  .o_web #no-more-tables tbody,
  .o_web #no-more-tables td,
  .o_web #no-more-tables th,
  .o_web #no-more-tables thead,
  .o_web #no-more-tables tr {
    display: block;
  }
  .o_web #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .o_web #no-more-tables tbody tr {
    border: 1px solid #ccc;
  }
  .o_web #no-more-tables tbody tr td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30% !important;
    white-space: normal;
    text-align: left;
  }
}
@media screen and (max-width: 767px) {
  .o_web #no-more-tables td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: 700;
  }
  .o_web #no-more-tables td:before {
    content: attr(data-title);
  }
}
.o_web .btm_info_text {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #989898;
  margin-top: 40px;
  margin-bottom: 30px;
}
.o_web .btm_info_text a {
  color: #f05939;
}
.o_web .rwrd_box {
  background: #fcfcfc;
  border-radius: 12px 12px 0 0;
  -webkit-box-shadow: 0 8px 20px #00000029;
  box-shadow: 0 8px 20px #00000029;
  padding: 20px 30px;
  width: 283px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .o_web .rwrd_box {
    width: 90%;
    text-align: center;
  }
}
.o_web .rwrd_box h3 {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: #0f1740;
  margin-bottom: 0;
}
.o_web .def_wt_bx_v2 {
  padding-top: 10px;
}
.o_web .width60 {
  width: 60px;
  margin-right: 12px;
  text-align: center;
}
.o_web .width60 h4 {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: -0.22px;
  color: #0f1740;
  margin-bottom: 5px;
  background: #f8f8f8;
  -webkit-box-shadow: 6px 9px 20px #00000029;
  box-shadow: 6px 5px 20px #00000029;
  border-radius: 12px;
  width: 100%;
  padding: 2px 0;
}
.o_web .prgrss_bx {
  padding: 0 10px;
  margin-bottom: 1rem;
}
.o_web .prgrss_bx h5 {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: #0f1740;
  margin-bottom: 15px;
}
.o_web .slider-value-label {
  color: #000;
  float: left;
  padding-left: 25px;
  font-size: 22px;
  margin-top: -7px;
}
.o_web .slider-label {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #989898;
  padding-left: 0;
  margin-bottom: 3px;
}
.o_web .rangeSlider__horizontal {
  position: relative;
  float: left;
  height: 10px;
  width: calc(100% - 65px);
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ccc;
  border-radius: 35px;
  background: 0 0;
  width: 100%;
  -webkit-box-shadow: inset 0 0 3px #00000063;
  box-shadow: inset 0 0 3px #00000063;
}
.o_web .rangeSlider__handle {
  width: 16px;
  height: 16px;
  top: -12px;
  background-position: left top;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-left: -2px;
  background: #f05139;
  border: 5px solid #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.8);
  margin-top: 7px;
  position: absolute;
  z-index: 5;
}
.o_web .rangeSlider__fill {
  border-radius: 0;
  background: url(../images/slider_bg.png) repeat-x 0 0;
  height: 8px;
  border-radius: 35px;
  position: absolute;
  top: 0;
  z-index: 0;
}
.o_web .value_bx {
  float: right;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.12px;
  color: #0f1740;
  margin-top: 10px;
}
.o_web .value_bx .slider-value-label {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.12px;
  color: #0f1740;
  float: none;
}
.o_web .bg_wt_bx {
  background: #fcfcfc;
  border-radius: 12px;
  -webkit-box-shadow: 0 0 22px #00000029;
  box-shadow: 0 0 22px #00000029;
  padding: 30px 15px 30px 15px;
  margin-top: 80px;
  width: 345px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .o_web .bg_wt_bx {
    width: 100%;
    margin-top: 30px;
  }
}
.o_web .crcl_bbx {
  height: 242px;
  width: 242px;
  margin: 0;
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.2;
  background: #0f1740;
  border-radius: 50%;
  padding: 20px 0 20px 0;
  -webkit-box-shadow: 4px 3px 16px 7px #00000054;
  box-shadow: 4px 3px 16px 7px #00000054;
}
.o_web .crcl_bbx .inner_crcle {
  position: absolute;
  left: 36px;
  top: 34px;
  height: 170px;
  width: 170px;
  background: #fff;
  border-radius: 50%;
}
.o_web .crcl_bbx strong {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: "SF Pro Display Light", Mulish, sans-serif;
  font-weight: 300;
  font-size: 36px;
  line-height: 33px;
  letter-spacing: -0.36px;
  color: #0f1740;
  z-index: 1;
}
.o_web .crcl_bbx label {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #989898;
  z-index: 1;
}
.o_web .crcl_bbx h4 {
  position: absolute;
  top: 135px;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: #0f1740;
  z-index: 1;
}
.o_web .crcl_bbx p {
  position: absolute;
  top: 162px;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #989898;
  z-index: 1;
}
.o_web .circle canvas {
  vertical-align: top;
}
.o_web .blck_bbx {
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#111a44),
      to(#010726)
    ) 0 0 no-repeat padding-box;
  background: transparent linear-gradient(180deg, #111a44 0, #010726 100%) 0 0
    no-repeat padding-box;
  border-radius: 0 0 12px 12px;
  width: 283px;
  margin: 0 auto 0 31px;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.12px;
  color: #fff;
  height: 50px;
  text-align: center;
  padding: 16px 10px;
  -webkit-box-shadow: 0 7px 18px 5px #00000029;
  box-shadow: 0 7px 18px 5px #00000029;
}

@media screen and (max-width: 767px) {
  .o_web .blck_bbx {
    width: 90%;
    margin: 0 5% 0 5%;
  }
}
@media screen and (min-width: 768px) {
  .o_web .right_d_wtpdng {
    padding-left: 30px;
  }
}
.o_web .crcl_prnt .crcl_bbx {
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f05139),
      to(#f08639)
    ) 0 0 no-repeat padding-box;
  background: transparent linear-gradient(180deg, #f05139 0, #f08639 100%) 0 0
    no-repeat padding-box;
}
.o_web .crcl_prnt .crcl_bbx label {
  color: #f08339;
}
.o_web .crcl_prnt .crcl_bbx strong {
  color: #f08339;
}
.o_web .crcl_prnt .blck_bbx {
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f05139),
      to(#f08639)
    ) 0 0 no-repeat padding-box;
  background: transparent linear-gradient(180deg, #f05139 0, #f08639 100%) 0 0
    no-repeat padding-box;
}
.o_web .dflt_bl_mdl {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 83px;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#111a44),
      to(#010726)
    ) 0 0 no-repeat padding-box;
  background: transparent linear-gradient(180deg, #111a44 0, #010726 100%) 0 0
    no-repeat padding-box;
  border: 1px solid #707070;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.o_web .dflt_bl_mdl.show {
  display: block;
  opacity: 1;
  visibility: visible;
}
.o_web .dflt_bl_mdl .mdlcls {
  position: absolute;
  right: 14%;
  top: 35px;
  border: none;
  height: 25px;
  width: 25px;
  background: url(../images/sprite_img.svg) no-repeat -218px -12px;
  background-size: 500px;
}
@media screen and (max-width: 575px) {
  .o_web .dflt_bl_mdl .mdlcls {
    right: 15px;
  }
}
.o_web .dflt_bl_mdl h3 {
  font-family: "Muli Regular", Mulish, sans-serif;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 4.2px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 30px;
}
.o_web .wallet_modal h3 {
  margin-bottom: 85px;
}
.o_web .wlt_list {
  text-align: center;
  margin: 0;
  padding: 0;
}
.o_web .wlt_list li {
  position: relative;
  display: inline-block;
  margin: 15px;
  list-style: none;
}
@media screen and (max-width: 575px) {
  .o_web .wlt_list li {
    margin: 10px 5px;
  }
}
.o_web .wlt_list li .ckbx_inpt {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.o_web .wlt_list li span {
  display: block;
  width: 227px;
  height: 123px;
  background: #fcfcfc;
  border-radius: 12px;
  padding: 31px;
  border: 2px solid transparent;
}
@media screen and (max-width: 575px) {
  .o_web .wlt_list li span {
    width: auto;
    height: auto;
    padding: 8px;
  }
}
.o_web .wlt_list li .ckbx_inpt:checked + span {
  border: 2px solid #f06e39;
}
.o_web .wltsm_modal {
  background: #f8f8f8;
  -webkit-box-shadow: 6px 9px 20px #00000029;
  box-shadow: 6px 9px 20px #00000029;
  border-radius: 12px;
  position: absolute;
  left: 20px;
  top: 15px;
  right: 20px;
  bottom: 15px;
  z-index: 9;
  min-height: 300px;
  padding: 25px 20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.o_web .wltsm_modal.show {
  display: block;
  opacity: 1;
  visibility: visible;
}
.o_web .wltsm_modal h4 {
  font-family: "SF Pro Display Light", Mulish, sans-serif;
  font-size: 17px;
  line-height: 28px;
  letter-spacing: 0.68px;
  color: #0f1740;
  margin-bottom: 25px;
}
.o_web .wltsm_modal .mdlcls {
  position: absolute;
  right: 15px;
  top: 28px;
  border: none;
  height: 25px;
  width: 25px;
  background: url(../images/sprite_img.svg) no-repeat -381px -62px;
  background-size: 500px;
}
@media screen and (max-width: 575px) {
  .o_web .wltsm_modal .mdlcls {
    right: 15px;
  }
}
.o_web .wltsm_modal .wlt_list li span {
  width: auto;
  height: auto;
  padding: 10px;
  background: #f8f8f8;
}
.o_web .b_adgex_bx {
  display: none;
}
.o_web .b_adgex_bx.show {
  display: block;
}
.o_web .b_adgex_bx p {
  padding: 0 5px;
  font-size: 9px !important;
}
.o_web .b_adgex_bx .bk_arrow {
  display: inline-block;
  height: 15px;
  width: 19px;
  background: url(../images/sprite_img.svg) no-repeat -426px -68px;
  background-size: 500px;
  margin: -4px 4px 6px 4px;
}
.o_web .b_adgex_bx label {
  display: block;
}
.o_web .zindx {
  z-index: 1;
  position: relative;
}
.o_web .eth_info {
  position: relative;
  top: -1rem;
  background: #fcfcfc;
  border-radius: 0 0 12px 12px;
  padding: 20px 20px 10px 20px;
  width: 283px;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 22px #00000029;
  box-shadow: 0 0 22px #00000029;
}
@media screen and (max-width: 767px) {
  .o_web .eth_info {
    width: 95%;
  }
}
.o_web .eth_info h4 {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.36px;
  color: #989898;
  margin-bottom: 0;
}
.o_web .eth_info h4 img {
  width: 20px;
  vertical-align: top;
  margin-top: 3px;
}
.o_web .eth_info h4 strong {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: #0f1740;
  float: right;
  margin-top: 4px;
}
.o_web .eth_info hr {
  margin-top: 5px;
  margin-bottom: 5px;
}
.o_web .eth_info .et_btminfo {
  padding: 4px 3px 0 3px;
}
.o_web .eth_info .et_btminfo .drk_p {
  color: #0f1740;
}
.o_web .eth_info .et_btminfo .green_p {
  color: #0dc471;
}
.o_web .eth_info .et_btminfo p {
  margin-bottom: 5px;
}
.o_web .card-input {
  width: 90%;
}
.o_web .eth_info .et_btminfo p i {
  display: inline-block;
  height: 15px;
  width: 19px;
  background: url(../images/sprite_img.svg) no-repeat -468px -68px;
  background-size: 500px;
  vertical-align: middle;
  margin-left: 2px;
}
.o_web .tooltip-inner {
  font-size: 11px !important;
}
.o_web .bg_wt_bx_v2 {
  margin-top: 0 !important;
  text-align: left;
  padding: 20px;
}
.o_web .bg_wt_bx_v2 h3 {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: #0f1740;
  margin-bottom: 15px;
  padding-top: 0.5rem;
}
.o_web .bg_wt_bx_v2 p {
  margin-bottom: 8px;
}
.o_web .bg_wt_bx_v2 .brdr_bx {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.16px;
  color: #0f1740;
  border: 1px solid #9b9b9b;
  border-radius: 6px;
  padding: 7px 2px;
  margin-bottom: 2px;
}
.o_web .bg_wt_bx_v2 .brdr_bx .col-4 {
  padding: 0;
}
.o_web .bg_wt_bx_v2 .brdr_bx .dt_txt {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  color: #989898;
}
.o_web .mx_450 {
  max-width: 450px;
  margin: 0 auto;
}
.o_web .crnc_prnt {
  padding: 0 15px;
}
.o_web .crnc_prnt h5 {
  font-family: "SF Pro Display Light", Mulish, sans-serif;
  font-size: 14px;
  line-height: 34px;
  letter-spacing: 0.56px;
  color: #fff;
  margin-bottom: 5px;
}
.o_web .loading-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  margin-top: -82px;
}
.o_web .loading-page p {
  margin-top: 1rem auto;
  color: #010726;
}
.o_web .small-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: auto;
}
.o_web .rn_ic_1 .active {
  color: #f16b39 !important;
}
.o_web .modal-wrapper {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
}
/* .modal {
  background-color: #fff !important;
  width: 30vw !important;
  height: 60vh !important;
  margin-top: 20vh !important;
  margin-left: 35vw !important;
  border-radius: 20px !important;
  padding: 1rem !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: space-evenly !important;
  -ms-flex-pack: space-evenly !important;
  justify-content: space-evenly !important;
} */

.projectModal {
  width: 100%;
  height: 100vh;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.projectModal input,.projectModal select{
  border: 1px solid;
}
.modal h2 {
  text-align: center !important;
  font-weight: var(--light) !important;
}
@media screen and (min-width: 768px) {
  .o_web .stake-container {
    padding: 0 2rem !important;
  }
  .staking-container {
    min-height: calc(100vh - 225px) !important;
  }
}
.o_web .stake-reward-banner {
  position: relative;
  z-index: 0;
  -webkit-box-shadow: 6px 12px 20px 12px rgba(0, 0, 0, 0.137) !important;
  box-shadow: 6px 12px 20px 12px rgba(0, 0, 0, 0.137) !important;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f05139),
      to(#f08639)
    ) 0 0 no-repeat padding-box;
  background: transparent linear-gradient(180deg, #f05139 0, #f08639 100%) 0 0
    no-repeat padding-box;
  padding: 1rem 2rem 4rem 2rem;
  width: 90%;
  margin: 0 auto -4rem auto;
  border-radius: 12px !important;
}
.o_web .stake-calculator-card {
  position: relative;
  top: -2.25rem;
  z-index: 1;
  -webkit-box-shadow: 6px 9px 20px 12px rgba(0, 0, 0, 0.137) !important;
  box-shadow: 6px 9px 20px 12px rgba(0, 0, 0, 0.137) !important;
  padding: 2rem 1.5rem 0 1.5rem !important;
  border-radius: 12px !important;
  margin: auto;
  width: 75%;
  padding: 1rem 2rem;
}
.o_web .rangeslider-horizontal {
  height: 10px !important;
}
.o_web .rangeslider__handle {
  outline: 0 !important;
}
.o_web .rangeslider__fill {
  background: transparent
    repeating-linear-gradient(
      90deg,
      #f05139,
      #f05139 1.5px,
      #979bab 1.5px,
      #979bab 3px
    )
    0 0 no-repeat padding-box;
}
.o_web .rangeslider .rangeslider__handle {
  border-color: transparent !important;
  width: 21px !important;
  height: 21px !important;
}
.o_web .rangeslider-horizontal .rangeslider__handle:after {
  width: 10px !important;
  height: 10px !important;
  top: 4.5px !important;
  left: 4.25px !important;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f05139),
      to(#f08639)
    ) 0 0 no-repeat padding-box;
  background: transparent linear-gradient(180deg, #f05139 0, #f08639 100%) 0 0
    no-repeat padding-box;
  -webkit-box-shadow: 0 8px 20px #00000029 !important;
  box-shadow: 0 8px 20px #00000029 !important;
}
.o_web .kyc-history-table .MuiPaper-elevation1 {
  -webkit-box-shadow: 6px 9px 20px 12px rgba(0, 0, 0, 0.137) !important;
  box-shadow: 6px 9px 20px 12px rgba(0, 0, 0, 0.137) !important;
  border-radius: 12px !important;
}
.o_web .kyc-history-table .MuiTypography-body2 {
  padding-top: 0 !important;
  margin-top: -5px !important;
  font-family: "Muli Regular";
}
.o_web .MuiMenuItem-root {
  font-family: "Muli Regular";
}
.o_web .settings-card {
  -webkit-box-shadow: 0 1px 4px #e5e9f2 !important;
  box-shadow: 0 1px 4px #e5e9f2 !important;
  border-radius: 23px !important;
  padding: 1rem;
}
.o_web .settings-card .MuiRadio-colorPrimary.Mui-checked {
  color: #f08639 !important;
}
.o_web .stake-tracker-card {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 6px 9px 20px 12px rgba(0, 0, 0, 0.137) !important;
  box-shadow: 6px 9px 20px 12px rgba(0, 0, 0, 0.137) !important;
  padding: 2.75rem 2rem !important;
  border-radius: 12px !important;
  margin: auto;
  width: 65%;
  padding: 1rem 2rem;
}
.o_web .tracker-border-gradient {
  position: absolute;
  height: 260px;
  width: 260px;
  border-radius: 50%;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f05139),
      to(#f08639)
    ) 0 0 no-repeat padding-box;
  background: transparent linear-gradient(180deg, #f05139 0, #f08639 100%) 0 0
    no-repeat padding-box;
  -webkit-box-shadow: 4px 3px 16px 7px #00000054;
  box-shadow: 4px 3px 16px 7px #00000054;
}
.o_web .tracker-border-gradient-dark {
  position: absolute;
  height: 260px;
  width: 260px;
  border-radius: 50%;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#0f1740),
      to(#111a44)
    ) 0 0 no-repeat padding-box;
  background: transparent linear-gradient(180deg, #0f1740 0, #111a44 100%) 0 0
    no-repeat padding-box;
  -webkit-box-shadow: 4px 3px 16px 7px #00000054;
  box-shadow: 4px 3px 16px 7px #00000054;
}
.o_web .tracker-border-gradient-inner {
  position: absolute;
  z-index: 2;
  height: 190px;
  width: 190px;
  border-radius: 50%;
  background-color: #fff;
}
@media screen and (max-width: 600px) {
  .o_web .modal {
    width: 100vw;
    margin-left: 0;
  }
}
.ant-modal.o_web {
  background: 0 0 !important;
  min-height: 0 !important;
}
.o_web .anticon svg {
  color: #000 !important;
}
.o_web .ant-modal-content {
  border-radius: 12px !important;
}
.o_web .ant-modal-header {
  border-radius: 12px 12px 0 0 !important;
}
.o_web .purchase-history p {
  padding-top: 0;
  font-family: "Muli Regular";
}
.o_web .purchase-history .MuiTablePagination-input {
  display: none;
}
@-moz-document url-prefix() {
  .o_web .input-1 {
    width: 67% !important;
  }
  .o_web .input-2 {
    width: 75.5% !important;
  }
}
.o_web .dropdown-menu {
  float: right;
  left: auto;
  right: 0;
  border: none;
  padding: 1rem;
  -webkit-box-shadow: 6px 9px 20px 5px #00000029 !important;
  box-shadow: 6px 9px 20px 5px #00000029 !important;
}
.o_web .dropdown-menu ul {
  list-style-type: none;
  margin-bottom: 0;
}
.o_web .dropdown-menu ul li {
  padding: 7px 0;
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 15.5px;
  letter-spacing: 0.2px;
  color: #989898;
  text-transform: capitalize;
  text-align: right;
}
.o_web .dropdown-nav-link {
  padding: 7px 0;
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 15.5px;
  letter-spacing: 0.2px;
  color: #989898;
  text-transform: capitalize;
}
.o_web .dropdown-nav-link:hover {
  color: #0f1740;
}
.o_web .dropdown-link-active {
  color: #f08639;
}
.o_web .dropdown-link-active:hover {
  color: #f08639;
}
.o_web .tabs-container {
  position: absolute;
  top: 62px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 0 0 19px 19px;
}
.o_web .opaque {
  background: #fff;
  border-radius: 0 0 19px 19px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: 0 18px 24px rgba(138, 138, 138, 0.3);
  box-shadow: 0 18px 24px rgba(138, 138, 138, 0.3);
}
.o_web .tabs-container li {
  margin: 0 18px;
  text-align: center;
}
.o_web .tabs-container li:nth-child(1) {
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 0;
}
.o_web .tabs-container li:nth-last-child(1) {
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 0;
}
@media screen and (max-width: 500px) {
  .o_web .tabs-container li {
    margin: 0 10px;
    text-align: center;
  }
}
@media screen and (max-width: 435px) {
  .o_web .tabs-container {
    max-width: 350px;
  }
}
@media screen and (max-width: 305px) {
  .o_web .tabs-container {
    max-width: 100%;
  }
}
.o_web .tab-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 120px;
  height: 47px;
}
.o_web .tab-item a {
  font-family: "SF Pro Display Regular";
  font-size: 13.5px;
  text-transform: capitalize;
  color: #868686;
}
.o_web .tab-item a:focus,
.o_web .tab-item-active a:focus {
  text-decoration: underline !important;
}
.o_web .tab-item a:hover {
  color: #0f1740;
}
.o_web .tab-item-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 125px;
  height: 47px;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f05139),
      to(#f08639)
    ) 0 0 no-repeat padding-box;
  background: transparent linear-gradient(180deg, #f05139 0, #f08639 100%) 0 0
    no-repeat padding-box;
  border-radius: 0 0 19px 19px;
}
.o_web .tab-item-active a {
  font-family: "SF Pro Display Bold";
  font-size: 13.5px;
  color: #fff;
}
.o_web .tab-item-active a:hover {
  color: #fff;
}
.o_web .history-button {
  background-color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  color: #32cc86;
  margin-bottom: 1rem;
  border: 1px solid #6ac98c;
  border-radius: 4.5px;
  padding: 3px 7px;
}
.o_web .history-button:hover {
  color: #32cc86;
  border-color: #32cc86;
}
.o_web .rangeslider {
  margin: 10px 0 !important;
}
@media screen and (min-width: 1200px) {
  .o_web .hidden-desktop {
    display: none;
  }
  .o_web .hidden-tablet {
    display: block;
  }
}
@media screen and (max-width: 1199px) {
  .o_web .hidden-desktop {
    display: block;
  }
  .o_web .hidden-tablet {
    display: none;
  }
  .o_web .rn_ic_5:nth-child(1) {
    display: block;
  }
  .o_web .my-lg-0 .right_nave {
    margin-top: 1rem;
    margin-right: -3.5rem;
    overflow: hidden;
  }
  .o_web .dropdown-menu {
    display: none !important;
  }
  .o_web .menu-burger {
    display: none !important;
  }
}
@media screen and (max-width: 990px) {
  .o_web .def_tab_v2 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .o_web .def_tab_v2 li {
    width: 120px;
    text-align: center;
  }
  .o_web .purchase-nav li {
    width: 140px;
    width: 100%;
  }
  .o_web .purchase-nav li a {
    width: 100%;
  }
  .o_web .purchase-nav li:nth-child(2) {
    left: 0;
  }
  .o_web .purchase-nav li:nth-child(3) {
    left: 0;
  }
  .o_web .tx-history-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .o_web .tx-history-nav li {
    margin-right: 10px;
    width: auto;
  }
}
@media screen and (max-width: 767px) {
  .o_web .def_tab_v2 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .o_web .def_tab_v2 li {
    text-align: center;
  }
}
.o_web
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: #f05d38 !important;
  border-color: #f05d38 !important;
}
.o_web .anticon svg {
  position: relative;
  top: -1px;
  color: #32cc86;
}
.o_web .ant-steps-item-finish .ant-steps-item-icon {
  border-color: #32cc86 !important;
}
.o_web
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #32cc86 !important;
}
.o_web .ant-spin-dot-item,
.suspense-loader .ant-spin-dot-item {
  background-color: #ef8638 !important;
}
.o_web .white-spinner .ant-spin-dot-item,
.white-spinner .ant-spin-dot-item {
  background-color: #fff !important;
}
.o_web .RSPBprogressBar {
  -webkit-box-shadow: 0 2px 5px rgba(141, 143, 152, 0.43),
    0 2px 5px rgba(141, 143, 152, 0.43) inset;
  box-shadow: 0 2px 5px rgba(141, 143, 152, 0.43),
    0 2px 5px rgba(141, 143, 152, 0.43) inset;
  border: 2px solid #fff;
  border-radius: 14px !important;
  background-color: #fff !important;
  margin-bottom: 4.5rem;
}
.o_web .RSPBprogressBar .RSPBprogressBarText {
  font-family: "SF Pro Display Bold", Mulish, sans-serif;
  font-size: 7px;
  letter-spacing: 0.18px;
}
.o_web .RSPBprogressBar .RSPBprogression {
  border-radius: 14px !important;
}
.o_web .RSPBprogressBar .RSPBstep:nth-child(1) {
  left: 3% !important;
}
.o_web .RSPBprogressBar .RSPBstep:nth-child(5) {
  left: 95% !important;
}
.o_web .step-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 4.5rem;
}
.o_web .step-container span {
  margin-top: 5px;
  font-family: "SF Pro Display Bold", Mulish, sans-serif;
  font-size: 13px;
  letter-spacing: 0.3px;
  color: #989898;
}
.o_web .next-reward-container {
  top: -3rem;
  position: absolute;
  width: 80vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.o_web .reward-block {
  position: relative;
  z-index: 100;
  width: 1.7rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.o_web .reward-block:nth-child(2) {
  left: 0.18rem;
}
.o_web .reward-block:nth-child(3) {
  left: 1rem;
}
.o_web .reward-block:nth-child(4) {
  left: 1rem;
}
.o_web .reward-block:nth-child(5) {
  left: 1rem;
}
.o_web .reward-block p {
  padding-top: 0;
  margin-bottom: -5px;
  min-width: 100px;
  font-size: 10px;
  font-family: "SF Pro Display Bold", Mulish, sans-serif;
  color: #32cc86;
}
.o_web .reward-block p:nth-child(2) {
  padding-top: 0;
  margin-bottom: 2px;
  min-width: 100px;
  font-size: 9px;
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  color: #989898;
}
.n_web .featured-media-bb {
  margin-top: 40px;
}
.n_web .featured-media-bb h3 {
  font-size: 14px;
  letter-spacing: 14px;
  margin: 0 0 20px;
}
.n_web .featured-media-bb .s-wd {
  width: 48%;
}
.n_web .featured-media-bb .img-prt {
  height: 288px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.n_web .featured-media-bb .img-prt img {
  height: 100%;
}
.n_web .featured-media-bb .detail-info small {
  color: #e5e5e5;
  top: -9px;
  position: relative;
}
.n_web .featured-media-bb .detail-info h4 {
  font-size: 24px;
  margin: 10px 0 12px;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.n_web .featured-media-bb .detail-info p {
  color: #d8d8d8;
  font-size: 14px;
  line-height: 22px;
  margin: 0 0 20px;
}
.n_web .featured-media-bb .detail-info p + p {
  margin-top: 12px;
}
.n_web .featured-media-bb .detail-info a {
  color: #f05139;
  font-size: 10px;
}
.n_web .featured-media-bb .detail-info a:hover {
  text-decoration: underline;
}
.n_web .recent-media-se {
  padding: 60px 0;
}
.n_web .recent-media-se h3 {
  letter-spacing: 10px;
  color: #707070;
  font-size: 14px;
  margin: 0 0 15px;
}
.n_web .recent-media-se .sm-w {
  width: 48%;
}
.n_web .recent-media-se .im-se {
  width: 155px;
  height: 120px;
  border-radius: 5px;
  overflow: hidden;
}
.n_web .recent-media-se .im-se img {
  height: 100%;
}
.n_web .recent-media-se .info-se {
  width: calc(100% - 155px);
  padding-left: 30px;
  color: #707070;
}
.n_web .recent-media-se .info-se small {
  font-size: 10px;
  top: -9px;
  position: relative;
}
.n_web .recent-media-se .info-se h4 {
  font-size: 20px;
  margin: 0 0 8px;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.n_web .recent-media-se .info-se p {
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 10px;
}
.n_web .recent-media-se .media-corn {
  margin: 15px 0;
}
.n_web .recent-media-se .info-se p + p {
  margin-top: 10px;
}
.n_web .recent-media-se .info-se a {
  color: #f05139;
  font-size: 12px;
}
.n_web .recent-media-se .info-se a:hover {
  text-decoration: underline;
}
.n_web .load-more {
  padding: 60px 0;
}
.n_web .load-more::after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#111a44),
    to(#010726)
  );
  background: linear-gradient(90deg, #111a44 0, #010726 100%);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  background-blend-mode: soft-light;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.n_web .load-more p {
  letter-spacing: 3px;
  font-size: 10px;
}
@media screen and (max-width: 991px) {
  .n_web .recent-media-se .sm-w {
    width: 100%;
  }
  .n_web .recent-media-se .media-corn {
    margin: 0;
  }
  .n_web .recent-media-se .media-corn + .media-corn {
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .n_web .featured-media-bb .s-wd {
    width: 100%;
  }
  .n_web .featured-media-bb .detail-info {
    margin: 15px 0 0;
  }
}
@media screen and (max-width: 479px) {
  .n_web .recent-media-se .im-se {
    width: 100%;
    height: 230px;
  }
  .n_web .recent-media-se .info-se {
    width: 100%;
    padding: 0;
    margin: 20px 0 0;
  }
}
.n_web .particles-js-canvas-el {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.n_web {
  font-size: 14px;
  color: #66667e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  font-family: muliregular;
  scroll-behavior: smooth;
}
.n_web {
  background-color: #111a44;
  position: relative;
  z-index: 2;
  overflow-x: hidden;
}
.body-scroll-dis {
  position: relative;
}
.body-scroll-dis::after {
  height: 100%;
  width: 100%;
  display: block;
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 8;
}
.n_web .full-box-link {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.n_web :focus,
.n_web button:focus {
  outline: 0;
}
.n_web .object-fit-cover {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}
.n_web .object-fit-fill {
  -o-object-fit: fill;
  object-fit: fill;
}
.n_web .object-fit-contain {
  -o-object-fit: contain;
  object-fit: contain;
}
.n_web .object-fit-none {
  -o-object-fit: none;
  object-fit: none;
}
.n_web .object-fit-scale-down {
  -o-object-fit: scale-down;
  object-fit: scale-down;
}
.n_web .ff-muli-r {
  font-family: muliregular;
}
.n_web .ff-muli-b,
.o_web nav.side-menu .ff-muli-b {
  font-family: mulibold;
}
.n_web .ff-muli-l {
  font-family: mulilight;
}
.n_web .ff-muli-sb {
  font-family: mulisemibold;
}
.n_web .ff-barlow-r {
  font-family: muliregular;
}
.n_web .ff-b-condensed {
  font-family: "Barlow Condensed", sans-serif;
}
.n_web ul,
.n_web ul li,
.o_web ul.menu,
.o_web ul.menu li {
  padding: 0;
  list-style: none;
  margin: 0;
}
@media screen and (max-width: 430px) {
  .n_web ul li{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
  }
  .n_web p{
    display: flex;
    justify-content: center;
  }
}
.n_web h1,
.n_web h2,
.n_web h3,
.n_web h4,
.n_web h5,
.n_web h6,
.n_web p {
  margin: 0;
}

.n_web .h-100 {
  height: 100%;
}
.n_web .a-center {
  height: calc(100% - 85px);
}
.n_web .d-none-n {
  display: none;
}
.n_web .after-bg-none::after,
.n_web .before-bg-none::before {
  display: none;
}
.n_web .after-po::after,
.n_web .before-po::before {
  position: absolute;
  content: "";
}
.n_web .b-s-20 {
  height: 20px;
}
.n_web .b-s-80 {
  height: 80px;
}
.n_web .b-s-15 {
  height: 15px;
}
.n_web .z-7 {
  z-index: 7;
}
.n_web a,
.n_web a:focus,
.n_web a:hover,
.n_web i {
  color: inherit;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.n_web .v-center {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.n_web .btn.focus,
.n_web .btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.n_web .d-none-n {
  display: none;
}
.n_web .orange-color {
  color: #f05139;
}
.n_web .for-after-sprite::after,
.n_web .for-before-sprite::before,
.n_web .for-sprite-call,
.o_web .for-after-sprite::after,
.o_web .for-before-sprite::before,
.o_web .for-sprite-call {
  background: url(../images/sprite.svg) no-repeat;
}
.n_web .for-after-sprite::after,
.n_web .for-before-sprite::before,
.o_web .for-after-sprite::after,
.o_web .for-before-sprite::before {
  position: absolute;
  content: "";
}
.n_web main {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-flex: 1;
}
.n_web .fixed-header {
  position: fixed !important;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: #fcfcfc;
  top: 0;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.19);
  -ms-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.19);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.19);
  -webkit-animation: smoothScroll 1s forwards;
  animation: smoothScroll 1s forwards;
  padding: 20px 0;
}
.n_web .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
@-webkit-keyframes smoothScroll {
  0% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes smoothScroll {
  0% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.n_web header .logo {
  max-width: 180px;
}
.n_web header .logo img {
  max-width: 100%;
}
.n_web .main-link-here {
  display: none;
}
.n_web .eco-system-se.test {
  background: url(../images/solutio_shape_bg.jpg) center bottom no-repeat;
  background-size: cover;
  width: 1280px;
  height: 771px;
  margin: 0 auto;
}
.n_web .eco-system-se.test-2 {
  background: url(../images/solutio_shape_bg.jpg) center bottom no-repeat;
  background-size: cover;
  width: 100%;
  height: 700px;
}
.n_web .navigation-dots-container {
  position: fixed;
  left: 15px;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.n_web .navigation-dots-container a.active {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  background-color: #fff;
}
.n_web .navigation-dots-container a {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #707070;
  font-size: 0;
  display: block;
}
.n_web .navigation-dots-container a + a {
  margin-top: 20px;
}
.n_web header {
  padding: 18px 0;
  background-color: #fcfcfc;
  z-index: 9;
}
.n_web header .letest-news {
  margin-right: 100px;
}
.n_web header .letest-news p {
  text-transform: uppercase;
  font-size: 10px;
  color: #66667e;
  font-weight: 300;
  letter-spacing: 2px;
}
.n_web header .letest-news p ~ p {
  text-transform: none;
  color: #66667e;
  font-size: 12px;
  letter-spacing: 1px;
}
.n_web header .letest-news:hover p {
  color: #ef4b23;
}
.n_web header nav.side-menu,
.o_web header nav.side-menu {
  height: 25px;
  width: 25px;
}
.n_web nav.side-menu .menu,
.o_web nav.side-menu .menu {
  top: 0;
  left: auto;
  margin: 0;
  z-index: 1;
  width: 100%;
  padding: 15px 30px;
  background: #fff;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(46%, #fff),
    to(#d8d8d8)
  );
  background: linear-gradient(180deg, #fff 46%, #d8d8d8 100%);
  position: absolute;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(1, 0.5, 0, 1);
  transition: -webkit-transform 0.3s cubic-bezier(1, 0.5, 0, 1);
  transition: transform 0.3s cubic-bezier(1, 0.5, 0, 1);
  transition: transform 0.3s cubic-bezier(1, 0.5, 0, 1),
    -webkit-transform 0.3s cubic-bezier(1, 0.5, 0, 1);
  width: 350px;
  right: 0;
  height: 100vh;
  overflow: auto;
}
.o_web nav.side-menu .menu {
  transform: translateY(-103%);
}
.n_web nav.side-menu .menu.active,
.o_web nav.side-menu .menu.active {
  top: 70px;
  height: calc(100vh - 70px);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  z-index: 5;
}
nav.side-menu .menu.active {
  -webkit-transform: translateY(-3%);
  transform: translateY(-3%);
  z-index: 5;
}
nav.side-menu .menu li,
nav.side-menu .menu li a,
nav.side-menu .menu li ul li button {
  color: #111a44;
  line-height: 1;
  font-size: 13px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
}
nav.side-menu > .menu > li > a {
  font-family: mulibold;
}
nav.side-menu > .menu > li > ul > li > a {
  font-family: mulilight;
}
.n_web nav.side-menu .menu li + li,
.o_web nav.side-menu .menu li + li {
  margin-top: 18px;
}
.n_web nav.side-menu .menu li:last-child,
.o_web nav.side-menu .menu li:last-child {
  margin-bottom: 24px;
}
.n_web nav.side-menu .menu li ul li + li,
.o_web nav.side-menu .menu li ul li + li {
  margin-top: 10px;
}
.n_web nav.side-menu .menu li ul,
.o_web nav.side-menu .menu li ul {
  padding: 15px 15px 0;
}
nav.side-menu .menu li ul li button {
  background: 0 0;
  border: none;
  padding: 0;
}
nav.side-menu .menu li ul li a,
nav.side-menu .menu li ul li button {
  color: #66667e;
  font-size: 11px;
}
.n_web
  nav.side-menu
  .menu
  li
  ul
  li
  button
  .n_web
  nav.side-menu
  .menu
  li
  a:hover,
.n_web nav.side-menu .menu li ul li button:hover {
  color: #ef4b23;
}
.n_web nav.side-menu .menu-toggle,
.o_web nav.side-menu .menu-toggle {
  z-index: 2;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.n_web nav.side-menu .menu-toggle:hover::before,
.o_web nav.side-menu .menu-toggle:hover::before {
  background-position: -30px 0;
}
.n_web nav.side-menu .menu-toggle span {
  display: none;
}
.n_web nav.side-menu .menu-toggle::after,
.n_web nav.side-menu .menu-toggle::before,
.o_web nav.side-menu .menu-toggle::after,
.o_web nav.side-menu .menu-toggle::before {
  left: 0;
  content: "";
  width: 25px;
  height: 25px;
  background-position: 0 0;
}
.n_web nav.side-menu .menu-toggle::before,
.o_web nav.side-menu .menu-toggle::before {
  top: 0;
}
.n_web nav.side-menu .menu-toggle::after,
.o_web nav.side-menu .menu-toggle::after {
  bottom: 0;
}
.n_web nav.side-menu .menu-toggle.menu-open span {
  background: rgba(255, 255, 255, 0);
}
.n_web nav.side-menu .menu-toggle.menu-open::after,
.n_web nav.side-menu .menu-toggle.menu-open::before,
.o_web nav.side-menu .menu-toggle.menu-open::after,
.o_web nav.side-menu .menu-toggle.menu-open::before {
  height: 25px;
  width: 25px;
  background-position: -30px 0;
}
.n_web nav.side-menu .search-bar {
  width: 250px;
}
.n_web .search {
  width: 100%;
}
.n_web .points-in-white {
  margin: 10px 0 0;
}
.n_web .points-in-white li {
  padding: 0 0 0 20px;
}
.n_web .points-in-white li::after {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #ef4b23;
  left: 0;
  top: 7px;
}
.n_web .points-in-white li + li {
  margin-top: 10px;
}
.n_web .search-term {
  width: 100%;
  background-color: transparent;
  border-width: 2px 2px 2px 2px;
  border-style: solid;
  border-color: transparent transparent #66667e transparent;
  border-right: none;
  padding: 5px;
  height: auto;
  outline: 0;
  color: #9dbfaf;
}
.n_web .search-term:focus {
  color: #00b4cc;
}
.n_web .searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid #00b4cc;
  background: #00b4cc;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}
@keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -400px 0;
  }
}
@-webkit-keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -400px 0;
  }
}
.n_web .bellow-text-part {
  padding-top: 20px;
  padding-bottom: 20px;
}
.n_web .bellow-text-part p {
  color: #d8d8d8;
  font-size: 14px;
  line-height: 26px;
  width: 48%;
  margin: 10px 0;
}
.n_web .bellow-text-part.new-our-sol {
  padding: 0 0 40px;
}
.n_web .bellow-text-part.new-our-sol p {
  color: inherit;
}
.n_web .small-conn {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}
.n_web .morinfo-ab-coint .small-conn .sw-p {
  padding: 0 30px;
}
.n_web .morinfo-ab-coint .small-conn .sm-title {
  margin: 0 0 20px 0;
}
.n_web .morinfo-ab-coint .small-conn .sm-title i {
  margin-right: 15px;
}
.n_web .morinfo-ab-coint .small-conn .sm-title h3 {
  color: #111a44;
  font-size: 18px;
  letter-spacing: 5px;
  line-height: 1;
}
.n_web .morinfo-ab-coint .small-conn .bellow-text-part .txt-p {
  width: 100%;
  padding: 0;
}
.n_web .find-out-more {
  line-height: 1;
  color: #fff;
  border-radius: 30px;
  padding: 10px 35px 12px;
  display: inline-block;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f05139),
    to(#f08639)
  );
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
  -webkit-box-shadow: 0 0 30px #f05139;
  box-shadow: 0 0 30px #f05139;
}
.n_web .find-out-more:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f08639),
    to(#f05139)
  );
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
  color: #fff;
}
.n_web .w-48 {
  width: 48%;
}
.n_web .breadcrumbs-div {
  margin: 0 0 20px;
}
.n_web .breadcrumbs-div li + li {
  margin-left: 8px;
}
.n_web .breadcrumbs-div li,
.n_web .breadcrumbs-div li a {
  color: #e5e5e5;
  font-size: 12px;
}
.n_web .breadcrumbs-div li a:hover {
  text-decoration: underline;
}
.n_web .breadcrumbs-div .arrow-ic {
  top: -1px;
}
.n_web .cust-zo-contact-form h2 {
  font-size: 24px;
  letter-spacing: 5px;
  line-height: 1.5;
  margin: 0 0 30px;
}
.modal button {
  background: #f08639;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f08639),
    to(#f05139)
  );
  background: linear-gradient(90deg, #f08639 0, #f05139 100%);
  padding: 12px 40px;
  color: #fff;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  outline: 0;
}
.n_web .apply-accelerated .fr-shapre,
.n_web .eco-system-se .for-shape,
.n_web .three-m-s.on-m-hmp .for-shape {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
}
.n_web .eco-system-se .for-shape::after,
.n_web .eco-system-se .for-shape::before {
  position: absolute;
  content: "";
}
.n_web .apply-accelerated .fr-shapre::before,
.n_web .eco-system-se .for-shape::after,
.n_web .eco-system-se .for-shape::before,
.n_web .eco-system-se.partners-shapes .for-shape::before,
.n_web .main-hop.eco-system-se .for-shape::after,
.n_web .main-hop.eco-system-se .for-shape::before,
.n_web .three-m-s.on-m-hmp .for-shape::before {
  background: url(../images/ring.png) 0 0 no-repeat;
  background-size: 100%;
}
.n_web .main-hop.eco-system-se .for-shape::after {
  width: 540px;
  height: 540px;
  top: -290px;
  left: -100px;
  bottom: auto;
}
.n_web .eco-system-se .for-shape::before {
  height: 570px;
  width: 570px;
  left: auto;
  right: -230px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.n_web .our-so-new-pg.eco-system-se .for-shape::before {
  bottom: auto;
  top: -250px;
}
.n_web .our-so-new-pg.eco-system-se .for-shape::after {
  height: 600px;
  width: 600px;
}
.n_web .eco-system-se.our-team .for-shape::before {
  top: 120px;
  bottom: auto;
  z-index: -1;
}
.n_web .eco-system-se.our-team .for-shape::after {
  top: 10px;
  bottom: auto;
  width: 600px;
  height: 600px;
  z-index: -1;
  left: -320px;
}
.n_web .eco-system-se.partners-shapes .for-shape::before {
  top: -250px;
  bottom: auto;
  right: -210px;
  height: 520px;
  width: 520px;
}
.n_web .eco-system-se .for-shape::after {
  top: 0;
  width: 540px;
  height: 540px;
  background-size: 100%;
  bottom: 0;
  margin: auto;
  left: -330px;
}
.n_web .eco-system-se.solutio-shape .for-shape::after {
  top: -250px;
  bottom: auto;
}
.n_web .eco-system-se.solutio-shape .for-shape::before {
  top: -280px;
  bottom: auto;
}
.n_web .apply-accelerated .fr-shapre::before {
  height: 490px;
  width: 490px;
  left: auto;
  right: -290px;
  top: -70px;
}
.n_web .contact-us-bg.apply-accelerated .fr-shapre::before {
  top: 100px;
}
.n_web .three-m-s.on-m-hmp .for-shape::before {
  height: 560px;
  width: 560px;
  left: auto;
  right: -300px;
  bottom: 0;
  top: 0;
  margin: auto;
}
.n_web .eco-system-se.events-bg .for-shape::after {
  bottom: -250px;
  top: auto;
  width: 550px;
  height: 590px;
}
.n_web .eco-system-se.events-bg .for-shape::before {
  top: -220px;
  bottom: auto;
}
.n_web .partner-shpe.apply-accelerated::after {
  height: 520px;
}
.n_web .main-title-s h2 {
  font-size: 15px;
  letter-spacing: 25px;
}
.n_web .main-title-s h1 {
  font-size: 88px;
  letter-spacing: 2px;
  line-height: 0.9;
}
.n_web .main-title h2 {
  font-size: 15px;
  letter-spacing: 25px;
}
.n_web .main-title h1 {
  font-size: 95px;
  letter-spacing: 2px;
  line-height: 1;
}
.n_web .text-contain {
  max-width: 500px;
  width: 100%;
  margin: 30px 0 0;
}
.n_web .text-contain p {
  font-size: 16px;
  line-height: 28px;
  color: #d8d8d8;
}
.n_web .text-contain p a:hover {
  text-decoration: underline;
}
.n_web .eco-system-se {
  padding: 60px 0;
  overflow: hidden;
}
.n_web .eco-system-se.solutio-shape {
  background: url(../images/solutio_shape_bg.jpg) 0 0 no-repeat;
  height: 100vh;
  width: 100%;
  background-size: cover;
  overflow-y: auto;
  background-position: bottom center;
}
.n_web .eco-system-se .main-link-here .full-bg-left::after {
  position: absolute;
  content: "";
  left: calc(-100% + 15px);
  height: 100%;
  width: 100%;
  background-color: #fff;
  top: 0;
}
.n_web .eco-system-se .main-link-here .left-link {
  background-color: #fff;
  padding: 20px 0;
}
.n_web .eco-system-se .left-link .sub-link {
  padding: 0 40px 0 0;
}
.n_web .eco-system-se .left-link .sub-link p {
  font-size: 10px;
  color: #66667e;
  letter-spacing: 1px;
}
.n_web .eco-system-se .left-link .sub-link:hover p {
  color: #ef4b23;
}
.n_web .eco-system-se .left-link .sub-link p + p {
  margin-top: 10px;
  color: #3e3e46;
  font-size: 13px;
}
.n_web .eco-system-se .left-link .sub-link + .sub-link {
  padding: 0 40px;
  position: relative;
}
.n_web .eco-system-se .left-link .sub-link + .sub-link::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  left: 0;
  top: 0;
  background-color: #d8d8d8;
}
.n_web .eco-system-se .main-link-here .rig-link a {
  padding: 35px 30px;
  display: inline-block;
  line-height: 1;
  background: #f05139;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f05139),
    to(#f08639)
  );
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
  color: #fff;
  border-radius: 0 25px 0 0;
}
.n_web .eco-system-se .main-link-here .rig-link a:hover {
  background: #f08639;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f08639),
    to(#f05139)
  );
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
}
.n_web .start-investing-ani {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 8;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.n_web .rig-link-fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 8;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.n_web .token-tabs-se {
  padding: 50px 0;
  background-color: #f2f2f2;
  z-index: 5;
}
.n_web .token-tabs-se::after {
  background-image: url(../images/back_bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  height: 400px;
  width: 400px;
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  z-index: -1;
}
.n_web .token-tabs-se .nav-tabs {
  display: none;
}
.n_web .token-tabs-se .card {
  background-color: transparent;
}
.n_web .token-tabs-se .nav-tabs {
  border: none;
}
.n_web .token-tabs-se .nav-tabs .nav-item {
  margin: 0;
}
.n_web .token-tabs-se .nav-tabs .nav-item + .nav-item {
  margin-left: 25px;
}
.n_web .token-tabs-se .the-edg-sou {
  margin-bottom: 20px;
  font-size: 16px;
}
.n_web .token-tabs-se .nav-tabs .nav-link {
  border-radius: 50px;
  background-color: #fff;
  color: #989898;
  border-radius: 12px;
  letter-spacing: 1.2px;
  font-size: 12px;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  min-width: 100px;
  text-align: center;
}
.n_web .token-tabs-se .card-body {
  padding: 20px 0 0 0;
}
.n_web .token-tabs-se .tab-swap .img-part {
  width: 48%;
}
.n_web .token-tabs-se .tab-swap .img-part img {
  max-width: 100%;
}
@media screen and (min-width: 992px) {
  .n_web .token-tabs-se .tab-swap .txt-part {
    width: 48%;
  }
}
.n_web .token-tabs-se .tab-swap .txt-part h2 {
  font-size: 52px;
  color: #0f1740;
  line-height: 1;
}
.n_web .token-tabs-se .tab-swap .txt-part small {
  font-size: 22px;
  letter-spacing: 6px;
  color: #262626;
}
.n_web .token-tabs-se .tab-swap .txt-part p {
  margin: 20px 0 0 0;
  font-size: 14px;
  line-height: 26px;
  color: #66667e;
}
.n_web .token-tabs-se .tab-swap .txt-part ul {
  margin: 15px 0;
}
.n_web .token-tabs-se .tab-swap .txt-part ul li {
  padding: 0 0 0 25px;
}
.n_web .token-tabs-se .tab-swap .txt-part ul li + li {
  margin-top: 10px;
}
.n_web .token-tabs-se .tab-swap .txt-part ul li::after {
  height: 10px;
  width: 10px;
  left: 0;
  top: 8px;
  border-radius: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f05139),
    to(#f08639)
  );
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
}
.n_web .token-tabs-se .tab-swap .txt-part .btn-apply-now {
  padding: 10px 20px 12px 24px;
  display: inline-block;
  line-height: 1;
  background: #f05139;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f05139),
    to(#f08639)
  );
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
  color: #fff;
  border-radius: 25px;
}
.n_web .token-tabs-se .tab-swap .txt-part .btn-apply-now:hover {
  background: #f08639;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f08639),
    to(#f05139)
  );
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
}
.n_web .token-tabs-se .tab-swap .txt-part .btn-apply-now .ic-rig8-arrow {
  height: 12px;
  width: 12px;
  background-position: -161px -2px;
  top: 2px;
  margin-left: 10px;
}
.n_web .token-tabs-se .tab-swap .txt-part .exc-rate p {
  margin: 0;
  font-size: 12px;
}
.n_web .token-tabs-se .tab-swap .txt-part .iner-pt {
  max-width: 560px;
  width: 100%;
}
.n_web .token-tabs-se .nav-tabs .nav-item,
.n_web .token-tabs-se .nav-tabs .nav-item:focus,
.n_web .token-tabs-se .nav-tabs .nav-link,
.n_web .token-tabs-se .nav-tabs .nav-link:focus,
.n_web .token-tabs-se .nav-tabs .nav-link:hover {
  border: none;
}
.n_web .token-tabs-se .nav-tabs .nav-item.show .nav-link,
.n_web .token-tabs-se .nav-tabs .nav-link.active,
.n_web .token-tabs-se .nav-tabs .nav-link:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f08639),
    to(#f05139)
  );
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
  color: #fff;
  border: none;
}
.n_web .token-tabs-se .edge-smal {
  left: auto;
  right: 0;
}
.n_web .btn-gp-2 .btn-apply-now {
  padding: 10px 20px 12px 24px;
  display: inline-block;
  line-height: 1;
  background: #f05139;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f05139),
    to(#f08639)
  );
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
  color: #fff;
  border-radius: 25px;
}
.n_web .btn-gp-2 .btn-apply-now:hover {
  background: #f08639;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f08639),
    to(#f05139)
  );
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
}
.n_web .btn-gp .btn-apply-now {
  padding: 10px 35px 12px;
  display: inline-block;
  line-height: 1;
  background-color: #fff;
  color: #f05139;
  border-radius: 25px;
  border: 2px solid #fff;
  margin-left: 50px;
}
.n_web .btn-gp .btn-apply-now:hover {
  background-color: transparent;
  color: #fff;
}
.n_web .main-hop.eco-system-se {
  height: auto;
  min-height: calc(100vh - 70px);
  overflow: inherit;
  padding: 80px 0 80px;
}
.n_web .main-hop .lining-to {
  margin: 20px 0 0 0;
  line-height: 1;
}
.n_web .main-hop .lining-to a,
.n_web .main-hop .lining-to p {
  font-size: 12px;
  letter-spacing: 1.5px;
  color: #f06f39;
}
.n_web .main-hop .lining-to span {
  display: inline-block;
  margin: 0 10px;
  color: #f06f39;
}
.n_web .main-hop.eco-system-se .follow-us-socil .btn-apply-now {
  padding: 15px 35px 17px;
}
.n_web .main-hop.eco-system-se .follow-us-socil .btn-apply-now .ic-rig8-arrow {
  height: 12px;
  width: 12px;
  background-position: -161px -2px;
  top: 2px;
  margin-left: 10px;
}
.n_web .main-hop.eco-system-se .follow-us-socil .line-btn {
  color: #fff;
  padding: 10px 30px 12px;
  border-radius: 25px;
  border: 1px solid #fff;
  line-height: 1;
  margin-left: 20px;
}
.n_web .main-hop.eco-system-se .follow-us-socil .line-btn:hover {
  background-color: #f06f39;
  border-color: #f06f39;
}
.n_web .we-hve-featu {
  padding: 30px 0 0;
}
.n_web .we-hve-featu .iiner-cont {
  padding: 0 70px 30px;
}
.n_web .we-hve-featu .lft-t {
  width: 300px;
}
.n_web .we-hve-featu .lft-t h3 {
  font-size: 28px;
  letter-spacing: 2px;
}
.n_web .we-hve-featu .rt-t {
  width: calc(100% - 320px);
  padding-left: 60px;
}
.n_web .we-hve-featu .rt-t p {
  font-size: 14px;
}
.n_web .advantage-over.part-logo {
  padding: 10px 0;
}
.n_web .part-logo .item .part-lgo-bx {
  min-height: 100px;
}
.n_web .part-logo .item .part-lgo-bx img {
  max-width: 100%;
  width: auto;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  opacity: 0.9;
}
.n_web .part-logo .logo-box {
  width: calc(100% / 8);
  padding: 0 10px;
  height: 60px;
}
.n_web .part-logo .logo-box img {
  max-width: 100%;
}
.n_web .advantageses-over {
  max-width: 680px;
  width: 100%;
  padding: 60px 0;
}
.n_web .advantageses-over h3 {
  font-size: 24px;
  letter-spacing: 5px;
  line-height: 1.3;
  margin: 0 0 20px;
}
.n_web .advantageses-over p {
  font-size: 14px;
  color: #d8d8d8;
  line-height: 26px;
}
.n_web .four-m-bxes .sm-w-bx {
  width: 50%;
}
.n_web .four-m-bxes .for-rt-p {
  padding-right: 50px;
}
.n_web .four-m-bxes .for-bm-p {
  padding-bottom: 50px;
}
.n_web .four-m-bxes .for-lt-p {
  padding-left: 50px;
}
.n_web .four-m-bxes .for-tp-p {
  padding-top: 50px;
}
.n_web .four-m-bxes .ic-four-i {
  width: 50px;
  height: 50px;
}
.n_web .four-m-bxes .ic-decentralized {
  background-position: -170px 0;
}
.n_web .four-m-bxes .ic-per-to {
  background-position: -220px 0;
}
.n_web .four-m-bxes .ic-true-value {
  background-position: -170px -50px;
}
.n_web .four-m-bxes .ic-multic-wal {
  background-position: -220px -50px;
}
.n_web .four-m-bxes .txt-se {
  width: calc(100% - 50px);
  padding-left: 25px;
}
.n_web .four-m-bxes .txt-se h3 {
  color: #e2e7f8;
  font-size: 18px;
  line-height: 1;
  margin: 0 0 10px;
}
.n_web .four-m-bxes .txt-se p {
  color: #d8d8d8;
  font-size: 14px;
  line-height: 26px;
}
.n_web .line-btne {
  color: #fff;
  padding: 10px 30px 12px;
  border-radius: 25px;
  border: 2px solid #fff;
  line-height: 1;
  margin: 20px 0 0 0;
}
.n_web .line-btne:hover {
  background-color: #f06f39;
  border-color: #f06f39;
  color: #fff;
}
.n_web .for-plus-sign::after,
.n_web .for-plus-sign::before {
  background-color: #282896;
  display: block;
  margin: auto;
  top: 0;
  left: 0;
}
.n_web .for-plus-sign::after {
  height: 100%;
  width: 1px;
  right: 0;
}
.n_web .for-plus-sign::before {
  width: 100%;
  height: 1px;
  bottom: -32px;
}
.n_web .our-technology.on-hmp-man::after {
  background-image: url(../images/back_bg.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  position: absolute;
  content: "";
  height: 400px;
  width: 400px;
  left: 0;
  top: auto;
  bottom: -50px;
  background-color: #111a44;
  z-index: -1;
  background-size: 100%;
  opacity: 0.2;
}
.n_web .advantage-over.we-are-differnent {
  padding: 0;
}
.n_web .we-are-differnent .into-two-part {
  width: 48%;
  padding: 40px 0;
  overflow: hidden;
  min-height: 380px;
}
.n_web .we-are-differnent .lft-tit h3 {
  font-size: 80px;
}
.n_web .we-are-differnent .for-cross-sign::after,
.n_web .we-are-differnent .for-cross-sign::before {
  height: calc(100% + 300px);
  width: 50px;
  background: #f05139;
  background: radial-gradient(circle, #f05139 0, #f05139 47%, #f08639 100%);
  left: 0;
  top: -150px;
  right: 0;
  margin: auto;
  z-index: -1;
}
.n_web .we-are-differnent .for-cross-sign::after {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
}
.n_web .we-are-differnent .for-cross-sign::before {
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
}
.n_web .we-are-differnent .rit-text small {
  font-size: 12px;
  line-height: 1;
  margin: 0 0 5px;
}
.n_web .we-are-differnent .rit-text h2 {
  letter-spacing: 3px;
  font-size: 24px;
  line-height: 1.3;
  margin: 0 0 20px;
}
.n_web .we-are-differnent .rit-text p {
  font-size: 14px;
  line-height: 26px;
  margin: 0 0 30px;
}
.n_web .we-are-differnent .rit-text a {
  line-height: 1;
  border: 2px solid #fff;
  border-radius: 30px;
  line-height: 1;
  padding: 10px 35px 12px;
  display: inline-block;
}
.n_web .we-are-differnent .rit-text a:hover {
  background-color: #fff;
  color: #111a44;
}
.n_web .three-m-s.on-m-hmp::after {
  background-color: #111a44;
  background-image: url(../images/full_overlay.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-blend-mode: hard-light;
  top: 0;
  left: 0;
  z-index: -1;
}
.n_web .cob-bcm-digital {
  padding: 70px 0;
  background-color: #34adff;
  background-image: -webkit-linear-gradient(180deg, #f2f2f2 50%, #0f1740 50%);
  background-image: gradient(180deg, #f2f2f2 50%, #0f1740 50%);
}
.n_web .cob-bcm-digital .sw-bx {
  width: 48%;
}
.n_web .cob-bcm-digital .sw-bx small {
  font-size: 12px;
}
.n_web .cob-bcm-digital .sw-bx h2 {
  margin: 20px 0;
  letter-spacing: 3px;
  font-size: 22px;
}
.n_web .cob-bcm-digital .sw-bx p {
  font-size: 14px;
  line-height: 26px;
  margin: 0 0 30px;
}
.n_web .cob-bcm-digital .rit-bx {
  padding: 0 0 0 50px;
}
.n_web .cob-bcm-digital .rit-bx small {
  color: #66667e;
}
.n_web .cob-bcm-digital .rit-bx p {
  color: #66667e;
}
.n_web .cob-bcm-digital .rit-bx h2 {
  color: #111a44;
}
.n_web .cob-bcm-digital .lft-bx {
  padding: 0 100px 0 0;
}
.n_web .cob-bcm-digital .lft-bx small {
  color: #d8d8d8;
}
.n_web .cob-bcm-digital .lft-bx p {
  color: #d8d8d8;
}
.n_web .cob-bcm-digital .lft-bx a {
  -webkit-box-shadow: 0 0 30px #f05139;
  box-shadow: 0 0 30px #f05139;
}
.n_web .cob-bcm-digital .rit-bx a {
  -webkit-box-shadow: 0 0 30px rgba(240, 81, 57, 0.5);
  box-shadow: 0 0 30px rgba(240, 81, 57, 0.5);
}
.n_web .cob-bcm-digital .lft-bx a,
.n_web .cob-bcm-digital .rit-bx a {
  line-height: 1;
  color: #fff;
  border-radius: 30px;
  line-height: 1;
  padding: 10px 35px 12px;
  display: inline-block;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f05139),
    to(#f08639)
  );
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
}
.n_web .cob-bcm-digital .lft-bx a:hover,
.n_web .cob-bcm-digital .rit-bx a:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f08639),
    to(#f05139)
  );
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
}
.n_web .comb-se .apply-accelerated.contact-us-bg {
  padding: 50px 0;
}
.n_web .advantage-over.apply-accelerated.contact-us-bg {
  background: #091b3e;
}
.n_web .comb-se .apply-accelerated.contact-us-bg .btn-apply-now {
  padding: 10px 20px 12px 24px;
  display: inline-block;
  line-height: 1;
  background: #f05139;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f05139),
    to(#f08639)
  );
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
  color: #fff;
  border-radius: 25px;
  border: none;
  letter-spacing: 0;
}
.n_web .comb-se .apply-accelerated.contact-us-bg .btn-apply-now:hover {
  background: #f08639;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f08639),
    to(#f05139)
  );
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
}
.n_web .comb-se .apply-accelerated.contact-us-bg .learn-more {
  border: 2px solid #fff;
  color: #fff;
  line-height: 1;
  border-radius: 20px;
  padding: 8px 30px 10px;
  font-size: 12px;
  display: inline-block;
}
.n_web .comb-se .apply-accelerated.contact-us-bg .learn-more:hover {
  background-color: #f05139;
  border-color: #f05139;
}
.n_web .comb-se .card {
  background-color: transparent;
  border: none;
}
.n_web .comb-se .card-body {
  padding: 0;
}
.n_web .comb-se .card-header .btn {
  padding: 0;
}
.n_web .comb-se .card-header {
  background-color: transparent;
  border: none;
}
.n_web .comb-se .x-tock.active-acc .blue-lg {
  display: none;
}
.n_web .comb-se .x-tock.active-acc .white-lg {
  display: block;
}
.n_web .comb-se .equity-tocke {
  margin-left: 45px;
}
.n_web .comb-se .btn-apply-now {
  padding: 10px 20px 12px 24px;
  line-height: 1;
  background-color: transparent;
  color: #fff;
  border-radius: 25px;
  border: 1px solid #fff;
}
.n_web .comb-se .btn-apply-now:hover {
  background: #f08639;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f08639),
    to(#f05139)
  );
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
  border-color: #f08639;
}
.n_web .comb-se .btn-apply-now .ic-rig8-arrow {
  height: 12px;
  width: 12px;
  background-position: -161px -2px;
  top: 2px;
  margin-left: 10px;
}
.n_web .morinfo-ab-coint .sw-p #edge-x-token-info {
  width: 100%;
}
.n_web .morinfo-ab-coint .bellow-text-part p {
  width: 100%;
  margin: 0;
}
.n_web .morinfo-ab-coint .bellow-text-part p + p {
  margin-top: 20px;
}
.n_web .morinfo-ab-coint .bellow-text-part .t-svg-img {
  width: 75px;
}
.n_web .morinfo-ab-coint .bellow-text-part .txt-p {
  width: calc(100% - 75px);
  padding-left: 10px;
}
.n_web .comb-se .current-scenario .current-se-box {
  padding: 0;
}
.n_web .current-indus-solu {
  padding: 40px 0;
  overflow: hidden;
}
.n_web .current-indus-solu::after {
  background-color: #111a44;
  background-image: url(../images/full_overlay.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-size: auto;
  background-blend-mode: color-dodge;
  top: 0;
  left: 0;
  z-index: -1;
}
.n_web .current-indus-solu::before {
  background-image: url(../images/back_bg.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  position: absolute;
  height: 270px;
  width: 300px;
  left: 0;
  top: auto;
  bottom: 0;
  background-blend-mode: soft-light;
  background-color: #111a44;
  background-size: 100%;
}
.n_web .current-indus-solu h2 {
  font-size: 24px;
  letter-spacing: 5px;
  line-height: 1.5;
  margin: 0 0 30px;
}
.n_web .current-indus-solu .inner-tx {
  max-width: 700px;
  width: 100%;
  margin: 0 0 40px;
}
.n_web .current-indus-solu .inner-tx p {
  color: #d8d8d8;
  font-size: 14px;
  line-height: 26px;
}
.n_web .current-indus-solu .com-thn {
  letter-spacing: 3px;
  font-size: 12px;
}
.n_web .current-indus-solu .comparetable-table tbody tr td.bnone {
  border: none;
}
.n_web .current-indus-solu .comparetable-table tbody tr th {
  line-height: 2;
  padding: 5px;
}
.n_web .current-indus-solu .comparetable-table tbody tr th.for-widht {
  width: 220px;
}
.n_web .current-indus-solu .comparetable-table tbody tr td {
  padding: 15px 15px 18px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
}
.n_web .current-indus-solu .comparetable-table tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.15);
}
.n_web .current-indus-solu .comparetable-table tbody tr.bg-none {
  background-color: transparent;
}
.n_web .current-indus-solu .comparetable-table tbody tr td.g-bg-col {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f08639),
    to(#f05139)
  );
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
}
.n_web .current-indus-solu .card {
  background-color: rgba(0, 0, 0, 0.2);
}
.n_web .current-indus-solu .card-header {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
.n_web .only-img-sec {
  background-image: url(../images/only_img.png);
  background-color: #111a44;
  background-blend-mode: lighten;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 720px;
  padding: 80px 0 0 0;
}
.n_web .only-img-sec .white-bar::after {
  position: absolute;
  content: "";
  top: auto;
  bottom: 0;
  height: 60px;
  max-width: 600px;
  background-color: #fff;
  width: 100%;
}
.n_web .current-scenario.cust-part {
  padding: 60px 0;
}
.n_web .current-scenario .current-se-box .left-se {
  width: 400px;
}
.n_web .current-scenario .current-se-box .right-se {
  width: calc(100% - 400px);
  padding-left: 30px;
}
.n_web .dot-img::after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#111a44),
    to(#010726)
  );
  background: linear-gradient(90deg, #111a44 0, #010726 100%);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  background-blend-mode: soft-light;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.n_web .current-scenario .current-se-box p {
  color: #66667e;
  font-size: 10px;
  margin: 0 0 15px;
  letter-spacing: 2px;
}
.n_web .current-scenario .current-se-box h2 {
  color: #111a44;
  font-size: 22px;
  letter-spacing: 5px;
}
.n_web .current-scenario .current-se-box .inner-contain p {
  margin: 0;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0;
}
.n_web .current-scenario.advantage-over {
  padding: 0;
}
.n_web .current-scenario.advantage-over .same-box h3 {
  margin: 0 0 10px;
  font-size: 22px;
}
.n_web .current-scenario.advantage-over .same-box p {
  color: #fff;
}
.n_web .current-scenario .three-points .same-box {
  padding: 60px 50px;
  width: 33%;
}
.n_web .current-scenario .same-box .ic-se {
  height: 70px;
  width: 70px;
  display: inline-block;
  margin: 0 0 25px;
}
.n_web .current-scenario .same-box .ic-money {
  background-position: -305px 0;
}
.n_web .current-scenario .same-box h3 {
  margin: 0 0 20px;
  font-size: 24px;
}
.n_web .current-scenario .same-box p {
  color: #d8d8d8;
  font-size: 14px;
  line-height: 26px;
}
.n_web .advantage-over {
  padding: 60px 0;
  z-index: 1;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f08639),
    to(#f05139)
  );
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
}
.n_web .advantage-over .btn-g {
  margin-top: 20px;
}
.n_web .advantage-over .btn-g a {
  line-height: 1;
  border: 2px solid #fff;
  border-radius: 30px;
  line-height: 1;
  padding: 10px 35px 12px;
  display: inline-block;
}
.n_web .advantage-over .btn-g a:hover {
  background-color: #fff;
  color: #111a44;
}
.n_web .over-lay-05::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f08639),
      to(#f05139)
    ),
    url(../images/overlay_05.png);
  background: linear-gradient(180deg, #f08639 0, #f05139 100%),
    url(../images/overlay_05.png);
  background-blend-mode: lighten;
  background-size: cover;
  background-repeat: no-repeat;
}
.n_web .advantage-over::after {
  content: "";
  height: 100%;
  width: 100%;
  max-width: 900px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f08639),
      to(#f05139)
    ),
    url(../images/back_bg.png);
  background: linear-gradient(180deg, #f08639 0, #f05139 100%),
    url(../images/back_bg.png);
  background-blend-mode: overlay;
  background-size: auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
.n_web .advantage-over.becom-our::after {
  display: none;
}
.n_web .advantage-over.becom-our .inner-text {
  max-width: 100%;
}
.n_web .btn-job-opning:hover {
  background-color: #fff;
  color: #f05139;
}
.n_web .advantage-over.becom-our .inner-text p {
  width: calc(100% - 150px);
}
.n_web .advantage-over .s-w {
  width: 48%;
}
.n_web .advantage-over .inner-text {
  max-width: 740px;
  width: 100%;
}
.n_web .advantage-over .inner-text.w-1 {
  max-width: 100%;
}
.n_web .advantage-over .inner-text h2 {
  margin: 0 0 30px;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 4px;
}
.n_web .advantage-over .inner-text p {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1.5px;
}
.n_web .advantage-over .inner-text p + p {
  margin-top: 20px;
}
.n_web .our-technology.on-hmp-man {
  padding: 0 0 80px;
}
.n_web .our-technology {
  padding: 0;
}
.n_web .our-technology .sm-w {
  width: 48%;
}
.n_web .our-technology .img-p img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
.our-technology .for-bg::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  max-width: 580px;
  background-image: url(../images/back_bg.png);
  background-color: #111a44;
  background-size: 100%;
  z-index: -1;
  top: 0;
  opacity: 0.2;
}
.n_web .our-technology .contain-p small {
  color: #d8d8d8;
  font-size: 10px;
  letter-spacing: 1px;
}
.n_web .our-technology .contain-p h2 {
  font-size: 21px;
  line-height: 28px;
  margin: 20px 0 30px;
  letter-spacing: 4px;
}
.n_web .our-technology .contain-p p {
  color: #d8d8d8;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1.3px;
}
.n_web .our-technology .contain-p .line-btn {
  color: #fff;
  padding: 10px 30px 12px;
  border-radius: 25px;
  border: 1px solid #fff;
  line-height: 1;
  margin: 20px 0 0 0;
}
.n_web .our-technology .contain-p .line-btn:hover {
  background-color: #f06f39;
  border-color: #f06f39;
}
.n_web .eco-system-se.our-team.who-bg::before {
  background: 0 0;
}
.n_web .eco-system-se.asia-blc::after,
.n_web .eco-system-se.who-bg::after {
  display: none;
}
.n_web .eco-system-se.asia-blc::before,
.n_web .eco-system-se.who-bg::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: url(../images/asia_hero.jpg) 0 0 no-repeat;
  background-size: cover;
  top: 0;
  z-index: -1;
}
.n_web .eco-system-se.our-so-new-pg .some-str-change {
  left: auto;
  right: 0;
}
.n_web .eco-system-se.our-so-new-pg .some-str-change .text-contain {
  padding: 0 70px 0 0;
}
.n_web .eco-system-se.our-so-new-pg {
  height: 700px;
  padding: 50px 0;
}
.n_web .eco-system-se.our-so-new-pg::before {
  width: 100%;
  background: url(../images/our_solution_new.jpg) 0 0 no-repeat;
  background-size: cover;
  top: 0;
  z-index: -1;
  display: block;
  -webkit-animation: none;
  animation: none;
  background-position: center center;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.n_web .eco-system-se.events-bg::before {
  height: 100%;
  width: 100%;
  background: url(../images/event_bg.jpg) 0 0 no-repeat;
  background-size: cover;
  top: 0;
  z-index: -1;
}
.n_web .eco-system-se.asia-blc .main-title h2 {
  letter-spacing: normal;
  font-size: 22px;
  line-height: 28px;
  margin-left: 20px;
}
.n_web .eco-system-se.asia-blc .main-title h1 {
  font-size: 120px;
  line-height: 1;
}
.n_web .eco-system-se .follow-us-socil {
  margin: 30px 0 0;
}
.n_web .eco-system-se .follow-us-socil .btn-apply-now {
  padding: 14px 45px;
  display: inline-block;
  line-height: 1;
  background: #f05139;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f05139),
    to(#f08639)
  );
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
  color: #fff;
  border-radius: 25px;
}
.n_web .eco-system-se .follow-us-socil .btn-apply-now:hover {
  background: #f08639;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f08639),
    to(#f05139)
  );
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
}
.n_web .eco-system-se .follow-us-socil .follow {
  margin-left: 30px;
}
.n_web .eco-system-se .follow-us-socil .follow p {
  margin-bottom: 5px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-so {
  height: 16px;
  width: 16px;
  display: inline-block;
}
.n_web .eco-system-se .follow-us-socil .social .ic-so + .ic-so {
  margin-left: 4px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-link {
  background-position: -124px -34px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-link:hover {
  background-position: -124px -17px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-fb {
  background-position: -56px -34px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-fb:hover {
  background-position: -56px -17px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-tw {
  background-position: -73px -34px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-tw:hover {
  background-position: -73px -17px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-inst {
  background-position: -90px -34px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-inst:hover {
  background-position: -90px -17px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-you {
  background-position: -107px -34px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-you:hover {
  background-position: -107px -17px;
}
.n_web .three-m-s {
  padding: 60px 0;
  overflow: hidden;
}
.n_web .three-m-s .inn-bx {
  width: 100%;
  max-width: 500px;
}
.n_web .three-m-s.in-who-we-are .inn-bx {
  max-width: 750px;
}
.n_web .three-m-s .inn-bx small {
  font-size: 11px;
  letter-spacing: 1px;
  color: #66667e;
  line-height: 1;
}
.n_web .three-m-s .inn-bx h3 {
  font-size: 24px;
  letter-spacing: 5px;
  line-height: 1.5;
  margin: 0 0 30px;
}
.n_web .three-m-s .inn-bx p {
  color: #66667e;
  font-size: 14px;
  line-height: 26px;
}
.n_web .three-m-s .inn-bx p + p {
  margin-top: 20px;
}
.n_web .advantage-over.rules-who-aply::after {
  display: none;
}
.n_web .advantage-over.rules-who-aply {
  padding: 65px 0;
}
.n_web .advantage-over.rules-who-aply .into-two {
  width: 48%;
  padding: 0 50px 0 0;
  position: relative;
}
.n_web .advantage-over.rules-who-aply .into-two::after {
  left: auto;
  right: 0;
  content: "";
  height: calc(100% + 130px);
  top: -65px;
  width: 1px;
  background-color: #fff;
  position: absolute;
}
.n_web .advantage-over.rules-who-aply .into-two + .into-two::after {
  display: none;
}
.n_web .advantage-over.rules-who-aply .into-two h4 {
  font-size: 16px;
  letter-spacing: 3px;
  margin: 0 0 25px 0;
}
.n_web .advantage-over.rules-who-aply .into-two ul li {
  font-size: 12px;
  line-height: 1.3;
  position: relative;
  padding: 0 0 0 15px;
}
.n_web .advantage-over.rules-who-aply .into-two ul li::after {
  position: absolute;
  content: "";
  height: 6px;
  width: 6px;
  left: 0;
  top: 6px;
  border-radius: 50%;
  background-color: #fff;
}
.n_web .advantage-over.rules-who-aply .into-two ul li + li {
  margin-top: 15px;
}
.n_web .apply-accelerated.contact-us-bg {
  padding: 60px 0 60px;
}
.n_web .apply-accelerated.contact-us-bg .form-section {
  margin: 30px 0 0 0;
  padding: 0;
}
.n_web .apply-accelerated {
  padding: 30px 0;
}
.n_web .apply-accelerated::after {
  background-image: url(../images/overlay_03.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 664px;
  height: 710px;
  background-size: 100%;
  top: auto;
  bottom: -80px;
  left: -250px;
}
.n_web .apply-accelerated::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: url(../images/asia_bottom.jpg) 0 0 no-repeat;
  background-size: cover;
  top: 0;
  z-index: -1;
}
.n_web .apply-accelerated.ambass-bg::before {
  background: url(../images/test2.jpg) 0 0 no-repeat;
  background-size: cover;
}
.n_web .apply-accelerated .left-title {
  width: 320px;
}
.n_web .apply-accelerated .left-title small {
  color: #d8d8d8;
}
.n_web .apply-accelerated .form-section {
  width: calc(100% - 320px);
  padding: 0 0 0 40px;
}
.n_web .apply-accelerated .awd-txt-im {
  margin: 15px 0;
}
.n_web .apply-accelerated .awd-txt-im .awd-img {
  width: 70px;
  height: 61px;
}
.n_web .apply-accelerated .awd-txt-im .awd-img img {
  max-width: 100%;
}
.n_web .apply-accelerated .awd-txt-im h4 {
  letter-spacing: 3px;
  font-size: 22px;
}
.n_web .apply-accelerated .left-title p {
  color: #d8d8d8;
  font-size: 14px;
  line-height: 26px;
}
.n_web .apply-accelerated .form-section .helf-width {
  width: 49%;
}
.n_web .apply-accelerated .form-section .full-width {
  width: 100%;
}
.n_web .apply-accelerated .form-section .top-s + .top-s {
  margin-top: 50px;
}
.n_web .apply-accelerated .form-section .float-label-field {
  border: none;
  outline: 0;
  position: relative;
  margin: 0 0 20px 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.n_web .apply-accelerated .form-section .float-label-field input {
  border: none;
  outline: 0;
  padding: 15px 5px 15px 0;
  width: 100%;
  font-size: 14px;
  border-bottom: solid 1px #66667e;
  background-color: transparent;
  color: #fff;
  font-family: muliregular;
}
.n_web .apply-accelerated .form-section .float-label-field label {
  position: absolute;
  top: 10px;
  left: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-size: 14px;
  color: #8e8ea5;
}
.n_web .apply-accelerated .form-section .float-label-field.focus label {
  color: #f05139;
}
.n_web .apply-accelerated .form-section .float-label-field.focus input {
  border-bottom: solid 1px #f05139;
}
.n_web .apply-accelerated .form-section .float-label-field.float label {
  opacity: 1;
  top: -8px;
  font-size: 80%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.n_web .apply-accelerated .form-section .float-label-field textarea {
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #66667e;
  resize: none;
  padding: 15px 5px 15px 0;
  width: 100%;
  font-size: 15px;
  color: #fff;
}
.n_web .apply-accelerated .form-section .float-label-field textarea:focus {
  border-bottom: solid 1px #f05139;
}
.n_web .apply-accelerated .form-section .file-upload {
  position: relative;
  color: #fff;
  font-size: 0;
  white-space: nowrap;
}
.n_web .apply-accelerated .form-section .file-upload .file-name {
  width: calc(100% - 190px);
  background-color: transparent;
  border-bottom: 1px solid #66667e;
  font-size: 14px;
  color: #8e8ea5;
  padding: 15px 5px 15px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.n_web .apply-accelerated .form-section .file-upload .customUpload-btn {
  padding: 10px 45px;
  border-radius: 40px;
  border: 1px solid #fff;
  font-size: 12px;
}
.n_web
  .apply-accelerated
  .form-section
  .file-upload
  .upload-btn:hover
  + .customUpload-btn {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f05139),
    to(#f08639)
  );
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
  border-color: #f05139;
}
.n_web .apply-accelerated .form-section .file-upload .upload-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  height: 100%;
  text-align: center;
  z-index: 2;
}
.n_web .apply-accelerated .form-section .tems-policy a {
  font-size: 10px;
  color: #d8d8d8;
}
.n_web .apply-accelerated .form-section .tems-policy a:hover {
  color: #f05139;
}
.n_web .apply-accelerated .form-section .tems-policy span {
  margin: 0 5px;
}
.n_web .apply-accelerated .form-section .float-label-field .chek-box label {
  position: relative;
  margin: 0;
  top: 0;
  color: #e5e5e5;
  font-size: 14px;
}
.n_web .apply-accelerated .form-section .form-group {
  display: block;
  margin-bottom: 15px;
}
.n_web .apply-accelerated .form-section .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.n_web .apply-accelerated .form-section .form-group label {
  position: relative;
  cursor: pointer;
}
.n_web .apply-accelerated .form-section .form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #66667e;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}
.n_web
  .apply-accelerated
  .form-section
  .form-group
  input:checked
  + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.n_web .apply-accelerated .btn-action {
  margin-bottom: 50px;
}
.n_web .apply-accelerated .btn-action .btn {
  border-radius: 40px;
  line-height: 1;
  font-size: 14px;
  padding: 12px 40px;
  color: #fff;
  border-width: 1px;
  border-style: solid;
}
.n_web .apply-accelerated .btn-action .btn + .btn {
  margin-left: 30px;
}
.n_web .apply-accelerated .btn-action .btn-submit {
  background: #f05139;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f05139),
    to(#f08639)
  );
  background: linear-gradient(90deg, #f05139 0, #f08639 100%);
  border-color: transparent;
}
.n_web .apply-accelerated .btn-action .btn-submit:hover {
  background: #f08639;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f08639),
    to(#f05139)
  );
  background: linear-gradient(90deg, #f08639 0, #f05139 100%);
}
.n_web .apply-accelerated .btn-action .btn-reset {
  border-color: #fff;
}
.n_web .apply-accelerated .btn-action .btn-reset:hover {
  background: #f05139;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f05139),
    to(#f08639)
  );
  background: linear-gradient(90deg, #f05139 0, #f08639 100%);
  border-color: transparent;
}
.n_web .apply-accelerated .form-section ::-webkit-input-placeholder {
  font-size: 14px;
  color: #8e8ea5;
}
.n_web .apply-accelerated .form-section ::-moz-placeholder {
  font-size: 14px;
  color: #8e8ea5;
}
.n_web .apply-accelerated .form-section :-ms-input-placeholder {
  font-size: 14px;
  color: #8e8ea5;
}
.n_web .apply-accelerated .form-section :-moz-placeholder {
  font-size: 14px;
  color: #8e8ea5;
}
.n_web .main-team-section::after {
  position: absolute;
  content: "";
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 8px;
  z-index: 1 !important;
  height: 100%;
  width: calc(100% - 130px);
}
.n_web .min-tm-container {
  margin-left: -15px;
  margin-right: -15px;
}
.n_web .one-row + .one-row {
  margin-top: 60px;
}
.n_web .team-m-box {
  width: calc((100% / 6) - 30px);
  position: relative;
  margin: 25px 15px;
}
.n_web .team-m-box .team-photo {
  margin: 0 0 30px;
}
.n_web .team-m-box .team-photo img {
  width: 155px;
  height: 155px;
  border-radius: 100%;
  overflow: hidden;
  border: 1px solid #111a44;
  display: block;
  margin: 0 auto;
}
.n_web .team-m-box .team-photo2 {
  margin: 0 0 30px;
}
.n_web .team-m-box .team-photo2 img {
  width: 140px;
  height: 155px;
  border-radius: 100%;
  overflow: hidden;
  border: 1px solid #111a44;
  display: block;
  margin: 0 auto;
}
.n_web .team-m-box .team-m-name {
  background-color: #0f1740;
  width: 130px;
  margin: 0 auto;
  left: 0;
  bottom: -20px;
  top: auto;
  right: 0;
  line-height: 1;
  padding: 8px 10px;
  border-radius: 50px;
}
.n_web .team-m-box .team-m-name h2 {
  font-size: 11px;
  color: #fff;
  line-height: 1;
}
.n_web .team-m-box .team-m-deg h3 {
  font-size: 11px;
  color: #404040;
}
.n_web .team-m-box .team-m-deg h4 {
  font-size: 12px;
  color: #66667e;
}
.n_web .team-m-box .team-m-deg .intex {
  margin-right: 10px;
  width: calc(100% - 30px);
}
.n_web .team-m-box .team-m-deg .ic-link {
  height: 15px;
  width: 16px;
  background-position: -125px 0;
  display: inline-block;
}
.n_web .team-m-box .team-m-deg .ic-link:hover {
  background-position: -143px 0;
}
.n_web .main-team-section {
  margin: 60px 0 0 0;
}
.n_web .main-team-section .tab-content {
  padding: 0;
  margin: 0 0 30px;
}
.n_web .main-team-section .card-body {
  padding: 0;
}
.n_web .main-team-section .card {
  border: none;
  padding: 0;
  border-radius: 0;
}
.n_web .main-team-section .nav-tabs {
  height: auto;
  padding: 0;
  position: relative;
  border: none;
}
.n_web .main-team-section .nav-tabs .nav-item {
  margin: 0;
}
.n_web .main-team-section .nav-tabs .nav-item + .nav-item {
  margin-left: 15px;
}
.n_web .main-team-section .nav-tabs .nav-item a {
  color: #fff;
  padding: 10px 5px;
  min-width: 150px;
  text-align: center;
  border-radius: 10px 10px 0 0;
  border: none;
  background: #f05139;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(40%, #f05139),
    to(#f08639)
  );
  background: linear-gradient(180deg, #f05139 40%, #f08639 100%);
  font-size: 10px;
  letter-spacing: 1px;
}
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab {
  background-color: #fff;
  display: none;
  position: absolute;
  right: 0;
  width: 150px;
  z-index: 1;
}
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab-action a {
  min-width: inherit;
  padding-left: 15px;
  padding-right: 15px;
}
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab a {
  border: 1px solid #d3d3d3;
  border-radius: 0;
  padding: 6px 10px;
}
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab a.active,
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab a:active,
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab a:focus,
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab a:hover {
  background-color: #d3d3d3;
}
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab:last-child {
  border-radius: 0 0 0 4px;
}
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab-action {
  position: absolute;
  right: 0;
}
.n_web .btn-job-opning {
  border-radius: 50px;
  border: 1px solid #fff;
  line-height: 1;
  padding: 9px 20px 11px;
  margin-left: 10px;
}
.n_web .for-same-bg::after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#111a44),
    to(#010726)
  );
  background: linear-gradient(90deg, #111a44 0, #010726 100%);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  left: 0;
  top: 0;
  height: 110%;
  width: 100%;
  z-index: -1;
  background-blend-mode: difference;
}
.n_web .list-of-con-map-img {
  padding: 50px 0;
}
.n_web .list-of-con-map-img::after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#111a44),
    to(#010726)
  );
  background: linear-gradient(90deg, #111a44 0, #010726 100%);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  background-blend-mode: soft-light;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.n_web .list-of-con-map-img .world-map img {
  width: 100%;
}
.n_web .list-of-con-map-img .partners-c-type p {
  margin-left: -5px;
  margin-right: -5px;
}
.n_web .list-of-con-map-img .partners-c-type h4 {
  color: #f05139;
  font-size: 15px;
  margin: 0 0 10px 0;
}
.n_web .list-of-con-map-img .partners-c-type p {
  padding: 8px 5px;
}
.n_web .list-of-con-map-img .type-of-partners {
  margin: 40px 0 0;
}
.n_web .list-of-con-map-img .type-of-partners p {
  width: calc(100% / 4);
}
.n_web .list-of-con-map-img .partners-c-type span {
  color: #d8d8d8;
  font-size: 14px;
}
.n_web .list-of-con-map-img .partners-c-type p:hover span {
  color: #f05139;
}
.n_web .list-of-con-map-img .all-country {
  margin: 70px 0 0 0;
}
.n_web .list-of-con-map-img .all-country p {
  width: calc(100% / 8);
}
.n_web .list-of-parters-s {
  padding: 30px 0;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#111a44),
    to(#010726)
  );
  background: linear-gradient(90deg, #111a44 0, #010726 100%);
}
.n_web .list-of-parters-s .logo-box {
  margin: 30px 0;
}
.n_web .list-of-parters-s .logo-box img {
  max-width: 100%;
  width: auto;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  opacity: 0.9;
}
.n_web .list-of-parters-s.be-orignal .logo-box img {
  -webkit-filter: none;
  filter: none;
}
.n_web .partner-multi-logo {
  padding: 60px 0;
}
.n_web .become-p-bl-div {
  padding: 40px 0;
  display: block;
}
.n_web .partner-multi-logo h2 {
  font-size: 24px;
  letter-spacing: 5px;
  line-height: 1.5;
  color: #0f1740;
}
.n_web .partner-multi-logo .part-lgo-bx {
  width: calc(20% - 30px);
  min-height: 120px;
  margin: 0 15px 15px;
  text-align: center;
}
.n_web .partner-multi-logo .part-lgo-bx a img {
  max-width: 80%;
  width: auto;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  opacity: 1;
}
.n_web .partner-multi-logo .part-lgo-bx.og-lg img {
  -webkit-filter: inherit;
  filter: inherit;
}
.n_web .partner-multi-logo .part-lgo-bx a:hover,
.n_web .partner-multi-logo .part-lgo-bx a:hover img {
  opacity: 1;
}
.n_web .partner-multi-logo.be-or-lg .part-lgo-bx a img {
  -webkit-filter: none;
  filter: none;
}
.n_web .faqs-main-que {
  padding: 0 0 60px;
}
.n_web .faqs-main-que .card {
  border: 0;
  margin: 15px 0;
  display: block;
  background-color: transparent;
}
.n_web .faqs-main-que .card .card-body p + p {
  margin-top: 15px;
}
.n_web .faqs-main-que .card .card-body p {
  line-height: 26px;
}
.n_web .faqs-main-que .card .card-body p a {
  color: #f05139;
}
.n_web .faqs-main-que .card .card-body p a:hover {
  text-decoration: underline;
}
.n_web .faqs-main-que .card .card-header {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  padding: 0;
}
.n_web .faqs-main-que .card .card-header .btn-header-link {
  border-radius: 12px 12px 0 0;
  border: none;
  color: #fff;
  display: block;
  text-align: left;
  background-color: #111a44;
  padding: 20px 80px 20px 20px;
  font-size: 14px;
}
.n_web .faqs-main-que .card .card-header .btn-header-link:after {
  height: 100%;
  width: 70px;
  background-position: 0 -100px;
  background-color: transparent;
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.n_web .faqs-main-que .card .card-header .btn-header-link.collapsed {
  border-radius: 12px;
  background: #f08639;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f08639),
    to(#f05139)
  );
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
  color: #fff;
}
.n_web .faqs-main-que .card .card-header .btn-header-link.collapsed:after {
  background-position: -70px -100px;
  background: rgba(0, 0, 0, 0.2) url(../images/plus-sign.svg) no-repeat center
    center;
}
.n_web .faqs-main-que .card .collapsing {
  background-color: #111a44;
  line-height: 30px;
  color: #d8d8d8;
}
.n_web .faqs-main-que .card .collapse {
  border: 0;
}
.n_web .faqs-main-que .card .collapse.show {
  background-color: #111a44;
  border-radius: 0 0 12px 12px;
  line-height: 30px;
  color: #d8d8d8;
}
.n_web .title-brd {
  padding: 30px 0 0;
}
.n_web .title-brd .breadcrumbs-div {
  margin-bottom: 10px;
}
.n_web .title-brd .breadcrumbs-div li,
.n_web .title-brd .breadcrumbs-div li a {
  color: #66667e;
}
.n_web .gp-tpus {
  padding: 10px 0 50px;
}
.n_web .gp-tpus::after {
  top: auto;
  bottom: 0;
  width: 100%;
  height: 8px;
  background-color: #111a44;
}
.n_web .gp-tpus .block + .block {
  margin-top: 30px;
}
.n_web .gp-tpus .block h2 {
  font-size: 22px;
  line-height: 28px;
  margin: 0 0 10px;
  letter-spacing: 4px;
  color: #0f1740;
}
.n_web .gp-tpus .block p {
  color: #66667e;
  font-size: 14px;
  line-height: 20px;
}
.n_web .gp-tpus .block ul {
  margin: 10px 0 0;
  color: #66667e;
  font-size: 14px;
  line-height: 22px;
}
.n_web .gp-tpus .block a {
  color: #f05139;
}
.n_web .gp-tpus .block a:hover {
  text-decoration: underline;
}
.n_web .gp-tpus .block ul li + li {
  margin-top: 10px;
}
.n_web .gp-tpus .block ul li span + span {
  margin-top: 10px;
}
.n_web .gp-tpus .block .wi-dots li {
  padding: 0 0 0 25px;
}
.n_web .gp-tpus .block .wi-dots li + li {
  margin-top: 5px;
}
.n_web .gp-tpus .block .wi-dots li::after {
  height: 10px;
  width: 10px;
  left: 0;
  top: 9px;
  border-radius: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f05139),
    to(#f08639)
  );
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
}
.n_web .gp-tpus .block .step-inner {
  margin: 10px 0;
}
.n_web .gp-tpus .block .step-inner li::after {
  background: 0 0;
  border: 1px solid #f05139;
}
.n_web .gp-tpus .block .step-inner li + li {
  margin-top: 5px;
}
.n_web .gp-tpus .block .inner-ul {
  padding: 0 0 0 30px;
}
.n_web footer {
  /* padding: 40px 0 20px; */
  background-color: #fcfcfc;
  margin-bottom: 15px;
  z-index: 4;
}
.n_web footer .f-notes {
  font-size: 13px;
  width: 100%;
  max-width: 770px;
  font-family: mulibold;
  color: #fff;
  margin: 0 auto;
  position: relative;
  padding: 15px 0;
}
.n_web footer .f-notes::after {
  content: "";
  position: absolute;
  height: 100%;
  width: calc(100% + 1000%);
  left: -500%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f08639),
    to(#f05139)
  );
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
  top: 0;
  right: 0;
  z-index: -1;
}
.n_web footer .about-edge {
  width: 280px;
}
.n_web footer .about-edge .footer-logo {
  margin: 0 0 15px 0;
}
.n_web footer .about-edge h3 {
  color: #404040;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 15px;
}
.n_web footer .about-edge p {
  color: #66667e;
  font-size: 14px;
  line-height: 26px;
}
.n_web footer .footer-nav-social .navi-g {
  width: 200px;
  padding: 0 35px;
}
.n_web footer .footer-nav-social .mail-links {
  padding: 0 35px;
  width: 200px;
}
.n_web footer .footer-nav-social .mail-links::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  background-color: #f0f0f0;
  top: 0;
}
.n_web footer .footer-nav-social .mail-links::before {
  left: auto;
  right: 0;
}
.n_web footer .footer-nav-social .quick-link p {
  color: #404040;
  margin: 0 0 4px;
}
.n_web footer .footer-nav-social .quick-link ul li {
  padding: 4px 0;
}
.n_web footer .footer-nav-social .quick-link ul li a {
  color: #404040;
  font-size: 14px;
}
.n_web footer .footer-nav-social .quick-link p a:hover {
  color: #ea3318;
}
.n_web footer .footer-nav-social .quick-link ul li a:hover {
  color: #ea3318;
}
.n_web footer .footer-nav-social .last-s {
  padding: 0 0 0 35px;
  width: 240px;
}
.n_web footer .footer-nav-social .last-s .tit p {
  color: #404040;
  margin: 0 0 4px;
}
.n_web footer .footer-nav-social .last-s .tit + .tit {
  margin-top: 10px;
}
.n_web footer .footer-nav-social .last-s .social {
  margin-left: -5px;
  margin-right: -5px;
  padding-right: 35px;
}
.n_web footer .footer-nav-social .last-s .social .ic-so {
  height: 24px;
  width: 24px;
  display: inline-block;
  margin: 1px 7px;
  opacity: 0.8;
}
.n_web footer .footer-nav-social .last-s .social .ic-so:hover {
  opacity: 1;
}
.n_web footer .footer-nav-social .last-s .social .ic-fb {
  background-position: -303px -118px;
}
.n_web footer .footer-nav-social .last-s .social .ic-fb:hover {
  background-position: -303px -142px;
}
.n_web footer .footer-nav-social .last-s .social .ic-tw {
  background-position: -327px -118px;
}
.n_web footer .footer-nav-social .last-s .social .ic-tw:hover {
  background-position: -327px -142px;
}
.n_web footer .footer-nav-social .last-s .social .ic-inst {
  background-position: -351px -118px;
}
.n_web footer .footer-nav-social .last-s .social .ic-inst:hover {
  background-position: -351px -142px;
}
.n_web footer .footer-nav-social .last-s .social .ic-you {
  background-position: -279px -70px;
}
.n_web footer .footer-nav-social .last-s .social .ic-you:hover {
  background-position: -279px -94px;
}
.n_web footer .footer-nav-social .last-s .social .ic-te {
  background-position: -327px -70px;
}
.n_web footer .footer-nav-social .last-s .social .ic-te:hover {
  background-position: -327px -94px;
}
.n_web footer .footer-nav-social .last-s .social .ic-lik {
  background-position: -303px -70px;
}
.n_web footer .footer-nav-social .last-s .social .ic-lik:hover {
  background-position: -303px -94px;
}
.n_web footer .footer-nav-social .last-s .social .ic-whap {
  background-position: -351px -70px;
}
.n_web footer .footer-nav-social .last-s .social .ic-whap:hover {
  background-position: -351px -94px;
}
.n_web footer .footer-nav-social .last-s .social .ic-medium {
  background-position: -279px -118px;
}
.n_web footer .footer-nav-social .last-s .social .ic-medium:hover {
  background-position: -279px -142px;
}
.n_web footer .footer-nav-social .last-s .cont-us a {
  color: #404040;
}
.n_web footer .footer-nav-social .last-s .cont-us a:hover {
  color: #ea3318;
}
.n_web footer .copy-right {
  margin: 25px 0 0 0;
}
.n_web footer .copy-right p {
  font-size: 10px;
  color: #66667e;
}
.n_web footer .copy-right .some-links a {
  margin: 0 10px;
  font-size: 10px;
}
.n_web footer .copy-right .some-links a:hover {
  color: #ea3318;
}
@media screen and (min-width: 1551px) {
  .n_web .three-m-s .inn-bx h3 {
    font-size: 35px;
  }
  .n_web .asia-blc .main-title h1,
  .n_web .main-title h1,
  .n_web .main-title-s h1 {
    font-size: 150px;
  }
  .n_web .main-title-s h2 {
    font-size: 30px;
    letter-spacing: 40px;
  }
  .n_web .our-so-new-pg.ad .text-contain {
    max-width: 500px;
  }
  .n_web .our-so-new-pg.ad .main-title-s h1 {
    font-size: 90px;
  }
  .n_web .our-so-new-pg.ad .main-title-s h2 {
    font-size: 15px;
    letter-spacing: 25px;
  }
  .n_web .our-so-new-pg.ad .text-contain p {
    font-size: 20px;
    line-height: 28px;
  }
  .n_web .eco-system-se.our-so-new-pg .some-str-change .text-contain {
    padding: 0;
  }
  .n_web .eco-system-se.our-so-new-pg {
    height: 750px;
  }
  .n_web .text-contain p {
    font-size: 22px;
    line-height: 35px;
  }
  .n_web .main-hop .lining-to a {
    font-size: 18px;
  }
  .n_web .main-hop.eco-system-se .follow-us-socil .btn-apply-now {
    padding: 21px 60px 23px;
    font-size: 20px;
    border-radius: 60px;
    top: 5px;
  }
  .n_web .main-hop.eco-system-se .follow-us-socil .line-btn {
    padding: 21px 60px 23px;
    border-radius: 60px;
    font-size: 20px;
  }
  .n_web .only-img-sec .white-bar::after {
    max-width: 1110px;
  }
  .n_web .advantage-over .inner-text,
  .n_web .current-scenario .current-se-box,
  .n_web .current-scenario .current-se-box .inner-contain,
  .n_web .text-contain,
  .n_web .three-m-s .inn-bx,
  .n_web .three-m-s.in-who-we-are .inn-bx {
    max-width: 100%;
  }
  .n_web .advantage-over .inner-text p,
  .n_web .advantage-over.rules-who-aply .into-two h4,
  .n_web .advantageses-over p,
  .n_web .cob-bcm-digital .lft-bx p,
  .n_web .cob-bcm-digital .rit-bx p,
  .n_web .current-scenario .current-se-box .inner-contain p,
  .n_web .four-m-bxes .txt-se p,
  .n_web .our-technology .contain-p p,
  .n_web .three-m-s .inn-bx p,
  .n_web .we-are-differnent .rit-text p {
    font-size: 20px;
    line-height: 36px;
  }
  .n_web .advantage-over.rules-who-aply .into-two ul li,
  .n_web .cob-bcm-digital .lft-bx small,
  .n_web .current-scenario .current-se-box p,
  .n_web .our-technology .contain-p small,
  .n_web .three-m-s .inn-bx small {
    font-size: 14px;
  }
  .n_web .advantageses-over h3,
  .n_web .cob-bcm-digital .sw-bx h2,
  .n_web .current-scenario .current-se-box h2,
  .n_web .four-m-bxes .txt-se h3,
  .n_web .our-technology .contain-p h2,
  .n_web .we-are-differnent .rit-text h2 {
    font-size: 30px;
    line-height: 34px;
  }
  .n_web .advantage-over .inner-text h2 {
    font-size: 24px;
  }
  .n_web .advantage-over.rules-who-aply .into-two ul li {
    line-height: 24px;
    padding: 0 0 0 30px;
  }
  .n_web .advantage-over.rules-who-aply .into-two ul li::after {
    height: 10px;
    width: 10px;
    top: 8px;
  }
  nav.side-menu .menu li,
  nav.side-menu .menu li a {
    font-size: 12px;
  }
  nav.side-menu .menu li + li {
    margin-top: 15px;
  }
  nav.side-menu .menu li ul li + li {
    margin-top: 20px;
  }
  nav.side-menu .menu li:last-child {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1450px) {
  .n_web header .container-fluid {
    padding-left: 50px;
    padding-right: 50px;
  }
  .n_web .eco-system-se::after {
    -webkit-animation: slide 10s linear infinite;
    animation: slide 10s linear infinite;
  }
}
@media screen and (min-width: 1368px) and (max-width: 1450px) {
  .n_web header .container-fluid {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media screen and (max-width: 1551px) and (min-width: 1200px) {
  .n_web .advantage-over .inner-text,
  .n_web .three-m-s .inn-bx,
  .n_web .three-m-s.in-who-we-are .inn-bx {
    margin-left: 0;
    max-width: 100%;
  }
  .n_web .edge_ourstory .breadcrumbs-div,
  .n_web .edge_ourstory .txt-part-se {
    margin-left: 0;
  }
}
@media screen and (min-width: 1368px) {
  .n_web .title-brd .breadcrumbs-div {
    margin: 0;
    z-index: 1;
  }
}
@media screen and (max-width: 1280px) {
  .n_web .main-team-section::after {
    width: calc(100% - 40px);
  }
}
@media screen and (max-width: 1199px) {
  .n_web .flex-wrap-1199 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .n_web footer .about-edge {
    width: 100%;
    margin: 0 0 30px;
  }
  .n_web footer .footer-nav-social .mail-links:first-child {
    padding-left: 0;
  }
  .n_web footer .about-edge .footer-logo {
    width: 150px;
  }
  .body-scroll-dis {
    padding: 0;
  }
  .n_web .main-team-section::after {
    width: calc(100% - 100px);
  }
  .n_web .team-m-box {
    width: calc((100% / 5) - 30px);
  }
  .n_web .our-so-new-pg.ad .text-contain {
    max-width: 420px;
  }
}
@media screen and (max-width: 1079px) {
  .n_web .main-team-section::after {
    width: calc(100% - 40px);
  }
}
@media screen and (max-width: 991px) {
  .n_web .d-none-991 {
    display: none;
  }
  .n_web .d-block-991 {
    display: block;
  }
  .n_web .flex-wrap-991 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .n_web .d-flex-991 {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
  }
  .n_web .w-100-991 {
    width: 100%;
  }
  .n_web .three-m-s.on-m-hmp .for-shape::before {
    display: none;
  }
  .n_web .justify-content-center-991 {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .n_web header .letest-news {
    margin-right: 20px;
  }
  .n_web .current-scenario .three-points .same-box {
    width: 100%;
    text-align: left;
  }
  .n_web .current-scenario .same-box .ic-se {
    margin: 0;
  }
  .n_web footer .footer-nav-social .navi-g {
    padding: 0;
  }
  .n_web .eco-system-se .main-link-here .full-bg-left::after {
    display: none;
  }
  .n_web .main-link-here .rig-link a {
    padding: 15px 20px;
    display: inline-block;
    line-height: 1;
    background: #f05139;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f05139),
      to(#f08639)
    );
    background: linear-gradient(180deg, #f05139 0, #f08639 100%);
    color: #fff;
    border-radius: 0 25px 0 0;
    position: fixed;
    left: 0;
    top: auto;
    bottom: 0;
    z-index: 9;
  }
  .n_web .main-link-here .rig-link a:hover {
    background: #f08639;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f08639),
      to(#f05139)
    );
    background: linear-gradient(180deg, #f08639 0, #f05139 100%);
  }
  .n_web .asia-blc.eco-system-se .for-shape::after {
    display: none;
  }
  .n_web .asia-blc.eco-system-se .for-shape::before {
    display: block;
    top: 0;
    right: 0;
    background-size: 100%;
    width: 130px;
  }
  .n_web .apply-accelerated .form-section .helf-width {
    width: 100%;
  }
  .n_web
    .apply-accelerated
    .form-section
    .helf-width.float-label-field
    + .helf-width.float-label-field {
    margin-top: 35px;
  }
  .n_web .apply-accelerated .form-section .top-s + .top-s {
    margin-top: 35px;
  }
  .n_web .apply-accelerated .left-title {
    width: 250px;
  }
  .n_web .apply-accelerated .awd-txt-im h4 {
    font-size: 22px;
  }
  .n_web .apply-accelerated .form-section {
    width: calc(100% - 250px);
  }
  .n_web .eco-system-se {
    height: auto;
    overflow: inherit;
  }
  .n_web .eco-system-se .for-shape::after {
    top: 0;
    width: 100px;
    background-size: 80px;
    height: 240px;
  }
  .n_web .eco-system-se .for-shape::before {
    top: 0;
    background-size: 150px;
    width: 140px;
    height: 230px;
  }
  .n_web .txt-part-se {
    margin: 0 auto 0;
  }
  .n_web .eco-system-se.our-so-new-pg .some-str-change .txt-part-se {
    margin: 0;
  }
  .n_web .eco-system-se.our-so-new-pg .some-str-change .text-contain {
    padding: 0;
  }
  .n_web .eco-system-se.our-so-new-pg::after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.55);
    -webkit-filter: none;
    filter: none;
    z-index: -1;
    top: 0;
    left: 0;
  }
  .n_web .eco-system-se.our-so-new-pg {
    height: 500px;
  }
  .n_web .team-m-box {
    width: calc((100% / 3) - 30px);
  }
  .n_web .main-title-s h1 {
    font-size: 60px;
  }
  .n_web .cob-bcm-digital .lft-bx {
    padding: 0;
  }
  .n_web .cob-bcm-digital .rit-bx {
    padding: 0 20px;
  }
  .n_web .three-m-s .inn-bx h3 {
    font-size: 20px;
  }
  .n_web .we-are-differnent .for-cross-sign::before {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
  .n_web .we-are-differnent .for-cross-sign::after {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  .n_web .token-tabs-se .tab-swap .txt-part h2 {
    font-size: 44px;
  }
  .n_web .token-tabs-se .edge-smal {
    width: 50px;
  }
  .n_web footer .footer-nav-social .mail-links {
    padding: 0 15px;
  }
  .n_web footer .footer-nav-social .last-s {
    padding: 0 15px;
    width: 200px;
  }
  .n_web footer .footer-nav-social .mail-links {
    width: 170px;
  }
  .n_web .advantage-over .inner-text {
    max-width: 100%;
  }
  .n_web .btn-gp .btn-apply-now {
    margin: 35px auto 0;
  }
  .n_web .current-scenario .current-se-box .left-se {
    width: 100%;
  }
  .n_web .current-scenario .current-se-box .right-se {
    width: 100%;
    padding: 0;
    margin: 20px 0 0 0;
  }
  .n_web .advantage-over .s-w {
    width: 100%;
  }
  .n_web .advantage-over .s-w + .s-w {
    margin-top: 30px;
  }
  .n_web .partner-multi-logo .part-lgo-bx {
    width: calc(25% - 30px);
  }
  .n_web .current-indus-solu .comparetable-table tbody tr th.for-widht {
    width: 155px;
  }
  .n_web .we-hve-featu .iiner-cont {
    padding: 0 0 30px;
  }
  .n_web .we-hve-featu .rt-t {
    padding: 0;
  }
  .n_web .token-tabs-se .nav-tabs {
    margin: 0 -10px;
  }
  .n_web .token-tabs-se .nav-tabs .nav-item + .nav-item {
    margin-left: 10px;
  }
  .n_web .token-tabs-se .nav-tabs .nav-item {
    margin: 10px;
  }
}
@media screen and (max-width: 767px) {
  .n_web .token-tabs-se .nav-tabs .nav-item {
    margin: 10px 0;
  }
  .n_web .token-tabs-se .nav-tabs .nav-item + .nav-item {
    margin-left: 0;
  }
  .n_web .token-tabs-se .nav-tabs {
    margin: 0;
  }
  .n_web .token-tabs-se .nav-tabs .nav-item a {
    width: 100%;
    min-width: 70px !important;
    padding: 7px 7px;
  }
}
@media screen and (min-width: 768px) {
  .n_web .token-tabs-se .nav-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .n_web .token-tabs-se .card {
    border: none;
  }
  .n_web .token-tabs-se .card .card-header {
    display: none;
  }
  .n_web .token-tabs-se .card .collapse {
    display: block;
  }
  .n_web .faqs-main-que .into-two-p {
    width: 49.3%;
  }
}
@media screen and (max-width: 767px) {
  .n_web .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .n_web .d-block-767 {
    display: block;
  }
  .n_web .flex-wrap-767 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .n_web .d-flex-767 {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
  }
  .n_web .justify-content-between-767 {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .n_web .w-100-767 {
    width: 100%;
  }
  .n_web .d-none-767 {
    display: none;
  }
  .n_web .order-1-767 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
  .n_web .order-2-767 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    order: 2;
  }
  .n_web .eco-system-se.our-so-new-pg .some-str-change {
    left: 25px;
    right: 25px;
  }
  .n_web footer .footer-nav-social .navi-g {
    width: 48%;
  }
  .n_web footer .footer-nav-social .mail-links {
    width: 48%;
    padding: 0;
  }
  .n_web footer .footer-nav-social .mail-links::after,
  .n_web footer .footer-nav-social .mail-links::before {
    display: none;
  }
  .n_web footer .footer-nav-social .last-s {
    padding: 0;
  }
  .n_web footer .footer-nav-social .last-s .tit {
    width: 48%;
  }
  .n_web footer .footer-nav-social .last-s .tit + .tit {
    margin-top: 0;
  }
  .n_web footer .copy-right {
    margin: 10px 0 0;
  }
  .n_web footer .copy-right .some-links {
    margin: 0 0 5px;
  }
  .n_web footer .copy-right .some-links a {
    margin: 0;
  }
  .n_web footer .copy-right .some-links a + a {
    margin-left: 50px;
  }
  .n_web .our-technology .for-bg::before {
    max-width: 460px;
  }
  .n_web .white-bar {
    display: none;
  }
  .n_web .current-scenario .current-se-box {
    width: 100%;
    max-width: 100%;
    padding: 40px;
  }
  .n_web .current-scenario.cust-part .current-se-box {
    padding: 0;
  }
  .n_web .our-technology .sm-w {
    width: 100%;
  }
  .n_web .only-img-sec {
    height: 390px;
  }
  .n_web .eco-system-se .for-shape::after {
    top: -90px;
    width: 130px;
    background-size: 80px;
    height: 240px;
  }
  .n_web .eco-system-se .for-shape::before {
    top: 240px;
    background-size: 130px;
    width: 110px;
    height: 220px;
  }
  .n_web .main-title h1 {
    font-size: 80px;
  }
  .n_web .main-title h2 {
    font-size: 12px;
    letter-spacing: 20px;
  }
  .n_web .text-contain {
    margin: 30px 0 0;
  }
  .n_web .eco-system-se .left-link .sub-link {
    width: 48%;
  }
  .n_web .eco-system-se .main-link-here .left-link {
    padding: 20px;
  }
  .n_web .eco-system-se .left-link .sub-link + .sub-link {
    padding: 15px 0;
  }
  .n_web .eco-system-se .left-link .sub-link + .sub-link::after {
    display: none;
  }
  .n_web .eco-system-se .left-link .sub-link {
    padding: 15px 0;
  }
  .n_web .asia-blc.eco-system-se {
    margin: 0;
    padding: 50px 0;
  }
  .n_web .advantage-over.rules-who-aply .into-two {
    width: 100%;
    padding: 0;
  }
  .n_web .advantage-over.rules-who-aply .into-two + .into-two {
    margin-top: 60px;
    padding-top: 60px;
  }
  .n_web .advantage-over.rules-who-aply .into-two::after {
    display: none;
  }
  .n_web .advantage-over.rules-who-aply .into-two + .into-two::before {
    left: auto;
    right: 0;
    content: "";
    height: 1px;
    top: 0;
    width: 100%;
    background-color: #fff;
    position: absolute;
  }
  .n_web .apply-accelerated .left-title {
    width: 100%;
  }
  .n_web .apply-accelerated .form-section {
    width: 100%;
    padding: 0;
    margin: 30px 0 0;
  }
  .n_web .main-team-section::after {
    display: none;
  }
  .n_web .main-team-section .card-body {
    padding: 0 30px;
    border-radius: 0;
  }
  .n_web .team-m-box {
    width: calc((100% / 2) - 30px);
  }
  .n_web .advantage-over.becom-our .inner-text p {
    width: 100%;
  }
  .n_web .btn-job-opning {
    margin: 15px 0 0 0;
  }
  .n_web .token-tabs-se .tab-content > .tab-pane {
    display: block !important;
    opacity: 1;
  }
  .n_web .cob-bcm-digital .sw-bx {
    width: 100%;
  }
  .n_web .cob-bcm-digital .lft-bx {
    padding: 30px 15px;
    background: #0f1740;
    margin: 0 -15px;
    width: calc(100% - -30px);
  }
  .n_web .cob-bcm-digital .rit-bx {
    padding: 30px 15px;
    margin: 0 -15px;
    width: calc(100% - -30px);
    background: #f2f2f2;
  }
  .n_web .cob-bcm-digital {
    background-color: #34adff;
    background-image: -webkit-linear-gradient(90deg, #f2f2f2 50%, #0f1740 50%);
    background-image: gradient(90deg, #f2f2f2 50%, #0f1740 50%);
    padding: 0;
  }
  .n_web .main-title-s h2 {
    font-size: 8px;
  }
  .n_web .four-m-bxes .sm-w-bx {
    width: 100%;
    position: relative;
  }
  .n_web .four-m-bxes .sm-w-bx + .sm-w-bx {
    margin-top: 30px;
    padding-top: 30px;
  }
  .n_web .four-m-bxes .sm-w-bx + .sm-w-bx::after {
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    content: "";
    height: 1px;
    background-color: #282896;
  }
  .n_web .four-m-bxes .for-bm-p {
    padding: 0;
  }
  .n_web .four-m-bxes .for-tp-p {
    padding: 0;
  }
  .n_web .for-plus-sign::after,
  .n_web .for-plus-sign::before {
    display: none;
  }
  .n_web .we-are-differnent .into-two-part {
    width: 100%;
    padding: 0;
  }
  .n_web .advantage-over.we-are-differnent {
    padding: 30px 0;
  }
  .n_web .we-are-differnent .for-cross-sign::after,
  .n_web .we-are-differnent .for-cross-sign::before {
    display: none;
  }
  .n_web .we-are-differnent .into-two-part {
    min-height: inherit;
  }
  .n_web .we-are-differnent .lft-tit h3 {
    font-size: 50px;
  }
  .n_web .we-hve-featu .lft-t {
    width: 100%;
  }
  .n_web .we-hve-featu .rt-t {
    width: 100%;
    padding: 15px 0 0 0;
  }
  .n_web .we-hve-featu .lft-t h3 {
    font-size: 18px;
  }
  .n_web .token-tabs-se .edge-smal {
    display: none;
  }
  .n_web .token-tabs-se .tab-swap .img-part {
    width: 100%;
    margin-top: 20px;
  }
  .n_web .token-tabs-se .card-header {
    border-bottom: none;
  }
  .n_web .token-tabs-se .tab-swap .txt-part {
    width: 100%;
    padding: 0;
  }
  .n_web .token-tabs-se .card-body {
    padding: 20px;
  }
  .n_web .token-tabs-se {
    padding: 30px 0;
  }
  .n_web .token-tabs-se .tab-swap .txt-part .iner-pt {
    max-width: inherit;
  }
  .n_web .token-tabs-se .card-header {
    padding: 0;
  }
  .n_web .token-tabs-se .card-header a {
    display: block;
    padding: 15px;
    line-height: 1;
  }
  .n_web .token-tabs-se .tab-content > .tab-pane + .tab-pane {
    margin-top: 20px;
  }
  .n_web .apply-accelerated::after {
    display: none;
  }
  .n_web .navigation-dots-container {
    display: none;
  }
  .n_web footer .footer-nav-social .last-s {
    width: 48%;
  }
  .n_web footer .footer-nav-social .last-s .tit {
    width: 100%;
  }
  .n_web .b-s-20-767 {
    height: 20px;
  }
  .n_web .faqs-main-que .into-two-p {
    width: 100%;
    margin: 0;
  }
  .n_web .faqs-main-que .into-two-p + .into-two-p {
    margin-top: 20px;
  }
  .n_web .faqs-main-que .card {
    margin: 0;
  }
  .n_web .faqs-main-que .card + .card {
    margin-top: 20px;
  }
  .n_web footer .footer-nav-social .last-s .social {
    padding-right: 0;
  }
  .n_web .partner-multi-logo .part-lgo-bx {
    width: calc(33% - 30px);
  }
  .n_web .bellow-text-part p {
    width: 100%;
    margin: 0;
  }
  .n_web .bellow-text-part p + p {
    margin-top: 20px;
  }
  .n_web .our-technology.on-hmp-man {
    padding: 40px 0;
  }
  .n_web .apply-accelerated.ambass-bg::before,
  .n_web .current-indus-solu::before,
  .n_web .our-technology .for-bg::before,
  .n_web .our-technology.on-hmp-man::after {
    display: none;
  }
  .n_web .current-indus-solu .card-header {
    pointer-events: none;
  }
  .n_web .our-technology.oslu {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .n_web .token-tabs-se .card-header [aria-expanded="true"] {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f08639),
      to(#f05139)
    );
    background: linear-gradient(180deg, #f08639 0, #f05139 100%);
    color: #fff;
  }
  .n_web .morinfo-ab-coint .sw-p {
    width: 100%;
  }
  .n_web .token-tabs-se .tab-swap .txt-part h2 {
    font-size: 34px;
  }
  .n_web .morinfo-ab-coint .small-conn .sw-p {
    padding: 0;
  }
}
@media screen and (max-width: 575px) {
  .n_web .flex-wrap-575 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .n_web .eco-system-se.asia-blc .main-title h1 {
    width: 100%;
    font-size: 120px;
  }
  .n_web .eco-system-se.asia-blc .main-title h2 {
    margin: 10px 0 0 0;
  }
}
@media screen and (max-width: 479px) {
  .n_web .flex-wrap-479 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .n_web footer .copy-right .some-links a + a {
    margin-left: 20px;
  }
  .n_web .our-technology .for-bg::before {
    max-width: 270px;
  }
  .n_web .main-title h1 {
    font-size: 60px;
  }
  .n_web .only-img-sec {
    height: 200px;
  }
  .n_web .main-title h2 {
    letter-spacing: 15px;
  }
  .n_web nav.side-menu .menu,
  .o_web nav.side-menu .menu {
    width: 280px;
    padding-bottom: 50px;
  }
  .n_web .eco-system-se .for-shape::after {
    display: none;
  }
  .n_web .three-m-s {
    padding: 30px 0;
  }
  .n_web .three-m-s .inn-bx h3 {
    font-size: 15px;
  }
  .n_web .main-hop.eco-system-se {
    padding: 30px 0;
  }
  .n_web .main-hop.eco-system-se .for-shape::before {
    display: none;
  }
  .n_web .main-title-s h1 {
    font-size: 48px;
  }
  .n_web .main-title-s h2 {
    letter-spacing: 10px;
  }
  .n_web .we-are-differnent .lft-tit h3 {
    font-size: 30px;
  }
  .n_web .we-are-differnent .rit-text h2 {
    font-size: 22px;
  }
  .n_web .advantageses-over h3,
  .n_web .current-indus-solu h2,
  .n_web .cust-zo-contact-form h2,
  .n_web .our-technology .contain-p h2,
  .n_web .partner-multi-logo h2 {
    font-size: 20px;
  }
  .n_web .partner-multi-logo .part-lgo-bx {
    width: calc(50% - 30px);
  }
  .n_web .morinfo-ab-coint .bellow-text-part .txt-p {
    width: 100%;
    padding: 0;
    margin: 15px 0 0 0;
  }
}
@media screen and (max-width: 430px) {
  .n_web .flex-wrap-430 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .n_web .w-100-430 {
    width: 100%;
  }
  .n_web .main-hop.eco-system-se .follow-us-socil .line-btn {
    padding: 10px 40px 12px;
    margin: 20px 0 0 0;
  }
  .n_web .team-m-box {
    width: calc((100% / 1) - 30px);
  }
  .n_web footer .footer-nav-social .mail-links {
    width: 100%;
  }
  .n_web .advantage-over .imagese img {
    max-width: 120px;
  }
  .n_web footer .footer-nav-social .last-s {
    width: 100%;
  }
  .n_web footer .footer-nav-social .last-s .social .ic-so {
    margin: 1px 3px;
  }
  .n_web header .logo {
    max-width: 170px;
  }
}
.n_web {
  background-color: #111a44;
  position: relative;
  z-index: 2;
  overflow-x: hidden;
}
@media screen and (min-width: 768px) {
  .n_web .border-blue-right {
    border-right: 1px solid #282896;
  }
  .n_web .border-blue-bottom {
    border-bottom: 1px solid #282896;
  }
  .n_web .four-m-bxes .for-lt-p,
  .n_web .four-m-bxes .for-rt-p {
    padding: 25px;
  }
  .n_web .four-m-bxes .for-lt-p:hover,
  .n_web .four-m-bxes .for-rt-p:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}
.n_web .body-padTop header {
  padding-top: 72px;
}
.n_web .team-parallax .react-parallax-bgimage {
  z-index: -2;
}
.n_web .ic-rig8-arrow {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.n_web .btn-apply-now:hover .ic-rig8-arrow {
  left: 5px !important;
}
.n_web .f12-400Arial {
  font-size: 12px;
  font-family: Arial;
}
.o_web {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  background-repeat: no-repeat;
  background: #f4f4f4;
  padding-top: 110px;
  min-height: 100vh;
}
.body-scroll-fixed {
  overflow: hidden;
  background-color: #111a44;
}
._color_gray {
  color: #66667e !important;
}
.transparent {
  background-color: transparent !important;
}
.landing-widget {
  margin-top: -2rem;
}
.landing-widget .purchase-nav li a {
  width: 125px;
}
.landing-widget .def_tab_v2 li a.active,
.stake-widget .def_tab_v2 li a.active {
  color: #fff !important;
}
.landing-widget .individual-token,
.swap-widget .individual-token {
  padding: 1rem 0.5rem;
}
.landing-widget .individual-token p,
.swap-widget .individual-token p {
  padding-top: 0;
}
.swap-widget {
  min-height: 0;
  position: relative !important;
  z-index: 0 !important;
}
.stake-widget {
  margin-top: -6rem;
  min-height: 0;
}
.stake-widget .def_tab_v2 li a {
  width: 108px;
}
.stake-widget .max-745 .d-flex > div {
  max-width: 350px !important;
}
.stake-widget p {
  margin-top: 0.75rem;
}
.history-header .tx-history-nav {
  justify-content: flex-start !important;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.react-tel-input .flag-dropdown {
  user-select: none;
}
.ant-tooltip-arrow {
  display: none !important;
}
.ant-tooltip-placement-right {
  padding-left: 0 !important;
}
.ant-tooltip-placement-left {
  padding-right: 0 !important;
}
.ant-tooltip-inner {
  background-color: #d8d8d8 !important;
  color: #868686 !important;
  font-family: "SF Pro Display Regular", sans-serif !important;
  border: 3px solid #fff !important;
  border-radius: 15px !important;
  padding: 4px 15px 2px 15px !important;
  font-size: 12px !important;
}
.password-meter {
  padding: 8px 5px 0 5px;
}
.password-meter p {
  font-family: "SF Pro Display Regular", sans-serif;
  padding-top: 0;
  text-transform: uppercase;
  font-size: 10px !important;
}
@media screen and (max-width: 990px) {
  .img-part {
    width: 100% !important;
  }
  .landing-widget .purchase-nav li a {
    width: 120px;
  }
  .stake-widget .def_tab_v2 li a {
    width: 111.3px;
  }
  .landing-widget .def_tab_v2,
  .stake-widget .def_tab_v2 {
    margin-bottom: -1.25rem !important;
  }
}
@media screen and (max-width: 768px) {
  .landing-widget .def_tab_v2,
  .stake-widget .def_tab_v2 {
    display: flex !important;
  }
}
.loginMenus a:hover {
  color: #ef4b23;
}
.o_web .loginMenus a {
  font-size: 14px;
  color: #66667e;
}
.kyc-modal {
  margin: 0 auto !important;
  justify-content: space-around !important;
  height: auto !important;
  max-width: 400px !important;
  min-width: 0 !important;
  width: 100% !important;
}
a.navbar-brand.focussable-image:focus {
  border: none;
}
.n_web #collapse-A .o_web,
.n_web #collapse-B .o_web,
.n_web #collapse-C .o_web,
.n_web #collapse-D .o_web {
  margin-top: 0 !important;
  padding-top: 0 !important;
  height: auto;
  min-height: auto;
}
.n_web #collapse-A .o_web .content {
  margin-top: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  min-height: auto !important;
}
.n_web #collapse-B .o_web .content {
  margin-top: 0 !important;
  padding-top: 0 !important;
  height: auto !important;
  min-height: auto !important;
}
.n_web #collapse-C .o_web {
  min-height: auto !important;
  margin-top: 0 !important;
}
.n_web #collapse-C .o_web .content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: auto !important;
}
.n_web #collapse-C .o_web .content .def_wt_bx {
  margin-top: 0;
}
.n_web #collapse-D .o_web .content {
  padding-bottom: 40px !important;
  margin-top: 0 !important;
  height: auto !important;
  min-height: auto !important;
  overflow: visible !important;
}
@media (max-width: 1279px) {
  .n_web .part-logo .item .part-lgo-bx img {
    max-width: 80%;
    margin: 0 auto;
  }
}
@media (max-width: 991px) {
  .n_web .eco-system-se.our-so-new-pg {
    background: rgba(0, 0, 0, 0.55);
  }
  .n_web #collapse-A .o_web,
  .n_web #collapse-B .o_web,
  .n_web #collapse-C .o_web,
  .n_web #collapse-D .o_web {
    margin-top: 30px !important;
  }
}
@media (max-width: 767px) {
  .n_web #collapse-A .o_web,
  .n_web #collapse-B .o_web,
  .n_web #collapse-C .o_web,
  .n_web #collapse-D .o_web {
    margin-top: 0 !important;
  }
  .n_web #collapse-A .o_web .left_d,
  .n_web #collapse-B .o_web .left_d,
  .n_web #collapse-C .o_web .left_d,
  .n_web #collapse-D .o_web .left_d {
    margin-top: 0 !important;
  }
}
.n_web #tabs-widgets .mobile-show {
  display: none;
}
@media screen and (max-width: 991px) {
  .o_web .history-header .tx-history-nav {
    width: calc(100% - 40px);
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 0;
    height: 73px;
    position: relative;
  }
  .o_web .asset-dropdown {
    width: 260px;
  }
}
@media screen and (max-width: 767px) {
  .n_web #tabs-widgets .desktop-show {
    display: none;
  }
  .n_web #tabs-widgets .mobile-show {
    display: block;
  }
  .content.edge196-calculator {
    height: auto !important;
    padding-bottom: 0;
  }
  .kyc_page_wraper_mobile {
    padding-bottom: 100px;
  }
  .o_web.kyc_page_wraper_mobile .swap-card {
    z-index: 8;
    margin: 0 15px;
  }
  .o_web.kyc_page_wraper_mobile .content {
    padding-bottom: 100px;
  }
}
.n_web #tabs-widgets .mobile-show a.active h5 {
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
  color: #fff;
}
.n_web #tabs-widgets .mobile-show .accordion > .card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.o_web .card-input .form-control {
  border-color: #989898 !important;
}
select.unselected {
  color: #989898 !important;
}
.f20-400 {
  font-size: 20px !important;
  font-family: "SF Pro Display Regular" !important;
  word-break: break-all;
}
@media screen and (max-width: 767px) {
  .f20-400 {
    font-size: 18px !important;
  }
  .n_web #tabs-widgets .mobile-show .accordion > .card {
    overflow: unset;
  }
  .n_web #tabs-widgets .mobile-show .collapse.show {
    z-index: 1;
  }
  .o_web .tab-item a {
    font-size: 9px;
  }
  .o_web .tab-item-active a {
    font-size: 9px;
  }
  .o_web .tabs-container li {
    padding: 0 10px;
    margin: 0;
  }
  .edge_menus_mobile_hide {
    display: none;
  }
}
.responsive-modal {
  padding-top: 0 !important;
  min-height: 0;
}
.card-input .form-control,
.card-input .react-tel-input .form-control {
  height: 3.5rem !important;
  padding: 1rem;
  border: none !important;
  width: 100%;
  border-radius: 12px;
  font-weight: 300;
  -webkit-box-shadow: 6px 9px 20px #00000029 !important;
  box-shadow: 6px 9px 20px #00000029 !important;
  background-color: #fff !important;
}
.edge_page_title {
  font-size: 18px;
  line-height: 16px;
  color: #989898;
  font-family: "SF Pro Display Bold";
}
.edge_accountno {
  font-size: 14px;
  line-height: 12px;
  color: #8c8c8c;
  font-family: "SF Pro Display Regular";
}
.edge_userName {
  font-size: 16px;
  line-height: 24px;
  color: #8c8c8c;
  font-family: "SF Pro Display Semibold";
}
.f12-Display-Semibold {
  font-size: 12px;
  line-height: 24px;
  color: #8c8c8c;
  font-family: "SF Pro Display Semibold";
}
.f9-Display-Regular {
  font-size: 11px;
  line-height: 12px;
  color: #8c8c8c;
  font-family: "SF Pro Display Regular";
}
.edge_avtar_box {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  position: relative;
  margin-right: 20px;
}
.edge_avtar_box img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.edge_avtar_box input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.edge_avtar_box.active:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #29cb97;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  top: 5px;
  right: 5px;
}
.f13-Display-Semibold {
  font-size: 13px;
  line-height: 16px;
  color: #8c8c8c;
  font-family: "SF Pro Display Bold";
}
.edgeWhiteBox {
  background: #fff;
  box-shadow: 0 1px 4px #e5e9f2;
  -webkit-box-shadow: 0 1px 4px #e5e9f2;
  -moz-box-shadow: 0 1px 4px #e5e9f2;
  border-radius: 23px;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  padding: 15px;
}
.edgeWhiteBox .form-control {
  font-size: 12px !important;
  line-height: 14px;
  color: #31394d;
  font-family: "SF Pro Display Regular";
}
.edgeWhiteBox input:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.edgeWhiteBox .react-datepicker-wrapper {
  width: 100%;
}
.datepicker {
  background: #fff url(../images/calendar.svg) no-repeat scroll 96% 50%/15px;
}
.edgeAddnewBtn {
  background: #f9fafe;
  border: 1px solid #ebedf4;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  font-size: 10px;
  color: #748aa1;
  padding: 15px;
}
.edge_paycard {
  box-shadow: 0 5px 20px #c0c7d6;
  -webkit-box-shadow: 0 5px 20px #c0c7d6;
  -moz-box-shadow: 0 5px 20px #c0c7d6;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  padding: 25px;
}
.edge_paycard .f16 {
  color: #fff;
  font-size: 16px;
}
.edge_paycard .f11 {
  color: #fff;
  font-size: 11px;
}
.edge_cardblue {
  background: transparent linear-gradient(180deg, #679cf6 0, #4072ee 100%);
}
.opacity50 {
  opacity: 0.5;
}
.edge_cardgreen {
  background: transparent linear-gradient(180deg, #29cb97 0, #2cb589 100%);
}
@media only screen and (max-width: 575px) {
  .purchase-nav li:nth-child(1) a {
    padding-left: 0 !important;
    padding-right: 10px !important;
  }
  .purchase-nav li:nth-last-child(1) a {
    padding-right: 0 !important;
    padding-left: 10px !important;
  }
}
@media only screen and (max-width: 500px) {
  body {
    background-color: #f4f4f4 !important;
  }
  .buy-container {
    padding: 0 !important;
    min-height: 100vh !important;
    align-items: flex-start !important;
  }
  .swap-container {
    height: 100vh !important;
    display: flex !important;
    align-items: center !important;
  }
  .settings-main-container {
    margin: 0 !important;
    min-height: 100vh !important;
    display: flex !important;
    justify-content: center !important;
  }
  .ant-modal-title {
    width: 95%;
    position: relative;
    top: -4.5px;
  }
  .ant-modal .def_sb_btn {
    margin-bottom: 0 !important;
  }
}
.n_web .current-scenario .edge-event-tree-box .same-box h3 {
  height: 56px;
}
.n_web .current-scenario .edge-event-tree-box .same-box {
  padding: 25px 50px 50px;
  position: relative;
}
.n_web .current-scenario .edge-event-tree-box a.btn-apply-now.sb.ff-muli-sb {
  position: absolute;
  bottom: 0;
}
.n_web .mx-500 {
  max-width: 500px !important;
}
nav nav.side-menu {
  margin-top: 0 !important;
}
.edge196_ambassadorSection1 {
  padding: 60px 15px;
}
.n_web .current-scenario .edge196_ambassadorSection1 .three-points .same-box {
  padding: 60px 50px 0;
}
.edge196_ambassadorSectionaccelerated.apply-accelerated {
  padding: 60px 0 45px;
}
.o_web .content.edge196_register_page {
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: 9% 0 0;
}
@media screen and (max-width: 1366px) {
  .n_web .current-scenario .current-se-box h2 {
    line-height: 24px;
    font-size: 18px;
  }
  .n_web .current-scenario .current-se-box p {
    margin: 0 0 8px;
  }
  .n_web .b-s-80 {
    height: 30px;
  }
  .n_web .apply-accelerated {
    padding: 0 0;
  }
}
@media screen and (max-width: 1199px) {
  .n_web nav.side-menu .menu-toggle:hover::before,
  .o_web nav.side-menu .menu-toggle:hover::before {
    background-position: 0 0;
  }
  .n_web nav.side-menu .menu-toggle.menu-open::after,
  .n_web nav.side-menu .menu-toggle.menu-open::before,
  .o_web nav.side-menu .menu-toggle.menu-open::after,
  .o_web nav.side-menu .menu-toggle.menu-open::before {
    background-position: -30px 0;
  }
  .n_web .token-tabs-se .tab-swap .txt-part h2 {
    line-height: 32px;
  }
  .n_web .advantage-over,
  .n_web .three-m-s {
    padding: 30px 0;
  }
  .n_web footer .footer-nav-social .quick-link ul li {
    padding: 2px 0;
  }
  .n_web footer .footer-nav-social .quick-link p {
    margin: 0 0 2px;
  }
  .n_web footer .footer-nav-social .last-s .tit p {
    margin: 0 0 2px;
  }
  .n_web footer .f-notes {
    font-size: 10px;
  }
  .n_web footer .footer-nav-social .last-s .tit + .tit {
    margin-top: 20px;
  }
  .n_web .b-s-20 {
    height: 15px;
  }
  .n_web .we-are-differnent .into-two-part {
    padding: 30px 0;
    min-height: 300px;
  }
  .n_web .our-technology.on-hmp-man {
    padding: 0 0 60px;
  }
  .n_web .eco-system-se.our-team .for-shape::after {
    left: -380px;
  }
  .n_web .current-scenario .edge196_ambassadorSection1 .three-points .same-box {
    padding: 30px 30px 0;
  }
  .n_web .advantage-over.rules-who-aply {
    padding: 30px 0;
  }
  .n_web .three-m-s .inn-bx h3 {
    margin: 0 0 10px;
  }
  .n_web .partner-multi-logo {
    padding: 30px 0;
  }
  .edge196_ambassadorSectionaccelerated.apply-accelerated {
    padding: 30px 0 15px;
  }
  .body-scroll-fixed {
    padding-right: 0;
  }
}
@media screen and (max-width: 991px) {
  .hero-banner img.react-parallax-bgimage {
    transform: translate3d(-25%, -21.0938px, 0) !important;
    -webkit-transform: translate3d(-25%, -21.0938px, 0) !important;
    -moz-transform: translate3d(-25%, -21.0938px, 0) !important;
  }
  .n_web .current-scenario .edge-event-tree-box .same-box h3 {
    height: auto;
  }
  .n_web .current-scenario .edge-event-tree-box .same-box {
    padding: 25px 0 50px;
  }
  .n_web .current-scenario.cust-part {
    padding: 30px 0;
  }
  .n_web .current-scenario .edge196_ambassadorSection1 .three-points .same-box {
    padding: 30px 0 0;
  }
  .o_web .content.edge196_register_page {
    padding: 15% 0 0;
  }
}
@media screen and (max-width: 767px) {
  .n_web #collapse-D .o_web .content,
  .n_web .o_web .max-745 {
    padding-bottom: 0 !important;
  }
  .n_web .line-btne {
    margin: 0;
  }
  .n_web .advantage-over .inner-text h2 {
    margin: 0 0 20px;
  }
  .n_web .advantage-over {
    padding: 30px 0;
  }
  .n_web .three-m-s .inn-bx h3 {
    margin: 0 0 20px;
  }
  .n_web footer .footer-nav-social .quick-link ul li {
    padding: 2px 0;
  }
  .n_web footer .footer-nav-social .quick-link p {
    margin: 0 0 2px;
  }
  .n_web footer .footer-nav-social .last-s .tit p {
    margin: 0 0 2px;
  }
  .n_web footer .f-notes {
    font-size: 10px;
  }
  .n_web .faqs-main-que {
    padding: 0 0 40px;
  }
  .n_web .apply-accelerated.contact-us-bg .form-section {
    margin: 10px 0 0 0;
  }
  .n_web .apply-accelerated.contact-us-bg {
    padding: 30px 0 0;
  }
  .n_web .we-are-differnent .into-two-part.Old_hom {
    padding: 0 !important;
    min-height: auto;
  }
  .n_web .cob-bcm-digital.Old_hom {
    padding: 0 !important;
  }
  .kyc-container {
    margin: 0 !important;
    padding: 0 !important;
    min-height: 100vh !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }
  .wallet-table-container {
    padding-top: 0 !important;
  }
  .o_web .content.edge196_register_page {
    padding: 100px 0 0;
  }
  .o_web .content.edge196_login_page {
    align-items: flex-start !important;
    padding-top: 100px !important;
  }
}
iframe.ambassadorForm {
  height: 1116px !important;
  margin-top: -45px;
}
iframe.contactusForm {
  height: 750px !important;
}
iframe.workwithusForm {
  height: 890px !important;
}
iframe.partnerForm {
  height: 950px !important;
  margin-left: -45px;
  margin-top: -45px;
}
.padding-60-0 {
  padding-top: 60px;
  padding-bottom: 60px;
}
f
  .n_web
  .current-scenario.current-scenario-second
  .edge-event-tree-box
  .same-box {
  padding: 0 50px 50px;
  margin: 60px 0;
}
.edge196_partner_apply-accelerated.apply-accelerated {
  padding: 0 0 30px;
}
.o_web nav.side-menu .menu.active {
  top: 45px;
  height: calc(100vh - 59px);
  right: -2rem;
}
.o_web nav.side-menu .menu {
  right: -2rem;
}
@media screen and (max-width: 1366px) {
  .n_web .main-title h1,
  .n_web .main-title-s h1 {
    font-size: 65px;
    line-height: 0.8;
  }
  .n_web .main-title h2,
  .n_web .main-title-s h2 {
    letter-spacing: 15px;
  }
  .n_web .eco-system-se.our-team .for-shape::after {
    top: -30px;
  }
  .n_web .eco-system-se.our-team .for-shape::before {
    top: 80px;
  }
  .edge196_mt_10 {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 1199px) {
  .n_web .apply-accelerated.contact-us-bg,
  .n_web .current-scenario.cust-part,
  .n_web .eco-system-se,
  .n_web .load-more,
  .n_web .recent-media-se {
    padding: 30px 0;
  }
  .n_web .current-scenario .three-points .same-box {
    padding: 30px 30px;
  }
  .padding-60-0 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .n_web .bellow-text-part.new-our-sol {
    padding: 0 0 15px;
  }
  .n_web .our-technology .img-p img {
    max-width: 50%;
  }
  .n_web .main-team-section {
    margin: 30px 0 0 0;
  }
  .n_web
    .current-scenario.current-scenario-second
    .edge-event-tree-box
    .same-box {
    margin: 30px 0;
  }
  .edge196_ambassadorSection1,
  .n_web .cob-bcm-digital {
    padding: 30px 15px;
  }
  .n_web .faqs-main-que {
    padding: 0 0 30px;
  }
  .n_web .advantageses-over.Old_hom {
    padding: 30px 0;
  }
  .n_web .current-indus-solu {
    padding: 30px 0 10px;
  }
  .n_web .our-technology.Old_hom.on-hmp-man {
    padding: 0 0 30px;
  }
  .n_web .cob-bcm-digital.Old_hom {
    padding: 30px 15px 30px;
  }
  .n_web .we-are-differnent .into-two-part {
    padding: 0;
  }
  .o_web .header {
    height: 63px;
  }
  .n_web .eco-system-se.our-team .for-shape::before {
    top: 10px;
  }
}
@media screen and (max-width: 991px) {
  .n_web .current-scenario .three-points .same-box {
    padding: 30px 0 0;
  }
  .n_web .current-scenario .three-points .same-box:last-child {
    padding-bottom: 30px;
  }
  .n_web .our-technology .img-p img {
    max-width: 60%;
  }
  .n_web
    .current-scenario.current-scenario-second
    .edge-event-tree-box
    .same-box {
    padding: 0;
    margin: 30px 0 0;
  }
  .n_web
    .current-scenario.current-scenario-second
    .edge-event-tree-box
    .same-box:last-child {
    padding-bottom: 30px;
  }
  .n_web .current-scenario .edge-event-tree-box a.btn-apply-now.sb.ff-muli-sb {
    position: unset;
  }
  .edge196_ambassadorSection1,
  .n_web .cob-bcm-digital {
    padding: 30px 15px 0;
  }
  .n_web .apply-accelerated.contact-us-bg .form-section {
    /* margin: 0; */
    padding: 0;
  }
  .n_web .edge196_asiablockchain.eco-system-se .for-shape::before {
    height: 570px;
    width: 570px;
    left: auto;
    right: -230px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .n_web .edge196_asiablockchain.eco-system-se .for-shape::after {
    display: block;
    top: 0;
    width: 540px;
    height: 540px;
    background-size: 100%;
    bottom: 0;
    margin: auto;
    left: -340px;
  }
  .n_web .eco-system-se.our-team .for-shape::after {
    background-size: 100%;
  }
  .n_web .eco-system-se.our-team .for-shape::before {
    background-size: 100%;
    height: 570px;
    width: 570px;
  }
  .n_web .eco-system-se.events-bg .for-shape::before {
    height: 570px;
    width: 570px;
    background-size: 100%;
  }
  .n_web .eco-system-se.events-bg .for-shape::after {
    width: 550px;
    height: 590px;
    background-size: 100%;
  }
  .n_web .eco-system-se .for-shape.ourSolutionRing::before,
  .n_web .eco-system-se .for-shape.ourStoryRing::before {
    height: 570px;
    width: 570px;
    background-size: 100%;
    top: 0;
  }
  .n_web .eco-system-se .for-shape.ambassadorRing::before {
    height: 570px;
    width: 570px;
    left: auto;
    right: -230px;
    top: 0;
    bottom: 0;
    background-size: 100%;
  }
  .n_web .eco-system-se .for-shape.ambassadorRing::after {
    display: block;
    width: 540px;
    height: 540px;
    background-size: 100%;
    left: -340px;
  }
  .n_web .eco-system-se .for-shape.ourSolutionRing::after {
    background-size: 100%;
  }
  .n_web .eco-system-se .for-shape.ourStoryRing::after {
    background-size: 100%;
    height: 540px;
    width: 540px;
    left: -340px;
    top: 0;
  }
}
.edge196_LoginBox {
  background: transparent linear-gradient(187deg, #f05139 0, #f08639 100%);
  border-radius: 19px;
  -webkit-border-radius: 19px;
  -moz-border-radius: 19px;
  width: 495px;
  height: auto;
  padding: 35px 30px 30px;
  position: fixed;
  top: 0;
  right: 50px;
  z-index: 6;
  animation: slideDown 0.3s forwards;
}
@keyframes slideDown {
  from {
    top: 0;
  }
  to {
    top: 50px;
  }
}
@-webkit-keyframes slideDown {
  from {
    top: 0;
  }
  to {
    top: 50px;
  }
}
@-moz-keyframes slideDown {
  from {
    top: 0;
  }
  to {
    top: 50px;
  }
}
.edge196_LoginBox input {
  font-size: 12px;
  color: #fff;
  font-family: muliregular;
  border: none;
  background: 0 0;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.edge196_LoginBox input:-webkit-autofill,
.edge196_LoginBox input:-webkit-autofill:active,
.edge196_LoginBox input:-webkit-autofill:focus,
.edge196_LoginBox input:-webkit-autofill:hover {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}
.edge196_LoginBox input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.edge196_LoginBox input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.edge196_LoginBox input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.edge196_LoginBox input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.edge196_showpasswordbtn {
  background: 0 0;
  border: none;
  position: absolute;
  top: 0;
  right: 15px;
}
.edge196_forgotLink {
  padding: 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 10px;
  color: #fff;
  font-family: muliregular;
}
.edge196_forgotLink button {
  font-family: mulibold;
  background: 0 0;
  border: none;
  padding: 0 0 0 5px;
}
.team-m-deg {
  width: 155px;
  margin: 0 auto;
}
.width132 {
  width: 132px;
}
@media screen and (max-width: 767px) {
  .edge_accountno {
    text-align: left;
  }
  iframe.partnerForm {
    height: 1000px !important;
    margin-left: 0;
  }
  iframe.ambassadorForm {
    height: 1250px !important;
  }
  iframe.workwithusForm {
    height: 940px !important;
  }
  iframe.contactusForm {
    height: 790px !important;
  }
  .o_web .max_prnt .form-control {
    padding-left: 15px;
    font-size: 18px !important;
  }
  .o_web .form-control,
  .o_web .input-with-dropdown input {
    font-size: 18px;
    color: #0f1740 !important;
  }
  .n_web footer .copy-right p {
    text-align: center;
  }
  .some-links.w-100-767.order-1-767 {
    display: flex;
    justify-content: center;
  }
  .o_web nav.side-menu .menu {
    right: -33px;
  }
  .o_web .header {
    height: 70px;
    padding: 26px 0;
  }
  .o_web .header .tabs-container {
    top: 70px;
  }
  .edge196_universal-swap {
    align-items: flex-start !important;
  }
  .edge196_mobile_top {
    align-items: flex-start !important;
    overflow: visible !important;
  }
  .edge196_mobile_top_Auction {
    align-items: flex-start !important;
    margin-top: 0 !important;
    overflow: visible !important;
    padding: 0 !important;
  }
  .o_web {
    padding-top: 160px;
  }
  .o_web .edge196_mobile_top_Auction .def_wt_bx {
    margin: 0;
  }
  .o_web .edge196_mobile_top .mx-400,
  .o_web .edge196_mobile_top_Auction .mx-400 {
    margin-bottom: 10px;
  }
  .o_web .settings-card {
    margin-top: 0;
  }
}
.width123 {
  width: 123px;
}
.main-team-section > .container {
  z-index: 2 !important;
  position: relative;
}
.activity-table thead td {
  padding-bottom: 1rem;
  color: #000;
}
.activity-table tbody td {
  padding: 0 0.75rem 0.85rem 0;
}
.z-index-0 {
  z-index: 0;
}
.z-index-0:after,
.z-index-0:before {
  z-index: 0;
}
.event-filler {
  height: 30px;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media screen and (max-width: 750px) {
  .n_web .asia-blc.eco-system-se .for-shape,
  .n_web .eco-system-se.our-team .for-shape {
    display: none;
  }
  .n_web .apply-accelerated .fr-shapre::before,
  .n_web .eco-system-se .for-shape::after,
  .n_web .eco-system-se .for-shape::before,
  .n_web .eco-system-se.partners-shapes .for-shape::before,
  .n_web .edge196_asiablockchain.eco-system-se .for-shape::after,
  .n_web .edge196_asiablockchain.eco-system-se .for-shape::before,
  .n_web .main-hop.eco-system-se .for-shape::after,
  .n_web .main-hop.eco-system-se .for-shape::before,
  .n_web .three-m-s.on-m-hmp .for-shape::before {
    display: none;
  }
}
@media screen and (max-width: 1280px) {
  .walletconnect-modal__base {
    padding: 10px 10px 22px !important;
    transform: translateY(0) !important;
    -webkit-transform: translateY(0) !important;
    -moz-transform: translateY(0) !important;
    top: 10% !important;
    max-width: 300px !important;
  }
  .walletconnect-qrcode__text {
    font-size: 14px !important;
  }
  .walletconnect-modal__footer a {
    font-size: 13px !important;
  }
}
.edge196_TOKENOMICSsection {
  position: relative;
  padding: 100px 0 100px;
}
.edge196_TOKENOMICSsection::after {
  content: "";
  width: calc(50% - -30px);
  height: 100%;
  display: block;
  background: url(../images/edge196_img2.jpg) no-repeat scroll center
    center/cover;
  position: absolute;
  top: 0;
  right: 0;
}
.edge196_TOKENOMICSsection::before {
  content: "";
  width: calc(50% - 30px);
  height: 100%;
  display: block;
  background: url(../images/edge196_img3.jpg) no-repeat scroll center
    center/cover;
  position: absolute;
  top: 0;
  left: 0;
}
.edge196_TOKENOMICSsectionBefore {
  position: absolute;
  background: #f0f0f0;
  top: 0;
  right: 0;
  display: block;
  width: 65%;
  height: 65px;
  z-index: 1;
}
.edge196_TOKENOMICSsectionBefore::before {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-top: 0 solid transparent;
  border-right: 50px solid #f0f0f0;
  border-bottom: 65px solid transparent;
  position: absolute;
  bottom: 0;
  left: -50px;
}
.f36-BC-500 {
  font-size: 36px;
  line-height: 36px;
  font-family: "Barlow Condensed";
  font-weight: 500;
}
.f66-BC-500 {
  font-size: 66px;
  line-height: 52px;
  font-family: "Barlow Condensed";
  font-weight: 500;
}
.f80-BC-500 {
  font-size: 80px;
  line-height: 62px;
  font-family: "Barlow Condensed";
  font-weight: 500;
}
.f92-BC-500 {
  font-size: 92px;
  line-height: 82px;
  font-family: "Barlow Condensed";
  font-weight: 500;
}
.f8-muli-400 {
  font-size: 12px;
  line-height: 16px;
  font-family: muliregular;
}
.f10-muli-400 {
  font-size: 10px;
  line-height: 16px;
  font-family: muliregular;
}
.f10-muli-700 {
  font-size: 10px;
  line-height: 16px;
  font-family: mulibold;
}
.f12-muli-400 {
  font-size: 12px;
  line-height: 30px;
  font-family: muliregular;
}
.f12-muli-700 {
  font-size: 12px;
  line-height: 15px;
  font-family: mulibold;
}
.f14-muli-400 {
  font-size: 14px !important;
  line-height: 30px !important;
  font-family: muliregular;
}
.f14-muli-700 {
  font-size: 14px !important;
  line-height: 18px !important;
  font-family: mulibold;
}
.f18-muli-400 {
  font-size: 18px !important;
  line-height: 23px !important;
  font-family: muliregular;
}
.f20-muli-700 {
  font-size: 20px;
  line-height: 30px;
  font-family: mulibold;
}
.f24-muli-700 {
  font-size: 24px !important;
  line-height: 30px !important;
  font-family: mulibold;
}
.letterspacing-19 {
  letter-spacing: 19px;
}
.letterspacing-4 {
  letter-spacing: 4px;
}
.letterspacing-19 {
  letter-spacing: 19px;
}
.f20-muli-400 {
  font-size: 20px;
  line-height: 40px;
  font-family: muliregular;
}
.edge196_borderdashed {
  border-bottom: 1px dashed #dbdbdb;
}
.z-index {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1199px) {
  .f92-BC-500 {
    font-size: 52px !important;
    line-height: 42px !important;
  }
  .f20-muli-400 {
    font-size: 16px;
    line-height: 28px;
  }
  .f80-BC-500 {
    font-size: 50px;
    line-height: 35px;
  }
  .f24-muli-700 {
    font-size: 20px;
    line-height: 28px;
  }
  .f66-BC-500 {
    font-size: 46px;
    line-height: 36px;
  }
  .f36-BC-500 {
    font-size: 30px;
    line-height: 26px;
  }
  .letterspacing-19 {
    letter-spacing: 8px;
  }
  .letterspacing-4 {
    letter-spacing: 3px;
  }
}
@media screen and (max-width: 991px) {
  .edge196_TOKENOMICSsectionBefore {
    height: 35px;
  }
  .edge196_TOKENOMICSsection {
    padding: 65px 0;
  }
}
@media screen and (max-width: 767px) {
  .edge196_TOKENOMICSsection::after,
  .edge196_TOKENOMICSsection::before {
    content: none;
  }
  .edge196_TOKENOMICSsection .col-md-6:first-child {
    padding: 30px 15px;
  }
  .edge196_TOKENOMICSsection .col-md-6:first-child::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: url(../images/edge196_img3.jpg) no-repeat scroll center
      center/cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  .edge196_TOKENOMICSsection .col-md-6:last-child {
    padding: 30px 15px;
  }
  .edge196_TOKENOMICSsection .col-md-6:last-child::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: url(../images/edge196_img2.jpg) no-repeat scroll center
      center/cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  .edge196_TOKENOMICSsection {
    padding: 0;
  }
  .edge196_TOKENOMICSsectionBefore {
    display: none;
  }
}

.paypal-checkout-details {
  margin-bottom: 2rem;
}

.paypal-checkout-details h4 {
  margin-bottom: 2rem;
  text-align: center;
}

.paypal-checkout-grid {
  display: grid;
  grid-auto-rows: auto;
  row-gap: 0.5rem;
}

.paypal-checkout-grid > div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 15px;
}
.notFoundWrapper {
  text-align: center;
  padding: 20px;
}
.UnderConstruction-container{
  height: 100vh;
    margin-top: 32vh !important;
}
.UnderConstruction-title, .UnderConstruction-description{
  font-weight: 600;
}
.UnderConstruction-logo {
  width: 240px !important;
}
.viewDataWrapper {
  width: 100%;
}
.viewDataWrapper table {
  width: 80%;
  margin: auto;
  color: #fff;
  margin-bottom: 30px;
}
.viewDataWrapper table tr {
  border-bottom: 1px solid #8080807a;
}
.viewDataWrapper table tr td,
.viewDataWrapper table tr th {
  padding: 10px;
}
.viewDataWrapper table img {
  width: 60px;
  border-radius: 100px;
  background: #fff;
}
.viewDataWrapper table button {
  background: #f06a39;
  border: none;
  padding: 2px 11px;
}
.viewDataWrapper h2 {
  color: #fff;
  margin: 61px 20px 30px 140px;
}
.viewDataWrapper h2 a,
.add-new {
  font-size: 18px;
  color: #fff;
  border: 1px solid;
  padding: 7px 19px;
  float: right;
  margin-right: 9%;
}
.addteam {
  width: 100%;
}
.formWrapper {
  width: 50%;
  margin: auto;
}
.formWrapper h2 {
  text-align: center;
  color: #fff;
  margin: 30px 0px;
}
.formWrapper input,
.formWrapper select {
  width: 100%;
  margin: 5px;
  height: 39px;
  border: none;
  border-radius: 2px;
  padding: 8px;
}
.formWrapper label {
  width: 100%;
  color: #fff;
  text-align: left;
  padding: 8px;
}
.formWrapper button {
  background: #f44336;
  width: 187px;
  padding: 7px;
  font-size: 15px;
  color: #fff;
  border: none;
  border-radius: 3px;
}
.formWrapper button:hover {
  box-shadow: 4px 4px 14px 0px grey;
}

.notFoundWrapper img {
  width: 30%;
}
.notFoundWrapper .info h3 {
  color: #fff !important;
}
.ambassadorWrapper .ff-muli-r {
  font-size: 16px !important;
  font-weight: bold !important;
}
.team-select {
  border: none;
  border-bottom: 1px solid black;
  
}
body{
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll !important;
}

.edge-contact-wrap {
  padding: 75px;
  background: rgb(0 0 0 / 45%);
  border-radius: 15px;
  width: 100%;
}

.edge-contact-wrap .form-label {
  font-size: 18px;
  color: #FFF;
}

.edge-contact-wrap .btn-lg {
  padding: 10px 50px;
  border-radius: 10px;
}

.edge-contact-wrap .form-control {
  padding: 0px 10px;
  padding-bottom: 0px;
  border-radius: 4px;
}

.project-slider {
  padding: 85px 100px;
  background: #F0F0F0;
}

.project-slider .slick-prev:before, .project-slider .slick-next:before {
  display: none;
}

.project-slider .section-title {
  font-size: 65px;
  line-height: 75px;
  font-weight: 500;
  margin-bottom: 25px;
  text-align: center;
  font-family: Barlow;
}

.project-box-wrap {
  width: 100%;
  height: 180px;
  position: relative;
  background: #FFF;
  margin-top: 25px;
  overflow: hidden;
}

.project-box-wrap:hover .project-about {
  top: -25px;
}

.slide-wrap {
  padding: 0 10px;
  box-shadow: 5px 5px 45px 0px rgba(0,0,0,0.06);
}

.project-logo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.project-logo img {
  height: 60px;
}

.project-about p.project-category {
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
}

.project-about p.project-desc {
  height: calc(100% - 25px);
  display: flex;
  align-items: center;
}

.project-about {
  width: 100%;
  height: calc(100% + 25px);
  position: absolute;
  z-index: 2;
  top: 155px;
  left: 0;
  background: rgb(240,81,57);
  background: linear-gradient(90deg, rgba(240,81,57,0.95) 0%, rgba(240,134,57,0.95) 100%);
  padding: 5px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  text-align: center;
  transition: all .3s cubic-bezier(0.23, 1, 0.320, 1);
}

.project-about p {
  font-size: 21px;
  line-height: 28px;
  font-weight: 700;
  color: #FFF;
}

.leftMenuWrapper{
  position: absolute;
  top: 104px;
  text-align: left;
}
.leftMenuWrapper li{
  list-style: none;
  font-size: 21px;
  color: #fff;
  border-bottom: 1px solid;
  cursor: pointer;
}
.leftMenuWrapper li.active{
  color: #f06c39;
}
.connect-now{
  text-align: center;
  background: #0e1940;
  color: #fff;
  padding: 15px;
}

.connect-now span{
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
}
.projectModal .modal-content{
    width: 350px;
    border: none;
}
.qi-editor{
  height: 133px;
}
.newsWrap .modal-content{
  width: 650px;
  border: none;
}
.modal-header{
border: none;
}
.became-partner{
  margin-bottom: 56px !important;
}

.bottom-logo-row {
  display: grid;
  grid-template-columns: 37% 61%;
  column-gap: 27px;
  margin-top: 25px;
  justify-content: center;
}

.logo-box-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
}

@media (max-width: 1600px) {
  .project-logo img {
    height: 45px;
  }
}

@media (max-width: 1440px) {
  .qr-title {
    font-size: 16px;
  }
}

@media (max-width: 1366px) {
  .slide-wrap {
    padding: 0 7.5px;
  }
}

@media (max-width: 1024px) {
  .edge-contact-wrap {
    padding: 55px;
  }
  .project-logo img {
    height: auto;
    width: 80%;
  }
  .project-slider .section-title {
    font-size: 42px;
    line-height: 65px;
  }
  .project-slider {
    padding: 55px 50px 65px;
  }
  .project-about p {
    font-size: 14px;
    line-height: 20px;
  }
  .bottom-logo-row {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 600px) {
  .edge-contact-wrap {
    padding: 30px;
  }

  .project-logo img {
    height: 50px;
    width: auto;
  }
  .project-slider .section-title {
    font-size: 35px;
    line-height: 56px;
  }

  .logo-box-group {
    grid-template-columns: 1fr;
    row-gap: 25px;
  }
}
.topMargin{
  margin-top: 20px !important;
  color: #66667e !important;
}

.cookiepara{
  margin-top: 7px !important;
}
.bold{
  font-weight: 600;
}

.listposition li{
  list-style: disc !important;
}

.circlelist li {
  list-style: circle !important;
}
.investWrap{
  position: relative;
}
.investWrap a, .investWrap a:hover{
  width: 380px;
  margin: auto;
  padding: 15px 0px;
  color: #fff;
  font-size: 16px;
  background-image: linear-gradient(#f05e39, #f07f39);
  border-radius: 11px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: -20px;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .investWrap a{
  width: 75%;
  }
}
.newsWrapper .col-md-4{
  margin-top: 30px;
}
.newsWrapper img{
  width: 100%;
}
.newsWrapper .title{
  font-weight: 700;
  color: #000;
}
.newsWrapper hr{
  margin: 10px 0px;
}
.hidden{
  display: none;
}
.ql-editor{
  min-height: 150px;
}

.footerAddress{
  /* border: 1px solid red; */
  margin-top: 2%;
  
}

@media (max-width: 770px) {
  .footerAddress {
      margin-top: 6%;
    }
}
@media (max-width: 650px) {
  .footerAddress {
      margin-top: 30%;
    }
}
@media (max-width: 430px) {
  .footerAddress {
      margin-top: 28%;
    }
}
@media (max-width: 390px) {
  .footerAddress {
      margin-top:42%;
    }
}

.countrys {
  border-radius: 10px !important;
}

/* @media screen and (max-width: 768px) {
  .selectTag{
  
    align-items: center;
    width: 100%;
  }
} */

.filterParent{
  width: 75%;
  /* border: 1px solid red; */
}

@media screen and (min-width: 1025px) {
  .filterParent{
    width: 60%;
  }
  .team-select{
    width: 50%;
  }
}
@media screen and (max-width: 1024px) {
  .filterParent{
    width: 100%;
  }
  .team-select{
    width: 50%;
  }
}
@media screen and (max-width: 712px) {
  .filterParent{
    flex-direction: column;
  }
  .team-select{
    width: 100%;
  }
}
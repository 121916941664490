@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://cdn.jsdelivr.net/npm/animate.css@3.5.2/animate.min.css);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.edge-section {
  padding: 130px 200px 50px;
  background: #fff;
  border-radius: 15px;
}

.qr-box {
  width: 100%;
  height: 230px;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.project-slider {
  padding: 85px 50px;
  background: #F0F0F0;
}

.section-title {
  font-size: 56px;
  line-height: 75px;
  font-weight: 500;
  margin-bottom: 25px;
}

.project-box-wrap {
  width: 100%;
  height: 110px;
  position: relative;
  background: #FFF;
  box-shadow: 5px 5px 45px 0px rgba(0,0,0,0.06);
}

.project-logo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  position: relative;
  z-index: 1;
}

.project-about {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 100px;
  left: 0;
  background: rgb(240,81,57);
  background: linear-gradient(90deg, rgba(240,81,57,0.95) 0%, rgba(240,134,57,0.95) 100%);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all .3s cubic-bezier(0.23, 1, 0.320, 1);
}

.project-about p {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  color: #FFF;
}

@media (max-width: 1440px) {
  .qr-box {
    height: auto;
  }
}
.qr-box img {
  height: 100%;
}
@media (max-width: 1440px) {
  .qr-box img {
    height: auto;
    width: 100%;
  }
}
.qr-box-wrap {
  background: #f05139;
  background: linear-gradient(175deg, #f05139 0%, #f08439 100%);
  padding: 30px;
  border-radius: 15px;
  height: 100%;
}
.qr-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 30px !important;
  color: #fff;
}
.qr-logo-box {
  width: 100%;
  height: 170px;
  border-radius: 0px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1440px) {
  .qr-logo-box {
    height: 65px;
  }
  .qr-title {
    font-size: 16px;
  }
}
.qr-logo-box img {
  width: 50%;
}

.product-box-wrap {
  position: relative;
  z-index: 5;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

@media (max-width: 1680px) {
  .edge-section {
    padding: 130px 130px 50px;
  }
}

@media (max-width: 1440px) {
  .qr-box-wrap {
    padding: 25px;
  }
  .connect-now {
    padding: 10px;
  }
}

@media (max-width: 1280px) {
  .qr-box-wrap {
    padding: 20px;
  }
  .qr-title {
    font-size: 14px;
    margin-bottom: 20px !important;
  }
  .edge-section {
    padding: 110px 110px 50px;
  }
}

@media (max-width: 1024px) {
  .mt-25px {
    margin-top: 25px;
  }

  .mb-25px {
    margin-bottom: 25px;
  }

  .edge-section {
    padding: 130px 100px 50px;
  }
}

@media (max-width: 992px) {
  .edge-section {
    padding: 130px 70px 50px;
  }

  .mt-md-25px {
    margin-top: 25px;
  }
}

@media (max-width: 600px) {
  .mt-sm-20px {
    margin-top: 20px;
  }

  .edge-section {
    padding: 50px;
  }
}
.hidden {
  display: none !important;
}

.modal-dialog {
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
}
.modal-content {
  border: none;
}
.modal-header {
  border-bottom: none;
  padding: 0;
}

.modal .close {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #D2D2D2;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #D2D2D2;
  opacity: 1;
  font-size: 18px;
  transition: all .3s ease;
}
button:focus {
  outline: none;
}
.modal .close:hover {
  color: #000;
  border-color: #000;
}
.modal-head-logo {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #D2D2D2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}
.modal-head-logo img {
  width: 70px;
}

.form-control {
  padding: 25px;
  margin-bottom: 15px;
  width: 100%;
  border: 1px solid #D2D2D2;
  border-radius: 5px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 200;
}
.modal-body {
  padding: 0;
}
.modal button.submit-btn {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  background: #2F61CB;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  color: #fff;
  transition: all .3s ease;
}
.modal button.submit-btn:hover {
  background-color: rgba(47, 97, 203, 0.3);
  color: #2F61CB;
}
/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
 :root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.h1,
h1 {
  font-size: 2.5rem;
}
.h2,
h2 {
  font-size: 2rem;
}
.h3,
h3 {
  font-size: 1.75rem;
}
.h4,
h4 {
  font-size: 1.5rem;
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.small,
small {
  font-size: 80%;
  font-weight: 400;
}
.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.333333%;
}
.offset-2 {
  margin-left: 16.666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333%;
}
.offset-5 {
  margin-left: 41.666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333%;
}
.offset-8 {
  margin-left: 66.666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333333%;
}
.offset-11 {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table-sm td,
.table-sm th {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}
.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #b8daff;
}
.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #7abaff;
}
.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}
.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #d6d8db;
}
.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #b3b7bb;
}
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.table-success,
.table-success > td,
.table-success > th {
  background-color: #c3e6cb;
}
.table-success tbody + tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #8fd19e;
}
.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}
.table-info,
.table-info > td,
.table-info > th {
  background-color: #bee5eb;
}
.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #86cfda;
}
.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}
.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #ffeeba;
}
.table-warning tbody + tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #ffdf7e;
}
.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}
.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #f5c6cb;
}
.table-danger tbody + tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #ed969e;
}
.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}
.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe;
}
.table-light tbody + tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #fbfcfc;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c6c8ca;
}
.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #95999c;
}
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
input[type="date"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control,
input[type="time"].form-control {
  -webkit-appearance: none;
  appearance: none;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control[multiple],
select.form-control[size] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}
.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}
.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #28a745;
}
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}
.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}
.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #dc3545;
}
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning.focus,
.btn-warning:focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light.focus,
.btn-light:focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark.focus,
.btn-dark:focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
}
.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group-vertical > .btn:hover,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .form-control {
  margin-left: -1px;
}
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-append,
.input-group-prepend {
  display: flex;
}
.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}
.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  z-index: 3;
}
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
  margin-top: 0;
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px);
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label,
.custom-control-input[disabled] ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before,
.custom-control-input[disabled] ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label,
.custom-file-input[disabled] ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}
.nav-fill .nav-item,
.nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom,
.card-img-top {
  flex-shrink: 0;
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item {
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:focus,
a.badge:hover {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary.focus,
a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary.focus,
a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success.focus,
a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:focus,
a.badge-info:hover {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info.focus,
a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:focus,
a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger.focus,
a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:focus,
a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light.focus,
a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark.focus,
a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
.media {
  display: flex;
  align-items: flex-start;
}
.media-body {
  flex: 1 1;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}
.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}
.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}
.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}
a.close.disabled {
  pointer-events: none;
}
.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-body {
  padding: 0.75rem;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
  margin-bottom: 0.5rem;
}
.bs-popover-auto[x-placement^="top"] > .arrow,
.bs-popover-top > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-auto[x-placement^="top"] > .arrow::before,
.bs-popover-top > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="top"] > .arrow::after,
.bs-popover-top > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
  margin-left: 0.5rem;
}
.bs-popover-auto[x-placement^="right"] > .arrow,
.bs-popover-right > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="right"] > .arrow::before,
.bs-popover-right > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="right"] > .arrow::after,
.bs-popover-right > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
  margin-top: 0.5rem;
}
.bs-popover-auto[x-placement^="bottom"] > .arrow,
.bs-popover-bottom > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-auto[x-placement^="bottom"] > .arrow::before,
.bs-popover-bottom > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
  margin-right: 0.5rem;
}
.bs-popover-auto[x-placement^="left"] > .arrow,
.bs-popover-left > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="left"] > .arrow::before,
.bs-popover-left > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="left"] > .arrow::after,
.bs-popover-left > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  transform: translateX(100%);
}
.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #007bff !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important;
}
.bg-info {
  background-color: #17a2b8 !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important;
}
.bg-warning {
  background-color: #ffc107 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important;
}
.bg-danger {
  background-color: #dc3545 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #007bff !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #28a745 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #dc3545 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #007bff !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #0056b3 !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: #494f54 !important;
}
.text-success {
  color: #28a745 !important;
}
a.text-success:focus,
a.text-success:hover {
  color: #19692c !important;
}
.text-info {
  color: #17a2b8 !important;
}
a.text-info:focus,
a.text-info:hover {
  color: #0f6674 !important;
}
.text-warning {
  color: #ffc107 !important;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #ba8b00 !important;
}
.text-danger {
  color: #dc3545 !important;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #a71d2a !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:focus,
a.text-light:hover {
  color: #cbd3da !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:focus,
a.text-dark:hover {
  color: #121416 !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  blockquote,
  pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  img,
  tr {
    page-break-inside: avoid;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*# sourceMappingURL=bootstrap.min.css.map */

@font-face {
  font-family: Mulish;
  src: url(/static/media/Mulish-Regular.36c43991.woff2) format("woff2"),
    url(/static/media/Mulish-Regular.dab57e0e.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display Bold";
  src: url(/static/media/SFProDisplayBold.ae9c7004.woff2) format("woff2"),
    url(/static/media/SFProDisplayBold.a76632a4.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}
@font-face {
  font-family: "SF Pro Display Light";
  src: url(/static/media/SFProDisplayLight.36bd7850.woff2) format("woff2"),
    url(/static/media/SFProDisplayLight.cd9f4f5f.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}
@font-face {
  font-family: "SF Pro Display Regular";
  src: url(/static/media/SFProDisplayRegular.3190980e.woff2) format("woff2"),
    url(/static/media/SFProDisplayRegular.43ea243f.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}
@font-face {
  font-family: "SF Pro Display Semibold";
  src: url(/static/media/SFProDisplaySemibold.165dd3f9.woff2) format("woff2"),
    url(/static/media/SFProDisplaySemibold.3e0db53c.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}
@font-face {
  font-family: "Muli Regular";
  src: url(/static/media/MuliRegular.f1c109a0.woff2) format("woff2"),
    url(/static/media/MuliRegular.835b7ad7.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-03BC;
}
@font-face {
  font-family: mulilight;
  src: url(/static/media/muli-light.d0972504.woff2) format("woff2"),
    url(/static/media/muli-light.2bc5afec.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: muliregular;
  src: url(/static/media/muli.8309685d.woff2) format("woff2"),
    url(/static/media/muli.4a2f065d.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: mulibold;
  src: url(/static/media/muli-bold.b90e7796.woff2) format("woff2"),
    url(/static/media/muli-bold.8126bba9.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: mulisemibold;
  src: url(/static/media/muli-semibold.7974e556.woff2) format("woff2"),
    url(/static/media/muli-semibold.6e4e7395.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: barlow_condensedblack;
  src: url(/static/media/barlowcondensed-black.d2a8f7db.woff2) format("woff2"),
    url(/static/media/barlowcondensed-black.eec3619e.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: barlow_condensedbold;
  src: url(/static/media/barlowcondensed-bold.67263b5b.woff2) format("woff2"),
    url(/static/media/barlowcondensed-bold.0a467383.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: barlow_condensedlight;
  src: url(/static/media/barlowcondensed-light.b8735571.woff2) format("woff2"),
    url(/static/media/barlowcondensed-light.d44d5ca9.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: barlow_condensedregular;
  src: url(/static/media/barlowcondensed-regular.2754d433.woff2) format("woff2"),
    url(/static/media/barlowcondensed-regular.9e9f00e0.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: barlow_condensedsemibold;
  src: url(/static/media/barlowcondensed-semibold.2fbf9cdb.woff2) format("woff2"),
    url(/static/media/barlowcondensed-semibold.31e85681.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: barlow_condensedSBdIt;
  src: url(/static/media/barlowcondensed-semibolditalic.159da24c.woff2)
      format("woff2"),
    url(/static/media/barlowcondensed-semibolditalic.da70fc86.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: barlow_condensedthin;
  src: url(/static/media/barlowcondensed-thin.a7e11cd1.woff2) format("woff2"),
    url(/static/media/barlowcondensed-thin.3d8cac35.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: barlow_condensedthin_italic;
  src: url(/static/media/barlowcondensed-thinitalic.c2a0083d.woff2) format("woff2"),
    url(/static/media/barlowcondensed-thinitalic.6e89fecf.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Barlow;
  src: url(/static/media/BarlowCondensed-Medium.b86496f1.ttf);
}

:root {
  --heading-color: "#37517e";
  --primary: "#f05139";
}
body::-webkit-scrollbar {
  -webkit-appearance: none !important;
  width: 8px !important;
}
body::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
}
.o_web .address-input {
  width: 100%;
  padding: 0.7rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 1rem;
}
.o_web .nav {
  justify-content: center !important;
}
.o_web button {
  box-shadow: none !important;
  outline: 0 !important;
}
.o_web a {
  color: #fc3044;
}
.o_web a:hover {
  color: #dc1e31;
}
.o_web a {
  text-decoration: none !important;
}
.o_web p {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 12px;
  color: #8c8c8c;
  letter-spacing: 0.5px;
  line-height: 20px;
  padding-top: 1rem;
}
.o_web .header {
  background: #fff;
  height: 62px;
  box-shadow: 1px 1px 8px rgba(255, 255, 255, 0.01);
  padding: 22px 0;
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 99;
}
.o_web .header nav {
  margin-top: -0.5rem;
}
.o_web .swap-input {
  margin-bottom: 20px;
}
.o_web .arrows {
  display: flex;
  align-items: center;
  justify-content: center;
}
.o_web .swap-card {
  box-shadow: rgba(0, 0, 0, 0.01) 0 0 1px, rgba(0, 0, 0, 0.04) 0 4px 8px,
    rgba(0, 0, 0, 0.04) 0 16px 24px, rgba(0, 0, 0, 0.01) 0 24px 32px !important;
  padding: 1rem;
  border-radius: 12px !important;
}
.o_web .sharpened {
  border-radius: 0 0 12px 12px !important;
}
.o_web .asset-dropdown {
  width: 21.75rem;
  height: 400px;
  background-color: #fff;
  box-shadow: 6px 9px 20px #00000029 !important;
  transform: translateY(60px);
  border-radius: 12px;
  overflow: hidden;
  z-index: 1;
  display: none;
  position: absolute;
}
.o_web .buy-dropdown {
  width: 15rem;
  height: 200px;
  background-color: #fff;
  box-shadow: 6px 9px 20px #00000029 !important;
  transform: translateY(60px);
  border-radius: 12px;
  overflow: hidden;
  z-index: 1;
  display: none;
  position: absolute;
}

.o_web .pool-dropdown {
  width: 23rem;
  height: 200px;
  background-color: #fff;
  box-shadow: 6px 9px 20px #00000029 !important;
  transform: translateY(60px);
  border-radius: 12px;
  overflow: hidden;
  z-index: 1;
  display: none;
  position: absolute;
}

@media screen and (max-width: 576px) {
  .o_web .pool-dropdown {
    width: 15rem;
  }
}

@media (max-width: 321px) and (orientation: portrait) {
  span.wallet-address {
    font-size: 0.8em !important;
  }
}

@media (min-width: 319px) and (max-width: 414px) and (orientation: portrait) {
  span.wallet-address {
    font-size: 0.9em !important;
  }

  .ambassador-btn {
    float: right;
  }
}
.o_web .individual-token {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0.5rem;
}
.o_web .input-with-dropdown {
  display: flex;
}
.o_web .input-with-dropdown input {
  padding: 1rem;
  border: none;
  width: 100%;
  height: 3.5rem;
  border-radius: 12px;
  /* font-size: 18px; */
  font-weight: 300;
  box-shadow: 6px 9px 20px #00000029 !important;
}
.o_web .form-label {
  font-size: 14px;
  font-weight: 500;
  color: #989898;
}
.o_web .dropdown-btn {
  background-color: #fff;
  border: none;
  box-shadow: 6px 9px 20px #00000029 !important;
  padding: 0.8rem 0.75rem;
  width: 7rem;
  height: 3.5rem;
  margin-right: 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
}

.o_web .dropdown-btn:focus {
  border: 1px solid #ef4b23;
}

.dropdown-item {
  margin: 0;
}
.dropdown-item:hover {
  color: #989898;
}
.o_web .individual-token:hover,
.individual-pool:hover {
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
  cursor: pointer;
}
.o_web .asset-dropdown .individual-token:nth-child(even) {
  background-color: #f8f8f8 !important;
}
.o_web .asset-dropdown .individual-token:nth-child(even):hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.o_web .individual-token p {
  font-size: 15px;
  font-weight: 700;
}

p.pool-p {
  font-family: "SF Pro Display Bold";
  padding-left: 15px;
  padding-right: 15px;
  line-height: 1em;
  margin: 0;
  font-size: 0.9rem !important;
}

span.pool-span {
  font-family: "SF Pro Display Regular" !important;
  padding-left: 15px;
  padding-right: 15px;
  color: #989898;
  font-size: 0.9em !important;
}
.o_web .individual-token img {
  margin-right: 0.5rem;
  width: 20px;
  height: 20px;
}
.o_web .MuiOutlinedInput-root {
  border-radius: none !important;
}
.o_web .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.o_web .header {
  box-shadow: 6px 9px 20px 5px #00000029 !important;
}
.o_web .header .navbar {
  padding: 0;
  background-color: #fff;
  width: 100%;
}
.o_web .header .navbar .nav-wrapper {
  width: 100%;
}
.o_web .header .navbar .navbar-brand {
  padding: 0;
  margin-right: 54px;
}

.btn-outlinegray {
  border: 2px solid #5a5a5a;
  font-size: 12px;
  line-height: 19px;
  font-family: mulibold;
  color: #5a5a5a !important;
  border-radius: 33px;
  -webkit-border-radius: 33px;
  -moz-border-radius: 33px;
  padding: 6px 30px;
  display: inline-block;
}

.btn-outlinegray:hover {
  background: #5a5a5a;
  color: #fff !important;
}

@media screen and (min-width: 1199px) {
  .o_web .header .navbar .ascollapse {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
@media screen and (max-width: 1199px) {
  .o_web .header .navbar .ascollapse {
    padding: 30px;
    text-align: right;
  }
}
@media screen and (max-width: 1199px) {
  .o_web .header .navbar .frshdw {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  }
}
.o_web .header .asnavbar.navbar-expand-xl .navbar-nav .nav-item {
  margin-right: 45px;
}
@media screen and (max-width: 1199px) {
  .o_web .header .asnavbar.navbar-expand-xl .navbar-nav .nav-item {
    margin-right: 0;
  }
}
.o_web .header .asnavbar.navbar-expand-xl .navbar-nav .nav-item .nav-link {
  padding: 7px 0;
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 15.5px;
  letter-spacing: 0.2px;
  color: #989898;
  text-transform: capitalize;
}
.o_web
  .header
  .asnavbar.navbar-expand-xl
  .navbar-nav
  .nav-item
  .nav-link:hover {
  color: #0f1740;
}
.o_web
  .header
  .asnavbar.navbar-expand-xl
  .navbar-nav
  .nav-item.active
  .nav-link {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  color: #f08639;
}
.o_web .right_nave {
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 1200px) {
  .o_web .right_nave {
    margin: 10px 0 0 0;
  }
}
.o_web .right_nave li {
  display: inline-block;
  margin-left: 55px;
  vertical-align: top;
}
@media screen and (max-width: 480px) {
  .o_web .right_nave li {
    margin-left: 18px;
  }
}
.o_web .right_nave li a {
  display: block;
  height: 27px;
  width: 27px;
  background: url(/static/media/sprite_img.01425b82.svg) no-repeat 0 0;
  background-size: 500px;
}
.o_web .rn_ic_1 span {
  display: none;
  position: relative;
  font-size: 12px;
  top: 1.8rem;
  left: -6px;
  color: #989898;
}
.o_web .rn_ic_2 span {
  display: none;
  position: relative;
  font-size: 12px;
  top: 1.55rem;
  left: 2px;
  color: #989898;
}
.o_web .rn_ic_3 span {
  display: none;
  position: relative;
  font-size: 12px;
  top: 1.5rem;
  left: -2.5px;
  color: #989898;
}
.o_web .rn_ic_4 span {
  display: none;
  position: relative;
  font-size: 12px;
  top: 1.5rem;
  left: -7px;
  color: #989898;
}
.o_web .rn_ic_1:hover span,
.o_web .rn_ic_2:hover span,
.o_web .rn_ic_3:hover span,
.o_web .rn_ic_4:hover span {
  color: #f08639;
}
.o_web .app-bar {
  background: transparent linear-gradient(180deg, #f08639 0, #f07039 100%) 0 0
    no-repeat padding-box;
  height: 4rem;
  padding: 0 2rem;
}
.o_web .right_nave li a.rn_ic_1 {
  background-position: -6px -64px;
  line-height: 0.9rem;
}
.o_web .right_nave li a.rn_ic_1.active,
.o_web .right_nave li a.rn_ic_1:hover {
  background-position: -6px -104px;
}
.o_web .right_nave li a.rn_ic_2 {
  background-position: -54px -64px;
}
.o_web .right_nave li a.rn_ic_2.active,
.o_web .right_nave li a.rn_ic_2:hover {
  background-position: -54px -104px;
}
.o_web .right_nave li a.rn_ic_3 {
  background-position: -110px -64px;
}
.o_web .right_nave li a.rn_ic_3.active,
.o_web .right_nave li a.rn_ic_3:hover {
  background-position: -110px -104px;
}
.o_web .right_nave li a.rn_ic_4 {
  background-position: -166px -64px;
}
.o_web .right_nave li a.rn_ic_4.active,
.o_web .right_nave li a.rn_ic_4:hover {
  background-position: -166px -104px;
}
.o_web .right_nave li a.rn_ic_5 {
  background-position: -237px -64px;
}
.o_web .right_nave li a.rn_ic_5.active,
.o_web .right_nave li a.rn_ic_5:hover {
  background-position: -237px -104px;
}
.o_web .right_nave li:first-child {
  margin-left: 0;
}
.o_web .ftr_btm {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  background: transparent linear-gradient(180deg, #f08639 0, #f07039 100%) 0 0
    no-repeat padding-box;
  padding: 15px 0;
  z-index: 19;
}
.o_web .ftr_btm .d-flex {
  max-width: 670px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 480px) {
  .o_web .ftr_btm .d-flex {
    display: block !important;
  }
}
.o_web .ftr_btm .d-flex p {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.3px;
  color: #fff;
  margin-top: 0 !important;
}
@media screen and (max-width: 480px) {
  .o_web .ftr_btm .d-flex p {
    margin: 0 0 15px 0;
  }
}
.o_web .ftr_btm .d-flex .btn-accept {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #fff;
  border: 1px solid;
  border-radius: 35px;
  border-color: #fff;
  width: 155px;
  height: 35px;
}
.o_web .ftr_btm .d-flex .btn-accept:hover {
  border-color: #ffac81;
}
.o_web .content {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 50px;
}
@media screen and (max-width: 767px) {
  .o_web .content {
    padding-top: 30px;
  }
}
@media screen and (max-width: 480px) {
  .o_web .content {
    padding-bottom: 50px;
  }
}
.o_web .max-480 {
  max-width: 480px;
  margin: 0 auto;
}
.o_web ::placeholder {
  color: #989898 !important;
}
.o_web .def_ttl {
  font-family: "Muli Regular", Mulish, sans-serif;
  font-size: 14px;
  letter-spacing: 4.2px;
  color: var(--heading-color);
  text-transform: uppercase;
  margin-bottom: 50px;
  font-weight: 600;
  color: #707070;
}
.o_web .mx-400 {
  max-width: 380px;
  margin: 0 auto;
}
.o_web .def_tab_v1 {
  border-bottom: none;
  margin-bottom: 10px;
}
.o_web .def_tab_v1 .nav-item:first-child {
  margin-right: 120px;
}
.o_web .def_tab_v1 .nav-item .nav-link {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.36px;
  color: #989898;
  border: none;
  border-radius: 0;
  padding: 0 5px;
  background: 0 0;
}
.o_web .def_tab_v1 .nav-item .nav-link.active {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  color: #0f1740;
  font-size: 15px;
}
.focussable-link:focus,
.o_web .def_tab_v1 .nav-item .nav-link:focus {
  color: #ef4b23 !important;
}
.focussable-button:focus {
  text-decoration: underline;
}
.o_web .def_wt_bx {
  margin-top: 10px;
  margin-bottom: 5px;
  background: #fff 0 0 no-repeat padding-box;
  border-radius: 12px;
  box-shadow: 6px 20px 20px 5px #00000029 !important;
  padding: 25px 12px 30px 12px;
  position: relative;
}
.o_web .react-tel-input .form-control {
  width: 100% !important;
  height: 3.5rem !important;
  background-color: #fff !important;
}
.o_web .form-control {
  border: 1px solid #f2f5f7 !important;
}
.o_web .def_form .form-group .form-control {
  background-color: #fff !important;
  border: 1px solid #f8f8f8;
  border-radius: 12px;
  height: 3.5rem;
  color: #0f1740;
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 15px;
  position: relative;
  -webkit-appearance: none !important;
  box-shadow: 6px 9px 20px #00000029 !important;
}
.o_web .form-control:disabled {
  background-color: #fff !important;
}
.o_web .jJcagm {
  height: 1.5rem !important;
}
.o_web .def_form .form-group .checkbox {
  padding-left: 20px;
}
.o_web .def_form .form-group .checkbox input[type="checkbox"] {
  opacity: 0;
  z-index: 1;
  position: absolute;
  margin-left: -20px;
}
.o_web .def_form .form-group .checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 10px;
  color: #989898;
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0.3px;
  cursor: pointer;
  margin-bottom: 0;
  padding-top: 3px;
}
.o_web .def_form .form-group .checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  top: 2px;
  margin-left: -20px;
  background: #f4f4f4;
  box-shadow: 3px 3px 7px #00000029;
  border: 0.25px solid #cacaca;
  border-radius: 4px;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.o_web .def_form .form-group .checkbox label:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 1px;
  top: 3px;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 9px;
  color: #555;
}
.o_web
  .def_form
  .form-group
  .checkbox
  input[type="checkbox"]:checked
  + label:before {
  border: 0.25px solid #cacaca;
}
.o_web
  .def_form
  .form-group
  .checkbox
  input[type="checkbox"]:checked
  + label:after {
  content: "" !important;
  height: 17px;
  width: 17px;
  background: url(/static/media/sprite_img.01425b82.svg) no-repeat -306px -70px;
  background-size: 500px;
}
.o_web .def_form .form-group .control-label {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #989898;
  padding-left: 5px;
}
.o_web .control-label {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #989898;
  padding-left: 5px;
}
.o_web .def_form .frgt_lnk {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.36px;
  color: #f08639;
  display: inline-block;
  text-decoration: underline;
  margin-bottom: 40px;
}
.o_web .text-right {
  text-align: right;
}
.o_web .def_form .frgt_lnk:hover {
  color: #ffac81;
}
.o_web .def_sb_btn {
  position: relative;
  background: transparent linear-gradient(180deg, #f05139 0, #f08639 100%) 0 0
    no-repeat padding-box;
  border-radius: 17px;
  border: 4px solid #fff;
  display: block;
  width: 100%;
  margin: 0 auto 30px auto;
  height: 3rem;
  text-align: left;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 15px;
  text-transform: capitalize;
  line-height: 22px;
  letter-spacing: 0.42px;
  color: #fff;
  padding: 5px 13px;
  box-shadow: rgba(0, 0, 0, 0.01) 0 0 1px, rgba(0, 0, 0, 0.04) 0 4px 8px,
    rgba(0, 0, 0, 0.04) 0 16px 24px, rgba(0, 0, 0, 0.01) 0 24px 32px !important;
}

@media (max-width: 361px) and (orientation: portrait) {
  .o_web .def_sb_btn {
    font-size: 11px;
  }
}
.focussable:focus {
  box-shadow: 0 4px 10px rgba(116, 116, 116, 0.61) !important;
}
.focussable-image:focus {
  border: 1px solid #f05139;
  border-radius: 5px;
}
.o_web a {
  cursor: pointer;
}
.o_web .def_sb_btn_loading {
  position: relative;
  background: transparent linear-gradient(180deg, #f05139 0, #f08639 35%) 0 0
    no-repeat padding-box;
  border-radius: 18px;
  border: 4px solid #fff;
  display: block;
  width: 96%;
  margin: 0 auto 30px auto;
  box-shadow: 0 6px 9px #00000017 !important;
  height: 3rem;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 22px;
  letter-spacing: 0.42px;
  color: #fff;
  padding: 5px 13px;
  box-shadow: rgba(0, 0, 0, 0.01) 0 0 1px, rgba(0, 0, 0, 0.04) 0 4px 8px,
    rgba(0, 0, 0, 0.04) 0 16px 24px, rgba(0, 0, 0, 0.01) 0 24px 32px !important;
}
.o_web .def_sb_btn::before {
  content: "";
  position: absolute;
  right: 5px;
  top: 15px;
  height: 10px;
  width: 30px;
  background: url(/static/media/sprite_img.01425b82.svg) no-repeat -328px -23px;
  background-size: 500px;
  transition: all 0.2s ease-out;
}
.o_web .def_sb_btn:hover::before {
  right: 2px;
}
.o_web .def_sb_btnv2::before {
  right: 13px;
  top: 15px;
  height: 20px;
  width: 16px;
  background: url(/static/media/sprite_img.01425b82.svg) no-repeat -330px -109px;
  background-size: 500px;
}
.o_web .footer {
  position: fixed;
  left: 0;
  bottom: 0 !important;
  width: 100%;
  padding: 10px;
  text-align: center;
  background-color: #f4f4f4;
  z-index: 9;
}
@media screen and (max-width: 480px) {
  .o_web .footer {
    bottom: 122px;
  }
}
.o_web .footer ul {
  margin: 0;
  padding: 0;
}
.o_web .footer ul li {
  display: inline-block;
  list-style: none;
  margin: 0 15px;
}
.o_web .footer ul li a {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 10px;
  color: #707070;
  line-height: 20px;
  letter-spacing: 0.84px;
  text-transform: uppercase;
}
.o_web .footer ul li a:focus,
.o_web .footer ul li a:hover {
  color: #ffac81;
}
.o_web .fgt_p_v1 {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 15px;
  color: #989898;
  line-height: 25px;
  letter-spacing: 0.36px;
  padding: 0 17px;
}
.o_web .fgt_p_v2 {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 10px;
  color: #989898;
  line-height: 18px;
  letter-spacing: 0.36px;
  padding: 0 17px;
}
.o_web .fgt_p_v3 {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 12px;
  color: #989898;
  line-height: 28px;
  letter-spacing: 0.36px;
  margin-top: 15px;
  margin-bottom: 40px;
  display: flex;
}
.o_web .max-745 {
  max-width: 745px;
  margin: 0 auto;
  padding-bottom: 20px;
}
.o_web .left_d {
  width: 310px;
}
@media screen and (max-width: 767px) {
  .o_web .left_d {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
}
.o_web .left_d .mx-400 {
  margin-left: 0;
  max-width: 100%;
}
.o_web .right_d {
  width: 410px;
}
@media screen and (max-width: 767px) {
  .o_web .right_d {
    width: 100%;
  }
}
.o_web .b_ttl {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 15px;
  color: #989898;
  line-height: 28px;
  letter-spacing: 0.42px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.o_web .def_tab_v2 {
  position: relative;
  border: none;
  margin-bottom: -9px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between !important;
}
.o_web .def_tab_v2 li {
  margin-right: 2px;
}
.o_web .def_tab_v2 li:last-child {
  margin-right: 0;
}
.o_web .def_tab_v2 li a {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 12px;
  color: #989898;
  line-height: 16px;
  letter-spacing: 0.9px;
  background-color: #ededed;
  border-radius: 2px 2px 0 0 !important;
  border: none !important;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px 0; */
  margin: 0 auto;
  width: 110px;
}
.o_web .purchase-nav li a {
  width: 140px;
}
.o_web .def_tab_v2 li:nth-child(1) {
  -webkit-clip-path: polygon(0 0, 85% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 85% 0, 100% 100%, 0 100%);
}
.o_web .def_tab_v2 li:nth-child(1) a {
  padding-right: 2em;
}
.o_web .def_tab_v2 li:nth-child(2) {
  position: relative;
  left: -11.5px;
  -webkit-clip-path: polygon(0 0, 85% 0, 100% 100%, 15% 100%);
  clip-path: polygon(0 0, 85% 0, 100% 100%, 15% 100%);
}
.o_web .def_tab_v2 li:nth-child(3) a {
  padding-left: 2em;
}
.o_web .def_tab_v2 li:nth-child(3) {
  position: relative;
  left: -24px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
}
.o_web .def_tab_v2 li a.active {
  font-family: "SF Pro Display Bold";
  font-size: 12px;
  color: #989898 !important;
  background-color: #fff !important;
}
@media screen and (max-width: 575px) {
  .o_web .def_tab_v2 li a {
    padding: 7px 13px;
  }
}
.o_web .tgl_btn {
  position: relative;
  background: #fff;
  box-shadow: 6px 9px 20px #00000029 !important;
  border-radius: 12px;
  text-align: center;
  height: 3.5rem;
  min-width: 58px;
  border: none !important;
  outline: 0 !important;
  margin-right: 8px;
}
.o_web .tgl_btn img {
  height: 32px;
  width: 32px;
}
.o_web .arrow-up {
  transform: rotate(180deg) !important;
}
.o_web .swap-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.o_web .amount {
  font-size: 32px;
  color: #010002;
  font-weight: 600;
  margin-top: 0 !important;
}
.o_web .amount-sm {
  font-size: 24px;
  color: #010002;
  font-weight: 600;
  margin-top: 0 !important;
}
.o_web .loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.o_web .loader p {
  padding-top: 0;
}
.o_web .address {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.o_web .address-sm {
  font-size: 18px;
  color: #010002;
  font-weight: 600;
  margin-top: 0 !important;
  word-break: break-all;
}
.o_web .label {
  margin-bottom: 0.1rem;
}
.o_web .time {
  font-size: 20px;
  color: #010002;
  padding-top: 0.5rem !important;
}
.o_web .swap-details {
  font-size: 18px;
  color: #a4a3aa;
}
.o_web .swap-btn {
  position: relative;
  background: #f05139;
  background: linear-gradient(to bottom, #f05139 0, #f08639 47%, #f08639 100%);
  border-radius: 12px;
  border: 3px solid #fff;
  display: block;
  width: 100%;
  margin-bottom: 30px;
  height: 3.5rem;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 15px;
  text-transform: capitalize;
  line-height: 22px;
  letter-spacing: 0.42px;
  color: #fff;
  padding: 5px 13px;
}
.o_web .error {
  color: var(--red);
  font-size: 15px;
  padding-top: 0;
}

.o_web .back-btn {
  text-align: center;
  margin-top: 0;
  border: none;
  background-color: #fff;
  border-radius: 12px;
  border: 3px solid #f08639;
  display: block;
  width: 30%;
  margin-bottom: 30px;
  height: 2.5rem;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 15px;
  text-transform: capitalize;
  line-height: 22px;
  letter-spacing: 0.42px;
  color: #f08639;
  padding: 5px 13px;
}

.o_web .back-btn:hover {
  text-align: center;
  margin-top: 0;
  border: none;
  background-color: #f08639;
  background: linear-gradient(to bottom, #f05139 0, #f08639 47%, #f08639 100%);
  border-radius: 12px;
  border: 3px solid #f08639;
  display: block;
  width: 30%;
  margin-bottom: 30px;
  height: 2.5rem;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 15px;
  text-transform: capitalize;
  line-height: 22px;
  letter-spacing: 0.42px;
  color: white;
  padding: 5px 13px;
}

.o_web .back-btn-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.o_web input:focus {
  outline: 0 !important;
}
.o_web .swap-info p {
  font-size: 15px;
}
.o_web .wt_arrow {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.o_web .wt_arrow::after {
  content: "";
  position: relative;
  display: inline-block;
  height: 9px;
  width: 14px;
  background: url(/static/media/sprite_img.01425b82.svg) no-repeat -386px -23px;
  background-size: 500px;
}
.o_web .max_prnt {
  position: relative;
  width: 100%;
}
.o_web .max_prnt .mx_btn {
  font-family: "SF Pro Display SemiBold", Mulish, sans-serif;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #32cc86;
  position: absolute;
  left: 13px;
  top: 18px;
  border: none !important;
  z-index: 1;
  border: 1px solid #6ac98c !important;
  border-radius: 5px;
  background: 0 0;
}
.o_web .max_prnt .form-control {
  width: 100% !important;
  text-align: right;
  padding-left: 48px;
  font-size: 26px !important;
  line-height: 36px !important;
  letter-spacing: -0.24px !important;
  box-shadow: 6px 9px 20px #00000029 !important;
}
.o_web ::placeholder {
  font-size: 15px;
}
.o_web .btminftxt {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.24px;
  color: #989898;
}
.o_web .btminftxt span {
  color: #32cc86;
}
.o_web .p_rltv {
  position: relative;
}
.o_web .select_bx {
  position: absolute;
  height: 300px;
  overflow: scroll;
  left: 3px;
  right: 3px;
  top: 0;
  background: #f8f8f8 0 0;
  box-shadow: 6px 9px 20px #00000029;
  border-radius: 12px;
  display: none;
  z-index: 99;
}
.o_web .select_bx .inner_pdng {
  padding: 10px 10px 10px 10px;
}
.o_web .select_bx .inner_pdng h3 {
  font-family: "SF Pro Display Light", Mulish, sans-serif;
  font-size: 17px;
  line-height: 34px;
  letter-spacing: 0.68px;
  color: #0f1740;
  font-weight: 300;
}
.o_web .select_bx .inner_pdng h3 img {
  margin-top: -3px;
  height: 20px;
  width: 20px;
}
.o_web .select_bx .inner_pdng h3 span {
  position: relative;
  display: inline-block;
  height: 9px;
  width: 16px;
  background: url(/static/media/sprite_img.01425b82.svg) no-repeat -453px -23px;
  background-size: 500px;
  float: right;
  margin-top: 13px;
  cursor: pointer;
}
.o_web .select_bx .btcn_lst {
  margin: 0;
  padding: 0;
}
.o_web .select_bx .btcn_lst li {
  list-style: none;
  display: block;
}
.o_web .select_bx .btcn_lst li a {
  position: relative;
  padding: 15px 20px 13px 43px;
  display: block;
  cursor: pointer;
}
.o_web .select_bx .btcn_lst li a .slct_img {
  position: absolute;
  left: 20px;
  top: 15px;
  width: 15px;
}
.o_web .select_bx .btcn_lst li a h5 {
  font-family: "SF Pro Display Bold", Mulish, sans-serif;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #010002;
  margin-bottom: 3px;
}
.o_web .select_bx .btcn_lst li a h6 {
  font-family: "SF Pro Display Bold", Mulish, sans-serif;
  font-size: 8px;
  line-height: 13px;
  letter-spacing: 0.24px;
  color: #010002;
  margin-bottom: 3px;
}
.o_web .select_bx .btcn_lst li a p {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 7px;
  line-height: 10px;
  letter-spacing: 0.18px;
  color: #929292;
  margin-bottom: 0;
}
.o_web .select_bx .btcn_lst li a p span {
  color: #0dc471 !important;
}
.o_web .select_bx .btcn_lst li a p span.clr_rd {
  color: #fc3044 !important;
}
.o_web .select_bx .btcn_lst li a:hover {
  background: #e4e4e4 !important;
}
.o_web .select_bx .btcn_lst li:nth-child(odd) a {
  background: #fff;
}
.o_web .select_bx .cntr_lst li a {
  padding: 10px 20px 8px 43px;
}
.o_web .select_bx .cntr_lst li a img {
  top: 10px !important;
}
.o_web .rt_info h4 {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.16px;
  color: #0f1740;
  margin-bottom: 0;
}
.o_web .rt_info h4 span {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 9px;
  line-height: 17px;
  letter-spacing: 0.24px;
  color: #3c3c3b;
}
.o_web .gdnt_bx {
  background: transparent linear-gradient(180deg, #f08639 0, #f07039 100%) 0 0
    no-repeat padding-box;
  padding: 15px 15px;
  border-radius: 19px 19px 0 0;
  width: 377px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .o_web .gdnt_bx {
    width: 95%;
  }
}
.o_web .gdnt_bx .info_t_v1 h6 {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.18px;
  color: #fff;
  margin-bottom: 5px;
  margin-top: 3px;
}
.o_web .gdnt_bx .info_t_v1 h5 {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 13px;
  line-height: 28px;
  letter-spacing: 0.42px;
  color: #fff;
  margin-bottom: 0;
}
.o_web .title-details {
  font-size: 10px !important;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif !important;
  color: #111a44;
}
.o_web .gdnt_bx .info_t_v1 h4 {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.36px;
  color: #111a44;
  margin-top: 3px;
  margin-bottom: 15px;
}
.o_web .info_t_v2 .d-flex {
  align-items: center;
  justify-content: flex-end;
  align-content: flex-end;
}
@media screen and (max-width: 767px) {
  .o_web .info_t_v2 .d-flex {
    margin-bottom: 8px;
  }
}
.o_web .info_t_v2 .d-flex a {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.18px;
  color: #fff;
}
.o_web .info_t_v2 .d-flex a.kvc_a {
  position: relative;
  padding-right: 20px;
  margin-top: 2px;
}
.o_web .info_t_v2 .d-flex a.kvc_a::after {
  content: "";
  position: absolute;
  right: -1px;
  top: -4px;
  width: 17px;
  height: 18px;
  background: url(/static/media/sprite_img.01425b82.svg) no-repeat -277px -17px;
  background-size: 500px;
}
.o_web .snackbar .MuiSnackbarContent-root {
  background-color: #f16b39;
  color: #fff;
  font-family: "Muli Regular";
  font-size: 15px;
  text-align: center;
  justify-content: center;
}
.o_web .ant-tooltip-arrow {
  display: none !important;
}
.o_web .info_t_v3 h6 {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 9px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #fff;
  margin-bottom: 3px;
}
.o_web .info_t_v3 h6 span {
  cursor: pointer;
  display: inline-block;
  font-size: 6px;
  line-height: 8px;
  letter-spacing: 0.18px;
  color: #fff;
  border: 1px solid #ffffff70;
  border-radius: 2px;
  padding: 1px 2px;
  vertical-align: top;
  margin-top: 2px;
  margin-left: 3px;
}
.o_web .info_t_v3 p {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  position: absolute;
  font-size: 10px;
  top: -18px;
  line-height: 17px;
  letter-spacing: 0.18px;
  color: #fff;
  margin-bottom: 0;
  text-transform: uppercase;
}
.o_web .info_t_v4 .float-right {
  margin: -10px 0 0 0;
}
@media screen and (max-width: 767px) {
  .o_web .info_t_v4 .float-right {
    margin: 0;
    float: none !important;
  }
}
.o_web .info_t_v4 label {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 8px;
  line-height: 17px;
  letter-spacing: 0.24px;
  color: #fff;
  margin-bottom: 0;
}
.o_web .info_t_v4 h5 {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: #fff;
  margin-bottom: 0;
}
.o_web .info_t_v4 h5 span {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  position: relative;
  font-size: 8px;
  line-height: 17px;
  letter-spacing: 0.24px;
  color: #fff;
  vertical-align: flex-end;
}
.o_web .hstr_tbl_bx {
  background: #fcfcfc;
  border-radius: 12px;
  box-shadow: 0 8px 20px #00000029;
}
.o_web .hstr_tbl_bx h3 {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.42px;
  color: #989898;
  margin-bottom: 0;
  padding: 15px 12px 10px 12px;
}
.o_web .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0) !important;
}
.o_web .at {
  font-size: 16px !important;
}
.o_web .table-striped tbody tr:nth-of-type(even) {
  background-color: #f3f3f3 !important;
}
.o_web #no-more-tables table thead th {
  background-color: #0f1740;
  border: none;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.24px;
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;
}
.o_web #no-more-tables table tbody tr td {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #3c3c3b;
}
.o_web #auction-history-tables table thead th {
  background-color: #0f1740;
  border: none;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.24px;
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;
}
.o_web #auction-history-tables table tbody tr td {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #3c3c3b;
}
.o_web #no-more-tables table tbody tr td .clm_txt {
  cursor: pointer;
  display: inline-block;
  font-size: 6px;
  line-height: 8px;
  letter-spacing: 0.18px;
  color: #707070;
  border: 1px solid #707070;
  border-radius: 2px;
  padding: 1px 3px;
  vertical-align: middle;
  margin-top: -1px;
  margin-left: 10px;
}
.o_web #no-more-tables table tbody tr td .clm_txt.active {
  color: #0dc471;
  border: 1px solid #0dc471;
}
.o_web #no-more-tables table tbody tr td.fnt_sm {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 12px;
  color: #0f1740;
}
@media screen and (max-width: 767px) {
  .o_web #no-more-tables table,
  .o_web #no-more-tables tbody,
  .o_web #no-more-tables td,
  .o_web #no-more-tables th,
  .o_web #no-more-tables thead,
  .o_web #no-more-tables tr {
    display: block;
  }
  .o_web #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .o_web #no-more-tables tbody tr {
    border: 1px solid #ccc;
  }
  .o_web #no-more-tables tbody tr td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30% !important;
    white-space: normal;
    text-align: left;
  }
}
@media screen and (max-width: 767px) {
  .o_web #no-more-tables td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: 700;
  }
  .o_web #no-more-tables td:before {
    content: attr(data-title);
  }
}
.o_web .btm_info_text {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #989898;
  margin-top: 40px;
  margin-bottom: 30px;
}
.o_web .btm_info_text a {
  color: #f05939;
}
.o_web .rwrd_box {
  background: #fcfcfc;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 8px 20px #00000029;
  padding: 20px 30px;
  width: 283px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .o_web .rwrd_box {
    width: 90%;
    text-align: center;
  }
}
.o_web .rwrd_box h3 {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: #0f1740;
  margin-bottom: 0;
}
.o_web .def_wt_bx_v2 {
  padding-top: 10px;
}
.o_web .width60 {
  width: 60px;
  margin-right: 12px;
  text-align: center;
}
.o_web .width60 h4 {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: -0.22px;
  color: #0f1740;
  margin-bottom: 5px;
  background: #f8f8f8;
  box-shadow: 6px 5px 20px #00000029;
  border-radius: 12px;
  width: 100%;
  padding: 2px 0;
}
.o_web .prgrss_bx {
  padding: 0 10px;
  margin-bottom: 1rem;
}
.o_web .prgrss_bx h5 {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: #0f1740;
  margin-bottom: 15px;
}
.o_web .slider-value-label {
  color: #000;
  float: left;
  padding-left: 25px;
  font-size: 22px;
  margin-top: -7px;
}
.o_web .slider-label {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #989898;
  padding-left: 0;
  margin-bottom: 3px;
}
.o_web .rangeSlider__horizontal {
  position: relative;
  float: left;
  height: 10px;
  width: calc(100% - 65px);
  box-shadow: none;
  border: 1px solid #ccc;
  border-radius: 35px;
  background: 0 0;
  width: 100%;
  box-shadow: inset 0 0 3px #00000063;
}
.o_web .rangeSlider__handle {
  width: 16px;
  height: 16px;
  top: -12px;
  background-position: left top;
  box-shadow: none;
  margin-left: -2px;
  background: #f05139;
  border: 5px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.8);
  margin-top: 7px;
  position: absolute;
  z-index: 5;
}
.o_web .rangeSlider__fill {
  border-radius: 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAIBAMAAAAsHUM2AAAAGFBMVEWUmKnjdTnibTmNj57dWUPiZjniXznjWDmiuAEBAAAAG0lEQVQI12NwdmYoKGBISGAICGBQUGAQEICQADafA4ffUN49AAAAAElFTkSuQmCC) repeat-x 0 0;
  height: 8px;
  border-radius: 35px;
  position: absolute;
  top: 0;
  z-index: 0;
}
.o_web .value_bx {
  float: right;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.12px;
  color: #0f1740;
  margin-top: 10px;
}
.o_web .value_bx .slider-value-label {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.12px;
  color: #0f1740;
  float: none;
}
.o_web .bg_wt_bx {
  background: #fcfcfc;
  border-radius: 12px;
  box-shadow: 0 0 22px #00000029;
  padding: 30px 15px 30px 15px;
  margin-top: 80px;
  width: 345px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .o_web .bg_wt_bx {
    width: 100%;
    margin-top: 30px;
  }
}
.o_web .crcl_bbx {
  height: 242px;
  width: 242px;
  margin: 0;
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.2;
  background: #0f1740;
  border-radius: 50%;
  padding: 20px 0 20px 0;
  box-shadow: 4px 3px 16px 7px #00000054;
}
.o_web .crcl_bbx .inner_crcle {
  position: absolute;
  left: 36px;
  top: 34px;
  height: 170px;
  width: 170px;
  background: #fff;
  border-radius: 50%;
}
.o_web .crcl_bbx strong {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: "SF Pro Display Light", Mulish, sans-serif;
  font-weight: 300;
  font-size: 36px;
  line-height: 33px;
  letter-spacing: -0.36px;
  color: #0f1740;
  z-index: 1;
}
.o_web .crcl_bbx label {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #989898;
  z-index: 1;
}
.o_web .crcl_bbx h4 {
  position: absolute;
  top: 135px;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: #0f1740;
  z-index: 1;
}
.o_web .crcl_bbx p {
  position: absolute;
  top: 162px;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #989898;
  z-index: 1;
}
.o_web .circle canvas {
  vertical-align: top;
}
.o_web .blck_bbx {
  background: transparent linear-gradient(180deg, #111a44 0, #010726 100%) 0 0
    no-repeat padding-box;
  border-radius: 0 0 12px 12px;
  width: 283px;
  margin: 0 auto 0 31px;
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.12px;
  color: #fff;
  height: 50px;
  text-align: center;
  padding: 16px 10px;
  box-shadow: 0 7px 18px 5px #00000029;
}

@media screen and (max-width: 767px) {
  .o_web .blck_bbx {
    width: 90%;
    margin: 0 5% 0 5%;
  }
}
@media screen and (min-width: 768px) {
  .o_web .right_d_wtpdng {
    padding-left: 30px;
  }
}
.o_web .crcl_prnt .crcl_bbx {
  background: transparent linear-gradient(180deg, #f05139 0, #f08639 100%) 0 0
    no-repeat padding-box;
}
.o_web .crcl_prnt .crcl_bbx label {
  color: #f08339;
}
.o_web .crcl_prnt .crcl_bbx strong {
  color: #f08339;
}
.o_web .crcl_prnt .blck_bbx {
  background: transparent linear-gradient(180deg, #f05139 0, #f08639 100%) 0 0
    no-repeat padding-box;
}
.o_web .dflt_bl_mdl {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 83px;
  background: transparent linear-gradient(180deg, #111a44 0, #010726 100%) 0 0
    no-repeat padding-box;
  border: 1px solid #707070;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-out;
}
.o_web .dflt_bl_mdl.show {
  display: block;
  opacity: 1;
  visibility: visible;
}
.o_web .dflt_bl_mdl .mdlcls {
  position: absolute;
  right: 14%;
  top: 35px;
  border: none;
  height: 25px;
  width: 25px;
  background: url(/static/media/sprite_img.01425b82.svg) no-repeat -218px -12px;
  background-size: 500px;
}
@media screen and (max-width: 575px) {
  .o_web .dflt_bl_mdl .mdlcls {
    right: 15px;
  }
}
.o_web .dflt_bl_mdl h3 {
  font-family: "Muli Regular", Mulish, sans-serif;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 4.2px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 30px;
}
.o_web .wallet_modal h3 {
  margin-bottom: 85px;
}
.o_web .wlt_list {
  text-align: center;
  margin: 0;
  padding: 0;
}
.o_web .wlt_list li {
  position: relative;
  display: inline-block;
  margin: 15px;
  list-style: none;
}
@media screen and (max-width: 575px) {
  .o_web .wlt_list li {
    margin: 10px 5px;
  }
}
.o_web .wlt_list li .ckbx_inpt {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.o_web .wlt_list li span {
  display: block;
  width: 227px;
  height: 123px;
  background: #fcfcfc;
  border-radius: 12px;
  padding: 31px;
  border: 2px solid transparent;
}
@media screen and (max-width: 575px) {
  .o_web .wlt_list li span {
    width: auto;
    height: auto;
    padding: 8px;
  }
}
.o_web .wlt_list li .ckbx_inpt:checked + span {
  border: 2px solid #f06e39;
}
.o_web .wltsm_modal {
  background: #f8f8f8;
  box-shadow: 6px 9px 20px #00000029;
  border-radius: 12px;
  position: absolute;
  left: 20px;
  top: 15px;
  right: 20px;
  bottom: 15px;
  z-index: 9;
  min-height: 300px;
  padding: 25px 20px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-out;
}
.o_web .wltsm_modal.show {
  display: block;
  opacity: 1;
  visibility: visible;
}
.o_web .wltsm_modal h4 {
  font-family: "SF Pro Display Light", Mulish, sans-serif;
  font-size: 17px;
  line-height: 28px;
  letter-spacing: 0.68px;
  color: #0f1740;
  margin-bottom: 25px;
}
.o_web .wltsm_modal .mdlcls {
  position: absolute;
  right: 15px;
  top: 28px;
  border: none;
  height: 25px;
  width: 25px;
  background: url(/static/media/sprite_img.01425b82.svg) no-repeat -381px -62px;
  background-size: 500px;
}
@media screen and (max-width: 575px) {
  .o_web .wltsm_modal .mdlcls {
    right: 15px;
  }
}
.o_web .wltsm_modal .wlt_list li span {
  width: auto;
  height: auto;
  padding: 10px;
  background: #f8f8f8;
}
.o_web .b_adgex_bx {
  display: none;
}
.o_web .b_adgex_bx.show {
  display: block;
}
.o_web .b_adgex_bx p {
  padding: 0 5px;
  font-size: 9px !important;
}
.o_web .b_adgex_bx .bk_arrow {
  display: inline-block;
  height: 15px;
  width: 19px;
  background: url(/static/media/sprite_img.01425b82.svg) no-repeat -426px -68px;
  background-size: 500px;
  margin: -4px 4px 6px 4px;
}
.o_web .b_adgex_bx label {
  display: block;
}
.o_web .zindx {
  z-index: 1;
  position: relative;
}
.o_web .eth_info {
  position: relative;
  top: -1rem;
  background: #fcfcfc;
  border-radius: 0 0 12px 12px;
  padding: 20px 20px 10px 20px;
  width: 283px;
  margin: 0 auto;
  box-shadow: 0 0 22px #00000029;
}
@media screen and (max-width: 767px) {
  .o_web .eth_info {
    width: 95%;
  }
}
.o_web .eth_info h4 {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.36px;
  color: #989898;
  margin-bottom: 0;
}
.o_web .eth_info h4 img {
  width: 20px;
  vertical-align: top;
  margin-top: 3px;
}
.o_web .eth_info h4 strong {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: #0f1740;
  float: right;
  margin-top: 4px;
}
.o_web .eth_info hr {
  margin-top: 5px;
  margin-bottom: 5px;
}
.o_web .eth_info .et_btminfo {
  padding: 4px 3px 0 3px;
}
.o_web .eth_info .et_btminfo .drk_p {
  color: #0f1740;
}
.o_web .eth_info .et_btminfo .green_p {
  color: #0dc471;
}
.o_web .eth_info .et_btminfo p {
  margin-bottom: 5px;
}
.o_web .card-input {
  width: 90%;
}
.o_web .eth_info .et_btminfo p i {
  display: inline-block;
  height: 15px;
  width: 19px;
  background: url(/static/media/sprite_img.01425b82.svg) no-repeat -468px -68px;
  background-size: 500px;
  vertical-align: middle;
  margin-left: 2px;
}
.o_web .tooltip-inner {
  font-size: 11px !important;
}
.o_web .bg_wt_bx_v2 {
  margin-top: 0 !important;
  text-align: left;
  padding: 20px;
}
.o_web .bg_wt_bx_v2 h3 {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: #0f1740;
  margin-bottom: 15px;
  padding-top: 0.5rem;
}
.o_web .bg_wt_bx_v2 p {
  margin-bottom: 8px;
}
.o_web .bg_wt_bx_v2 .brdr_bx {
  font-family: "SF Pro Display Semibold", Mulish, sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.16px;
  color: #0f1740;
  border: 1px solid #9b9b9b;
  border-radius: 6px;
  padding: 7px 2px;
  margin-bottom: 2px;
}
.o_web .bg_wt_bx_v2 .brdr_bx .col-4 {
  padding: 0;
}
.o_web .bg_wt_bx_v2 .brdr_bx .dt_txt {
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  color: #989898;
}
.o_web .mx_450 {
  max-width: 450px;
  margin: 0 auto;
}
.o_web .crnc_prnt {
  padding: 0 15px;
}
.o_web .crnc_prnt h5 {
  font-family: "SF Pro Display Light", Mulish, sans-serif;
  font-size: 14px;
  line-height: 34px;
  letter-spacing: 0.56px;
  color: #fff;
  margin-bottom: 5px;
}
.o_web .loading-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-top: -82px;
}
.o_web .loading-page p {
  margin-top: 1rem auto;
  color: #010726;
}
.o_web .small-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.o_web .rn_ic_1 .active {
  color: #f16b39 !important;
}
.o_web .modal-wrapper {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
/* .modal {
  background-color: #fff !important;
  width: 30vw !important;
  height: 60vh !important;
  margin-top: 20vh !important;
  margin-left: 35vw !important;
  border-radius: 20px !important;
  padding: 1rem !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: space-evenly !important;
  -ms-flex-pack: space-evenly !important;
  justify-content: space-evenly !important;
} */

.projectModal {
  width: 100%;
  height: 100vh;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.projectModal input,.projectModal select{
  border: 1px solid;
}
.modal h2 {
  text-align: center !important;
  font-weight: var(--light) !important;
}
@media screen and (min-width: 768px) {
  .o_web .stake-container {
    padding: 0 2rem !important;
  }
  .staking-container {
    min-height: calc(100vh - 225px) !important;
  }
}
.o_web .stake-reward-banner {
  position: relative;
  z-index: 0;
  box-shadow: 6px 12px 20px 12px rgba(0, 0, 0, 0.137) !important;
  background: transparent linear-gradient(180deg, #f05139 0, #f08639 100%) 0 0
    no-repeat padding-box;
  padding: 1rem 2rem 4rem 2rem;
  width: 90%;
  margin: 0 auto -4rem auto;
  border-radius: 12px !important;
}
.o_web .stake-calculator-card {
  position: relative;
  top: -2.25rem;
  z-index: 1;
  box-shadow: 6px 9px 20px 12px rgba(0, 0, 0, 0.137) !important;
  padding: 2rem 1.5rem 0 1.5rem !important;
  border-radius: 12px !important;
  margin: auto;
  width: 75%;
  padding: 1rem 2rem;
}
.o_web .rangeslider-horizontal {
  height: 10px !important;
}
.o_web .rangeslider__handle {
  outline: 0 !important;
}
.o_web .rangeslider__fill {
  background: transparent
    repeating-linear-gradient(
      90deg,
      #f05139,
      #f05139 1.5px,
      #979bab 1.5px,
      #979bab 3px
    )
    0 0 no-repeat padding-box;
}
.o_web .rangeslider .rangeslider__handle {
  border-color: transparent !important;
  width: 21px !important;
  height: 21px !important;
}
.o_web .rangeslider-horizontal .rangeslider__handle:after {
  width: 10px !important;
  height: 10px !important;
  top: 4.5px !important;
  left: 4.25px !important;
  background: transparent linear-gradient(180deg, #f05139 0, #f08639 100%) 0 0
    no-repeat padding-box;
  box-shadow: 0 8px 20px #00000029 !important;
}
.o_web .kyc-history-table .MuiPaper-elevation1 {
  box-shadow: 6px 9px 20px 12px rgba(0, 0, 0, 0.137) !important;
  border-radius: 12px !important;
}
.o_web .kyc-history-table .MuiTypography-body2 {
  padding-top: 0 !important;
  margin-top: -5px !important;
  font-family: "Muli Regular";
}
.o_web .MuiMenuItem-root {
  font-family: "Muli Regular";
}
.o_web .settings-card {
  box-shadow: 0 1px 4px #e5e9f2 !important;
  border-radius: 23px !important;
  padding: 1rem;
}
.o_web .settings-card .MuiRadio-colorPrimary.Mui-checked {
  color: #f08639 !important;
}
.o_web .stake-tracker-card {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 6px 9px 20px 12px rgba(0, 0, 0, 0.137) !important;
  padding: 2.75rem 2rem !important;
  border-radius: 12px !important;
  margin: auto;
  width: 65%;
  padding: 1rem 2rem;
}
.o_web .tracker-border-gradient {
  position: absolute;
  height: 260px;
  width: 260px;
  border-radius: 50%;
  background: transparent linear-gradient(180deg, #f05139 0, #f08639 100%) 0 0
    no-repeat padding-box;
  box-shadow: 4px 3px 16px 7px #00000054;
}
.o_web .tracker-border-gradient-dark {
  position: absolute;
  height: 260px;
  width: 260px;
  border-radius: 50%;
  background: transparent linear-gradient(180deg, #0f1740 0, #111a44 100%) 0 0
    no-repeat padding-box;
  box-shadow: 4px 3px 16px 7px #00000054;
}
.o_web .tracker-border-gradient-inner {
  position: absolute;
  z-index: 2;
  height: 190px;
  width: 190px;
  border-radius: 50%;
  background-color: #fff;
}
@media screen and (max-width: 600px) {
  .o_web .modal {
    width: 100vw;
    margin-left: 0;
  }
}
.ant-modal.o_web {
  background: 0 0 !important;
  min-height: 0 !important;
}
.o_web .anticon svg {
  color: #000 !important;
}
.o_web .ant-modal-content {
  border-radius: 12px !important;
}
.o_web .ant-modal-header {
  border-radius: 12px 12px 0 0 !important;
}
.o_web .purchase-history p {
  padding-top: 0;
  font-family: "Muli Regular";
}
.o_web .purchase-history .MuiTablePagination-input {
  display: none;
}
@-moz-document url-prefix() {
  .o_web .input-1 {
    width: 67% !important;
  }
  .o_web .input-2 {
    width: 75.5% !important;
  }
}
.o_web .dropdown-menu {
  float: right;
  left: auto;
  right: 0;
  border: none;
  padding: 1rem;
  box-shadow: 6px 9px 20px 5px #00000029 !important;
}
.o_web .dropdown-menu ul {
  list-style-type: none;
  margin-bottom: 0;
}
.o_web .dropdown-menu ul li {
  padding: 7px 0;
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 15.5px;
  letter-spacing: 0.2px;
  color: #989898;
  text-transform: capitalize;
  text-align: right;
}
.o_web .dropdown-nav-link {
  padding: 7px 0;
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  font-size: 15.5px;
  letter-spacing: 0.2px;
  color: #989898;
  text-transform: capitalize;
}
.o_web .dropdown-nav-link:hover {
  color: #0f1740;
}
.o_web .dropdown-link-active {
  color: #f08639;
}
.o_web .dropdown-link-active:hover {
  color: #f08639;
}
.o_web .tabs-container {
  position: absolute;
  top: 62px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: all 0.3s ease;
  border-radius: 0 0 19px 19px;
}
.o_web .opaque {
  background: #fff;
  border-radius: 0 0 19px 19px;
  transition: all 0.3s ease;
  box-shadow: 0 18px 24px rgba(138, 138, 138, 0.3);
}
.o_web .tabs-container li {
  margin: 0 18px;
  text-align: center;
}
.o_web .tabs-container li:nth-child(1) {
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 0;
}
.o_web .tabs-container li:nth-last-child(1) {
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 0;
}
@media screen and (max-width: 500px) {
  .o_web .tabs-container li {
    margin: 0 10px;
    text-align: center;
  }
}
@media screen and (max-width: 435px) {
  .o_web .tabs-container {
    max-width: 350px;
  }
}
@media screen and (max-width: 305px) {
  .o_web .tabs-container {
    max-width: 100%;
  }
}
.o_web .tab-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 47px;
}
.o_web .tab-item a {
  font-family: "SF Pro Display Regular";
  font-size: 13.5px;
  text-transform: capitalize;
  color: #868686;
}
.o_web .tab-item a:focus,
.o_web .tab-item-active a:focus {
  text-decoration: underline !important;
}
.o_web .tab-item a:hover {
  color: #0f1740;
}
.o_web .tab-item-active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
  height: 47px;
  background: transparent linear-gradient(180deg, #f05139 0, #f08639 100%) 0 0
    no-repeat padding-box;
  border-radius: 0 0 19px 19px;
}
.o_web .tab-item-active a {
  font-family: "SF Pro Display Bold";
  font-size: 13.5px;
  color: #fff;
}
.o_web .tab-item-active a:hover {
  color: #fff;
}
.o_web .history-button {
  background-color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  color: #32cc86;
  margin-bottom: 1rem;
  border: 1px solid #6ac98c;
  border-radius: 4.5px;
  padding: 3px 7px;
}
.o_web .history-button:hover {
  color: #32cc86;
  border-color: #32cc86;
}
.o_web .rangeslider {
  margin: 10px 0 !important;
}
@media screen and (min-width: 1200px) {
  .o_web .hidden-desktop {
    display: none;
  }
  .o_web .hidden-tablet {
    display: block;
  }
}
@media screen and (max-width: 1199px) {
  .o_web .hidden-desktop {
    display: block;
  }
  .o_web .hidden-tablet {
    display: none;
  }
  .o_web .rn_ic_5:nth-child(1) {
    display: block;
  }
  .o_web .my-lg-0 .right_nave {
    margin-top: 1rem;
    margin-right: -3.5rem;
    overflow: hidden;
  }
  .o_web .dropdown-menu {
    display: none !important;
  }
  .o_web .menu-burger {
    display: none !important;
  }
}
@media screen and (max-width: 990px) {
  .o_web .def_tab_v2 {
    flex-direction: row;
    align-items: center;
  }
  .o_web .def_tab_v2 li {
    width: 120px;
    text-align: center;
  }
  .o_web .purchase-nav li {
    width: 140px;
    width: 100%;
  }
  .o_web .purchase-nav li a {
    width: 100%;
  }
  .o_web .purchase-nav li:nth-child(2) {
    left: 0;
  }
  .o_web .purchase-nav li:nth-child(3) {
    left: 0;
  }
  .o_web .tx-history-nav {
    flex-direction: row;
  }
  .o_web .tx-history-nav li {
    margin-right: 10px;
    width: auto;
  }
}
@media screen and (max-width: 767px) {
  .o_web .def_tab_v2 {
    flex-direction: row;
    justify-content: center;
  }
  .o_web .def_tab_v2 li {
    text-align: center;
  }
}
.o_web
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: #f05d38 !important;
  border-color: #f05d38 !important;
}
.o_web .anticon svg {
  position: relative;
  top: -1px;
  color: #32cc86;
}
.o_web .ant-steps-item-finish .ant-steps-item-icon {
  border-color: #32cc86 !important;
}
.o_web
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #32cc86 !important;
}
.o_web .ant-spin-dot-item,
.suspense-loader .ant-spin-dot-item {
  background-color: #ef8638 !important;
}
.o_web .white-spinner .ant-spin-dot-item,
.white-spinner .ant-spin-dot-item {
  background-color: #fff !important;
}
.o_web .RSPBprogressBar {
  box-shadow: 0 2px 5px rgba(141, 143, 152, 0.43),
    0 2px 5px rgba(141, 143, 152, 0.43) inset;
  border: 2px solid #fff;
  border-radius: 14px !important;
  background-color: #fff !important;
  margin-bottom: 4.5rem;
}
.o_web .RSPBprogressBar .RSPBprogressBarText {
  font-family: "SF Pro Display Bold", Mulish, sans-serif;
  font-size: 7px;
  letter-spacing: 0.18px;
}
.o_web .RSPBprogressBar .RSPBprogression {
  border-radius: 14px !important;
}
.o_web .RSPBprogressBar .RSPBstep:nth-child(1) {
  left: 3% !important;
}
.o_web .RSPBprogressBar .RSPBstep:nth-child(5) {
  left: 95% !important;
}
.o_web .step-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4.5rem;
}
.o_web .step-container span {
  margin-top: 5px;
  font-family: "SF Pro Display Bold", Mulish, sans-serif;
  font-size: 13px;
  letter-spacing: 0.3px;
  color: #989898;
}
.o_web .next-reward-container {
  top: -3rem;
  position: absolute;
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.o_web .reward-block {
  position: relative;
  z-index: 100;
  width: 1.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.o_web .reward-block:nth-child(2) {
  left: 0.18rem;
}
.o_web .reward-block:nth-child(3) {
  left: 1rem;
}
.o_web .reward-block:nth-child(4) {
  left: 1rem;
}
.o_web .reward-block:nth-child(5) {
  left: 1rem;
}
.o_web .reward-block p {
  padding-top: 0;
  margin-bottom: -5px;
  min-width: 100px;
  font-size: 10px;
  font-family: "SF Pro Display Bold", Mulish, sans-serif;
  color: #32cc86;
}
.o_web .reward-block p:nth-child(2) {
  padding-top: 0;
  margin-bottom: 2px;
  min-width: 100px;
  font-size: 9px;
  font-family: "SF Pro Display Regular", Mulish, sans-serif;
  color: #989898;
}
.n_web .featured-media-bb {
  margin-top: 40px;
}
.n_web .featured-media-bb h3 {
  font-size: 14px;
  letter-spacing: 14px;
  margin: 0 0 20px;
}
.n_web .featured-media-bb .s-wd {
  width: 48%;
}
.n_web .featured-media-bb .img-prt {
  height: 288px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.n_web .featured-media-bb .img-prt img {
  height: 100%;
}
.n_web .featured-media-bb .detail-info small {
  color: #e5e5e5;
  top: -9px;
  position: relative;
}
.n_web .featured-media-bb .detail-info h4 {
  font-size: 24px;
  margin: 10px 0 12px;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.n_web .featured-media-bb .detail-info p {
  color: #d8d8d8;
  font-size: 14px;
  line-height: 22px;
  margin: 0 0 20px;
}
.n_web .featured-media-bb .detail-info p + p {
  margin-top: 12px;
}
.n_web .featured-media-bb .detail-info a {
  color: #f05139;
  font-size: 10px;
}
.n_web .featured-media-bb .detail-info a:hover {
  text-decoration: underline;
}
.n_web .recent-media-se {
  padding: 60px 0;
}
.n_web .recent-media-se h3 {
  letter-spacing: 10px;
  color: #707070;
  font-size: 14px;
  margin: 0 0 15px;
}
.n_web .recent-media-se .sm-w {
  width: 48%;
}
.n_web .recent-media-se .im-se {
  width: 155px;
  height: 120px;
  border-radius: 5px;
  overflow: hidden;
}
.n_web .recent-media-se .im-se img {
  height: 100%;
}
.n_web .recent-media-se .info-se {
  width: calc(100% - 155px);
  padding-left: 30px;
  color: #707070;
}
.n_web .recent-media-se .info-se small {
  font-size: 10px;
  top: -9px;
  position: relative;
}
.n_web .recent-media-se .info-se h4 {
  font-size: 20px;
  margin: 0 0 8px;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.n_web .recent-media-se .info-se p {
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 10px;
}
.n_web .recent-media-se .media-corn {
  margin: 15px 0;
}
.n_web .recent-media-se .info-se p + p {
  margin-top: 10px;
}
.n_web .recent-media-se .info-se a {
  color: #f05139;
  font-size: 12px;
}
.n_web .recent-media-se .info-se a:hover {
  text-decoration: underline;
}
.n_web .load-more {
  padding: 60px 0;
}
.n_web .load-more::after {
  background: linear-gradient(90deg, #111a44 0, #010726 100%);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  background-blend-mode: soft-light;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.n_web .load-more p {
  letter-spacing: 3px;
  font-size: 10px;
}
@media screen and (max-width: 991px) {
  .n_web .recent-media-se .sm-w {
    width: 100%;
  }
  .n_web .recent-media-se .media-corn {
    margin: 0;
  }
  .n_web .recent-media-se .media-corn + .media-corn {
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .n_web .featured-media-bb .s-wd {
    width: 100%;
  }
  .n_web .featured-media-bb .detail-info {
    margin: 15px 0 0;
  }
}
@media screen and (max-width: 479px) {
  .n_web .recent-media-se .im-se {
    width: 100%;
    height: 230px;
  }
  .n_web .recent-media-se .info-se {
    width: 100%;
    padding: 0;
    margin: 20px 0 0;
  }
}
.n_web .particles-js-canvas-el {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.n_web {
  font-size: 14px;
  color: #66667e;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: muliregular;
  scroll-behavior: smooth;
}
.n_web {
  background-color: #111a44;
  position: relative;
  z-index: 2;
  overflow-x: hidden;
}
.body-scroll-dis {
  position: relative;
}
.body-scroll-dis::after {
  height: 100%;
  width: 100%;
  display: block;
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 8;
}
.n_web .full-box-link {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.n_web :focus,
.n_web button:focus {
  outline: 0;
}
.n_web .object-fit-cover {
  object-fit: cover;
  width: 100%;
}
.n_web .object-fit-fill {
  object-fit: fill;
}
.n_web .object-fit-contain {
  object-fit: contain;
}
.n_web .object-fit-none {
  object-fit: none;
}
.n_web .object-fit-scale-down {
  object-fit: scale-down;
}
.n_web .ff-muli-r {
  font-family: muliregular;
}
.n_web .ff-muli-b,
.o_web nav.side-menu .ff-muli-b {
  font-family: mulibold;
}
.n_web .ff-muli-l {
  font-family: mulilight;
}
.n_web .ff-muli-sb {
  font-family: mulisemibold;
}
.n_web .ff-barlow-r {
  font-family: muliregular;
}
.n_web .ff-b-condensed {
  font-family: "Barlow Condensed", sans-serif;
}
.n_web ul,
.n_web ul li,
.o_web ul.menu,
.o_web ul.menu li {
  padding: 0;
  list-style: none;
  margin: 0;
}
@media screen and (max-width: 430px) {
  .n_web ul li{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
  }
  .n_web p{
    display: flex;
    justify-content: center;
  }
}
.n_web h1,
.n_web h2,
.n_web h3,
.n_web h4,
.n_web h5,
.n_web h6,
.n_web p {
  margin: 0;
}

.n_web .h-100 {
  height: 100%;
}
.n_web .a-center {
  height: calc(100% - 85px);
}
.n_web .d-none-n {
  display: none;
}
.n_web .after-bg-none::after,
.n_web .before-bg-none::before {
  display: none;
}
.n_web .after-po::after,
.n_web .before-po::before {
  position: absolute;
  content: "";
}
.n_web .b-s-20 {
  height: 20px;
}
.n_web .b-s-80 {
  height: 80px;
}
.n_web .b-s-15 {
  height: 15px;
}
.n_web .z-7 {
  z-index: 7;
}
.n_web a,
.n_web a:focus,
.n_web a:hover,
.n_web i {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}
.n_web .v-center {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.n_web .btn.focus,
.n_web .btn:focus {
  box-shadow: none;
}
.n_web .d-none-n {
  display: none;
}
.n_web .orange-color {
  color: #f05139;
}
.n_web .for-after-sprite::after,
.n_web .for-before-sprite::before,
.n_web .for-sprite-call,
.o_web .for-after-sprite::after,
.o_web .for-before-sprite::before,
.o_web .for-sprite-call {
  background: url(/static/media/sprite.9803528f.svg) no-repeat;
}
.n_web .for-after-sprite::after,
.n_web .for-before-sprite::before,
.o_web .for-after-sprite::after,
.o_web .for-before-sprite::before {
  position: absolute;
  content: "";
}
.n_web main {
  flex: 1 0 auto;
  -webkit-box-flex: 1;
}
.n_web .fixed-header {
  position: fixed !important;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: #fcfcfc;
  top: 0;
  -ms-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.19);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.19);
  animation: smoothScroll 1s forwards;
  padding: 20px 0;
}
.n_web .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0);
  }
}
.n_web header .logo {
  max-width: 180px;
}
.n_web header .logo img {
  max-width: 100%;
}
.n_web .main-link-here {
  display: none;
}
.n_web .eco-system-se.test {
  background: url(/static/media/solutio_shape_bg.f1f06d1a.jpg) center bottom no-repeat;
  background-size: cover;
  width: 1280px;
  height: 771px;
  margin: 0 auto;
}
.n_web .eco-system-se.test-2 {
  background: url(/static/media/solutio_shape_bg.f1f06d1a.jpg) center bottom no-repeat;
  background-size: cover;
  width: 100%;
  height: 700px;
}
.n_web .navigation-dots-container {
  position: fixed;
  left: 15px;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.n_web .navigation-dots-container a.active {
  transform: scale(1.3);
  background-color: #fff;
}
.n_web .navigation-dots-container a {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #707070;
  font-size: 0;
  display: block;
}
.n_web .navigation-dots-container a + a {
  margin-top: 20px;
}
.n_web header {
  padding: 18px 0;
  background-color: #fcfcfc;
  z-index: 9;
}
.n_web header .letest-news {
  margin-right: 100px;
}
.n_web header .letest-news p {
  text-transform: uppercase;
  font-size: 10px;
  color: #66667e;
  font-weight: 300;
  letter-spacing: 2px;
}
.n_web header .letest-news p ~ p {
  text-transform: none;
  color: #66667e;
  font-size: 12px;
  letter-spacing: 1px;
}
.n_web header .letest-news:hover p {
  color: #ef4b23;
}
.n_web header nav.side-menu,
.o_web header nav.side-menu {
  height: 25px;
  width: 25px;
}
.n_web nav.side-menu .menu,
.o_web nav.side-menu .menu {
  top: 0;
  left: auto;
  margin: 0;
  z-index: 1;
  width: 100%;
  padding: 15px 30px;
  background: #fff;
  background: linear-gradient(180deg, #fff 46%, #d8d8d8 100%);
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s cubic-bezier(1, 0.5, 0, 1);
  width: 350px;
  right: 0;
  height: 100vh;
  overflow: auto;
}
.o_web nav.side-menu .menu {
  transform: translateY(-103%);
}
.n_web nav.side-menu .menu.active,
.o_web nav.side-menu .menu.active {
  top: 70px;
  height: calc(100vh - 70px);
  transform: translateY(0);
  z-index: 5;
}
nav.side-menu .menu.active {
  transform: translateY(-3%);
  z-index: 5;
}
nav.side-menu .menu li,
nav.side-menu .menu li a,
nav.side-menu .menu li ul li button {
  color: #111a44;
  line-height: 1;
  font-size: 13px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
}
nav.side-menu > .menu > li > a {
  font-family: mulibold;
}
nav.side-menu > .menu > li > ul > li > a {
  font-family: mulilight;
}
.n_web nav.side-menu .menu li + li,
.o_web nav.side-menu .menu li + li {
  margin-top: 18px;
}
.n_web nav.side-menu .menu li:last-child,
.o_web nav.side-menu .menu li:last-child {
  margin-bottom: 24px;
}
.n_web nav.side-menu .menu li ul li + li,
.o_web nav.side-menu .menu li ul li + li {
  margin-top: 10px;
}
.n_web nav.side-menu .menu li ul,
.o_web nav.side-menu .menu li ul {
  padding: 15px 15px 0;
}
nav.side-menu .menu li ul li button {
  background: 0 0;
  border: none;
  padding: 0;
}
nav.side-menu .menu li ul li a,
nav.side-menu .menu li ul li button {
  color: #66667e;
  font-size: 11px;
}
.n_web
  nav.side-menu
  .menu
  li
  ul
  li
  button
  .n_web
  nav.side-menu
  .menu
  li
  a:hover,
.n_web nav.side-menu .menu li ul li button:hover {
  color: #ef4b23;
}
.n_web nav.side-menu .menu-toggle,
.o_web nav.side-menu .menu-toggle {
  z-index: 2;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.n_web nav.side-menu .menu-toggle:hover::before,
.o_web nav.side-menu .menu-toggle:hover::before {
  background-position: -30px 0;
}
.n_web nav.side-menu .menu-toggle span {
  display: none;
}
.n_web nav.side-menu .menu-toggle::after,
.n_web nav.side-menu .menu-toggle::before,
.o_web nav.side-menu .menu-toggle::after,
.o_web nav.side-menu .menu-toggle::before {
  left: 0;
  content: "";
  width: 25px;
  height: 25px;
  background-position: 0 0;
}
.n_web nav.side-menu .menu-toggle::before,
.o_web nav.side-menu .menu-toggle::before {
  top: 0;
}
.n_web nav.side-menu .menu-toggle::after,
.o_web nav.side-menu .menu-toggle::after {
  bottom: 0;
}
.n_web nav.side-menu .menu-toggle.menu-open span {
  background: rgba(255, 255, 255, 0);
}
.n_web nav.side-menu .menu-toggle.menu-open::after,
.n_web nav.side-menu .menu-toggle.menu-open::before,
.o_web nav.side-menu .menu-toggle.menu-open::after,
.o_web nav.side-menu .menu-toggle.menu-open::before {
  height: 25px;
  width: 25px;
  background-position: -30px 0;
}
.n_web nav.side-menu .search-bar {
  width: 250px;
}
.n_web .search {
  width: 100%;
}
.n_web .points-in-white {
  margin: 10px 0 0;
}
.n_web .points-in-white li {
  padding: 0 0 0 20px;
}
.n_web .points-in-white li::after {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #ef4b23;
  left: 0;
  top: 7px;
}
.n_web .points-in-white li + li {
  margin-top: 10px;
}
.n_web .search-term {
  width: 100%;
  background-color: transparent;
  border-width: 2px 2px 2px 2px;
  border-style: solid;
  border-color: transparent transparent #66667e transparent;
  border-right: none;
  padding: 5px;
  height: auto;
  outline: 0;
  color: #9dbfaf;
}
.n_web .search-term:focus {
  color: #00b4cc;
}
.n_web .searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid #00b4cc;
  background: #00b4cc;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}
@keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -400px 0;
  }
}
.n_web .bellow-text-part {
  padding-top: 20px;
  padding-bottom: 20px;
}
.n_web .bellow-text-part p {
  color: #d8d8d8;
  font-size: 14px;
  line-height: 26px;
  width: 48%;
  margin: 10px 0;
}
.n_web .bellow-text-part.new-our-sol {
  padding: 0 0 40px;
}
.n_web .bellow-text-part.new-our-sol p {
  color: inherit;
}
.n_web .small-conn {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}
.n_web .morinfo-ab-coint .small-conn .sw-p {
  padding: 0 30px;
}
.n_web .morinfo-ab-coint .small-conn .sm-title {
  margin: 0 0 20px 0;
}
.n_web .morinfo-ab-coint .small-conn .sm-title i {
  margin-right: 15px;
}
.n_web .morinfo-ab-coint .small-conn .sm-title h3 {
  color: #111a44;
  font-size: 18px;
  letter-spacing: 5px;
  line-height: 1;
}
.n_web .morinfo-ab-coint .small-conn .bellow-text-part .txt-p {
  width: 100%;
  padding: 0;
}
.n_web .find-out-more {
  line-height: 1;
  color: #fff;
  border-radius: 30px;
  padding: 10px 35px 12px;
  display: inline-block;
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
  box-shadow: 0 0 30px #f05139;
}
.n_web .find-out-more:hover {
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
  color: #fff;
}
.n_web .w-48 {
  width: 48%;
}
.n_web .breadcrumbs-div {
  margin: 0 0 20px;
}
.n_web .breadcrumbs-div li + li {
  margin-left: 8px;
}
.n_web .breadcrumbs-div li,
.n_web .breadcrumbs-div li a {
  color: #e5e5e5;
  font-size: 12px;
}
.n_web .breadcrumbs-div li a:hover {
  text-decoration: underline;
}
.n_web .breadcrumbs-div .arrow-ic {
  top: -1px;
}
.n_web .cust-zo-contact-form h2 {
  font-size: 24px;
  letter-spacing: 5px;
  line-height: 1.5;
  margin: 0 0 30px;
}
.modal button {
  background: #f08639;
  background: linear-gradient(90deg, #f08639 0, #f05139 100%);
  padding: 12px 40px;
  color: #fff;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  outline: 0;
}
.n_web .apply-accelerated .fr-shapre,
.n_web .eco-system-se .for-shape,
.n_web .three-m-s.on-m-hmp .for-shape {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
}
.n_web .eco-system-se .for-shape::after,
.n_web .eco-system-se .for-shape::before {
  position: absolute;
  content: "";
}
.n_web .apply-accelerated .fr-shapre::before,
.n_web .eco-system-se .for-shape::after,
.n_web .eco-system-se .for-shape::before,
.n_web .eco-system-se.partners-shapes .for-shape::before,
.n_web .main-hop.eco-system-se .for-shape::after,
.n_web .main-hop.eco-system-se .for-shape::before,
.n_web .three-m-s.on-m-hmp .for-shape::before {
  background: url(/static/media/ring.b10408aa.png) 0 0 no-repeat;
  background-size: 100%;
}
.n_web .main-hop.eco-system-se .for-shape::after {
  width: 540px;
  height: 540px;
  top: -290px;
  left: -100px;
  bottom: auto;
}
.n_web .eco-system-se .for-shape::before {
  height: 570px;
  width: 570px;
  left: auto;
  right: -230px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.n_web .our-so-new-pg.eco-system-se .for-shape::before {
  bottom: auto;
  top: -250px;
}
.n_web .our-so-new-pg.eco-system-se .for-shape::after {
  height: 600px;
  width: 600px;
}
.n_web .eco-system-se.our-team .for-shape::before {
  top: 120px;
  bottom: auto;
  z-index: -1;
}
.n_web .eco-system-se.our-team .for-shape::after {
  top: 10px;
  bottom: auto;
  width: 600px;
  height: 600px;
  z-index: -1;
  left: -320px;
}
.n_web .eco-system-se.partners-shapes .for-shape::before {
  top: -250px;
  bottom: auto;
  right: -210px;
  height: 520px;
  width: 520px;
}
.n_web .eco-system-se .for-shape::after {
  top: 0;
  width: 540px;
  height: 540px;
  background-size: 100%;
  bottom: 0;
  margin: auto;
  left: -330px;
}
.n_web .eco-system-se.solutio-shape .for-shape::after {
  top: -250px;
  bottom: auto;
}
.n_web .eco-system-se.solutio-shape .for-shape::before {
  top: -280px;
  bottom: auto;
}
.n_web .apply-accelerated .fr-shapre::before {
  height: 490px;
  width: 490px;
  left: auto;
  right: -290px;
  top: -70px;
}
.n_web .contact-us-bg.apply-accelerated .fr-shapre::before {
  top: 100px;
}
.n_web .three-m-s.on-m-hmp .for-shape::before {
  height: 560px;
  width: 560px;
  left: auto;
  right: -300px;
  bottom: 0;
  top: 0;
  margin: auto;
}
.n_web .eco-system-se.events-bg .for-shape::after {
  bottom: -250px;
  top: auto;
  width: 550px;
  height: 590px;
}
.n_web .eco-system-se.events-bg .for-shape::before {
  top: -220px;
  bottom: auto;
}
.n_web .partner-shpe.apply-accelerated::after {
  height: 520px;
}
.n_web .main-title-s h2 {
  font-size: 15px;
  letter-spacing: 25px;
}
.n_web .main-title-s h1 {
  font-size: 88px;
  letter-spacing: 2px;
  line-height: 0.9;
}
.n_web .main-title h2 {
  font-size: 15px;
  letter-spacing: 25px;
}
.n_web .main-title h1 {
  font-size: 95px;
  letter-spacing: 2px;
  line-height: 1;
}
.n_web .text-contain {
  max-width: 500px;
  width: 100%;
  margin: 30px 0 0;
}
.n_web .text-contain p {
  font-size: 16px;
  line-height: 28px;
  color: #d8d8d8;
}
.n_web .text-contain p a:hover {
  text-decoration: underline;
}
.n_web .eco-system-se {
  padding: 60px 0;
  overflow: hidden;
}
.n_web .eco-system-se.solutio-shape {
  background: url(/static/media/solutio_shape_bg.f1f06d1a.jpg) 0 0 no-repeat;
  height: 100vh;
  width: 100%;
  background-size: cover;
  overflow-y: auto;
  background-position: bottom center;
}
.n_web .eco-system-se .main-link-here .full-bg-left::after {
  position: absolute;
  content: "";
  left: calc(-100% + 15px);
  height: 100%;
  width: 100%;
  background-color: #fff;
  top: 0;
}
.n_web .eco-system-se .main-link-here .left-link {
  background-color: #fff;
  padding: 20px 0;
}
.n_web .eco-system-se .left-link .sub-link {
  padding: 0 40px 0 0;
}
.n_web .eco-system-se .left-link .sub-link p {
  font-size: 10px;
  color: #66667e;
  letter-spacing: 1px;
}
.n_web .eco-system-se .left-link .sub-link:hover p {
  color: #ef4b23;
}
.n_web .eco-system-se .left-link .sub-link p + p {
  margin-top: 10px;
  color: #3e3e46;
  font-size: 13px;
}
.n_web .eco-system-se .left-link .sub-link + .sub-link {
  padding: 0 40px;
  position: relative;
}
.n_web .eco-system-se .left-link .sub-link + .sub-link::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  left: 0;
  top: 0;
  background-color: #d8d8d8;
}
.n_web .eco-system-se .main-link-here .rig-link a {
  padding: 35px 30px;
  display: inline-block;
  line-height: 1;
  background: #f05139;
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
  color: #fff;
  border-radius: 0 25px 0 0;
}
.n_web .eco-system-se .main-link-here .rig-link a:hover {
  background: #f08639;
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
}
.n_web .start-investing-ani {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 8;
  transition: all 0.5s ease-out;
}
.n_web .rig-link-fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 8;
  transition: all 0.5s ease-out;
}
.n_web .token-tabs-se {
  padding: 50px 0;
  background-color: #f2f2f2;
  z-index: 5;
}
.n_web .token-tabs-se::after {
  background-image: url(/static/media/back_bg.fd52c51f.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  height: 400px;
  width: 400px;
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  z-index: -1;
}
.n_web .token-tabs-se .nav-tabs {
  display: none;
}
.n_web .token-tabs-se .card {
  background-color: transparent;
}
.n_web .token-tabs-se .nav-tabs {
  border: none;
}
.n_web .token-tabs-se .nav-tabs .nav-item {
  margin: 0;
}
.n_web .token-tabs-se .nav-tabs .nav-item + .nav-item {
  margin-left: 25px;
}
.n_web .token-tabs-se .the-edg-sou {
  margin-bottom: 20px;
  font-size: 16px;
}
.n_web .token-tabs-se .nav-tabs .nav-link {
  border-radius: 50px;
  background-color: #fff;
  color: #989898;
  border-radius: 12px;
  letter-spacing: 1.2px;
  font-size: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  min-width: 100px;
  text-align: center;
}
.n_web .token-tabs-se .card-body {
  padding: 20px 0 0 0;
}
.n_web .token-tabs-se .tab-swap .img-part {
  width: 48%;
}
.n_web .token-tabs-se .tab-swap .img-part img {
  max-width: 100%;
}
@media screen and (min-width: 992px) {
  .n_web .token-tabs-se .tab-swap .txt-part {
    width: 48%;
  }
}
.n_web .token-tabs-se .tab-swap .txt-part h2 {
  font-size: 52px;
  color: #0f1740;
  line-height: 1;
}
.n_web .token-tabs-se .tab-swap .txt-part small {
  font-size: 22px;
  letter-spacing: 6px;
  color: #262626;
}
.n_web .token-tabs-se .tab-swap .txt-part p {
  margin: 20px 0 0 0;
  font-size: 14px;
  line-height: 26px;
  color: #66667e;
}
.n_web .token-tabs-se .tab-swap .txt-part ul {
  margin: 15px 0;
}
.n_web .token-tabs-se .tab-swap .txt-part ul li {
  padding: 0 0 0 25px;
}
.n_web .token-tabs-se .tab-swap .txt-part ul li + li {
  margin-top: 10px;
}
.n_web .token-tabs-se .tab-swap .txt-part ul li::after {
  height: 10px;
  width: 10px;
  left: 0;
  top: 8px;
  border-radius: 100%;
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
}
.n_web .token-tabs-se .tab-swap .txt-part .btn-apply-now {
  padding: 10px 20px 12px 24px;
  display: inline-block;
  line-height: 1;
  background: #f05139;
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
  color: #fff;
  border-radius: 25px;
}
.n_web .token-tabs-se .tab-swap .txt-part .btn-apply-now:hover {
  background: #f08639;
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
}
.n_web .token-tabs-se .tab-swap .txt-part .btn-apply-now .ic-rig8-arrow {
  height: 12px;
  width: 12px;
  background-position: -161px -2px;
  top: 2px;
  margin-left: 10px;
}
.n_web .token-tabs-se .tab-swap .txt-part .exc-rate p {
  margin: 0;
  font-size: 12px;
}
.n_web .token-tabs-se .tab-swap .txt-part .iner-pt {
  max-width: 560px;
  width: 100%;
}
.n_web .token-tabs-se .nav-tabs .nav-item,
.n_web .token-tabs-se .nav-tabs .nav-item:focus,
.n_web .token-tabs-se .nav-tabs .nav-link,
.n_web .token-tabs-se .nav-tabs .nav-link:focus,
.n_web .token-tabs-se .nav-tabs .nav-link:hover {
  border: none;
}
.n_web .token-tabs-se .nav-tabs .nav-item.show .nav-link,
.n_web .token-tabs-se .nav-tabs .nav-link.active,
.n_web .token-tabs-se .nav-tabs .nav-link:hover {
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
  color: #fff;
  border: none;
}
.n_web .token-tabs-se .edge-smal {
  left: auto;
  right: 0;
}
.n_web .btn-gp-2 .btn-apply-now {
  padding: 10px 20px 12px 24px;
  display: inline-block;
  line-height: 1;
  background: #f05139;
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
  color: #fff;
  border-radius: 25px;
}
.n_web .btn-gp-2 .btn-apply-now:hover {
  background: #f08639;
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
}
.n_web .btn-gp .btn-apply-now {
  padding: 10px 35px 12px;
  display: inline-block;
  line-height: 1;
  background-color: #fff;
  color: #f05139;
  border-radius: 25px;
  border: 2px solid #fff;
  margin-left: 50px;
}
.n_web .btn-gp .btn-apply-now:hover {
  background-color: transparent;
  color: #fff;
}
.n_web .main-hop.eco-system-se {
  height: auto;
  min-height: calc(100vh - 70px);
  overflow: inherit;
  padding: 80px 0 80px;
}
.n_web .main-hop .lining-to {
  margin: 20px 0 0 0;
  line-height: 1;
}
.n_web .main-hop .lining-to a,
.n_web .main-hop .lining-to p {
  font-size: 12px;
  letter-spacing: 1.5px;
  color: #f06f39;
}
.n_web .main-hop .lining-to span {
  display: inline-block;
  margin: 0 10px;
  color: #f06f39;
}
.n_web .main-hop.eco-system-se .follow-us-socil .btn-apply-now {
  padding: 15px 35px 17px;
}
.n_web .main-hop.eco-system-se .follow-us-socil .btn-apply-now .ic-rig8-arrow {
  height: 12px;
  width: 12px;
  background-position: -161px -2px;
  top: 2px;
  margin-left: 10px;
}
.n_web .main-hop.eco-system-se .follow-us-socil .line-btn {
  color: #fff;
  padding: 10px 30px 12px;
  border-radius: 25px;
  border: 1px solid #fff;
  line-height: 1;
  margin-left: 20px;
}
.n_web .main-hop.eco-system-se .follow-us-socil .line-btn:hover {
  background-color: #f06f39;
  border-color: #f06f39;
}
.n_web .we-hve-featu {
  padding: 30px 0 0;
}
.n_web .we-hve-featu .iiner-cont {
  padding: 0 70px 30px;
}
.n_web .we-hve-featu .lft-t {
  width: 300px;
}
.n_web .we-hve-featu .lft-t h3 {
  font-size: 28px;
  letter-spacing: 2px;
}
.n_web .we-hve-featu .rt-t {
  width: calc(100% - 320px);
  padding-left: 60px;
}
.n_web .we-hve-featu .rt-t p {
  font-size: 14px;
}
.n_web .advantage-over.part-logo {
  padding: 10px 0;
}
.n_web .part-logo .item .part-lgo-bx {
  min-height: 100px;
}
.n_web .part-logo .item .part-lgo-bx img {
  max-width: 100%;
  width: auto;
  filter: brightness(0) invert(1);
  opacity: 0.9;
}
.n_web .part-logo .logo-box {
  width: calc(100% / 8);
  padding: 0 10px;
  height: 60px;
}
.n_web .part-logo .logo-box img {
  max-width: 100%;
}
.n_web .advantageses-over {
  max-width: 680px;
  width: 100%;
  padding: 60px 0;
}
.n_web .advantageses-over h3 {
  font-size: 24px;
  letter-spacing: 5px;
  line-height: 1.3;
  margin: 0 0 20px;
}
.n_web .advantageses-over p {
  font-size: 14px;
  color: #d8d8d8;
  line-height: 26px;
}
.n_web .four-m-bxes .sm-w-bx {
  width: 50%;
}
.n_web .four-m-bxes .for-rt-p {
  padding-right: 50px;
}
.n_web .four-m-bxes .for-bm-p {
  padding-bottom: 50px;
}
.n_web .four-m-bxes .for-lt-p {
  padding-left: 50px;
}
.n_web .four-m-bxes .for-tp-p {
  padding-top: 50px;
}
.n_web .four-m-bxes .ic-four-i {
  width: 50px;
  height: 50px;
}
.n_web .four-m-bxes .ic-decentralized {
  background-position: -170px 0;
}
.n_web .four-m-bxes .ic-per-to {
  background-position: -220px 0;
}
.n_web .four-m-bxes .ic-true-value {
  background-position: -170px -50px;
}
.n_web .four-m-bxes .ic-multic-wal {
  background-position: -220px -50px;
}
.n_web .four-m-bxes .txt-se {
  width: calc(100% - 50px);
  padding-left: 25px;
}
.n_web .four-m-bxes .txt-se h3 {
  color: #e2e7f8;
  font-size: 18px;
  line-height: 1;
  margin: 0 0 10px;
}
.n_web .four-m-bxes .txt-se p {
  color: #d8d8d8;
  font-size: 14px;
  line-height: 26px;
}
.n_web .line-btne {
  color: #fff;
  padding: 10px 30px 12px;
  border-radius: 25px;
  border: 2px solid #fff;
  line-height: 1;
  margin: 20px 0 0 0;
}
.n_web .line-btne:hover {
  background-color: #f06f39;
  border-color: #f06f39;
  color: #fff;
}
.n_web .for-plus-sign::after,
.n_web .for-plus-sign::before {
  background-color: #282896;
  display: block;
  margin: auto;
  top: 0;
  left: 0;
}
.n_web .for-plus-sign::after {
  height: 100%;
  width: 1px;
  right: 0;
}
.n_web .for-plus-sign::before {
  width: 100%;
  height: 1px;
  bottom: -32px;
}
.n_web .our-technology.on-hmp-man::after {
  background-image: url(/static/media/back_bg.fd52c51f.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  position: absolute;
  content: "";
  height: 400px;
  width: 400px;
  left: 0;
  top: auto;
  bottom: -50px;
  background-color: #111a44;
  z-index: -1;
  background-size: 100%;
  opacity: 0.2;
}
.n_web .advantage-over.we-are-differnent {
  padding: 0;
}
.n_web .we-are-differnent .into-two-part {
  width: 48%;
  padding: 40px 0;
  overflow: hidden;
  min-height: 380px;
}
.n_web .we-are-differnent .lft-tit h3 {
  font-size: 80px;
}
.n_web .we-are-differnent .for-cross-sign::after,
.n_web .we-are-differnent .for-cross-sign::before {
  height: calc(100% + 300px);
  width: 50px;
  background: #f05139;
  background: radial-gradient(circle, #f05139 0, #f05139 47%, #f08639 100%);
  left: 0;
  top: -150px;
  right: 0;
  margin: auto;
  z-index: -1;
}
.n_web .we-are-differnent .for-cross-sign::after {
  transform: rotate(40deg);
}
.n_web .we-are-differnent .for-cross-sign::before {
  transform: rotate(-40deg);
}
.n_web .we-are-differnent .rit-text small {
  font-size: 12px;
  line-height: 1;
  margin: 0 0 5px;
}
.n_web .we-are-differnent .rit-text h2 {
  letter-spacing: 3px;
  font-size: 24px;
  line-height: 1.3;
  margin: 0 0 20px;
}
.n_web .we-are-differnent .rit-text p {
  font-size: 14px;
  line-height: 26px;
  margin: 0 0 30px;
}
.n_web .we-are-differnent .rit-text a {
  line-height: 1;
  border: 2px solid #fff;
  border-radius: 30px;
  line-height: 1;
  padding: 10px 35px 12px;
  display: inline-block;
}
.n_web .we-are-differnent .rit-text a:hover {
  background-color: #fff;
  color: #111a44;
}
.n_web .three-m-s.on-m-hmp::after {
  background-color: #111a44;
  background-image: url(/static/media/full_overlay.5d895464.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-blend-mode: hard-light;
  top: 0;
  left: 0;
  z-index: -1;
}
.n_web .cob-bcm-digital {
  padding: 70px 0;
  background-color: #34adff;
  background-image: -webkit-linear-gradient(180deg, #f2f2f2 50%, #0f1740 50%);
  background-image: gradient(180deg, #f2f2f2 50%, #0f1740 50%);
}
.n_web .cob-bcm-digital .sw-bx {
  width: 48%;
}
.n_web .cob-bcm-digital .sw-bx small {
  font-size: 12px;
}
.n_web .cob-bcm-digital .sw-bx h2 {
  margin: 20px 0;
  letter-spacing: 3px;
  font-size: 22px;
}
.n_web .cob-bcm-digital .sw-bx p {
  font-size: 14px;
  line-height: 26px;
  margin: 0 0 30px;
}
.n_web .cob-bcm-digital .rit-bx {
  padding: 0 0 0 50px;
}
.n_web .cob-bcm-digital .rit-bx small {
  color: #66667e;
}
.n_web .cob-bcm-digital .rit-bx p {
  color: #66667e;
}
.n_web .cob-bcm-digital .rit-bx h2 {
  color: #111a44;
}
.n_web .cob-bcm-digital .lft-bx {
  padding: 0 100px 0 0;
}
.n_web .cob-bcm-digital .lft-bx small {
  color: #d8d8d8;
}
.n_web .cob-bcm-digital .lft-bx p {
  color: #d8d8d8;
}
.n_web .cob-bcm-digital .lft-bx a {
  box-shadow: 0 0 30px #f05139;
}
.n_web .cob-bcm-digital .rit-bx a {
  box-shadow: 0 0 30px rgba(240, 81, 57, 0.5);
}
.n_web .cob-bcm-digital .lft-bx a,
.n_web .cob-bcm-digital .rit-bx a {
  line-height: 1;
  color: #fff;
  border-radius: 30px;
  line-height: 1;
  padding: 10px 35px 12px;
  display: inline-block;
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
}
.n_web .cob-bcm-digital .lft-bx a:hover,
.n_web .cob-bcm-digital .rit-bx a:hover {
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
}
.n_web .comb-se .apply-accelerated.contact-us-bg {
  padding: 50px 0;
}
.n_web .advantage-over.apply-accelerated.contact-us-bg {
  background: #091b3e;
}
.n_web .comb-se .apply-accelerated.contact-us-bg .btn-apply-now {
  padding: 10px 20px 12px 24px;
  display: inline-block;
  line-height: 1;
  background: #f05139;
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
  color: #fff;
  border-radius: 25px;
  border: none;
  letter-spacing: 0;
}
.n_web .comb-se .apply-accelerated.contact-us-bg .btn-apply-now:hover {
  background: #f08639;
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
}
.n_web .comb-se .apply-accelerated.contact-us-bg .learn-more {
  border: 2px solid #fff;
  color: #fff;
  line-height: 1;
  border-radius: 20px;
  padding: 8px 30px 10px;
  font-size: 12px;
  display: inline-block;
}
.n_web .comb-se .apply-accelerated.contact-us-bg .learn-more:hover {
  background-color: #f05139;
  border-color: #f05139;
}
.n_web .comb-se .card {
  background-color: transparent;
  border: none;
}
.n_web .comb-se .card-body {
  padding: 0;
}
.n_web .comb-se .card-header .btn {
  padding: 0;
}
.n_web .comb-se .card-header {
  background-color: transparent;
  border: none;
}
.n_web .comb-se .x-tock.active-acc .blue-lg {
  display: none;
}
.n_web .comb-se .x-tock.active-acc .white-lg {
  display: block;
}
.n_web .comb-se .equity-tocke {
  margin-left: 45px;
}
.n_web .comb-se .btn-apply-now {
  padding: 10px 20px 12px 24px;
  line-height: 1;
  background-color: transparent;
  color: #fff;
  border-radius: 25px;
  border: 1px solid #fff;
}
.n_web .comb-se .btn-apply-now:hover {
  background: #f08639;
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
  border-color: #f08639;
}
.n_web .comb-se .btn-apply-now .ic-rig8-arrow {
  height: 12px;
  width: 12px;
  background-position: -161px -2px;
  top: 2px;
  margin-left: 10px;
}
.n_web .morinfo-ab-coint .sw-p #edge-x-token-info {
  width: 100%;
}
.n_web .morinfo-ab-coint .bellow-text-part p {
  width: 100%;
  margin: 0;
}
.n_web .morinfo-ab-coint .bellow-text-part p + p {
  margin-top: 20px;
}
.n_web .morinfo-ab-coint .bellow-text-part .t-svg-img {
  width: 75px;
}
.n_web .morinfo-ab-coint .bellow-text-part .txt-p {
  width: calc(100% - 75px);
  padding-left: 10px;
}
.n_web .comb-se .current-scenario .current-se-box {
  padding: 0;
}
.n_web .current-indus-solu {
  padding: 40px 0;
  overflow: hidden;
}
.n_web .current-indus-solu::after {
  background-color: #111a44;
  background-image: url(/static/media/full_overlay.5d895464.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-size: auto;
  background-blend-mode: color-dodge;
  top: 0;
  left: 0;
  z-index: -1;
}
.n_web .current-indus-solu::before {
  background-image: url(/static/media/back_bg.fd52c51f.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  position: absolute;
  height: 270px;
  width: 300px;
  left: 0;
  top: auto;
  bottom: 0;
  background-blend-mode: soft-light;
  background-color: #111a44;
  background-size: 100%;
}
.n_web .current-indus-solu h2 {
  font-size: 24px;
  letter-spacing: 5px;
  line-height: 1.5;
  margin: 0 0 30px;
}
.n_web .current-indus-solu .inner-tx {
  max-width: 700px;
  width: 100%;
  margin: 0 0 40px;
}
.n_web .current-indus-solu .inner-tx p {
  color: #d8d8d8;
  font-size: 14px;
  line-height: 26px;
}
.n_web .current-indus-solu .com-thn {
  letter-spacing: 3px;
  font-size: 12px;
}
.n_web .current-indus-solu .comparetable-table tbody tr td.bnone {
  border: none;
}
.n_web .current-indus-solu .comparetable-table tbody tr th {
  line-height: 2;
  padding: 5px;
}
.n_web .current-indus-solu .comparetable-table tbody tr th.for-widht {
  width: 220px;
}
.n_web .current-indus-solu .comparetable-table tbody tr td {
  padding: 15px 15px 18px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
}
.n_web .current-indus-solu .comparetable-table tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.15);
}
.n_web .current-indus-solu .comparetable-table tbody tr.bg-none {
  background-color: transparent;
}
.n_web .current-indus-solu .comparetable-table tbody tr td.g-bg-col {
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
}
.n_web .current-indus-solu .card {
  background-color: rgba(0, 0, 0, 0.2);
}
.n_web .current-indus-solu .card-header {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
.n_web .only-img-sec {
  background-image: url(/static/media/only_img.2f7ae0cb.png);
  background-color: #111a44;
  background-blend-mode: lighten;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 720px;
  padding: 80px 0 0 0;
}
.n_web .only-img-sec .white-bar::after {
  position: absolute;
  content: "";
  top: auto;
  bottom: 0;
  height: 60px;
  max-width: 600px;
  background-color: #fff;
  width: 100%;
}
.n_web .current-scenario.cust-part {
  padding: 60px 0;
}
.n_web .current-scenario .current-se-box .left-se {
  width: 400px;
}
.n_web .current-scenario .current-se-box .right-se {
  width: calc(100% - 400px);
  padding-left: 30px;
}
.n_web .dot-img::after {
  background: linear-gradient(90deg, #111a44 0, #010726 100%);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  background-blend-mode: soft-light;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.n_web .current-scenario .current-se-box p {
  color: #66667e;
  font-size: 10px;
  margin: 0 0 15px;
  letter-spacing: 2px;
}
.n_web .current-scenario .current-se-box h2 {
  color: #111a44;
  font-size: 22px;
  letter-spacing: 5px;
}
.n_web .current-scenario .current-se-box .inner-contain p {
  margin: 0;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0;
}
.n_web .current-scenario.advantage-over {
  padding: 0;
}
.n_web .current-scenario.advantage-over .same-box h3 {
  margin: 0 0 10px;
  font-size: 22px;
}
.n_web .current-scenario.advantage-over .same-box p {
  color: #fff;
}
.n_web .current-scenario .three-points .same-box {
  padding: 60px 50px;
  width: 33%;
}
.n_web .current-scenario .same-box .ic-se {
  height: 70px;
  width: 70px;
  display: inline-block;
  margin: 0 0 25px;
}
.n_web .current-scenario .same-box .ic-money {
  background-position: -305px 0;
}
.n_web .current-scenario .same-box h3 {
  margin: 0 0 20px;
  font-size: 24px;
}
.n_web .current-scenario .same-box p {
  color: #d8d8d8;
  font-size: 14px;
  line-height: 26px;
}
.n_web .advantage-over {
  padding: 60px 0;
  z-index: 1;
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
}
.n_web .advantage-over .btn-g {
  margin-top: 20px;
}
.n_web .advantage-over .btn-g a {
  line-height: 1;
  border: 2px solid #fff;
  border-radius: 30px;
  line-height: 1;
  padding: 10px 35px 12px;
  display: inline-block;
}
.n_web .advantage-over .btn-g a:hover {
  background-color: #fff;
  color: #111a44;
}
.n_web .over-lay-05::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: linear-gradient(180deg, #f08639 0, #f05139 100%),
    url(/static/media/overlay_05.d9070c91.png);
  background-blend-mode: lighten;
  background-size: cover;
  background-repeat: no-repeat;
}
.n_web .advantage-over::after {
  content: "";
  height: 100%;
  width: 100%;
  max-width: 900px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: linear-gradient(180deg, #f08639 0, #f05139 100%),
    url(/static/media/back_bg.fd52c51f.png);
  background-blend-mode: overlay;
  background-size: auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
.n_web .advantage-over.becom-our::after {
  display: none;
}
.n_web .advantage-over.becom-our .inner-text {
  max-width: 100%;
}
.n_web .btn-job-opning:hover {
  background-color: #fff;
  color: #f05139;
}
.n_web .advantage-over.becom-our .inner-text p {
  width: calc(100% - 150px);
}
.n_web .advantage-over .s-w {
  width: 48%;
}
.n_web .advantage-over .inner-text {
  max-width: 740px;
  width: 100%;
}
.n_web .advantage-over .inner-text.w-1 {
  max-width: 100%;
}
.n_web .advantage-over .inner-text h2 {
  margin: 0 0 30px;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 4px;
}
.n_web .advantage-over .inner-text p {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1.5px;
}
.n_web .advantage-over .inner-text p + p {
  margin-top: 20px;
}
.n_web .our-technology.on-hmp-man {
  padding: 0 0 80px;
}
.n_web .our-technology {
  padding: 0;
}
.n_web .our-technology .sm-w {
  width: 48%;
}
.n_web .our-technology .img-p img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
.our-technology .for-bg::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  max-width: 580px;
  background-image: url(/static/media/back_bg.fd52c51f.png);
  background-color: #111a44;
  background-size: 100%;
  z-index: -1;
  top: 0;
  opacity: 0.2;
}
.n_web .our-technology .contain-p small {
  color: #d8d8d8;
  font-size: 10px;
  letter-spacing: 1px;
}
.n_web .our-technology .contain-p h2 {
  font-size: 21px;
  line-height: 28px;
  margin: 20px 0 30px;
  letter-spacing: 4px;
}
.n_web .our-technology .contain-p p {
  color: #d8d8d8;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1.3px;
}
.n_web .our-technology .contain-p .line-btn {
  color: #fff;
  padding: 10px 30px 12px;
  border-radius: 25px;
  border: 1px solid #fff;
  line-height: 1;
  margin: 20px 0 0 0;
}
.n_web .our-technology .contain-p .line-btn:hover {
  background-color: #f06f39;
  border-color: #f06f39;
}
.n_web .eco-system-se.our-team.who-bg::before {
  background: 0 0;
}
.n_web .eco-system-se.asia-blc::after,
.n_web .eco-system-se.who-bg::after {
  display: none;
}
.n_web .eco-system-se.asia-blc::before,
.n_web .eco-system-se.who-bg::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: url(/static/media/asia_hero.8fa3bff7.jpg) 0 0 no-repeat;
  background-size: cover;
  top: 0;
  z-index: -1;
}
.n_web .eco-system-se.our-so-new-pg .some-str-change {
  left: auto;
  right: 0;
}
.n_web .eco-system-se.our-so-new-pg .some-str-change .text-contain {
  padding: 0 70px 0 0;
}
.n_web .eco-system-se.our-so-new-pg {
  height: 700px;
  padding: 50px 0;
}
.n_web .eco-system-se.our-so-new-pg::before {
  width: 100%;
  background: url(/static/media/our_solution_new.a2d651a9.jpg) 0 0 no-repeat;
  background-size: cover;
  top: 0;
  z-index: -1;
  display: block;
  animation: none;
  background-position: center center;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.n_web .eco-system-se.events-bg::before {
  height: 100%;
  width: 100%;
  background: url(/static/media/event_bg.f0a383da.jpg) 0 0 no-repeat;
  background-size: cover;
  top: 0;
  z-index: -1;
}
.n_web .eco-system-se.asia-blc .main-title h2 {
  letter-spacing: normal;
  font-size: 22px;
  line-height: 28px;
  margin-left: 20px;
}
.n_web .eco-system-se.asia-blc .main-title h1 {
  font-size: 120px;
  line-height: 1;
}
.n_web .eco-system-se .follow-us-socil {
  margin: 30px 0 0;
}
.n_web .eco-system-se .follow-us-socil .btn-apply-now {
  padding: 14px 45px;
  display: inline-block;
  line-height: 1;
  background: #f05139;
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
  color: #fff;
  border-radius: 25px;
}
.n_web .eco-system-se .follow-us-socil .btn-apply-now:hover {
  background: #f08639;
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
}
.n_web .eco-system-se .follow-us-socil .follow {
  margin-left: 30px;
}
.n_web .eco-system-se .follow-us-socil .follow p {
  margin-bottom: 5px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-so {
  height: 16px;
  width: 16px;
  display: inline-block;
}
.n_web .eco-system-se .follow-us-socil .social .ic-so + .ic-so {
  margin-left: 4px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-link {
  background-position: -124px -34px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-link:hover {
  background-position: -124px -17px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-fb {
  background-position: -56px -34px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-fb:hover {
  background-position: -56px -17px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-tw {
  background-position: -73px -34px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-tw:hover {
  background-position: -73px -17px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-inst {
  background-position: -90px -34px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-inst:hover {
  background-position: -90px -17px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-you {
  background-position: -107px -34px;
}
.n_web .eco-system-se .follow-us-socil .social .ic-you:hover {
  background-position: -107px -17px;
}
.n_web .three-m-s {
  padding: 60px 0;
  overflow: hidden;
}
.n_web .three-m-s .inn-bx {
  width: 100%;
  max-width: 500px;
}
.n_web .three-m-s.in-who-we-are .inn-bx {
  max-width: 750px;
}
.n_web .three-m-s .inn-bx small {
  font-size: 11px;
  letter-spacing: 1px;
  color: #66667e;
  line-height: 1;
}
.n_web .three-m-s .inn-bx h3 {
  font-size: 24px;
  letter-spacing: 5px;
  line-height: 1.5;
  margin: 0 0 30px;
}
.n_web .three-m-s .inn-bx p {
  color: #66667e;
  font-size: 14px;
  line-height: 26px;
}
.n_web .three-m-s .inn-bx p + p {
  margin-top: 20px;
}
.n_web .advantage-over.rules-who-aply::after {
  display: none;
}
.n_web .advantage-over.rules-who-aply {
  padding: 65px 0;
}
.n_web .advantage-over.rules-who-aply .into-two {
  width: 48%;
  padding: 0 50px 0 0;
  position: relative;
}
.n_web .advantage-over.rules-who-aply .into-two::after {
  left: auto;
  right: 0;
  content: "";
  height: calc(100% + 130px);
  top: -65px;
  width: 1px;
  background-color: #fff;
  position: absolute;
}
.n_web .advantage-over.rules-who-aply .into-two + .into-two::after {
  display: none;
}
.n_web .advantage-over.rules-who-aply .into-two h4 {
  font-size: 16px;
  letter-spacing: 3px;
  margin: 0 0 25px 0;
}
.n_web .advantage-over.rules-who-aply .into-two ul li {
  font-size: 12px;
  line-height: 1.3;
  position: relative;
  padding: 0 0 0 15px;
}
.n_web .advantage-over.rules-who-aply .into-two ul li::after {
  position: absolute;
  content: "";
  height: 6px;
  width: 6px;
  left: 0;
  top: 6px;
  border-radius: 50%;
  background-color: #fff;
}
.n_web .advantage-over.rules-who-aply .into-two ul li + li {
  margin-top: 15px;
}
.n_web .apply-accelerated.contact-us-bg {
  padding: 60px 0 60px;
}
.n_web .apply-accelerated.contact-us-bg .form-section {
  margin: 30px 0 0 0;
  padding: 0;
}
.n_web .apply-accelerated {
  padding: 30px 0;
}
.n_web .apply-accelerated::after {
  background-image: url(/static/media/overlay_03.c8e12cb7.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 664px;
  height: 710px;
  background-size: 100%;
  top: auto;
  bottom: -80px;
  left: -250px;
}
.n_web .apply-accelerated::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: url(/static/media/asia_bottom.da28463c.jpg) 0 0 no-repeat;
  background-size: cover;
  top: 0;
  z-index: -1;
}
.n_web .apply-accelerated.ambass-bg::before {
  background: url(/static/media/test2.ab14e569.jpg) 0 0 no-repeat;
  background-size: cover;
}
.n_web .apply-accelerated .left-title {
  width: 320px;
}
.n_web .apply-accelerated .left-title small {
  color: #d8d8d8;
}
.n_web .apply-accelerated .form-section {
  width: calc(100% - 320px);
  padding: 0 0 0 40px;
}
.n_web .apply-accelerated .awd-txt-im {
  margin: 15px 0;
}
.n_web .apply-accelerated .awd-txt-im .awd-img {
  width: 70px;
  height: 61px;
}
.n_web .apply-accelerated .awd-txt-im .awd-img img {
  max-width: 100%;
}
.n_web .apply-accelerated .awd-txt-im h4 {
  letter-spacing: 3px;
  font-size: 22px;
}
.n_web .apply-accelerated .left-title p {
  color: #d8d8d8;
  font-size: 14px;
  line-height: 26px;
}
.n_web .apply-accelerated .form-section .helf-width {
  width: 49%;
}
.n_web .apply-accelerated .form-section .full-width {
  width: 100%;
}
.n_web .apply-accelerated .form-section .top-s + .top-s {
  margin-top: 50px;
}
.n_web .apply-accelerated .form-section .float-label-field {
  border: none;
  outline: 0;
  position: relative;
  margin: 0 0 20px 0;
  padding: 0;
  box-sizing: border-box;
}
.n_web .apply-accelerated .form-section .float-label-field input {
  border: none;
  outline: 0;
  padding: 15px 5px 15px 0;
  width: 100%;
  font-size: 14px;
  border-bottom: solid 1px #66667e;
  background-color: transparent;
  color: #fff;
  font-family: muliregular;
}
.n_web .apply-accelerated .form-section .float-label-field label {
  position: absolute;
  top: 10px;
  left: 0;
  transition: all 0.2s ease;
  font-size: 14px;
  color: #8e8ea5;
}
.n_web .apply-accelerated .form-section .float-label-field.focus label {
  color: #f05139;
}
.n_web .apply-accelerated .form-section .float-label-field.focus input {
  border-bottom: solid 1px #f05139;
}
.n_web .apply-accelerated .form-section .float-label-field.float label {
  opacity: 1;
  top: -8px;
  font-size: 80%;
  transition: all 0.2s ease;
}
.n_web .apply-accelerated .form-section .float-label-field textarea {
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #66667e;
  resize: none;
  padding: 15px 5px 15px 0;
  width: 100%;
  font-size: 15px;
  color: #fff;
}
.n_web .apply-accelerated .form-section .float-label-field textarea:focus {
  border-bottom: solid 1px #f05139;
}
.n_web .apply-accelerated .form-section .file-upload {
  position: relative;
  color: #fff;
  font-size: 0;
  white-space: nowrap;
}
.n_web .apply-accelerated .form-section .file-upload .file-name {
  width: calc(100% - 190px);
  background-color: transparent;
  border-bottom: 1px solid #66667e;
  font-size: 14px;
  color: #8e8ea5;
  padding: 15px 5px 15px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.n_web .apply-accelerated .form-section .file-upload .customUpload-btn {
  padding: 10px 45px;
  border-radius: 40px;
  border: 1px solid #fff;
  font-size: 12px;
}
.n_web
  .apply-accelerated
  .form-section
  .file-upload
  .upload-btn:hover
  + .customUpload-btn {
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
  border-color: #f05139;
}
.n_web .apply-accelerated .form-section .file-upload .upload-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  height: 100%;
  text-align: center;
  z-index: 2;
}
.n_web .apply-accelerated .form-section .tems-policy a {
  font-size: 10px;
  color: #d8d8d8;
}
.n_web .apply-accelerated .form-section .tems-policy a:hover {
  color: #f05139;
}
.n_web .apply-accelerated .form-section .tems-policy span {
  margin: 0 5px;
}
.n_web .apply-accelerated .form-section .float-label-field .chek-box label {
  position: relative;
  margin: 0;
  top: 0;
  color: #e5e5e5;
  font-size: 14px;
}
.n_web .apply-accelerated .form-section .form-group {
  display: block;
  margin-bottom: 15px;
}
.n_web .apply-accelerated .form-section .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.n_web .apply-accelerated .form-section .form-group label {
  position: relative;
  cursor: pointer;
}
.n_web .apply-accelerated .form-section .form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #66667e;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}
.n_web
  .apply-accelerated
  .form-section
  .form-group
  input:checked
  + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.n_web .apply-accelerated .btn-action {
  margin-bottom: 50px;
}
.n_web .apply-accelerated .btn-action .btn {
  border-radius: 40px;
  line-height: 1;
  font-size: 14px;
  padding: 12px 40px;
  color: #fff;
  border-width: 1px;
  border-style: solid;
}
.n_web .apply-accelerated .btn-action .btn + .btn {
  margin-left: 30px;
}
.n_web .apply-accelerated .btn-action .btn-submit {
  background: #f05139;
  background: linear-gradient(90deg, #f05139 0, #f08639 100%);
  border-color: transparent;
}
.n_web .apply-accelerated .btn-action .btn-submit:hover {
  background: #f08639;
  background: linear-gradient(90deg, #f08639 0, #f05139 100%);
}
.n_web .apply-accelerated .btn-action .btn-reset {
  border-color: #fff;
}
.n_web .apply-accelerated .btn-action .btn-reset:hover {
  background: #f05139;
  background: linear-gradient(90deg, #f05139 0, #f08639 100%);
  border-color: transparent;
}
.n_web .apply-accelerated .form-section ::-webkit-input-placeholder {
  font-size: 14px;
  color: #8e8ea5;
}
.n_web .apply-accelerated .form-section ::-moz-placeholder {
  font-size: 14px;
  color: #8e8ea5;
}
.n_web .apply-accelerated .form-section :-ms-input-placeholder {
  font-size: 14px;
  color: #8e8ea5;
}
.n_web .apply-accelerated .form-section :-moz-placeholder {
  font-size: 14px;
  color: #8e8ea5;
}
.n_web .main-team-section::after {
  position: absolute;
  content: "";
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 8px;
  z-index: 1 !important;
  height: 100%;
  width: calc(100% - 130px);
}
.n_web .min-tm-container {
  margin-left: -15px;
  margin-right: -15px;
}
.n_web .one-row + .one-row {
  margin-top: 60px;
}
.n_web .team-m-box {
  width: calc((100% / 6) - 30px);
  position: relative;
  margin: 25px 15px;
}
.n_web .team-m-box .team-photo {
  margin: 0 0 30px;
}
.n_web .team-m-box .team-photo img {
  width: 155px;
  height: 155px;
  border-radius: 100%;
  overflow: hidden;
  border: 1px solid #111a44;
  display: block;
  margin: 0 auto;
}
.n_web .team-m-box .team-photo2 {
  margin: 0 0 30px;
}
.n_web .team-m-box .team-photo2 img {
  width: 140px;
  height: 155px;
  border-radius: 100%;
  overflow: hidden;
  border: 1px solid #111a44;
  display: block;
  margin: 0 auto;
}
.n_web .team-m-box .team-m-name {
  background-color: #0f1740;
  width: 130px;
  margin: 0 auto;
  left: 0;
  bottom: -20px;
  top: auto;
  right: 0;
  line-height: 1;
  padding: 8px 10px;
  border-radius: 50px;
}
.n_web .team-m-box .team-m-name h2 {
  font-size: 11px;
  color: #fff;
  line-height: 1;
}
.n_web .team-m-box .team-m-deg h3 {
  font-size: 11px;
  color: #404040;
}
.n_web .team-m-box .team-m-deg h4 {
  font-size: 12px;
  color: #66667e;
}
.n_web .team-m-box .team-m-deg .intex {
  margin-right: 10px;
  width: calc(100% - 30px);
}
.n_web .team-m-box .team-m-deg .ic-link {
  height: 15px;
  width: 16px;
  background-position: -125px 0;
  display: inline-block;
}
.n_web .team-m-box .team-m-deg .ic-link:hover {
  background-position: -143px 0;
}
.n_web .main-team-section {
  margin: 60px 0 0 0;
}
.n_web .main-team-section .tab-content {
  padding: 0;
  margin: 0 0 30px;
}
.n_web .main-team-section .card-body {
  padding: 0;
}
.n_web .main-team-section .card {
  border: none;
  padding: 0;
  border-radius: 0;
}
.n_web .main-team-section .nav-tabs {
  height: auto;
  padding: 0;
  position: relative;
  border: none;
}
.n_web .main-team-section .nav-tabs .nav-item {
  margin: 0;
}
.n_web .main-team-section .nav-tabs .nav-item + .nav-item {
  margin-left: 15px;
}
.n_web .main-team-section .nav-tabs .nav-item a {
  color: #fff;
  padding: 10px 5px;
  min-width: 150px;
  text-align: center;
  border-radius: 10px 10px 0 0;
  border: none;
  background: #f05139;
  background: linear-gradient(180deg, #f05139 40%, #f08639 100%);
  font-size: 10px;
  letter-spacing: 1px;
}
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab {
  background-color: #fff;
  display: none;
  position: absolute;
  right: 0;
  width: 150px;
  z-index: 1;
}
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab-action a {
  min-width: inherit;
  padding-left: 15px;
  padding-right: 15px;
}
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab a {
  border: 1px solid #d3d3d3;
  border-radius: 0;
  padding: 6px 10px;
}
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab a.active,
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab a:active,
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab a:focus,
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab a:hover {
  background-color: #d3d3d3;
}
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab:last-child {
  border-radius: 0 0 0 4px;
}
.n_web .main-team-section .nav-tabs .nav-item.overflow-tab-action {
  position: absolute;
  right: 0;
}
.n_web .btn-job-opning {
  border-radius: 50px;
  border: 1px solid #fff;
  line-height: 1;
  padding: 9px 20px 11px;
  margin-left: 10px;
}
.n_web .for-same-bg::after {
  background: linear-gradient(90deg, #111a44 0, #010726 100%);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  left: 0;
  top: 0;
  height: 110%;
  width: 100%;
  z-index: -1;
  background-blend-mode: difference;
}
.n_web .list-of-con-map-img {
  padding: 50px 0;
}
.n_web .list-of-con-map-img::after {
  background: linear-gradient(90deg, #111a44 0, #010726 100%);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  background-blend-mode: soft-light;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.n_web .list-of-con-map-img .world-map img {
  width: 100%;
}
.n_web .list-of-con-map-img .partners-c-type p {
  margin-left: -5px;
  margin-right: -5px;
}
.n_web .list-of-con-map-img .partners-c-type h4 {
  color: #f05139;
  font-size: 15px;
  margin: 0 0 10px 0;
}
.n_web .list-of-con-map-img .partners-c-type p {
  padding: 8px 5px;
}
.n_web .list-of-con-map-img .type-of-partners {
  margin: 40px 0 0;
}
.n_web .list-of-con-map-img .type-of-partners p {
  width: calc(100% / 4);
}
.n_web .list-of-con-map-img .partners-c-type span {
  color: #d8d8d8;
  font-size: 14px;
}
.n_web .list-of-con-map-img .partners-c-type p:hover span {
  color: #f05139;
}
.n_web .list-of-con-map-img .all-country {
  margin: 70px 0 0 0;
}
.n_web .list-of-con-map-img .all-country p {
  width: calc(100% / 8);
}
.n_web .list-of-parters-s {
  padding: 30px 0;
  background: linear-gradient(90deg, #111a44 0, #010726 100%);
}
.n_web .list-of-parters-s .logo-box {
  margin: 30px 0;
}
.n_web .list-of-parters-s .logo-box img {
  max-width: 100%;
  width: auto;
  filter: brightness(0) invert(1);
  opacity: 0.9;
}
.n_web .list-of-parters-s.be-orignal .logo-box img {
  filter: none;
}
.n_web .partner-multi-logo {
  padding: 60px 0;
}
.n_web .become-p-bl-div {
  padding: 40px 0;
  display: block;
}
.n_web .partner-multi-logo h2 {
  font-size: 24px;
  letter-spacing: 5px;
  line-height: 1.5;
  color: #0f1740;
}
.n_web .partner-multi-logo .part-lgo-bx {
  width: calc(20% - 30px);
  min-height: 120px;
  margin: 0 15px 15px;
  text-align: center;
}
.n_web .partner-multi-logo .part-lgo-bx a img {
  max-width: 80%;
  width: auto;
  filter: brightness(0) invert(1);
  opacity: 1;
}
.n_web .partner-multi-logo .part-lgo-bx.og-lg img {
  filter: inherit;
}
.n_web .partner-multi-logo .part-lgo-bx a:hover,
.n_web .partner-multi-logo .part-lgo-bx a:hover img {
  opacity: 1;
}
.n_web .partner-multi-logo.be-or-lg .part-lgo-bx a img {
  filter: none;
}
.n_web .faqs-main-que {
  padding: 0 0 60px;
}
.n_web .faqs-main-que .card {
  border: 0;
  margin: 15px 0;
  display: block;
  background-color: transparent;
}
.n_web .faqs-main-que .card .card-body p + p {
  margin-top: 15px;
}
.n_web .faqs-main-que .card .card-body p {
  line-height: 26px;
}
.n_web .faqs-main-que .card .card-body p a {
  color: #f05139;
}
.n_web .faqs-main-que .card .card-body p a:hover {
  text-decoration: underline;
}
.n_web .faqs-main-que .card .card-header {
  border: 0;
  box-shadow: none;
  border-radius: 2px;
  padding: 0;
}
.n_web .faqs-main-que .card .card-header .btn-header-link {
  border-radius: 12px 12px 0 0;
  border: none;
  color: #fff;
  display: block;
  text-align: left;
  background-color: #111a44;
  padding: 20px 80px 20px 20px;
  font-size: 14px;
}
.n_web .faqs-main-que .card .card-header .btn-header-link:after {
  height: 100%;
  width: 70px;
  background-position: 0 -100px;
  background-color: transparent;
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.n_web .faqs-main-que .card .card-header .btn-header-link.collapsed {
  border-radius: 12px;
  background: #f08639;
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
  color: #fff;
}
.n_web .faqs-main-que .card .card-header .btn-header-link.collapsed:after {
  background-position: -70px -100px;
  background: rgba(0, 0, 0, 0.2) url(/static/media/plus-sign.b7ea6135.svg) no-repeat center
    center;
}
.n_web .faqs-main-que .card .collapsing {
  background-color: #111a44;
  line-height: 30px;
  color: #d8d8d8;
}
.n_web .faqs-main-que .card .collapse {
  border: 0;
}
.n_web .faqs-main-que .card .collapse.show {
  background-color: #111a44;
  border-radius: 0 0 12px 12px;
  line-height: 30px;
  color: #d8d8d8;
}
.n_web .title-brd {
  padding: 30px 0 0;
}
.n_web .title-brd .breadcrumbs-div {
  margin-bottom: 10px;
}
.n_web .title-brd .breadcrumbs-div li,
.n_web .title-brd .breadcrumbs-div li a {
  color: #66667e;
}
.n_web .gp-tpus {
  padding: 10px 0 50px;
}
.n_web .gp-tpus::after {
  top: auto;
  bottom: 0;
  width: 100%;
  height: 8px;
  background-color: #111a44;
}
.n_web .gp-tpus .block + .block {
  margin-top: 30px;
}
.n_web .gp-tpus .block h2 {
  font-size: 22px;
  line-height: 28px;
  margin: 0 0 10px;
  letter-spacing: 4px;
  color: #0f1740;
}
.n_web .gp-tpus .block p {
  color: #66667e;
  font-size: 14px;
  line-height: 20px;
}
.n_web .gp-tpus .block ul {
  margin: 10px 0 0;
  color: #66667e;
  font-size: 14px;
  line-height: 22px;
}
.n_web .gp-tpus .block a {
  color: #f05139;
}
.n_web .gp-tpus .block a:hover {
  text-decoration: underline;
}
.n_web .gp-tpus .block ul li + li {
  margin-top: 10px;
}
.n_web .gp-tpus .block ul li span + span {
  margin-top: 10px;
}
.n_web .gp-tpus .block .wi-dots li {
  padding: 0 0 0 25px;
}
.n_web .gp-tpus .block .wi-dots li + li {
  margin-top: 5px;
}
.n_web .gp-tpus .block .wi-dots li::after {
  height: 10px;
  width: 10px;
  left: 0;
  top: 9px;
  border-radius: 100%;
  background: linear-gradient(180deg, #f05139 0, #f08639 100%);
}
.n_web .gp-tpus .block .step-inner {
  margin: 10px 0;
}
.n_web .gp-tpus .block .step-inner li::after {
  background: 0 0;
  border: 1px solid #f05139;
}
.n_web .gp-tpus .block .step-inner li + li {
  margin-top: 5px;
}
.n_web .gp-tpus .block .inner-ul {
  padding: 0 0 0 30px;
}
.n_web footer {
  /* padding: 40px 0 20px; */
  background-color: #fcfcfc;
  margin-bottom: 15px;
  z-index: 4;
}
.n_web footer .f-notes {
  font-size: 13px;
  width: 100%;
  max-width: 770px;
  font-family: mulibold;
  color: #fff;
  margin: 0 auto;
  position: relative;
  padding: 15px 0;
}
.n_web footer .f-notes::after {
  content: "";
  position: absolute;
  height: 100%;
  width: calc(100% + 1000%);
  left: -500%;
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
  top: 0;
  right: 0;
  z-index: -1;
}
.n_web footer .about-edge {
  width: 280px;
}
.n_web footer .about-edge .footer-logo {
  margin: 0 0 15px 0;
}
.n_web footer .about-edge h3 {
  color: #404040;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 15px;
}
.n_web footer .about-edge p {
  color: #66667e;
  font-size: 14px;
  line-height: 26px;
}
.n_web footer .footer-nav-social .navi-g {
  width: 200px;
  padding: 0 35px;
}
.n_web footer .footer-nav-social .mail-links {
  padding: 0 35px;
  width: 200px;
}
.n_web footer .footer-nav-social .mail-links::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  background-color: #f0f0f0;
  top: 0;
}
.n_web footer .footer-nav-social .mail-links::before {
  left: auto;
  right: 0;
}
.n_web footer .footer-nav-social .quick-link p {
  color: #404040;
  margin: 0 0 4px;
}
.n_web footer .footer-nav-social .quick-link ul li {
  padding: 4px 0;
}
.n_web footer .footer-nav-social .quick-link ul li a {
  color: #404040;
  font-size: 14px;
}
.n_web footer .footer-nav-social .quick-link p a:hover {
  color: #ea3318;
}
.n_web footer .footer-nav-social .quick-link ul li a:hover {
  color: #ea3318;
}
.n_web footer .footer-nav-social .last-s {
  padding: 0 0 0 35px;
  width: 240px;
}
.n_web footer .footer-nav-social .last-s .tit p {
  color: #404040;
  margin: 0 0 4px;
}
.n_web footer .footer-nav-social .last-s .tit + .tit {
  margin-top: 10px;
}
.n_web footer .footer-nav-social .last-s .social {
  margin-left: -5px;
  margin-right: -5px;
  padding-right: 35px;
}
.n_web footer .footer-nav-social .last-s .social .ic-so {
  height: 24px;
  width: 24px;
  display: inline-block;
  margin: 1px 7px;
  opacity: 0.8;
}
.n_web footer .footer-nav-social .last-s .social .ic-so:hover {
  opacity: 1;
}
.n_web footer .footer-nav-social .last-s .social .ic-fb {
  background-position: -303px -118px;
}
.n_web footer .footer-nav-social .last-s .social .ic-fb:hover {
  background-position: -303px -142px;
}
.n_web footer .footer-nav-social .last-s .social .ic-tw {
  background-position: -327px -118px;
}
.n_web footer .footer-nav-social .last-s .social .ic-tw:hover {
  background-position: -327px -142px;
}
.n_web footer .footer-nav-social .last-s .social .ic-inst {
  background-position: -351px -118px;
}
.n_web footer .footer-nav-social .last-s .social .ic-inst:hover {
  background-position: -351px -142px;
}
.n_web footer .footer-nav-social .last-s .social .ic-you {
  background-position: -279px -70px;
}
.n_web footer .footer-nav-social .last-s .social .ic-you:hover {
  background-position: -279px -94px;
}
.n_web footer .footer-nav-social .last-s .social .ic-te {
  background-position: -327px -70px;
}
.n_web footer .footer-nav-social .last-s .social .ic-te:hover {
  background-position: -327px -94px;
}
.n_web footer .footer-nav-social .last-s .social .ic-lik {
  background-position: -303px -70px;
}
.n_web footer .footer-nav-social .last-s .social .ic-lik:hover {
  background-position: -303px -94px;
}
.n_web footer .footer-nav-social .last-s .social .ic-whap {
  background-position: -351px -70px;
}
.n_web footer .footer-nav-social .last-s .social .ic-whap:hover {
  background-position: -351px -94px;
}
.n_web footer .footer-nav-social .last-s .social .ic-medium {
  background-position: -279px -118px;
}
.n_web footer .footer-nav-social .last-s .social .ic-medium:hover {
  background-position: -279px -142px;
}
.n_web footer .footer-nav-social .last-s .cont-us a {
  color: #404040;
}
.n_web footer .footer-nav-social .last-s .cont-us a:hover {
  color: #ea3318;
}
.n_web footer .copy-right {
  margin: 25px 0 0 0;
}
.n_web footer .copy-right p {
  font-size: 10px;
  color: #66667e;
}
.n_web footer .copy-right .some-links a {
  margin: 0 10px;
  font-size: 10px;
}
.n_web footer .copy-right .some-links a:hover {
  color: #ea3318;
}
@media screen and (min-width: 1551px) {
  .n_web .three-m-s .inn-bx h3 {
    font-size: 35px;
  }
  .n_web .asia-blc .main-title h1,
  .n_web .main-title h1,
  .n_web .main-title-s h1 {
    font-size: 150px;
  }
  .n_web .main-title-s h2 {
    font-size: 30px;
    letter-spacing: 40px;
  }
  .n_web .our-so-new-pg.ad .text-contain {
    max-width: 500px;
  }
  .n_web .our-so-new-pg.ad .main-title-s h1 {
    font-size: 90px;
  }
  .n_web .our-so-new-pg.ad .main-title-s h2 {
    font-size: 15px;
    letter-spacing: 25px;
  }
  .n_web .our-so-new-pg.ad .text-contain p {
    font-size: 20px;
    line-height: 28px;
  }
  .n_web .eco-system-se.our-so-new-pg .some-str-change .text-contain {
    padding: 0;
  }
  .n_web .eco-system-se.our-so-new-pg {
    height: 750px;
  }
  .n_web .text-contain p {
    font-size: 22px;
    line-height: 35px;
  }
  .n_web .main-hop .lining-to a {
    font-size: 18px;
  }
  .n_web .main-hop.eco-system-se .follow-us-socil .btn-apply-now {
    padding: 21px 60px 23px;
    font-size: 20px;
    border-radius: 60px;
    top: 5px;
  }
  .n_web .main-hop.eco-system-se .follow-us-socil .line-btn {
    padding: 21px 60px 23px;
    border-radius: 60px;
    font-size: 20px;
  }
  .n_web .only-img-sec .white-bar::after {
    max-width: 1110px;
  }
  .n_web .advantage-over .inner-text,
  .n_web .current-scenario .current-se-box,
  .n_web .current-scenario .current-se-box .inner-contain,
  .n_web .text-contain,
  .n_web .three-m-s .inn-bx,
  .n_web .three-m-s.in-who-we-are .inn-bx {
    max-width: 100%;
  }
  .n_web .advantage-over .inner-text p,
  .n_web .advantage-over.rules-who-aply .into-two h4,
  .n_web .advantageses-over p,
  .n_web .cob-bcm-digital .lft-bx p,
  .n_web .cob-bcm-digital .rit-bx p,
  .n_web .current-scenario .current-se-box .inner-contain p,
  .n_web .four-m-bxes .txt-se p,
  .n_web .our-technology .contain-p p,
  .n_web .three-m-s .inn-bx p,
  .n_web .we-are-differnent .rit-text p {
    font-size: 20px;
    line-height: 36px;
  }
  .n_web .advantage-over.rules-who-aply .into-two ul li,
  .n_web .cob-bcm-digital .lft-bx small,
  .n_web .current-scenario .current-se-box p,
  .n_web .our-technology .contain-p small,
  .n_web .three-m-s .inn-bx small {
    font-size: 14px;
  }
  .n_web .advantageses-over h3,
  .n_web .cob-bcm-digital .sw-bx h2,
  .n_web .current-scenario .current-se-box h2,
  .n_web .four-m-bxes .txt-se h3,
  .n_web .our-technology .contain-p h2,
  .n_web .we-are-differnent .rit-text h2 {
    font-size: 30px;
    line-height: 34px;
  }
  .n_web .advantage-over .inner-text h2 {
    font-size: 24px;
  }
  .n_web .advantage-over.rules-who-aply .into-two ul li {
    line-height: 24px;
    padding: 0 0 0 30px;
  }
  .n_web .advantage-over.rules-who-aply .into-two ul li::after {
    height: 10px;
    width: 10px;
    top: 8px;
  }
  nav.side-menu .menu li,
  nav.side-menu .menu li a {
    font-size: 12px;
  }
  nav.side-menu .menu li + li {
    margin-top: 15px;
  }
  nav.side-menu .menu li ul li + li {
    margin-top: 20px;
  }
  nav.side-menu .menu li:last-child {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1450px) {
  .n_web header .container-fluid {
    padding-left: 50px;
    padding-right: 50px;
  }
  .n_web .eco-system-se::after {
    animation: slide 10s linear infinite;
  }
}
@media screen and (min-width: 1368px) and (max-width: 1450px) {
  .n_web header .container-fluid {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media screen and (max-width: 1551px) and (min-width: 1200px) {
  .n_web .advantage-over .inner-text,
  .n_web .three-m-s .inn-bx,
  .n_web .three-m-s.in-who-we-are .inn-bx {
    margin-left: 0;
    max-width: 100%;
  }
  .n_web .edge_ourstory .breadcrumbs-div,
  .n_web .edge_ourstory .txt-part-se {
    margin-left: 0;
  }
}
@media screen and (min-width: 1368px) {
  .n_web .title-brd .breadcrumbs-div {
    margin: 0;
    z-index: 1;
  }
}
@media screen and (max-width: 1280px) {
  .n_web .main-team-section::after {
    width: calc(100% - 40px);
  }
}
@media screen and (max-width: 1199px) {
  .n_web .flex-wrap-1199 {
    flex-wrap: wrap;
  }
  .n_web footer .about-edge {
    width: 100%;
    margin: 0 0 30px;
  }
  .n_web footer .footer-nav-social .mail-links:first-child {
    padding-left: 0;
  }
  .n_web footer .about-edge .footer-logo {
    width: 150px;
  }
  .body-scroll-dis {
    padding: 0;
  }
  .n_web .main-team-section::after {
    width: calc(100% - 100px);
  }
  .n_web .team-m-box {
    width: calc((100% / 5) - 30px);
  }
  .n_web .our-so-new-pg.ad .text-contain {
    max-width: 420px;
  }
}
@media screen and (max-width: 1079px) {
  .n_web .main-team-section::after {
    width: calc(100% - 40px);
  }
}
@media screen and (max-width: 991px) {
  .n_web .d-none-991 {
    display: none;
  }
  .n_web .d-block-991 {
    display: block;
  }
  .n_web .flex-wrap-991 {
    flex-wrap: wrap;
  }
  .n_web .d-flex-991 {
    display: flex;
  }
  .n_web .w-100-991 {
    width: 100%;
  }
  .n_web .three-m-s.on-m-hmp .for-shape::before {
    display: none;
  }
  .n_web .justify-content-center-991 {
    justify-content: center;
  }
  .n_web header .letest-news {
    margin-right: 20px;
  }
  .n_web .current-scenario .three-points .same-box {
    width: 100%;
    text-align: left;
  }
  .n_web .current-scenario .same-box .ic-se {
    margin: 0;
  }
  .n_web footer .footer-nav-social .navi-g {
    padding: 0;
  }
  .n_web .eco-system-se .main-link-here .full-bg-left::after {
    display: none;
  }
  .n_web .main-link-here .rig-link a {
    padding: 15px 20px;
    display: inline-block;
    line-height: 1;
    background: #f05139;
    background: linear-gradient(180deg, #f05139 0, #f08639 100%);
    color: #fff;
    border-radius: 0 25px 0 0;
    position: fixed;
    left: 0;
    top: auto;
    bottom: 0;
    z-index: 9;
  }
  .n_web .main-link-here .rig-link a:hover {
    background: #f08639;
    background: linear-gradient(180deg, #f08639 0, #f05139 100%);
  }
  .n_web .asia-blc.eco-system-se .for-shape::after {
    display: none;
  }
  .n_web .asia-blc.eco-system-se .for-shape::before {
    display: block;
    top: 0;
    right: 0;
    background-size: 100%;
    width: 130px;
  }
  .n_web .apply-accelerated .form-section .helf-width {
    width: 100%;
  }
  .n_web
    .apply-accelerated
    .form-section
    .helf-width.float-label-field
    + .helf-width.float-label-field {
    margin-top: 35px;
  }
  .n_web .apply-accelerated .form-section .top-s + .top-s {
    margin-top: 35px;
  }
  .n_web .apply-accelerated .left-title {
    width: 250px;
  }
  .n_web .apply-accelerated .awd-txt-im h4 {
    font-size: 22px;
  }
  .n_web .apply-accelerated .form-section {
    width: calc(100% - 250px);
  }
  .n_web .eco-system-se {
    height: auto;
    overflow: inherit;
  }
  .n_web .eco-system-se .for-shape::after {
    top: 0;
    width: 100px;
    background-size: 80px;
    height: 240px;
  }
  .n_web .eco-system-se .for-shape::before {
    top: 0;
    background-size: 150px;
    width: 140px;
    height: 230px;
  }
  .n_web .txt-part-se {
    margin: 0 auto 0;
  }
  .n_web .eco-system-se.our-so-new-pg .some-str-change .txt-part-se {
    margin: 0;
  }
  .n_web .eco-system-se.our-so-new-pg .some-str-change .text-contain {
    padding: 0;
  }
  .n_web .eco-system-se.our-so-new-pg::after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.55);
    filter: none;
    z-index: -1;
    top: 0;
    left: 0;
  }
  .n_web .eco-system-se.our-so-new-pg {
    height: 500px;
  }
  .n_web .team-m-box {
    width: calc((100% / 3) - 30px);
  }
  .n_web .main-title-s h1 {
    font-size: 60px;
  }
  .n_web .cob-bcm-digital .lft-bx {
    padding: 0;
  }
  .n_web .cob-bcm-digital .rit-bx {
    padding: 0 20px;
  }
  .n_web .three-m-s .inn-bx h3 {
    font-size: 20px;
  }
  .n_web .we-are-differnent .for-cross-sign::before {
    transform: rotate(-30deg);
  }
  .n_web .we-are-differnent .for-cross-sign::after {
    transform: rotate(30deg);
  }
  .n_web .token-tabs-se .tab-swap .txt-part h2 {
    font-size: 44px;
  }
  .n_web .token-tabs-se .edge-smal {
    width: 50px;
  }
  .n_web footer .footer-nav-social .mail-links {
    padding: 0 15px;
  }
  .n_web footer .footer-nav-social .last-s {
    padding: 0 15px;
    width: 200px;
  }
  .n_web footer .footer-nav-social .mail-links {
    width: 170px;
  }
  .n_web .advantage-over .inner-text {
    max-width: 100%;
  }
  .n_web .btn-gp .btn-apply-now {
    margin: 35px auto 0;
  }
  .n_web .current-scenario .current-se-box .left-se {
    width: 100%;
  }
  .n_web .current-scenario .current-se-box .right-se {
    width: 100%;
    padding: 0;
    margin: 20px 0 0 0;
  }
  .n_web .advantage-over .s-w {
    width: 100%;
  }
  .n_web .advantage-over .s-w + .s-w {
    margin-top: 30px;
  }
  .n_web .partner-multi-logo .part-lgo-bx {
    width: calc(25% - 30px);
  }
  .n_web .current-indus-solu .comparetable-table tbody tr th.for-widht {
    width: 155px;
  }
  .n_web .we-hve-featu .iiner-cont {
    padding: 0 0 30px;
  }
  .n_web .we-hve-featu .rt-t {
    padding: 0;
  }
  .n_web .token-tabs-se .nav-tabs {
    margin: 0 -10px;
  }
  .n_web .token-tabs-se .nav-tabs .nav-item + .nav-item {
    margin-left: 10px;
  }
  .n_web .token-tabs-se .nav-tabs .nav-item {
    margin: 10px;
  }
}
@media screen and (max-width: 767px) {
  .n_web .token-tabs-se .nav-tabs .nav-item {
    margin: 10px 0;
  }
  .n_web .token-tabs-se .nav-tabs .nav-item + .nav-item {
    margin-left: 0;
  }
  .n_web .token-tabs-se .nav-tabs {
    margin: 0;
  }
  .n_web .token-tabs-se .nav-tabs .nav-item a {
    width: 100%;
    min-width: 70px !important;
    padding: 7px 7px;
  }
}
@media screen and (min-width: 768px) {
  .n_web .token-tabs-se .nav-tabs {
    display: flex;
  }
  .n_web .token-tabs-se .card {
    border: none;
  }
  .n_web .token-tabs-se .card .card-header {
    display: none;
  }
  .n_web .token-tabs-se .card .collapse {
    display: block;
  }
  .n_web .faqs-main-que .into-two-p {
    width: 49.3%;
  }
}
@media screen and (max-width: 767px) {
  .n_web .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .n_web .d-block-767 {
    display: block;
  }
  .n_web .flex-wrap-767 {
    flex-wrap: wrap;
  }
  .n_web .d-flex-767 {
    display: flex;
  }
  .n_web .justify-content-between-767 {
    justify-content: space-between;
  }
  .n_web .w-100-767 {
    width: 100%;
  }
  .n_web .d-none-767 {
    display: none;
  }
  .n_web .order-1-767 {
    order: 1;
  }
  .n_web .order-2-767 {
    order: 2;
  }
  .n_web .eco-system-se.our-so-new-pg .some-str-change {
    left: 25px;
    right: 25px;
  }
  .n_web footer .footer-nav-social .navi-g {
    width: 48%;
  }
  .n_web footer .footer-nav-social .mail-links {
    width: 48%;
    padding: 0;
  }
  .n_web footer .footer-nav-social .mail-links::after,
  .n_web footer .footer-nav-social .mail-links::before {
    display: none;
  }
  .n_web footer .footer-nav-social .last-s {
    padding: 0;
  }
  .n_web footer .footer-nav-social .last-s .tit {
    width: 48%;
  }
  .n_web footer .footer-nav-social .last-s .tit + .tit {
    margin-top: 0;
  }
  .n_web footer .copy-right {
    margin: 10px 0 0;
  }
  .n_web footer .copy-right .some-links {
    margin: 0 0 5px;
  }
  .n_web footer .copy-right .some-links a {
    margin: 0;
  }
  .n_web footer .copy-right .some-links a + a {
    margin-left: 50px;
  }
  .n_web .our-technology .for-bg::before {
    max-width: 460px;
  }
  .n_web .white-bar {
    display: none;
  }
  .n_web .current-scenario .current-se-box {
    width: 100%;
    max-width: 100%;
    padding: 40px;
  }
  .n_web .current-scenario.cust-part .current-se-box {
    padding: 0;
  }
  .n_web .our-technology .sm-w {
    width: 100%;
  }
  .n_web .only-img-sec {
    height: 390px;
  }
  .n_web .eco-system-se .for-shape::after {
    top: -90px;
    width: 130px;
    background-size: 80px;
    height: 240px;
  }
  .n_web .eco-system-se .for-shape::before {
    top: 240px;
    background-size: 130px;
    width: 110px;
    height: 220px;
  }
  .n_web .main-title h1 {
    font-size: 80px;
  }
  .n_web .main-title h2 {
    font-size: 12px;
    letter-spacing: 20px;
  }
  .n_web .text-contain {
    margin: 30px 0 0;
  }
  .n_web .eco-system-se .left-link .sub-link {
    width: 48%;
  }
  .n_web .eco-system-se .main-link-here .left-link {
    padding: 20px;
  }
  .n_web .eco-system-se .left-link .sub-link + .sub-link {
    padding: 15px 0;
  }
  .n_web .eco-system-se .left-link .sub-link + .sub-link::after {
    display: none;
  }
  .n_web .eco-system-se .left-link .sub-link {
    padding: 15px 0;
  }
  .n_web .asia-blc.eco-system-se {
    margin: 0;
    padding: 50px 0;
  }
  .n_web .advantage-over.rules-who-aply .into-two {
    width: 100%;
    padding: 0;
  }
  .n_web .advantage-over.rules-who-aply .into-two + .into-two {
    margin-top: 60px;
    padding-top: 60px;
  }
  .n_web .advantage-over.rules-who-aply .into-two::after {
    display: none;
  }
  .n_web .advantage-over.rules-who-aply .into-two + .into-two::before {
    left: auto;
    right: 0;
    content: "";
    height: 1px;
    top: 0;
    width: 100%;
    background-color: #fff;
    position: absolute;
  }
  .n_web .apply-accelerated .left-title {
    width: 100%;
  }
  .n_web .apply-accelerated .form-section {
    width: 100%;
    padding: 0;
    margin: 30px 0 0;
  }
  .n_web .main-team-section::after {
    display: none;
  }
  .n_web .main-team-section .card-body {
    padding: 0 30px;
    border-radius: 0;
  }
  .n_web .team-m-box {
    width: calc((100% / 2) - 30px);
  }
  .n_web .advantage-over.becom-our .inner-text p {
    width: 100%;
  }
  .n_web .btn-job-opning {
    margin: 15px 0 0 0;
  }
  .n_web .token-tabs-se .tab-content > .tab-pane {
    display: block !important;
    opacity: 1;
  }
  .n_web .cob-bcm-digital .sw-bx {
    width: 100%;
  }
  .n_web .cob-bcm-digital .lft-bx {
    padding: 30px 15px;
    background: #0f1740;
    margin: 0 -15px;
    width: calc(100% - -30px);
  }
  .n_web .cob-bcm-digital .rit-bx {
    padding: 30px 15px;
    margin: 0 -15px;
    width: calc(100% - -30px);
    background: #f2f2f2;
  }
  .n_web .cob-bcm-digital {
    background-color: #34adff;
    background-image: -webkit-linear-gradient(90deg, #f2f2f2 50%, #0f1740 50%);
    background-image: gradient(90deg, #f2f2f2 50%, #0f1740 50%);
    padding: 0;
  }
  .n_web .main-title-s h2 {
    font-size: 8px;
  }
  .n_web .four-m-bxes .sm-w-bx {
    width: 100%;
    position: relative;
  }
  .n_web .four-m-bxes .sm-w-bx + .sm-w-bx {
    margin-top: 30px;
    padding-top: 30px;
  }
  .n_web .four-m-bxes .sm-w-bx + .sm-w-bx::after {
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    content: "";
    height: 1px;
    background-color: #282896;
  }
  .n_web .four-m-bxes .for-bm-p {
    padding: 0;
  }
  .n_web .four-m-bxes .for-tp-p {
    padding: 0;
  }
  .n_web .for-plus-sign::after,
  .n_web .for-plus-sign::before {
    display: none;
  }
  .n_web .we-are-differnent .into-two-part {
    width: 100%;
    padding: 0;
  }
  .n_web .advantage-over.we-are-differnent {
    padding: 30px 0;
  }
  .n_web .we-are-differnent .for-cross-sign::after,
  .n_web .we-are-differnent .for-cross-sign::before {
    display: none;
  }
  .n_web .we-are-differnent .into-two-part {
    min-height: inherit;
  }
  .n_web .we-are-differnent .lft-tit h3 {
    font-size: 50px;
  }
  .n_web .we-hve-featu .lft-t {
    width: 100%;
  }
  .n_web .we-hve-featu .rt-t {
    width: 100%;
    padding: 15px 0 0 0;
  }
  .n_web .we-hve-featu .lft-t h3 {
    font-size: 18px;
  }
  .n_web .token-tabs-se .edge-smal {
    display: none;
  }
  .n_web .token-tabs-se .tab-swap .img-part {
    width: 100%;
    margin-top: 20px;
  }
  .n_web .token-tabs-se .card-header {
    border-bottom: none;
  }
  .n_web .token-tabs-se .tab-swap .txt-part {
    width: 100%;
    padding: 0;
  }
  .n_web .token-tabs-se .card-body {
    padding: 20px;
  }
  .n_web .token-tabs-se {
    padding: 30px 0;
  }
  .n_web .token-tabs-se .tab-swap .txt-part .iner-pt {
    max-width: inherit;
  }
  .n_web .token-tabs-se .card-header {
    padding: 0;
  }
  .n_web .token-tabs-se .card-header a {
    display: block;
    padding: 15px;
    line-height: 1;
  }
  .n_web .token-tabs-se .tab-content > .tab-pane + .tab-pane {
    margin-top: 20px;
  }
  .n_web .apply-accelerated::after {
    display: none;
  }
  .n_web .navigation-dots-container {
    display: none;
  }
  .n_web footer .footer-nav-social .last-s {
    width: 48%;
  }
  .n_web footer .footer-nav-social .last-s .tit {
    width: 100%;
  }
  .n_web .b-s-20-767 {
    height: 20px;
  }
  .n_web .faqs-main-que .into-two-p {
    width: 100%;
    margin: 0;
  }
  .n_web .faqs-main-que .into-two-p + .into-two-p {
    margin-top: 20px;
  }
  .n_web .faqs-main-que .card {
    margin: 0;
  }
  .n_web .faqs-main-que .card + .card {
    margin-top: 20px;
  }
  .n_web footer .footer-nav-social .last-s .social {
    padding-right: 0;
  }
  .n_web .partner-multi-logo .part-lgo-bx {
    width: calc(33% - 30px);
  }
  .n_web .bellow-text-part p {
    width: 100%;
    margin: 0;
  }
  .n_web .bellow-text-part p + p {
    margin-top: 20px;
  }
  .n_web .our-technology.on-hmp-man {
    padding: 40px 0;
  }
  .n_web .apply-accelerated.ambass-bg::before,
  .n_web .current-indus-solu::before,
  .n_web .our-technology .for-bg::before,
  .n_web .our-technology.on-hmp-man::after {
    display: none;
  }
  .n_web .current-indus-solu .card-header {
    pointer-events: none;
  }
  .n_web .our-technology.oslu {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .n_web .token-tabs-se .card-header [aria-expanded="true"] {
    background: linear-gradient(180deg, #f08639 0, #f05139 100%);
    color: #fff;
  }
  .n_web .morinfo-ab-coint .sw-p {
    width: 100%;
  }
  .n_web .token-tabs-se .tab-swap .txt-part h2 {
    font-size: 34px;
  }
  .n_web .morinfo-ab-coint .small-conn .sw-p {
    padding: 0;
  }
}
@media screen and (max-width: 575px) {
  .n_web .flex-wrap-575 {
    flex-wrap: wrap;
  }
  .n_web .eco-system-se.asia-blc .main-title h1 {
    width: 100%;
    font-size: 120px;
  }
  .n_web .eco-system-se.asia-blc .main-title h2 {
    margin: 10px 0 0 0;
  }
}
@media screen and (max-width: 479px) {
  .n_web .flex-wrap-479 {
    flex-wrap: wrap;
  }
  .n_web footer .copy-right .some-links a + a {
    margin-left: 20px;
  }
  .n_web .our-technology .for-bg::before {
    max-width: 270px;
  }
  .n_web .main-title h1 {
    font-size: 60px;
  }
  .n_web .only-img-sec {
    height: 200px;
  }
  .n_web .main-title h2 {
    letter-spacing: 15px;
  }
  .n_web nav.side-menu .menu,
  .o_web nav.side-menu .menu {
    width: 280px;
    padding-bottom: 50px;
  }
  .n_web .eco-system-se .for-shape::after {
    display: none;
  }
  .n_web .three-m-s {
    padding: 30px 0;
  }
  .n_web .three-m-s .inn-bx h3 {
    font-size: 15px;
  }
  .n_web .main-hop.eco-system-se {
    padding: 30px 0;
  }
  .n_web .main-hop.eco-system-se .for-shape::before {
    display: none;
  }
  .n_web .main-title-s h1 {
    font-size: 48px;
  }
  .n_web .main-title-s h2 {
    letter-spacing: 10px;
  }
  .n_web .we-are-differnent .lft-tit h3 {
    font-size: 30px;
  }
  .n_web .we-are-differnent .rit-text h2 {
    font-size: 22px;
  }
  .n_web .advantageses-over h3,
  .n_web .current-indus-solu h2,
  .n_web .cust-zo-contact-form h2,
  .n_web .our-technology .contain-p h2,
  .n_web .partner-multi-logo h2 {
    font-size: 20px;
  }
  .n_web .partner-multi-logo .part-lgo-bx {
    width: calc(50% - 30px);
  }
  .n_web .morinfo-ab-coint .bellow-text-part .txt-p {
    width: 100%;
    padding: 0;
    margin: 15px 0 0 0;
  }
}
@media screen and (max-width: 430px) {
  .n_web .flex-wrap-430 {
    flex-wrap: wrap;
  }
  .n_web .w-100-430 {
    width: 100%;
  }
  .n_web .main-hop.eco-system-se .follow-us-socil .line-btn {
    padding: 10px 40px 12px;
    margin: 20px 0 0 0;
  }
  .n_web .team-m-box {
    width: calc((100% / 1) - 30px);
  }
  .n_web footer .footer-nav-social .mail-links {
    width: 100%;
  }
  .n_web .advantage-over .imagese img {
    max-width: 120px;
  }
  .n_web footer .footer-nav-social .last-s {
    width: 100%;
  }
  .n_web footer .footer-nav-social .last-s .social .ic-so {
    margin: 1px 3px;
  }
  .n_web header .logo {
    max-width: 170px;
  }
}
.n_web {
  background-color: #111a44;
  position: relative;
  z-index: 2;
  overflow-x: hidden;
}
@media screen and (min-width: 768px) {
  .n_web .border-blue-right {
    border-right: 1px solid #282896;
  }
  .n_web .border-blue-bottom {
    border-bottom: 1px solid #282896;
  }
  .n_web .four-m-bxes .for-lt-p,
  .n_web .four-m-bxes .for-rt-p {
    padding: 25px;
  }
  .n_web .four-m-bxes .for-lt-p:hover,
  .n_web .four-m-bxes .for-rt-p:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}
.n_web .body-padTop header {
  padding-top: 72px;
}
.n_web .team-parallax .react-parallax-bgimage {
  z-index: -2;
}
.n_web .ic-rig8-arrow {
  transition: all 0.3s ease-in-out;
}
.n_web .btn-apply-now:hover .ic-rig8-arrow {
  left: 5px !important;
}
.n_web .f12-400Arial {
  font-size: 12px;
  font-family: Arial;
}
.o_web {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  background-repeat: no-repeat;
  background: #f4f4f4;
  padding-top: 110px;
  min-height: 100vh;
}
.body-scroll-fixed {
  overflow: hidden;
  background-color: #111a44;
}
._color_gray {
  color: #66667e !important;
}
.transparent {
  background-color: transparent !important;
}
.landing-widget {
  margin-top: -2rem;
}
.landing-widget .purchase-nav li a {
  width: 125px;
}
.landing-widget .def_tab_v2 li a.active,
.stake-widget .def_tab_v2 li a.active {
  color: #fff !important;
}
.landing-widget .individual-token,
.swap-widget .individual-token {
  padding: 1rem 0.5rem;
}
.landing-widget .individual-token p,
.swap-widget .individual-token p {
  padding-top: 0;
}
.swap-widget {
  min-height: 0;
  position: relative !important;
  z-index: 0 !important;
}
.stake-widget {
  margin-top: -6rem;
  min-height: 0;
}
.stake-widget .def_tab_v2 li a {
  width: 108px;
}
.stake-widget .max-745 .d-flex > div {
  max-width: 350px !important;
}
.stake-widget p {
  margin-top: 0.75rem;
}
.history-header .tx-history-nav {
  justify-content: flex-start !important;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.react-tel-input .flag-dropdown {
  -webkit-user-select: none;
          user-select: none;
}
.ant-tooltip-arrow {
  display: none !important;
}
.ant-tooltip-placement-right {
  padding-left: 0 !important;
}
.ant-tooltip-placement-left {
  padding-right: 0 !important;
}
.ant-tooltip-inner {
  background-color: #d8d8d8 !important;
  color: #868686 !important;
  font-family: "SF Pro Display Regular", sans-serif !important;
  border: 3px solid #fff !important;
  border-radius: 15px !important;
  padding: 4px 15px 2px 15px !important;
  font-size: 12px !important;
}
.password-meter {
  padding: 8px 5px 0 5px;
}
.password-meter p {
  font-family: "SF Pro Display Regular", sans-serif;
  padding-top: 0;
  text-transform: uppercase;
  font-size: 10px !important;
}
@media screen and (max-width: 990px) {
  .img-part {
    width: 100% !important;
  }
  .landing-widget .purchase-nav li a {
    width: 120px;
  }
  .stake-widget .def_tab_v2 li a {
    width: 111.3px;
  }
  .landing-widget .def_tab_v2,
  .stake-widget .def_tab_v2 {
    margin-bottom: -1.25rem !important;
  }
}
@media screen and (max-width: 768px) {
  .landing-widget .def_tab_v2,
  .stake-widget .def_tab_v2 {
    display: flex !important;
  }
}
.loginMenus a:hover {
  color: #ef4b23;
}
.o_web .loginMenus a {
  font-size: 14px;
  color: #66667e;
}
.kyc-modal {
  margin: 0 auto !important;
  justify-content: space-around !important;
  height: auto !important;
  max-width: 400px !important;
  min-width: 0 !important;
  width: 100% !important;
}
a.navbar-brand.focussable-image:focus {
  border: none;
}
.n_web #collapse-A .o_web,
.n_web #collapse-B .o_web,
.n_web #collapse-C .o_web,
.n_web #collapse-D .o_web {
  margin-top: 0 !important;
  padding-top: 0 !important;
  height: auto;
  min-height: auto;
}
.n_web #collapse-A .o_web .content {
  margin-top: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  min-height: auto !important;
}
.n_web #collapse-B .o_web .content {
  margin-top: 0 !important;
  padding-top: 0 !important;
  height: auto !important;
  min-height: auto !important;
}
.n_web #collapse-C .o_web {
  min-height: auto !important;
  margin-top: 0 !important;
}
.n_web #collapse-C .o_web .content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: auto !important;
}
.n_web #collapse-C .o_web .content .def_wt_bx {
  margin-top: 0;
}
.n_web #collapse-D .o_web .content {
  padding-bottom: 40px !important;
  margin-top: 0 !important;
  height: auto !important;
  min-height: auto !important;
  overflow: visible !important;
}
@media (max-width: 1279px) {
  .n_web .part-logo .item .part-lgo-bx img {
    max-width: 80%;
    margin: 0 auto;
  }
}
@media (max-width: 991px) {
  .n_web .eco-system-se.our-so-new-pg {
    background: rgba(0, 0, 0, 0.55);
  }
  .n_web #collapse-A .o_web,
  .n_web #collapse-B .o_web,
  .n_web #collapse-C .o_web,
  .n_web #collapse-D .o_web {
    margin-top: 30px !important;
  }
}
@media (max-width: 767px) {
  .n_web #collapse-A .o_web,
  .n_web #collapse-B .o_web,
  .n_web #collapse-C .o_web,
  .n_web #collapse-D .o_web {
    margin-top: 0 !important;
  }
  .n_web #collapse-A .o_web .left_d,
  .n_web #collapse-B .o_web .left_d,
  .n_web #collapse-C .o_web .left_d,
  .n_web #collapse-D .o_web .left_d {
    margin-top: 0 !important;
  }
}
.n_web #tabs-widgets .mobile-show {
  display: none;
}
@media screen and (max-width: 991px) {
  .o_web .history-header .tx-history-nav {
    width: calc(100% - 40px);
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 0;
    height: 73px;
    position: relative;
  }
  .o_web .asset-dropdown {
    width: 260px;
  }
}
@media screen and (max-width: 767px) {
  .n_web #tabs-widgets .desktop-show {
    display: none;
  }
  .n_web #tabs-widgets .mobile-show {
    display: block;
  }
  .content.edge196-calculator {
    height: auto !important;
    padding-bottom: 0;
  }
  .kyc_page_wraper_mobile {
    padding-bottom: 100px;
  }
  .o_web.kyc_page_wraper_mobile .swap-card {
    z-index: 8;
    margin: 0 15px;
  }
  .o_web.kyc_page_wraper_mobile .content {
    padding-bottom: 100px;
  }
}
.n_web #tabs-widgets .mobile-show a.active h5 {
  background: linear-gradient(180deg, #f08639 0, #f05139 100%);
  color: #fff;
}
.n_web #tabs-widgets .mobile-show .accordion > .card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.o_web .card-input .form-control {
  border-color: #989898 !important;
}
select.unselected {
  color: #989898 !important;
}
.f20-400 {
  font-size: 20px !important;
  font-family: "SF Pro Display Regular" !important;
  word-break: break-all;
}
@media screen and (max-width: 767px) {
  .f20-400 {
    font-size: 18px !important;
  }
  .n_web #tabs-widgets .mobile-show .accordion > .card {
    overflow: unset;
  }
  .n_web #tabs-widgets .mobile-show .collapse.show {
    z-index: 1;
  }
  .o_web .tab-item a {
    font-size: 9px;
  }
  .o_web .tab-item-active a {
    font-size: 9px;
  }
  .o_web .tabs-container li {
    padding: 0 10px;
    margin: 0;
  }
  .edge_menus_mobile_hide {
    display: none;
  }
}
.responsive-modal {
  padding-top: 0 !important;
  min-height: 0;
}
.card-input .form-control,
.card-input .react-tel-input .form-control {
  height: 3.5rem !important;
  padding: 1rem;
  border: none !important;
  width: 100%;
  border-radius: 12px;
  font-weight: 300;
  box-shadow: 6px 9px 20px #00000029 !important;
  background-color: #fff !important;
}
.edge_page_title {
  font-size: 18px;
  line-height: 16px;
  color: #989898;
  font-family: "SF Pro Display Bold";
}
.edge_accountno {
  font-size: 14px;
  line-height: 12px;
  color: #8c8c8c;
  font-family: "SF Pro Display Regular";
}
.edge_userName {
  font-size: 16px;
  line-height: 24px;
  color: #8c8c8c;
  font-family: "SF Pro Display Semibold";
}
.f12-Display-Semibold {
  font-size: 12px;
  line-height: 24px;
  color: #8c8c8c;
  font-family: "SF Pro Display Semibold";
}
.f9-Display-Regular {
  font-size: 11px;
  line-height: 12px;
  color: #8c8c8c;
  font-family: "SF Pro Display Regular";
}
.edge_avtar_box {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  position: relative;
  margin-right: 20px;
}
.edge_avtar_box img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.edge_avtar_box input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.edge_avtar_box.active:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #29cb97;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  top: 5px;
  right: 5px;
}
.f13-Display-Semibold {
  font-size: 13px;
  line-height: 16px;
  color: #8c8c8c;
  font-family: "SF Pro Display Bold";
}
.edgeWhiteBox {
  background: #fff;
  box-shadow: 0 1px 4px #e5e9f2;
  -webkit-box-shadow: 0 1px 4px #e5e9f2;
  -moz-box-shadow: 0 1px 4px #e5e9f2;
  border-radius: 23px;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  padding: 15px;
}
.edgeWhiteBox .form-control {
  font-size: 12px !important;
  line-height: 14px;
  color: #31394d;
  font-family: "SF Pro Display Regular";
}
.edgeWhiteBox input:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.edgeWhiteBox .react-datepicker-wrapper {
  width: 100%;
}
.datepicker {
  background: #fff url(/static/media/calendar.7b5e8dc5.svg) no-repeat scroll 96% 50%/15px;
}
.edgeAddnewBtn {
  background: #f9fafe;
  border: 1px solid #ebedf4;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  font-size: 10px;
  color: #748aa1;
  padding: 15px;
}
.edge_paycard {
  box-shadow: 0 5px 20px #c0c7d6;
  -webkit-box-shadow: 0 5px 20px #c0c7d6;
  -moz-box-shadow: 0 5px 20px #c0c7d6;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  padding: 25px;
}
.edge_paycard .f16 {
  color: #fff;
  font-size: 16px;
}
.edge_paycard .f11 {
  color: #fff;
  font-size: 11px;
}
.edge_cardblue {
  background: transparent linear-gradient(180deg, #679cf6 0, #4072ee 100%);
}
.opacity50 {
  opacity: 0.5;
}
.edge_cardgreen {
  background: transparent linear-gradient(180deg, #29cb97 0, #2cb589 100%);
}
@media only screen and (max-width: 575px) {
  .purchase-nav li:nth-child(1) a {
    padding-left: 0 !important;
    padding-right: 10px !important;
  }
  .purchase-nav li:nth-last-child(1) a {
    padding-right: 0 !important;
    padding-left: 10px !important;
  }
}
@media only screen and (max-width: 500px) {
  body {
    background-color: #f4f4f4 !important;
  }
  .buy-container {
    padding: 0 !important;
    min-height: 100vh !important;
    align-items: flex-start !important;
  }
  .swap-container {
    height: 100vh !important;
    display: flex !important;
    align-items: center !important;
  }
  .settings-main-container {
    margin: 0 !important;
    min-height: 100vh !important;
    display: flex !important;
    justify-content: center !important;
  }
  .ant-modal-title {
    width: 95%;
    position: relative;
    top: -4.5px;
  }
  .ant-modal .def_sb_btn {
    margin-bottom: 0 !important;
  }
}
.n_web .current-scenario .edge-event-tree-box .same-box h3 {
  height: 56px;
}
.n_web .current-scenario .edge-event-tree-box .same-box {
  padding: 25px 50px 50px;
  position: relative;
}
.n_web .current-scenario .edge-event-tree-box a.btn-apply-now.sb.ff-muli-sb {
  position: absolute;
  bottom: 0;
}
.n_web .mx-500 {
  max-width: 500px !important;
}
nav nav.side-menu {
  margin-top: 0 !important;
}
.edge196_ambassadorSection1 {
  padding: 60px 15px;
}
.n_web .current-scenario .edge196_ambassadorSection1 .three-points .same-box {
  padding: 60px 50px 0;
}
.edge196_ambassadorSectionaccelerated.apply-accelerated {
  padding: 60px 0 45px;
}
.o_web .content.edge196_register_page {
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: 9% 0 0;
}
@media screen and (max-width: 1366px) {
  .n_web .current-scenario .current-se-box h2 {
    line-height: 24px;
    font-size: 18px;
  }
  .n_web .current-scenario .current-se-box p {
    margin: 0 0 8px;
  }
  .n_web .b-s-80 {
    height: 30px;
  }
  .n_web .apply-accelerated {
    padding: 0 0;
  }
}
@media screen and (max-width: 1199px) {
  .n_web nav.side-menu .menu-toggle:hover::before,
  .o_web nav.side-menu .menu-toggle:hover::before {
    background-position: 0 0;
  }
  .n_web nav.side-menu .menu-toggle.menu-open::after,
  .n_web nav.side-menu .menu-toggle.menu-open::before,
  .o_web nav.side-menu .menu-toggle.menu-open::after,
  .o_web nav.side-menu .menu-toggle.menu-open::before {
    background-position: -30px 0;
  }
  .n_web .token-tabs-se .tab-swap .txt-part h2 {
    line-height: 32px;
  }
  .n_web .advantage-over,
  .n_web .three-m-s {
    padding: 30px 0;
  }
  .n_web footer .footer-nav-social .quick-link ul li {
    padding: 2px 0;
  }
  .n_web footer .footer-nav-social .quick-link p {
    margin: 0 0 2px;
  }
  .n_web footer .footer-nav-social .last-s .tit p {
    margin: 0 0 2px;
  }
  .n_web footer .f-notes {
    font-size: 10px;
  }
  .n_web footer .footer-nav-social .last-s .tit + .tit {
    margin-top: 20px;
  }
  .n_web .b-s-20 {
    height: 15px;
  }
  .n_web .we-are-differnent .into-two-part {
    padding: 30px 0;
    min-height: 300px;
  }
  .n_web .our-technology.on-hmp-man {
    padding: 0 0 60px;
  }
  .n_web .eco-system-se.our-team .for-shape::after {
    left: -380px;
  }
  .n_web .current-scenario .edge196_ambassadorSection1 .three-points .same-box {
    padding: 30px 30px 0;
  }
  .n_web .advantage-over.rules-who-aply {
    padding: 30px 0;
  }
  .n_web .three-m-s .inn-bx h3 {
    margin: 0 0 10px;
  }
  .n_web .partner-multi-logo {
    padding: 30px 0;
  }
  .edge196_ambassadorSectionaccelerated.apply-accelerated {
    padding: 30px 0 15px;
  }
  .body-scroll-fixed {
    padding-right: 0;
  }
}
@media screen and (max-width: 991px) {
  .hero-banner img.react-parallax-bgimage {
    transform: translate3d(-25%, -21.0938px, 0) !important;
    -webkit-transform: translate3d(-25%, -21.0938px, 0) !important;
    -moz-transform: translate3d(-25%, -21.0938px, 0) !important;
  }
  .n_web .current-scenario .edge-event-tree-box .same-box h3 {
    height: auto;
  }
  .n_web .current-scenario .edge-event-tree-box .same-box {
    padding: 25px 0 50px;
  }
  .n_web .current-scenario.cust-part {
    padding: 30px 0;
  }
  .n_web .current-scenario .edge196_ambassadorSection1 .three-points .same-box {
    padding: 30px 0 0;
  }
  .o_web .content.edge196_register_page {
    padding: 15% 0 0;
  }
}
@media screen and (max-width: 767px) {
  .n_web #collapse-D .o_web .content,
  .n_web .o_web .max-745 {
    padding-bottom: 0 !important;
  }
  .n_web .line-btne {
    margin: 0;
  }
  .n_web .advantage-over .inner-text h2 {
    margin: 0 0 20px;
  }
  .n_web .advantage-over {
    padding: 30px 0;
  }
  .n_web .three-m-s .inn-bx h3 {
    margin: 0 0 20px;
  }
  .n_web footer .footer-nav-social .quick-link ul li {
    padding: 2px 0;
  }
  .n_web footer .footer-nav-social .quick-link p {
    margin: 0 0 2px;
  }
  .n_web footer .footer-nav-social .last-s .tit p {
    margin: 0 0 2px;
  }
  .n_web footer .f-notes {
    font-size: 10px;
  }
  .n_web .faqs-main-que {
    padding: 0 0 40px;
  }
  .n_web .apply-accelerated.contact-us-bg .form-section {
    margin: 10px 0 0 0;
  }
  .n_web .apply-accelerated.contact-us-bg {
    padding: 30px 0 0;
  }
  .n_web .we-are-differnent .into-two-part.Old_hom {
    padding: 0 !important;
    min-height: auto;
  }
  .n_web .cob-bcm-digital.Old_hom {
    padding: 0 !important;
  }
  .kyc-container {
    margin: 0 !important;
    padding: 0 !important;
    min-height: 100vh !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }
  .wallet-table-container {
    padding-top: 0 !important;
  }
  .o_web .content.edge196_register_page {
    padding: 100px 0 0;
  }
  .o_web .content.edge196_login_page {
    align-items: flex-start !important;
    padding-top: 100px !important;
  }
}
iframe.ambassadorForm {
  height: 1116px !important;
  margin-top: -45px;
}
iframe.contactusForm {
  height: 750px !important;
}
iframe.workwithusForm {
  height: 890px !important;
}
iframe.partnerForm {
  height: 950px !important;
  margin-left: -45px;
  margin-top: -45px;
}
.padding-60-0 {
  padding-top: 60px;
  padding-bottom: 60px;
}
f
  .n_web
  .current-scenario.current-scenario-second
  .edge-event-tree-box
  .same-box {
  padding: 0 50px 50px;
  margin: 60px 0;
}
.edge196_partner_apply-accelerated.apply-accelerated {
  padding: 0 0 30px;
}
.o_web nav.side-menu .menu.active {
  top: 45px;
  height: calc(100vh - 59px);
  right: -2rem;
}
.o_web nav.side-menu .menu {
  right: -2rem;
}
@media screen and (max-width: 1366px) {
  .n_web .main-title h1,
  .n_web .main-title-s h1 {
    font-size: 65px;
    line-height: 0.8;
  }
  .n_web .main-title h2,
  .n_web .main-title-s h2 {
    letter-spacing: 15px;
  }
  .n_web .eco-system-se.our-team .for-shape::after {
    top: -30px;
  }
  .n_web .eco-system-se.our-team .for-shape::before {
    top: 80px;
  }
  .edge196_mt_10 {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 1199px) {
  .n_web .apply-accelerated.contact-us-bg,
  .n_web .current-scenario.cust-part,
  .n_web .eco-system-se,
  .n_web .load-more,
  .n_web .recent-media-se {
    padding: 30px 0;
  }
  .n_web .current-scenario .three-points .same-box {
    padding: 30px 30px;
  }
  .padding-60-0 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .n_web .bellow-text-part.new-our-sol {
    padding: 0 0 15px;
  }
  .n_web .our-technology .img-p img {
    max-width: 50%;
  }
  .n_web .main-team-section {
    margin: 30px 0 0 0;
  }
  .n_web
    .current-scenario.current-scenario-second
    .edge-event-tree-box
    .same-box {
    margin: 30px 0;
  }
  .edge196_ambassadorSection1,
  .n_web .cob-bcm-digital {
    padding: 30px 15px;
  }
  .n_web .faqs-main-que {
    padding: 0 0 30px;
  }
  .n_web .advantageses-over.Old_hom {
    padding: 30px 0;
  }
  .n_web .current-indus-solu {
    padding: 30px 0 10px;
  }
  .n_web .our-technology.Old_hom.on-hmp-man {
    padding: 0 0 30px;
  }
  .n_web .cob-bcm-digital.Old_hom {
    padding: 30px 15px 30px;
  }
  .n_web .we-are-differnent .into-two-part {
    padding: 0;
  }
  .o_web .header {
    height: 63px;
  }
  .n_web .eco-system-se.our-team .for-shape::before {
    top: 10px;
  }
}
@media screen and (max-width: 991px) {
  .n_web .current-scenario .three-points .same-box {
    padding: 30px 0 0;
  }
  .n_web .current-scenario .three-points .same-box:last-child {
    padding-bottom: 30px;
  }
  .n_web .our-technology .img-p img {
    max-width: 60%;
  }
  .n_web
    .current-scenario.current-scenario-second
    .edge-event-tree-box
    .same-box {
    padding: 0;
    margin: 30px 0 0;
  }
  .n_web
    .current-scenario.current-scenario-second
    .edge-event-tree-box
    .same-box:last-child {
    padding-bottom: 30px;
  }
  .n_web .current-scenario .edge-event-tree-box a.btn-apply-now.sb.ff-muli-sb {
    position: unset;
  }
  .edge196_ambassadorSection1,
  .n_web .cob-bcm-digital {
    padding: 30px 15px 0;
  }
  .n_web .apply-accelerated.contact-us-bg .form-section {
    /* margin: 0; */
    padding: 0;
  }
  .n_web .edge196_asiablockchain.eco-system-se .for-shape::before {
    height: 570px;
    width: 570px;
    left: auto;
    right: -230px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .n_web .edge196_asiablockchain.eco-system-se .for-shape::after {
    display: block;
    top: 0;
    width: 540px;
    height: 540px;
    background-size: 100%;
    bottom: 0;
    margin: auto;
    left: -340px;
  }
  .n_web .eco-system-se.our-team .for-shape::after {
    background-size: 100%;
  }
  .n_web .eco-system-se.our-team .for-shape::before {
    background-size: 100%;
    height: 570px;
    width: 570px;
  }
  .n_web .eco-system-se.events-bg .for-shape::before {
    height: 570px;
    width: 570px;
    background-size: 100%;
  }
  .n_web .eco-system-se.events-bg .for-shape::after {
    width: 550px;
    height: 590px;
    background-size: 100%;
  }
  .n_web .eco-system-se .for-shape.ourSolutionRing::before,
  .n_web .eco-system-se .for-shape.ourStoryRing::before {
    height: 570px;
    width: 570px;
    background-size: 100%;
    top: 0;
  }
  .n_web .eco-system-se .for-shape.ambassadorRing::before {
    height: 570px;
    width: 570px;
    left: auto;
    right: -230px;
    top: 0;
    bottom: 0;
    background-size: 100%;
  }
  .n_web .eco-system-se .for-shape.ambassadorRing::after {
    display: block;
    width: 540px;
    height: 540px;
    background-size: 100%;
    left: -340px;
  }
  .n_web .eco-system-se .for-shape.ourSolutionRing::after {
    background-size: 100%;
  }
  .n_web .eco-system-se .for-shape.ourStoryRing::after {
    background-size: 100%;
    height: 540px;
    width: 540px;
    left: -340px;
    top: 0;
  }
}
.edge196_LoginBox {
  background: transparent linear-gradient(187deg, #f05139 0, #f08639 100%);
  border-radius: 19px;
  -webkit-border-radius: 19px;
  -moz-border-radius: 19px;
  width: 495px;
  height: auto;
  padding: 35px 30px 30px;
  position: fixed;
  top: 0;
  right: 50px;
  z-index: 6;
  animation: slideDown 0.3s forwards;
}
@keyframes slideDown {
  from {
    top: 0;
  }
  to {
    top: 50px;
  }
}
.edge196_LoginBox input {
  font-size: 12px;
  color: #fff;
  font-family: muliregular;
  border: none;
  background: 0 0;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.edge196_LoginBox input:-webkit-autofill,
.edge196_LoginBox input:-webkit-autofill:active,
.edge196_LoginBox input:-webkit-autofill:focus,
.edge196_LoginBox input:-webkit-autofill:hover {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}
.edge196_LoginBox input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.edge196_LoginBox input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.edge196_LoginBox input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.edge196_LoginBox input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.edge196_showpasswordbtn {
  background: 0 0;
  border: none;
  position: absolute;
  top: 0;
  right: 15px;
}
.edge196_forgotLink {
  padding: 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 10px;
  color: #fff;
  font-family: muliregular;
}
.edge196_forgotLink button {
  font-family: mulibold;
  background: 0 0;
  border: none;
  padding: 0 0 0 5px;
}
.team-m-deg {
  width: 155px;
  margin: 0 auto;
}
.width132 {
  width: 132px;
}
@media screen and (max-width: 767px) {
  .edge_accountno {
    text-align: left;
  }
  iframe.partnerForm {
    height: 1000px !important;
    margin-left: 0;
  }
  iframe.ambassadorForm {
    height: 1250px !important;
  }
  iframe.workwithusForm {
    height: 940px !important;
  }
  iframe.contactusForm {
    height: 790px !important;
  }
  .o_web .max_prnt .form-control {
    padding-left: 15px;
    font-size: 18px !important;
  }
  .o_web .form-control,
  .o_web .input-with-dropdown input {
    font-size: 18px;
    color: #0f1740 !important;
  }
  .n_web footer .copy-right p {
    text-align: center;
  }
  .some-links.w-100-767.order-1-767 {
    display: flex;
    justify-content: center;
  }
  .o_web nav.side-menu .menu {
    right: -33px;
  }
  .o_web .header {
    height: 70px;
    padding: 26px 0;
  }
  .o_web .header .tabs-container {
    top: 70px;
  }
  .edge196_universal-swap {
    align-items: flex-start !important;
  }
  .edge196_mobile_top {
    align-items: flex-start !important;
    overflow: visible !important;
  }
  .edge196_mobile_top_Auction {
    align-items: flex-start !important;
    margin-top: 0 !important;
    overflow: visible !important;
    padding: 0 !important;
  }
  .o_web {
    padding-top: 160px;
  }
  .o_web .edge196_mobile_top_Auction .def_wt_bx {
    margin: 0;
  }
  .o_web .edge196_mobile_top .mx-400,
  .o_web .edge196_mobile_top_Auction .mx-400 {
    margin-bottom: 10px;
  }
  .o_web .settings-card {
    margin-top: 0;
  }
}
.width123 {
  width: 123px;
}
.main-team-section > .container {
  z-index: 2 !important;
  position: relative;
}
.activity-table thead td {
  padding-bottom: 1rem;
  color: #000;
}
.activity-table tbody td {
  padding: 0 0.75rem 0.85rem 0;
}
.z-index-0 {
  z-index: 0;
}
.z-index-0:after,
.z-index-0:before {
  z-index: 0;
}
.event-filler {
  height: 30px;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media screen and (max-width: 750px) {
  .n_web .asia-blc.eco-system-se .for-shape,
  .n_web .eco-system-se.our-team .for-shape {
    display: none;
  }
  .n_web .apply-accelerated .fr-shapre::before,
  .n_web .eco-system-se .for-shape::after,
  .n_web .eco-system-se .for-shape::before,
  .n_web .eco-system-se.partners-shapes .for-shape::before,
  .n_web .edge196_asiablockchain.eco-system-se .for-shape::after,
  .n_web .edge196_asiablockchain.eco-system-se .for-shape::before,
  .n_web .main-hop.eco-system-se .for-shape::after,
  .n_web .main-hop.eco-system-se .for-shape::before,
  .n_web .three-m-s.on-m-hmp .for-shape::before {
    display: none;
  }
}
@media screen and (max-width: 1280px) {
  .walletconnect-modal__base {
    padding: 10px 10px 22px !important;
    transform: translateY(0) !important;
    -webkit-transform: translateY(0) !important;
    -moz-transform: translateY(0) !important;
    top: 10% !important;
    max-width: 300px !important;
  }
  .walletconnect-qrcode__text {
    font-size: 14px !important;
  }
  .walletconnect-modal__footer a {
    font-size: 13px !important;
  }
}
.edge196_TOKENOMICSsection {
  position: relative;
  padding: 100px 0 100px;
}
.edge196_TOKENOMICSsection::after {
  content: "";
  width: calc(50% - -30px);
  height: 100%;
  display: block;
  background: url(/static/media/edge196_img2.a4becf2b.jpg) no-repeat scroll center
    center/cover;
  position: absolute;
  top: 0;
  right: 0;
}
.edge196_TOKENOMICSsection::before {
  content: "";
  width: calc(50% - 30px);
  height: 100%;
  display: block;
  background: url(/static/media/edge196_img3.e9439bdb.jpg) no-repeat scroll center
    center/cover;
  position: absolute;
  top: 0;
  left: 0;
}
.edge196_TOKENOMICSsectionBefore {
  position: absolute;
  background: #f0f0f0;
  top: 0;
  right: 0;
  display: block;
  width: 65%;
  height: 65px;
  z-index: 1;
}
.edge196_TOKENOMICSsectionBefore::before {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-top: 0 solid transparent;
  border-right: 50px solid #f0f0f0;
  border-bottom: 65px solid transparent;
  position: absolute;
  bottom: 0;
  left: -50px;
}
.f36-BC-500 {
  font-size: 36px;
  line-height: 36px;
  font-family: "Barlow Condensed";
  font-weight: 500;
}
.f66-BC-500 {
  font-size: 66px;
  line-height: 52px;
  font-family: "Barlow Condensed";
  font-weight: 500;
}
.f80-BC-500 {
  font-size: 80px;
  line-height: 62px;
  font-family: "Barlow Condensed";
  font-weight: 500;
}
.f92-BC-500 {
  font-size: 92px;
  line-height: 82px;
  font-family: "Barlow Condensed";
  font-weight: 500;
}
.f8-muli-400 {
  font-size: 12px;
  line-height: 16px;
  font-family: muliregular;
}
.f10-muli-400 {
  font-size: 10px;
  line-height: 16px;
  font-family: muliregular;
}
.f10-muli-700 {
  font-size: 10px;
  line-height: 16px;
  font-family: mulibold;
}
.f12-muli-400 {
  font-size: 12px;
  line-height: 30px;
  font-family: muliregular;
}
.f12-muli-700 {
  font-size: 12px;
  line-height: 15px;
  font-family: mulibold;
}
.f14-muli-400 {
  font-size: 14px !important;
  line-height: 30px !important;
  font-family: muliregular;
}
.f14-muli-700 {
  font-size: 14px !important;
  line-height: 18px !important;
  font-family: mulibold;
}
.f18-muli-400 {
  font-size: 18px !important;
  line-height: 23px !important;
  font-family: muliregular;
}
.f20-muli-700 {
  font-size: 20px;
  line-height: 30px;
  font-family: mulibold;
}
.f24-muli-700 {
  font-size: 24px !important;
  line-height: 30px !important;
  font-family: mulibold;
}
.letterspacing-19 {
  letter-spacing: 19px;
}
.letterspacing-4 {
  letter-spacing: 4px;
}
.letterspacing-19 {
  letter-spacing: 19px;
}
.f20-muli-400 {
  font-size: 20px;
  line-height: 40px;
  font-family: muliregular;
}
.edge196_borderdashed {
  border-bottom: 1px dashed #dbdbdb;
}
.z-index {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1199px) {
  .f92-BC-500 {
    font-size: 52px !important;
    line-height: 42px !important;
  }
  .f20-muli-400 {
    font-size: 16px;
    line-height: 28px;
  }
  .f80-BC-500 {
    font-size: 50px;
    line-height: 35px;
  }
  .f24-muli-700 {
    font-size: 20px;
    line-height: 28px;
  }
  .f66-BC-500 {
    font-size: 46px;
    line-height: 36px;
  }
  .f36-BC-500 {
    font-size: 30px;
    line-height: 26px;
  }
  .letterspacing-19 {
    letter-spacing: 8px;
  }
  .letterspacing-4 {
    letter-spacing: 3px;
  }
}
@media screen and (max-width: 991px) {
  .edge196_TOKENOMICSsectionBefore {
    height: 35px;
  }
  .edge196_TOKENOMICSsection {
    padding: 65px 0;
  }
}
@media screen and (max-width: 767px) {
  .edge196_TOKENOMICSsection::after,
  .edge196_TOKENOMICSsection::before {
    content: none;
  }
  .edge196_TOKENOMICSsection .col-md-6:first-child {
    padding: 30px 15px;
  }
  .edge196_TOKENOMICSsection .col-md-6:first-child::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: url(/static/media/edge196_img3.e9439bdb.jpg) no-repeat scroll center
      center/cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  .edge196_TOKENOMICSsection .col-md-6:last-child {
    padding: 30px 15px;
  }
  .edge196_TOKENOMICSsection .col-md-6:last-child::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: url(/static/media/edge196_img2.a4becf2b.jpg) no-repeat scroll center
      center/cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  .edge196_TOKENOMICSsection {
    padding: 0;
  }
  .edge196_TOKENOMICSsectionBefore {
    display: none;
  }
}

.paypal-checkout-details {
  margin-bottom: 2rem;
}

.paypal-checkout-details h4 {
  margin-bottom: 2rem;
  text-align: center;
}

.paypal-checkout-grid {
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
}

.paypal-checkout-grid > div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 15px;
}
.notFoundWrapper {
  text-align: center;
  padding: 20px;
}
.UnderConstruction-container{
  height: 100vh;
    margin-top: 32vh !important;
}
.UnderConstruction-title, .UnderConstruction-description{
  font-weight: 600;
}
.UnderConstruction-logo {
  width: 240px !important;
}
.viewDataWrapper {
  width: 100%;
}
.viewDataWrapper table {
  width: 80%;
  margin: auto;
  color: #fff;
  margin-bottom: 30px;
}
.viewDataWrapper table tr {
  border-bottom: 1px solid #8080807a;
}
.viewDataWrapper table tr td,
.viewDataWrapper table tr th {
  padding: 10px;
}
.viewDataWrapper table img {
  width: 60px;
  border-radius: 100px;
  background: #fff;
}
.viewDataWrapper table button {
  background: #f06a39;
  border: none;
  padding: 2px 11px;
}
.viewDataWrapper h2 {
  color: #fff;
  margin: 61px 20px 30px 140px;
}
.viewDataWrapper h2 a,
.add-new {
  font-size: 18px;
  color: #fff;
  border: 1px solid;
  padding: 7px 19px;
  float: right;
  margin-right: 9%;
}
.addteam {
  width: 100%;
}
.formWrapper {
  width: 50%;
  margin: auto;
}
.formWrapper h2 {
  text-align: center;
  color: #fff;
  margin: 30px 0px;
}
.formWrapper input,
.formWrapper select {
  width: 100%;
  margin: 5px;
  height: 39px;
  border: none;
  border-radius: 2px;
  padding: 8px;
}
.formWrapper label {
  width: 100%;
  color: #fff;
  text-align: left;
  padding: 8px;
}
.formWrapper button {
  background: #f44336;
  width: 187px;
  padding: 7px;
  font-size: 15px;
  color: #fff;
  border: none;
  border-radius: 3px;
}
.formWrapper button:hover {
  box-shadow: 4px 4px 14px 0px grey;
}

.notFoundWrapper img {
  width: 30%;
}
.notFoundWrapper .info h3 {
  color: #fff !important;
}
.ambassadorWrapper .ff-muli-r {
  font-size: 16px !important;
  font-weight: bold !important;
}
.team-select {
  border: none;
  border-bottom: 1px solid black;
  
}
body{
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll !important;
}

.edge-contact-wrap {
  padding: 75px;
  background: rgb(0 0 0 / 45%);
  border-radius: 15px;
  width: 100%;
}

.edge-contact-wrap .form-label {
  font-size: 18px;
  color: #FFF;
}

.edge-contact-wrap .btn-lg {
  padding: 10px 50px;
  border-radius: 10px;
}

.edge-contact-wrap .form-control {
  padding: 0px 10px;
  padding-bottom: 0px;
  border-radius: 4px;
}

.project-slider {
  padding: 85px 100px;
  background: #F0F0F0;
}

.project-slider .slick-prev:before, .project-slider .slick-next:before {
  display: none;
}

.project-slider .section-title {
  font-size: 65px;
  line-height: 75px;
  font-weight: 500;
  margin-bottom: 25px;
  text-align: center;
  font-family: Barlow;
}

.project-box-wrap {
  width: 100%;
  height: 180px;
  position: relative;
  background: #FFF;
  margin-top: 25px;
  overflow: hidden;
}

.project-box-wrap:hover .project-about {
  top: -25px;
}

.slide-wrap {
  padding: 0 10px;
  box-shadow: 5px 5px 45px 0px rgba(0,0,0,0.06);
}

.project-logo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.project-logo img {
  height: 60px;
}

.project-about p.project-category {
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
}

.project-about p.project-desc {
  height: calc(100% - 25px);
  display: flex;
  align-items: center;
}

.project-about {
  width: 100%;
  height: calc(100% + 25px);
  position: absolute;
  z-index: 2;
  top: 155px;
  left: 0;
  background: rgb(240,81,57);
  background: linear-gradient(90deg, rgba(240,81,57,0.95) 0%, rgba(240,134,57,0.95) 100%);
  padding: 5px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  text-align: center;
  transition: all .3s cubic-bezier(0.23, 1, 0.320, 1);
}

.project-about p {
  font-size: 21px;
  line-height: 28px;
  font-weight: 700;
  color: #FFF;
}

.leftMenuWrapper{
  position: absolute;
  top: 104px;
  text-align: left;
}
.leftMenuWrapper li{
  list-style: none;
  font-size: 21px;
  color: #fff;
  border-bottom: 1px solid;
  cursor: pointer;
}
.leftMenuWrapper li.active{
  color: #f06c39;
}
.connect-now{
  text-align: center;
  background: #0e1940;
  color: #fff;
  padding: 15px;
}

.connect-now span{
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
}
.projectModal .modal-content{
    width: 350px;
    border: none;
}
.qi-editor{
  height: 133px;
}
.newsWrap .modal-content{
  width: 650px;
  border: none;
}
.modal-header{
border: none;
}
.became-partner{
  margin-bottom: 56px !important;
}

.bottom-logo-row {
  display: grid;
  grid-template-columns: 37% 61%;
  grid-column-gap: 27px;
  column-gap: 27px;
  margin-top: 25px;
  justify-content: center;
}

.logo-box-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  column-gap: 30px;
}

@media (max-width: 1600px) {
  .project-logo img {
    height: 45px;
  }
}

@media (max-width: 1440px) {
  .qr-title {
    font-size: 16px;
  }
}

@media (max-width: 1366px) {
  .slide-wrap {
    padding: 0 7.5px;
  }
}

@media (max-width: 1024px) {
  .edge-contact-wrap {
    padding: 55px;
  }
  .project-logo img {
    height: auto;
    width: 80%;
  }
  .project-slider .section-title {
    font-size: 42px;
    line-height: 65px;
  }
  .project-slider {
    padding: 55px 50px 65px;
  }
  .project-about p {
    font-size: 14px;
    line-height: 20px;
  }
  .bottom-logo-row {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 600px) {
  .edge-contact-wrap {
    padding: 30px;
  }

  .project-logo img {
    height: 50px;
    width: auto;
  }
  .project-slider .section-title {
    font-size: 35px;
    line-height: 56px;
  }

  .logo-box-group {
    grid-template-columns: 1fr;
    grid-row-gap: 25px;
    row-gap: 25px;
  }
}
.topMargin{
  margin-top: 20px !important;
  color: #66667e !important;
}

.cookiepara{
  margin-top: 7px !important;
}
.bold{
  font-weight: 600;
}

.listposition li{
  list-style: disc !important;
}

.circlelist li {
  list-style: circle !important;
}
.investWrap{
  position: relative;
}
.investWrap a, .investWrap a:hover{
  width: 380px;
  margin: auto;
  padding: 15px 0px;
  color: #fff;
  font-size: 16px;
  background-image: linear-gradient(#f05e39, #f07f39);
  border-radius: 11px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: -20px;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .investWrap a{
  width: 75%;
  }
}
.newsWrapper .col-md-4{
  margin-top: 30px;
}
.newsWrapper img{
  width: 100%;
}
.newsWrapper .title{
  font-weight: 700;
  color: #000;
}
.newsWrapper hr{
  margin: 10px 0px;
}
.hidden{
  display: none;
}
.ql-editor{
  min-height: 150px;
}

.footerAddress{
  /* border: 1px solid red; */
  margin-top: 2%;
  
}

@media (max-width: 770px) {
  .footerAddress {
      margin-top: 6%;
    }
}
@media (max-width: 650px) {
  .footerAddress {
      margin-top: 30%;
    }
}
@media (max-width: 430px) {
  .footerAddress {
      margin-top: 28%;
    }
}
@media (max-width: 390px) {
  .footerAddress {
      margin-top:42%;
    }
}

.countrys {
  border-radius: 10px !important;
}

/* @media screen and (max-width: 768px) {
  .selectTag{
  
    align-items: center;
    width: 100%;
  }
} */

.filterParent{
  width: 75%;
  /* border: 1px solid red; */
}

@media screen and (min-width: 1025px) {
  .filterParent{
    width: 60%;
  }
  .team-select{
    width: 50%;
  }
}
@media screen and (max-width: 1024px) {
  .filterParent{
    width: 100%;
  }
  .team-select{
    width: 50%;
  }
}
@media screen and (max-width: 712px) {
  .filterParent{
    flex-direction: column;
  }
  .team-select{
    width: 100%;
  }
}


